import { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

import { generateNotification } from "sfm-component-library";
import { UserContext } from "../../pages/App";
import { Page, useAxios } from "../AxiosUtil";
import {
  loadAllFilledProtocols,
  loadAllProtocolsForCompanyAndGroups,
  loadProtocol,
} from "./Protocol.axios";
import { Protocol } from "./Protocol.types";

/**
 * SWR Hook - to fetch all the latest EnergyBaords
 * @returns a list of {@link EnergyBoard} when successful, else returns an empty list
 */
export const useProtocol = (
  originId?: string
): {
  Protocols: Protocol[];
  isLoading: boolean;
  isError: any;
  mutate(Protocols?: Protocol[]): void;
  deleteFilled(idToDelete: string): void;
  size?: number;
  setSize?: (size: number) => void;
  hasMore?: boolean;
  fetch: (id: string) => Promise<Protocol | null>;
} => {
  const [hasMore, sethasMore] = useState<boolean>(true);
  const { user } = useContext(UserContext);
  const { axios } = useAxios();
  const { data, error, mutate } = useSWR(
    !!axios && user && !originId ? `/data/protocol/company/group/` : null,
    () =>
      loadAllProtocolsForCompanyAndGroups(axios!, user!.companyId!, user!.id!),
    {
      fallbackData: [],
    }
  );

  /**
   * Helper to load protocol and generate notification if needed
   * @param id id to load
   * @returns loaded protocoll
   */
  const fetchProtocol = (id: string): Promise<Protocol | null> => {
    return loadProtocol(axios, id).then((protocol) => {
      if (protocol) return protocol;
      else {
        generateNotification("Protokoll konnte nicht geladen werden", "danger");
        return null;
      }
    });
  };

  /**
   * SWR helper to get key for paginated loading of energy specifications
   * @param pageIndex the current page index
   * @param previousPageData the previous page data
   * @returns the key for the current page
   */
  const getKey = (pageIndex: number, previousPageData: Page<Protocol[]>) => {
    if (
      (previousPageData && previousPageData.size === 0) ||
      !axios ||
      !originId ||
      !user ||
      !user.id ||
      !user.companyId
    ) {
      return null;
    } // reached the end
    // reached the end
    return `?page=${pageIndex}&size=1000`; // SWR key
  };
  const {
    data: filledOutProtocols,
    size,
    setSize,
    mutate: mutateFilled,
  } = useSWRInfinite(getKey, (paginationUrl) =>
    loadAllFilledProtocols(
      axios,
      paginationUrl,
      originId || "",
      user!.id!,
      user!.companyId
    )
  );
  /**
   * Check if all protocols are loaded, if so set hasMore to false
   */
  useEffect(() => {
    if (!filledOutProtocols || filledOutProtocols.length <= 0) return;
    const page: Page<Protocol[]> = filledOutProtocols[0];
    const loadedAmountElements = filledOutProtocols.reduce(
      (acc, page) => acc + page.content.length,
      0
    );

    if (loadedAmountElements === page.totalElements) sethasMore(false);
  }, [filledOutProtocols]);

  return {
    Protocols: originId
      ? filledOutProtocols?.flatMap((page) => page.content) || []
      : data || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
    deleteFilled: (idToDelete: string) =>
      mutateFilled(() => {
        if (!filledOutProtocols) return;
        let update = [...filledOutProtocols];
        update.forEach((page) => {
          page.content = page.content.filter(
            (protocol) => protocol.id !== idToDelete
          );
        });
        return update;
      }),
    size: size,
    setSize: setSize,
    hasMore: hasMore,
    fetch: fetchProtocol,
  };
};
