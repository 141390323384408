import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ButtonComponentProps, PopupComponent } from "sfm-component-library";

interface DeletePopUpProps {
  isOpen: boolean;
  toggleOpen(isOpen: boolean): void;
  onDelete?(): void;
  deleteIsLoading?: boolean;
  content: JSX.Element | JSX.Element[];
  customButtons?: ButtonComponentProps[];
  title?: JSX.Element | string;
  className?: string;
}

const DeletePopUp: FC<DeletePopUpProps> = ({
  isOpen,
  toggleOpen,
  onDelete,
  deleteIsLoading = false,
  content,
  customButtons,
  title,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <PopupComponent
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      size="small"
      title={title}
      className={className}
      footerButtons={
        customButtons || [
          {
            isLoading: deleteIsLoading,
            title: t("general.buttons.cancel"),
            onClick: () => toggleOpen(false),
            borderColor: "black",
            className: "color-white",
          },
          {
            isLoading: deleteIsLoading,
            title: t("general.buttons.delete"),
            borderColor: "#F86B6B",
            onClick: () => onDelete?.(),
          },
        ]
      }
    >
      {content}
    </PopupComponent>
  );
};

export default DeletePopUp;
