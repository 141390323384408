import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ButtonComponent,
  LayoutComponent,
  LoaderComponent,
  TableComponent,
} from "sfm-component-library";
import { RowEntry } from "sfm-component-library/build/TableComponent/TableComponent.types";
import { useAxios } from "../utils/AxiosUtil";
import { NavigationConfiguration } from "../utils/navigation/NavigationConfiguration";
import { PageType } from "../utils/navigation/NavigationConfiguration.types";
import {
  Notification,
  NotificationAction,
  NotificationType,
} from "../utils/notification/Notification.types";
import { markNotificationsAsSeen } from "../utils/notification/Notification.utils";
import { useNotification } from "../utils/notification/useNotification";
import { ShopfloorBoardConfiguration } from "../utils/sfboard/SfBoard.types";
import { useSimpleShopfloorBoardConfiguration } from "../utils/sfboard/useSimpleShopfloorBoardConfiguration";
import { ReactComponent as CloseIcon } from "./../assets/icons/close.svg";
import { ReactComponent as MarkAsReadIcon } from "./../assets/icons/markAsRead.svg";
import "./../styles/NotificationPageStyles.scss";
import { UserContext } from "./App";
interface NotificationProps {}

const NotificationPage: FC<NotificationProps> = () => {
  const { t } = useTranslation();
  const {
    notifications,
    isLoading,
    isError,
    mutate: mutateNofications,
  } = useNotification();
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { shopfloorBoardConfigurations: loadedConfigs } =
    useSimpleShopfloorBoardConfiguration();
  /**
   * maps list of {@link Notification} to a {@link RowEntry} object for display
   * @param notifications
   * @returns a list of {@link RowEntry} which can be empty
   */
  const mapNotificationsToRowEntry = (
    notifications: Notification[]
  ): RowEntry[] =>
    notifications
      .sort((notificationA, notificationB) => {
        let valueA: number = notificationA.seenByUserIds.includes(
          user?.id || ""
        )
          ? 1
          : 0;
        let valueB: number = notificationB.seenByUserIds.includes(
          user?.id || ""
        )
          ? 1
          : 0;
        //sort seen to bottom, else descending by date
        if (valueA !== valueB) return valueA - valueB;
        else
          return (
            new Date(notificationB.createdAt).getTime() -
            new Date(notificationA.createdAt).getTime()
          );
      })
      .map((notification) => {
        let backgroundColor: string | undefined = undefined;

        switch (notification.action) {
          case NotificationAction.FINISHED:
            backgroundColor = "#fcdf00";
            break;
          case NotificationAction.DEADLINE_MISSED:
            backgroundColor = "#ff4a4a";
            break;
          default:
            break;
        }
        const foundBoard: ShopfloorBoardConfiguration | undefined =
          loadedConfigs.find((board) => board.id === notification.boardId);
        return {
          id: notification.id!,
          content: [
            <div className={"notification__card"}>
              <div className={"notification__card__header"}>
                <h1>
                  {t(
                    `notifications.${notification.notificationType}.title.${notification.action}`,
                    {
                      boardName: loadedConfigs.find(
                        (board) => board.id === notification.boardId
                      )?.name,
                    }
                  )}
                </h1>
                {!notification.seenByUserIds.includes(user?.id || "") && (
                  <CloseIcon
                    onClick={() => handleMarkNotifications([notification.id!])}
                  />
                )}
              </div>

              <p className={"notification__card__content"}>
                {t(
                  `notifications.${notification.notificationType}.${notification.action}`,
                  {
                    senderName: `${notification.senderFirstName} ${notification.senderLastName}`,
                    contentName: notification.changedContentName,
                    boardName: foundBoard?.name,
                  }
                )}
              </p>
              <p className={"notification__card__timestamp"}>
                {new Date(notification.createdAt).toLocaleString("de-DE", {
                  timeZone: "UTC",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            </div>,
          ],
          backgroundColor: backgroundColor || foundBoard?.color,
          opacity: notification.seenByUserIds.includes(user?.id || "")
            ? 0.6
            : 1,
        };
      });

  /**
   * marks all given notifications as seen. if no notifications given, marks all as seen
   * @param notifications notifcations to mark
   */
  const handleMarkNotifications = (notifications?: string[]): void => {
    if (!axios) return;
    markNotificationsAsSeen(axios, user!.id!, notifications).then(
      (response) => response && mutateNofications([])
    );
  };

  /**
   * Helper to properly redirect for the notification
   * @param type of notification
   * @param action that notification was send for
   * @param boardId for boards (power bi, energy, shopfloor), id of board to load in detail
   */
  const handleRedirectForNotificationType = (
    type: NotificationType,
    action: NotificationAction,
    boardId: string,
    itemId: string
  ): void => {
    if (action === NotificationAction.DELETED) return;
    let redirectUrl: string = "/";
    switch (type) {
      case NotificationType.BOARD:
      case NotificationType.BOARD_CONFIG:
      case NotificationType.TASK:
      case NotificationType.TASK_FILES:
        redirectUrl = "/sfboard/detail";
        break;
      case NotificationType.ENERGY_BOARD:
        redirectUrl = "/energy/detail";
        break;
      case NotificationType.POWER_BI:
        redirectUrl = "/bi/detail";
        break;
      case NotificationType.LOGO:
        redirectUrl = "/admin";
        break;
      case NotificationType.PROTOCOL:
        switch (action) {
          case NotificationAction.NOTIFY:
            redirectUrl = "/protocol/detail";
            break;
          case NotificationAction.CREATED:
          default:
            redirectUrl = "/protocol/filled/" + boardId;
            break;
        }
        break;
    }
    history.push(redirectUrl, {
      configId: boardId,
      itemId: itemId,
    });
  };

  return (
    <LayoutComponent
      title={t("notifications.title")}
      {...NavigationConfiguration(PageType.NOTIFICATION)}
    >
      <div className={"notification-page"}>
        <div className="notification__mark-all">
          <ButtonComponent
            title={
              <div className={"notification__mark-all__button-content"}>
                <p>{t("notifications.markAll.buttonText")}</p>
                <MarkAsReadIcon />
              </div>
            }
            borderColor="black"
            className="color-white"
            onClick={() => handleMarkNotifications()}
          />
        </div>
        {!isLoading && !isError && notifications ? (
          notifications.length === 0 ? (
            <p>{t("notifications.empty")}</p>
          ) : (
            <TableComponent
              listEntries={mapNotificationsToRowEntry(notifications)}
              customTag={[{ flex: 1, color: "black" }]}
              onClick={(notificationId) => {
                handleMarkNotifications([notificationId]);
                const notificationForId: Notification | undefined =
                  notifications.find(
                    (notificationToFind) =>
                      notificationToFind.id === notificationId
                  );
                if (!notificationForId) return;
                handleRedirectForNotificationType(
                  notificationForId.notificationType,
                  notificationForId.action,
                  notificationForId.boardId,
                  notificationForId.taskId
                );
              }}
            />
          )
        ) : (
          <LoaderComponent />
        )}
      </div>
    </LayoutComponent>
  );
};

export default NotificationPage;
