import { FeatureConfiguration } from "../configuration/FeatureConfiguration.types";
import { getRandomColor } from "../GeneralUtils";
import { FeatureModule } from "../license/License.types";
import { User } from "../user/User.types";

export interface PowerBIConfiguration extends FeatureConfiguration {
  name: string;
  color: string;
  boardResponsible: string;
  viewKey: string;
  groups: string[];
  showGroupsOnly: boolean;
}

/**
 * Helper to generate an empty Power Bi Config
 * @param createUser to determine which user created it
 * @returns an empty {@link PowerBIConfiguration}
 */
export const generateNewPowerBIConfig = (
  createUser: User
): PowerBIConfiguration => ({
  name: "",
  boardResponsible: createUser.id!,
  createdBy: createUser.id!,
  lastUpdatedBy: createUser.id!,
  companyId: createUser.companyId,
  createDate: new Date(),
  featureModule: FeatureModule.POWERBI,
  version: 0,
  color: getRandomColor(),
  viewKey: "",
  groups: [],
  showGroupsOnly: false,
});
