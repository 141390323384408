import {
  CountWidget,
  CountWidgetConfig,
} from "../../components/dashboard/widgets/CountWidget";
import {
  EmptyWidget,
  EmptyWidgetConfig,
} from "../../components/dashboard/widgets/EmptyWidget";
import {
  TextWidget,
  TextWidgetConfig,
} from "../../components/dashboard/widgets/TextWidget";
import {
  TimeWidget,
  TimeWidgetConfig,
} from "../../components/dashboard/widgets/TimeWidget";

export interface DashboardConfiguration {
  widgets: BackendWidgetInfo<WidgetType>[];
  updatedAt: Date;
}

/**
 * Widget information for a single widget
 */
export interface WidgetInfo<T extends WidgetType = WidgetType> {
  type: T;
  key: string;
  color: string;
  gridData: ReactGridLayout.Layout; // stringified: ReactGridLayout.Layout;
  options: WidgetOptions<T>;
}

/**
 * Widget Model from the backend. Notice the grid data is a stringified json.
 */
export type BackendWidgetInfo<T extends WidgetType = WidgetType> = Omit<
  WidgetInfo<T>,
  "gridData" | "type"
> & { gridData: string; type: string };

/**
 * Widget options which contains info about the specific widget type
 */
export type WidgetOptions<T extends WidgetType> = {
  [key in T]?: WidgetMapping[T];
};

/**
 * The widget component which is used to represent the widget body (all stuff in the widget)
 * Use this instead of React.FC for better type support
 */
export type WidgetComponent<T extends WidgetType> = React.FC<
  WidgetInfo<T>["options"][T]
>;

/**
 * The widget config component which is used for config options for a specific widget type.
 * Use this instead of React.FC for better type support
 */
export type WidgetConfigComponent<T extends WidgetType> = React.FC<{
  info: Omit<WidgetInfo, "options">;
  options: WidgetInfo<T>["options"][T];
  setOptions(options: WidgetInfo<T>["options"][T]): void;
  mutateOptions(options: Partial<WidgetInfo<T>["options"][T]>): void;
  setInfo(info: Omit<WidgetInfo, "options">): void;
  mutateInfo(options: Partial<Omit<WidgetInfo, "options">>): void;
}>;

// ---- Custom widgets (widgets need to be registered here) ---- //

/**
 * Widget types of all available widgets
 */
export enum WidgetType {
  EMPTY = "empty",
  TEXT = "text",
  COUNT = "count",
  TIME = "time",
}

/**
 * WidgetType to WidgetInfo mapper for automatic types
 * Add the correct widget info to your widget here.
 */
export interface WidgetMapping {
  [WidgetType.EMPTY]: {}; // the empty widget do not have any options to set
  [WidgetType.TEXT]: TextWidgetInfo;
  [WidgetType.COUNT]: CountWidgetInfo;
  [WidgetType.TIME]: TimeWidgetInfo;
}

/**
 * A list of all available widgets to use
 * If a widget is not included here, the widget will not be showed at the sidebar widget selection.
 */
export const availableWidgets: {
  [K in WidgetType]?: {
    body: WidgetComponent<K>;
    config: WidgetConfigComponent<K>;
  };
} = {
  [WidgetType.EMPTY]: { body: EmptyWidget, config: EmptyWidgetConfig },
  [WidgetType.TEXT]: { body: TextWidget, config: TextWidgetConfig },
  [WidgetType.COUNT]: { body: CountWidget, config: CountWidgetConfig },
  [WidgetType.TIME]: { body: TimeWidget, config: TimeWidgetConfig },
};

// ---- Custom widgets info ---- //

/**
 * Widget info of the text widget
 */
export interface TextWidgetInfo {
  text: string;
}

/**
 * Widget info of the count widget
 */
export interface CountWidgetInfo {
  type: "sfboard" | "powerbi" | "user" | "group" | "dataApi" | "task";
}

/**
 * Widget info of the time widget
 */
export interface TimeWidgetInfo {
  format: string;
}
