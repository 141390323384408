import { AxiosInstance } from "axios";
import { GateCountType, GateResult, TextDetection, Vector4 } from "./ML.types";
import { generateDownloadDocument } from "../tasks/TasksUtil";
import { Page } from "../AxiosUtil";

/**
 * POST API to send an image for detection
 * @param axios  - the network instance
 * @param image  - the image to detect
 *
 */
export const detectSubImages = async (
  axios: AxiosInstance,
  image: File
): Promise<Vector4[]> => {
  let uploadFormData = new FormData();
  uploadFormData.append("file", image);
  return axios
    .post("carddetection/api/v1/yolo_v8/predict", uploadFormData, {
      headers: {
        "Content-Type": "multipart/form-data charset=utf-8",
        accept: "application/json",
      },
    })
    .then((res) => res.data.boxes_xyxy)
    .catch((err) => console.log(err));
};

/**
 * API method to send image to detect texts
 * @param axios  - the network instance
 * @param image  - the image to detect
 * @returns    - the text detection
 */
export const detectText = async (
  axios: AxiosInstance,
  image: File
): Promise<TextDetection> => {
  let uploadFormData = new FormData();
  uploadFormData.append("file", image);
  return axios
    .post("textdetection/api/v1/donut/predict", uploadFormData, {
      headers: {
        "Content-Type": "multipart/form-data charset=utf-8",
        accept: "application/json",
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

/**
 * NETWORK METHOD - to fetch all results by company id
 *
 * @param axios network instance
 * @param companyId id to load data for
 * @returns loaded results or an empty array on error
 */
export const getAllGateResults = (
  axios: AxiosInstance,
  companyId: string
): Promise<GateResult[]> => {
  return axios
    .get("data/gate/overview/", { params: { companyId: companyId } })
    .then((data) => data.data)
    .catch(() => {
      console.error("could not fetch data for gate result overview");
      return [];
    });
};

/**
 * NETWORK METHOD - fetches all gate results by gate name
 * @param axios network instance
 * @param companyId id to load data for
 * @param gateName  id which gate name is to use
 * @returns all found gate results or empty array
 */
export const getAllGateResultsForGateName = (
  axios: AxiosInstance,
  companyId: string,
  gateName: string,
  paginationUrl?: string
): Promise<Page<GateResult[]>> => {
  return axios
    .get(`data/gate/all/${paginationUrl || ""}`, {
      params: { companyId: companyId, gateName: gateName },
    })
    .then((data) => data.data)
    .catch(() => {
      console.error("could not fetch data for gate result all by gate name");
      return [];
    });
};

/**
 * API method to load all gate counts for a company and gatename
 * @param axios   network instance
 * @param companyId  id to load data for
 * @param gateName  id which gate name is to use
 * @returns all found gate counts
 */
export const getAllGateCountsForGateName = (
  axios: AxiosInstance,
  companyId: string,
  gateName: string
): Promise<GateCountType> => {
  return axios
    .get("data/gate/all/count/", {
      params: { companyId: companyId, gateName: gateName },
    })
    .then((data) => data.data)
    .catch(() => {
      console.error("could not fetch data for gate counts by gate name");
      return [];
    });
};

/**
 * NETWORK METHOD - deletes a gate result by id
 * @param axios network instance
 * @param gateId  id which gate should be deleted
 * @returns true, when deletion was succesful, false otherwise
 */
export const deleteGateById = (
  axios: AxiosInstance,
  gateId: string
): Promise<boolean> => {
  return axios
    .post("data/gate/delete/", gateId)
    .then((result) => result.status === 200)
    .catch(() => {
      console.error("could not delete gate result");
      return false;
    });
};

/**
 * NETWORK METHOD - fetches a file for a gate result
 * @param axios network instance
 * @param fileName name to load
 * @param origFileName original filename for storage
 */
export const downloadImageFileForGate = async (
  axios: AxiosInstance,
  fileName: string,
  origFileName: string
): Promise<void> => {
  return axios
    .get("/data/gate/file/", {
      params: { fileName: fileName },
      responseType: "blob",
    })
    .then((fileResponse) =>
      generateDownloadDocument(
        new File([fileResponse.data], fileName, { type: "image/*" }),
        origFileName
      )
    )
    .catch((exc) => {
      console.error("Error during download file!", exc);
    });
};
