import { useCallback } from "react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  ButtonComponent,
  LayoutComponent,
  LoaderComponent,
} from "sfm-component-library";
import CenterHeaderButton from "../../components/centerheaderbutton/CenterHeaderButton";
import TaskCreationEdit from "../../components/shopfloorboard/task/TaskCreationEdit";
import "../../styles/ShopfloorBoardStyle.scss";
import { useAxios } from "../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { ShopfloorBoardConfiguration } from "../../utils/sfboard/SfBoard.types";
import { saveTaskForShopfloorBoard } from "../../utils/sfboard/SfBoard.utils";
import { loadShopfloorBoardConfig } from "../../utils/sfboard/SfBoardOverview.utils";
import { Task } from "../../utils/tasks/Tasks.types";
import { postAllFiles } from "../../utils/tasks/TasksUtil";
import { User } from "../../utils/user/User.types";
import { loadAllUsersFromBackendByCompanyId } from "../../utils/user/User.utils";
import { UserContext } from "../App";

interface ShopfloorBoardTaskCreationProps {}

const ShopfloorBoardTaskCreation: React.FC<
  ShopfloorBoardTaskCreationProps
> = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation<{ configId?: string }>();
  const configId: string | undefined = location.state.configId;
  const [isLoading, setLoading] = useState(false);
  const [config, setConfig] = useState<ShopfloorBoardConfiguration>();
  const [users, setUsers] = useState<User[]>();
  const [task, setTask] = useState<Task>();
  const [files, setFiles] = useState<{
    imageFiles: File[];
    documentFiles: File[];
  }>({ documentFiles: [], imageFiles: [] });

  /**
   * Checks for needed config or redirects to overview page
   */
  useEffect(() => {
    if (!configId) history.push("/sfboard");
    // eslint-disable-next-line
  }, [configId]);

  /**
   * Helper to fetch board infomation.
   */
  useEffect(() => {
    if (configId) {
      setLoading(true);
      Promise.all([loadShopfloorBoardConfig(configId, axios)]).then(
        ([_config]) => {
          setConfig(_config);

          Promise.all([
            loadAllUsersFromBackendByCompanyId(_config.companyId, axios),
          ]).then(([_users]) => {
            setUsers(_users);
            setLoading(false);
          });
        }
      );
    }
  }, [axios, configId]);

  /**
   * Save the task and redirects the user back to the shopfloorboard overview if successful
   */
  const saveTask = useCallback(() => {
    if (configId && task && user) {
      setLoading(true);
      saveTaskForShopfloorBoard(task, axios).then((newTask) => {
        if (!newTask || !newTask.id) {
          generateNotificationWithTranslations("warning");
          return;
        }

        postAllFiles(
          files.imageFiles,
          files.documentFiles,
          newTask.id,
          user.id!,
          axios
        ).then(() => {
          generateNotificationWithTranslations("success");
          setLoading(false);
          history.push("/sfboard");
        });
      });
    }
  }, [configId, task, user, axios, files, history]);

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.SHOPFLOORBOARD, false)}
      title={
        <CenterHeaderButton
          title={config ? config.name : ""}
          onClick={() => history.push("/sfboard")}
        />
      }
    >
      {config && !isLoading && users ? (
        <div className="shopfloor-task-creation">
          <div className="shopfloor-task-creation--wrapper">
            <TaskCreationEdit
              users={users}
              setFiles={setFiles}
              setTask={setTask}
              isLoading={isLoading}
              sfConfig={config}
              popup={document.body}
              onSave={saveTask}
            />
            <div className="shopfloor-task-creation--footer">
              <ButtonComponent
                title={t("general.buttons.save")}
                form="task-creation-from-input"
              />
            </div>
          </div>
        </div>
      ) : (
        <LoaderComponent />
      )}
    </LayoutComponent>
  );
};

export default ShopfloorBoardTaskCreation;
