import React, { useContext, useEffect, useState } from "react";
import {
  ButtonComponent,
  CheckboxComponent,
  LayoutComponent,
  LoaderComponent,
  PopupComponent,
  generateNotification,
} from "sfm-component-library";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";

import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { useTranslation } from "react-i18next";
import { Page, useAxios } from "../../utils/AxiosUtil";
import {
  deleteGateById,
  downloadImageFileForGate,
  getAllGateCountsForGateName,
  getAllGateResultsForGateName,
} from "../../utils/ml/ML.axios";
import "../../styles/MLDetailPageStyles.scss";
import { GateResult } from "../../utils/ml/ML.types";
import { UserContext } from "../App";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download_img_1.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron-down.svg";
import Collapsible from "react-collapsible";
import { cutTextByLength } from "../../utils/GeneralUtils";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { UserRole } from "../../utils/user/User.types";

const MLDetailPage: React.FC = () => {
  const location = useLocation<{ gateName: string }>();
  const { user } = useContext(UserContext);
  const [hasMore, sethasMore] = useState<boolean>(true);
  const [gateResults, setGateResults] = useState<GateResult[]>([]);
  const history = useHistory();
  const { t } = useTranslation();
  const { axios } = useAxios();
  const [filterType, setFilterType] = useState<{
    zero: boolean;
    one: boolean;
    two: boolean;
  }>({ one: true, zero: true, two: true });
  const [countType, setCountType] = useState<{
    zero: number;
    one: number;
    two: number;
  }>({ one: 0, zero: 0, two: 0 });
  const [showDeletePopup, toggleShowDeletePopup] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>("");
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [totalAmountElements, setTotalAmountElements] = useState<number>(0);

  /**
   * SWR helper to get key for paginated loading of gate results
   * @param pageIndex the current page index
   * @param previousPageData the previous page data
   * @returns the key for the current page
   */
  const getKey = (pageIndex: number, previousPageData: Page<GateResult[]>) => {
    if (
      (previousPageData && previousPageData.size === 0) ||
      !axios ||
      !user?.companyId ||
      !location.state?.gateName
    )
      return null; // reached the end
    // reached the end
    return `?page=${pageIndex}&size=1000`; // SWR key
  };
  const { data, size, setSize, isValidating }: SWRInfiniteResponse =
    useSWRInfinite(
      getKey,
      (paginationUrl) =>
        getAllGateResultsForGateName(
          axios,
          user!.companyId,
          location.state.gateName,
          paginationUrl
        ),
      {
        revalidateOnFocus: false,
        //revalidating every 5 min only
        refreshInterval: 300000,
      }
    );

  /**
   * this useeffect checks if all needed location data is present
   */
  useEffect(() => {
    if (!axios || !user || !location.state || !location.state?.gateName) {
      history.push("/ml");
      return;
    }
  }, [axios, user, location.state, history]);

  /**
   * if data loaded sets gateresult and count type
   */
  useEffect(() => {
    if (!data || data.length <= 0) return;
    const gateResults: GateResult[] = data?.flatMap((page) => page.content);
    setGateResults(gateResults);
    const page: Page<GateResult[]> = data[0];
    const loadedAmountElements = data.reduce(
      (acc, page) => acc + page.content.length,
      0
    );
    setTotalAmountElements(page.totalElements);
    if (loadedAmountElements === page.totalElements) sethasMore(false);
  }, [data]);

  /**
   * Loads count type data for gate
   */
  useEffect(() => {
    if (!axios || !user || !location.state || !location.state?.gateName) return;
    getAllGateCountsForGateName(
      axios,
      user!.companyId,
      location.state.gateName
    ).then((count) => {
      setCountType({
        zero: count.statusZeroCount,
        one: count.statusOneCount,
        two: count.statusTwoCount,
      });
    });
  }, [axios, user, location.state]);

  /**
   * Helper to get number of violations
   * @param violations data to count
   * @returns number of violation splitting
   */
  const getNumberOfViolations = (violations: string): number => {
    if (!violations) return 0;
    return violations.split("},{").length;
  };

  /**
   * Helper to generate violation Styling
   * @param violations text with all violations (currently company specific)
   * @returns generated violation boxes
   */
  const generateVioloationsElements = (violations: string): JSX.Element[] => {
    if (!violations) return [];

    let result: JSX.Element[] = [];
    const splittedViolations = violations.split("},{");

    splittedViolations.forEach((violation, index) => {
      let storedViolation = violation;
      violation = violation.replace(/{|}+/g, "");
      violation = violation.replace(
        /<(OrderNumberViolationReason|DestinationTypeViolationReason|BussinesRuleViolationType|CardNumberViolationReason|SortNumberViolationReason|DefaultViolationReason|StationViolationReason|DefaultViolationType)[.][a-zA-Z_]*[:]/g,
        ""
      );
      violation = violation.replace(/{|}|<|>+/g, "");
      violation = violation.replace(/'+/g, '"');
      violation = `{${violation}}`;
      try {
        let currentElements: JSX.Element[] = [];
        let convertedJson = JSON.parse(violation);

        let counter = 0;
        for (const key in convertedJson) {
          counter++;
          currentElements.push(
            <div
              className="violation-data-entry"
              key={`violoation-data-${index}-${counter}`}
            >
              <div className="data-key">{key}</div>
              <div className="data-data">{convertedJson[key]}</div>
            </div>
          );
        }
        result.push(
          <div className="violations-entry-wrapper" key={`violation-${index}`}>
            {currentElements}
          </div>
        );
      } catch (err) {
        console.error("violation could not be converted", violation);
        result.push(
          <div className="violations-entry-wrapper">{storedViolation}</div>
        );
      }
    });
    return result;
  };

  /**
   * handles loading state and deletes gateResult in backend, when successful gateResults are updated too
   * @param id
   */
  const deleteGateByIdAndUpdateList = (id: string): void => {
    toggleLoading(true);
    deleteGateById(axios, id).then((success) => {
      if (success) {
        setGateResults((results) =>
          results.filter((localResult) => localResult.id !== id)
        );
        toggleShowDeletePopup(false);
      } else generateNotification(t("ml.deletionFailed"), "warning");
      toggleLoading(false);
    });
  };

  /**
   * Helper to get date and time string for result id
   * @param id of result
   * @returns object containing date and time as string
   */
  const getDateTimeForResultId = (
    id: string
  ): { date: string; time: string } => {
    const result: GateResult | undefined = gateResults.find(
      (res) => res.id === id
    );
    if (!result?.createDate) return { date: "", time: "" };
    else
      return {
        date: new Date(result.createDate!).toLocaleDateString("de-DE"),
        time: new Date(result.createDate!).toLocaleTimeString("de-DE"),
      };
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.ML)}
      title={t("ml.title")}
    >
      <div className="ml-page__wrapper">
        {isValidating ? (
          <LoaderComponent />
        ) : (
          <>
            <PopupComponent
              isOpen={showDeletePopup}
              toggleOpen={toggleShowDeletePopup}
              footerButtons={[
                {
                  title: t("general.buttons.cancel"),
                  borderColor: "#f86b6b",
                  onClick: () => toggleShowDeletePopup(false),
                },
                {
                  title: t("general.buttons.delete"),
                  borderColor: "black",
                  className: "color-white",
                  onClick: () => deleteGateByIdAndUpdateList(idToDelete),
                },
              ]}
            >
              {isLoading ? (
                <LoaderComponent />
              ) : (
                t("ml.deleteResult", {
                  replace: {
                    ...getDateTimeForResultId(idToDelete),
                  },
                })
              )}
            </PopupComponent>
            <div className="whole-result-count">{totalAmountElements}</div>
            <div className="filter-ml-responses">
              <div>
                <CheckboxComponent
                  checked={filterType.zero}
                  onCheck={() =>
                    setFilterType({ ...filterType, zero: !filterType.zero })
                  }
                  value="Erfolgreich"
                />
                <b>({countType.zero})</b>
              </div>
              <div>
                <CheckboxComponent
                  checked={filterType.two}
                  onCheck={() =>
                    setFilterType({ ...filterType, two: !filterType.two })
                  }
                  value="Unbekannter Fehler"
                />
                <b>({countType.two})</b>
              </div>
              <div>
                <CheckboxComponent
                  checked={filterType.one}
                  onCheck={() =>
                    setFilterType({ ...filterType, one: !filterType.one })
                  }
                  value={t("ml.violations")}
                />
                <b>({countType.one})</b>
              </div>
            </div>

            {gateResults
              .filter((a) => {
                switch (a.status) {
                  case 0:
                    return filterType.zero;
                  case 1:
                    return filterType.one;
                  case 2:
                    return filterType.two;
                  default:
                    return true;
                }
              })
              .sort(
                (a, b) =>
                  new Date(b.createDate!).getTime() -
                  new Date(a.createDate!).getTime()
              )
              .map((result, index) => (
                <div
                  key={`result-index-${index}`}
                  className="gate-result-entry-wrapper"
                >
                  {user &&
                    [UserRole.ADMIN, UserRole.PLATFORM_ADMIN].includes(
                      user.role
                    ) && (
                      <DeleteIcon
                        className="delete-result-icon"
                        onClick={() => {
                          toggleShowDeletePopup(true);
                          setIdToDelete(result.id!);
                        }}
                      />
                    )}
                  <div
                    className={[
                      "status-wrapper",
                      `status-${result.status}`,
                    ].join(" ")}
                  >
                    {index + 1}
                  </div>
                  <div className="result-text">
                    <div>
                      {new Date(result.createDate!).toLocaleDateString("de-DE")}{" "}
                      {new Date(result.createDate!).toLocaleTimeString("de-DE")}
                    </div>
                    <div>
                      {t("ml.time")}: {result.duration}s
                    </div>
                    {!!result.violations ? (
                      <div className="collap-wrapper">
                        <Collapsible
                          trigger={
                            <div className="collap-trigger">
                              <div>
                                <b>
                                  {getNumberOfViolations(result.violations)}
                                </b>{" "}
                                {t("ml.violationsTotal")}
                              </div>
                              <div className="chevron-icon">
                                <ChevronDownIcon />
                              </div>
                            </div>
                          }
                        >
                          {generateVioloationsElements(result.violations)}
                        </Collapsible>
                      </div>
                    ) : (
                      <div style={{ flexGrow: 1 }} />
                    )}

                    <div className="image-wrapper">
                      {result.files.map((file) => (
                        <div
                          className="image-wrapper--entry"
                          key={`file-${file.originalFileName}`}
                          onClick={() =>
                            downloadImageFileForGate(
                              axios,
                              file.fileName,
                              file.originalFileName
                            )
                          }
                        >
                          <div className="icon">
                            <DownloadIcon />
                          </div>
                          <div>
                            {cutTextByLength(file.originalFileName, 20, true)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            {hasMore && (
              <ButtonComponent
                title={t("general.buttons.loadMore")}
                onClick={() => setSize?.((size ?? 0) + 1)}
              />
            )}
          </>
        )}
      </div>
    </LayoutComponent>
  );
};

export default MLDetailPage;
