import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  TextInputComponent,
  generateNotification,
} from "sfm-component-library";
import { ReactComponent as RemoveIcon } from "../../assets/icons/delete.svg";
import { convertToDateForInput } from "../../utils/GeneralUtils";
import "./NotificationRotation.styles.scss";
import {
  DayOfWeek,
  Month,
  MonthStartType,
  NotificationRotationProps,
  RotationType,
  TimingRule,
  createNewTimingRule,
} from "./NotificationRotation.types";

const NotificationRotation: React.FC<NotificationRotationProps> = ({
  config,
  setConfig,
  submitConfig,
  userOptions,
}) => {
  const { t } = useTranslation();

  /**
   * update a rule in config
   * @param index of rule to update
   * @param callbackUpdate callback function to do update with
   */
  const updateRule = (
    index: number,
    callbackUpdate: (rule: TimingRule) => TimingRule
  ) => {
    setConfig?.((config) => {
      const updateRules: TimingRule[] = [...config.rules];
      updateRules[index] = callbackUpdate(updateRules[index]);
      return { ...config, rules: updateRules };
    });
  };

  /**
   * Submit method, that verifies config before perfoming given submit method for config
   */
  const submitNotificationRotationConfig = (): void => {
    if (config.userIds.length === 0) {
      generateNotification(
        t("protocol.config.notificationRotationConfigs.noUserSelected"),
        "warning"
      );
      return;
    }
    if (!config.start) {
      generateNotification(
        t("protocol.config.notificationRotationConfigs.noStartSelected"),
        "warning"
      );
      return;
    }
    for (const rule of config.rules) {
      //check if daysOfWeek should be filled and generate notification, if so
      if (
        rule.daysOfWeek.length === 0 &&
        (rule.type === RotationType.WEEKLY ||
          (rule.useRelativeDay &&
            [RotationType.MONTHLY, RotationType.YEARLY].includes(rule.type)))
      ) {
        generateNotification(
          t("protocol.config.notificationRotationConfigs.daysOfWeekMissing"),
          "warning"
        );
        break;
      }
    }
    submitConfig();
  };

  const toggleDayOfWeek = (dayOfWeek: DayOfWeek, index: number): void => {
    updateRule(index, (rule) => {
      let updatedDaysOfWeek = [...rule.daysOfWeek];
      if (rule.daysOfWeek.includes(dayOfWeek)) {
        updatedDaysOfWeek = updatedDaysOfWeek.filter(
          (currentDay) => currentDay !== dayOfWeek
        );
      } else updatedDaysOfWeek.push(dayOfWeek);
      return {
        ...rule,
        daysOfWeek: updatedDaysOfWeek,
      };
    });
  };

  return (
    <form
      className="notification-rotation-component"
      onSubmit={(evt) => {
        evt.preventDefault();
        submitNotificationRotationConfig();
      }}
    >
      <div className="section-wrapper">
        <h3>{t("protocol.config.notificationRotationConfigs.userIds")}</h3>
        <div className="user-tags">
          {config.userIds.length > 0
            ? config.userIds.map((userId) => (
                <div>
                  {userOptions.find((option) => option.value === userId)
                    ?.label || t("protocol.config.userNotFound")}
                  <RemoveIcon
                    onClick={() =>
                      setConfig?.((config) => {
                        let updatedConfig = { ...config };
                        updatedConfig.userIds = updatedConfig.userIds.filter(
                          (currentUserId) => userId !== currentUserId
                        );
                        return updatedConfig;
                      })
                    }
                  />
                </div>
              ))
            : t("protocol.config.notificationRotationConfigs.noUsersSelected")}
        </div>
        <div className="input-wrapper-inline">
          <div>{t("protocol.config.addUser")}</div>
          <DropdownComponent
            placeholder={t("protocol.config.addUser")}
            disabled={!setConfig}
            onChange={(option) =>
              setConfig?.((config) => {
                let updatedConfig = { ...config };
                updatedConfig.userIds.push(option.value);
                return updatedConfig;
              })
            }
            options={userOptions.filter(
              (option) => !config.userIds.includes(option.value)
            )}
            selectedOption={""}
          />
        </div>
      </div>
      <div className="section-wrapper">
        <h3>{t("protocol.config.notificationRotationConfigs.rules")}</h3>
        <div className="input-wrapper-inline">
          <div>{t("protocol.config.notificationRotationConfigs.start")}</div>
          <div>
            <TextInputComponent
              disabled={!setConfig}
              type="date"
              value={convertToDateForInput(config.start)}
              onChange={(value) =>
                setConfig?.((config) => ({
                  ...config,
                  start: new Date(value),
                }))
              }
            />
          </div>
        </div>
        <div className="input-wrapper-inline">
          <div>{t("protocol.config.notificationRotationConfigs.until")}</div>
          <div>
            <TextInputComponent
              disabled={!setConfig}
              type="date"
              value={convertToDateForInput(config.until)}
              onChange={(value) =>
                setConfig?.((config) => ({
                  ...config,
                  until: value ? new Date(value) : undefined,
                }))
              }
            />
          </div>
        </div>
        <div>
          {config.rules.map((rule, index) => (
            <div id={`timing-rule-${index}`} className="rule-listing">
              {index !== 0 && (
                <RemoveIcon
                  className="remove-rule"
                  onClick={() =>
                    setConfig?.((config) => ({
                      ...config,
                      rules: config.rules.filter(
                        (_, ruleIndex) => ruleIndex !== index
                      ),
                    }))
                  }
                />
              )}
              <div className="input-wrapper-inline">
                <div>
                  {t("protocol.config.notificationRotationConfigs.rotation")}
                </div>
                <DropdownComponent
                  disabled={!setConfig}
                  onChange={(option) =>
                    updateRule(index, (rule) => ({
                      ...rule,
                      type: option.value as RotationType,
                      wasLastSent: undefined,
                    }))
                  }
                  options={Object.values(RotationType).map((type) => ({
                    label: t(`enum.rotationType.${type}`),
                    value: type,
                  }))}
                  selectedOption={rule.type}
                />
              </div>

              <div className="input-wrapper-inline">
                <div>
                  {t("protocol.config.notificationRotationConfigs.every")}
                </div>
                <div className="number-text-wrapper">
                  <TextInputComponent
                    disabled={!setConfig}
                    onChange={(value) => {
                      let numericValue: number = parseInt(value);
                      updateRule(index, (rule) => ({
                        ...rule,
                        rotation: numericValue,
                      }));
                    }}
                    type="number"
                    min={1}
                    step={1}
                    value={rule.rotation}
                  />
                  <div>
                    {t(
                      `protocol.config.notificationRotationConfigs.unit.${rule.type}`
                    )}
                  </div>
                </div>
              </div>
              {rule.type !== RotationType.DAILY && (
                <>
                  {rule.type !== RotationType.WEEKLY && (
                    <CheckboxComponent
                      checked={rule.useRelativeDay}
                      value={t(
                        "protocol.config.notificationRotationConfigs.useRelativeDay"
                      )}
                      onCheck={(value) =>
                        updateRule(index, (rule) => ({
                          ...rule,
                          useRelativeDay: value,
                        }))
                      }
                    />
                  )}
                  {rule.useRelativeDay || rule.type === RotationType.WEEKLY ? (
                    <>
                      {[RotationType.MONTHLY, RotationType.YEARLY].includes(
                        rule.type
                      ) && (
                        <div className="input-wrapper-inline">
                          <div>
                            {t(
                              "protocol.config.notificationRotationConfigs.at"
                            )}
                          </div>
                          <DropdownComponent
                            disabled={!setConfig}
                            onChange={(option) =>
                              updateRule(index, (rule) => ({
                                ...rule,
                                monthStartType: option.value as MonthStartType,
                              }))
                            }
                            options={Object.values(MonthStartType).map(
                              (type) => ({
                                label: t(`enum.monthStartType.${type}`),
                                value: type,
                              })
                            )}
                            selectedOption={rule.monthStartType}
                          />
                        </div>
                      )}
                      <div className="input-wrapper-inline">
                        <div>
                          {t(
                            "protocol.config.notificationRotationConfigs.daysOfWeek"
                          )}
                        </div>

                        <div className="week-day-wrapper">
                          {Object.values(DayOfWeek).map((day) => (
                            <div
                              key={`week-array-${day}-${index}`}
                              onClick={() => toggleDayOfWeek(day, index)}
                              className={[
                                "week-day-wrapper--entry",
                                rule.daysOfWeek.includes(day)
                                  ? "active"
                                  : undefined,
                              ].join(" ")}
                            >
                              {t(`notificationrotation.${day}`)}
                            </div>
                          ))}
                        </div>
                      </div>
                      {rule.type === RotationType.YEARLY && (
                        <div className="input-wrapper-inline">
                          <div>
                            {t(
                              "protocol.config.notificationRotationConfigs.of"
                            )}
                          </div>
                          <DropdownComponent
                            selectedOption={rule.month}
                            onChange={(option) =>
                              updateRule(index, (rule) => ({
                                ...rule,
                                month: option.value as Month,
                              }))
                            }
                            options={Object.values(Month).map((month) => ({
                              value: month,
                              label: t(`enum.month.${month}`),
                            }))}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="input-wrapper-inline">
                        <div>
                          {t("protocol.config.notificationRotationConfigs.at")}
                        </div>
                        <div className="number-text-wrapper">
                          <TextInputComponent
                            disabled={!setConfig}
                            onChange={(value) => {
                              let numericValue: number = parseInt(value);
                              if (numericValue > 31) numericValue = 31;
                              updateRule(index, (rule) => ({
                                ...rule,
                                dayOfMonth: numericValue,
                              }));
                            }}
                            value={rule.dayOfMonth.toString()}
                            type="number"
                            min={1}
                            max={31}
                            step={1}
                          />
                          {rule.type === RotationType.YEARLY && (
                            <DropdownComponent
                              selectedOption={rule.month}
                              onChange={(option) =>
                                updateRule(index, (rule) => ({
                                  ...rule,
                                  month: option.value as Month,
                                }))
                              }
                              options={Object.values(Month).map((month) => ({
                                value: month,
                                label: t(`enum.month.${month}`),
                              }))}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        <ButtonComponent
          type="button"
          title={t("protocol.config.notificationRotationConfigs.addTimingRule")}
          onClick={() => {
            setConfig?.((config) => ({
              ...config,
              rules: [...config.rules, createNewTimingRule()],
            }));
          }}
        />
      </div>
      <ButtonComponent
        title={t("protocol.config.notificationRotationConfigs.finishConfig")}
        type="submit"
      />
    </form>
  );
};

export default NotificationRotation;
