import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  LayoutComponent,
  TextInputComponent,
} from "sfm-component-library";
import { useAxios } from "../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../utils/GeneralUtils";
import { Group } from "../utils/group/Group.types";
import { useGroups } from "../utils/group/Group.utils";
import { NavigationConfiguration } from "../utils/navigation/NavigationConfiguration";
import { PageType } from "../utils/navigation/NavigationConfiguration.types";
import { updateUserOnBackend } from "../utils/user/User.utils";
import "./../styles/ProfilePageStyle.scss";
import { UserContext } from "./App";
interface ProfileProps {}

export const ProfilePage: FC<ProfileProps> = () => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const [userToEdit, setUserToEdit] = useState(user);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState<
    { firstInput?: string; secondInput?: string } | undefined
  >();
  const loadedGroups = useGroups() || [];
  const userGroups: Group[] = loadedGroups.filter((group) => {
    return user?.groups.includes(group.id);
  });

  /**
   * sets userToEdit, when user is available
   */
  useEffect(() => {
    if (user) {
      setUserToEdit(user);
    }
  }, [user]);

  /**
   * checks if first and second password input are the same
   */
  useEffect(() => {
    if (
      password?.firstInput &&
      password?.secondInput &&
      password.firstInput === password.secondInput
    ) {
      setUserToEdit((userObj) => ({
        ...userObj!,
        password: password.firstInput,
      }));
    } else {
      setUserToEdit((userObj) => ({ ...userObj!, password: undefined }));
    }
  }, [password]);

  /**
   *handles submit, shows popUp after request
   */
  const handleSubmit = async (): Promise<void> =>
    userToEdit &&
    updateUserOnBackend(userToEdit, axios)
      .then((res) => {
        if (res) {
          generateNotificationWithTranslations("success");
          setUser(userToEdit);
        } else {
          generateNotificationWithTranslations("warning");
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        generateNotificationWithTranslations("warning");
      });

  return (
    <LayoutComponent
      title={t("profile.title")}
      {...NavigationConfiguration(PageType.PROFILE)}
    >
      <div className="profile__wrapper">
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            handleSubmit();
          }}
        >
          <TextInputComponent
            onChange={(newValue) =>
              setUserToEdit({ ...userToEdit!, firstname: newValue })
            }
            value={userToEdit?.firstname || ""}
            label={t("profile.firstname")}
          />
          <TextInputComponent
            onChange={(newValue) =>
              setUserToEdit({ ...userToEdit!, lastname: newValue })
            }
            value={userToEdit?.lastname || ""}
            label={t("profile.lastname")}
          />
          <TextInputComponent
            onChange={(newValue) =>
              setUserToEdit({ ...userToEdit!, mail: newValue })
            }
            value={userToEdit?.mail || ""}
            label={t("profile.mail")}
            type="email"
          />
          <TextInputComponent
            onChange={(newValue) =>
              setUserToEdit({ ...userToEdit!, username: newValue })
            }
            value={userToEdit?.username || ""}
            label={t("profile.username")}
          />
          <TextInputComponent
            onChange={(newValue) =>
              setPassword({ ...password, firstInput: newValue })
            }
            value={password?.firstInput || ""}
            label={t("profile.password")}
            type="password"
          />
          <TextInputComponent
            onChange={(newValue) =>
              setPassword({ ...password, secondInput: newValue })
            }
            value={password?.secondInput || ""}
            label={t("profile.passwordRepeat")}
            type="password"
            borderColor={
              password?.firstInput === password?.secondInput
                ? undefined
                : "#F86B6B"
            }
          />
          <ButtonComponent
            title={t("general.buttons.save")}
            disabled={
              userToEdit === user ||
              password?.firstInput !== password?.secondInput
            }
            isLoading={isLoading}
          />
        </form>
        <div className="profile__wrapper-groups">
          <div>{t("profile.groups")}</div>
          {userGroups.map((group) => (
            <div
              key={`profile__wrapper-group-${group.id}`}
              className="profile__wrapper-groups-group"
            >
              {group.name}
            </div>
          ))}
        </div>
      </div>
    </LayoutComponent>
  );
};
