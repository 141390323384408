import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DropdownComponent, TextInputComponent } from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { loadCountOfAllDataApisByCompany } from "../../../utils/apidata/DataApi.utils";
import { useResource } from "../../../utils/GeneralUtils";
import { countGroupsByCompany } from "../../../utils/group/Group.utils";
import { loadCountOfAllPowerBiCompany } from "../../../utils/powerbi/PowerBIOverview.utils";
import { loadCountOfAllShopfloorboardsByCompany } from "../../../utils/sfboard/SfBoard.utils";
import { loadCountOfTasksbyUserAndCompany } from "../../../utils/tasks/TasksUtil";
import {
  CountWidgetInfo,
  WidgetComponent,
  WidgetConfigComponent,
  WidgetType,
} from "../../../utils/user/Dashboard.types";
import { UserRole } from "../../../utils/user/User.types";
import { loadCountOfUsersByCompany } from "../../../utils/user/User.utils";

export const CountWidget: WidgetComponent<WidgetType.COUNT> = ({ type }) => {
  const { t } = useTranslation();

  /**
   * Hook to load the count of the correct type.
   */
  const count = useResource(
    async (axios, { user }) => {
      switch (type) {
        case "sfboard":
          return loadCountOfAllShopfloorboardsByCompany(
            user.companyId,
            user.id!,
            axios
          );

        case "powerbi":
          return loadCountOfAllPowerBiCompany(user.companyId, user.id!, axios);

        case "dataApi":
          return loadCountOfAllDataApisByCompany(
            user.companyId,
            user.id!,
            axios
          );

        case "user":
          return loadCountOfUsersByCompany(user.companyId, axios);

        case "group":
          return countGroupsByCompany(user.companyId, axios);

        case "task":
          return loadCountOfTasksbyUserAndCompany(
            user.companyId,
            user.id!,
            axios
          );

        default:
          return "?";
      }
    },
    [type]
  );

  return (
    <div className="count-widget">
      <p className="header">
        {t("dashboard.widget.count.header", {
          replace: {
            type: t(`dashboard.widget.count.types.${type}`, {
              defaultValue: "",
            }),
          },
        })}
      </p>
      <p className="count">{count}</p>
    </div>
  );
};

export const CountWidgetConfig: WidgetConfigComponent<WidgetType.COUNT> = ({
  info,
  options,
  mutateInfo,
  mutateOptions,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  /**
   * Calculate the available types to use.
   *
   * A admin has a user and group count, which a non admin user has not.
   */
  const availableTypes = useMemo(() => {
    if (!user) return [];
    return [
      ...["sfboard", "powerbi", "dataApi", "task"],
      ...(user.role === UserRole.ADMIN ? ["user", "group"] : []),
    ];
  }, [user]);

  return (
    <>
      <p>{t("dashboard.edit.color")}</p>
      <TextInputComponent
        type="color"
        value={info.color}
        onChange={(color) => mutateInfo({ color })}
      />
      <p>{t("dashboard.edit.type")}</p>
      <DropdownComponent
        selectedOption={options?.type ?? ""}
        options={availableTypes.map((type) => ({
          value: type,
          label: t(`dashboard.widget.count.types.${type}`, {
            defaultValue: "",
          }),
        }))}
        onChange={({ value }) =>
          mutateOptions({ type: value as CountWidgetInfo["type"] })
        }
        portal={document.body}
      />
    </>
  );
};
