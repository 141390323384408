import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  Option,
  TextInputComponent,
  UploadComponent,
} from "sfm-component-library";
import {
  ExtractValue,
  ProtocolInput,
  ProtocolInputPriority,
  ProtocolInputType,
} from "./Protocol.types";
import { ReactComponent as CameraIcon } from "./../../assets/icons/camera_add.svg";
import { ReactComponent as BarcodeIcon } from "./../../assets/icons/barcode.svg";

import i18n from "../../i18n";
import { FileEntry, FileType } from "../tasks/Tasks.types";
import { uploadFileForProtocol } from "./Protocol.axios";
import { AxiosInstance } from "axios";
import Compressor from "compressorjs";

/**
 * Util to create dropdown options for protocol input priorities
 * @returns  {Option[]} dropdown options
 */
export const createProtocolInputPriorityDropdownOptions = (): Option[] =>
  Object.keys(ProtocolInputPriority).map((key) => ({
    label: i18n.t(`protocol.priority.${key}`),
    value: key,
  }));

/**
 * Util to create dropdown options for protocol input types
 * @returns  {Option[]} dropdown options
 */
export const createProtocolInputTypeDropdownOptions = (): Option[] =>
  Object.keys(ProtocolInputType).map((key) => ({
    label: i18n.t(`protocol.types.${key}`),
    value: key,
  }));

/**
 * Render method the get the correct element for the protocol input
 * @param input  protocol input
 * @param onChange  onChange function
 * @param viewMode  viewMode
 * @param onClick  onClick function
 * @returns  {JSX.Element} element
 */
export const getCorrectElementForProtocolInput = (
  input: ProtocolInput,
  onChange: (value: ExtractValue<ProtocolInput["inputType"]>) => void,
  viewMode: boolean,
  onClick?: (protocolInputId: string) => void
): JSX.Element => {
  switch (input.inputType) {
    case ProtocolInputType.TEXT:
      return (
        <TextInputComponent
          label={input.name}
          type="text"
          value={input.value as ExtractValue<ProtocolInputType.TEXT>}
          onChange={onChange}
          required={input.required}
          disabled={viewMode}
        />
      );
    case ProtocolInputType.NUMBER:
      return (
        <TextInputComponent
          label={input.name}
          type="number"
          value={input.value as ExtractValue<ProtocolInputType.NUMBER>}
          onChange={onChange}
          required={input.required}
          disabled={viewMode}
        />
      );
    case ProtocolInputType.CHECKBOX:
      return (
        <CheckboxComponent
          value={input.name}
          checked={input.value as ExtractValue<ProtocolInputType.CHECKBOX>}
          onCheck={(checked) => onChange(checked)}
          disabled={viewMode}
        />
      );
    case ProtocolInputType.IMAGE:
      return (
        <div className="protocol-detail__image-container">
          <p>{input.name}</p>

          <UploadComponent
            buttonContent={<CameraIcon />}
            files={
              !!input.value
                ? [input.value as ExtractValue<ProtocolInputType.IMAGE>]
                : []
            }
            accept="image/*"
            addFiles={(files) => onChange(files[0])}
            multiple={false}
            removeFile={() => onChange(undefined!)}
            previewImage
            disabled={viewMode}
          />
        </div>
      );
    case ProtocolInputType.DROPDOWN:
      return (
        <DropdownComponent
          required={input.required}
          disabled={viewMode}
          label={input.name}
          options={input.options.map((entry) => ({
            label: entry,
            value: entry,
          }))}
          selectedOption={
            (input.value as ExtractValue<ProtocolInputType.DROPDOWN>) || ""
          }
          onChange={(selectedValue) => onChange(selectedValue.value)}
        />
      );
    case ProtocolInputType.BARCODE:
      return (
        <div className="protocol-detail__barcode-wrapper">
          <TextInputComponent
            value={input.value as ExtractValue<ProtocolInputType.TEXT>}
            onChange={(value) => onChange(value)}
            label={input.name}
            required={input.required}
            disabled={viewMode}
          />
          {!viewMode && (
            <ButtonComponent
              onClick={() => onClick?.(input.id)}
              title={<BarcodeIcon />}
              type="button"
            />
          )}
        </div>
      );
  }
};

/**
 * Prepares a promise for image compression of a protocols image input
 * @param axios  axios instance
 * @param imageInput  image input
 * @param protocolId  id of the protocol
 * @param userId   id of the user
 * @returns {Promise<void>} promise
 */
export const getCompressedImagePromise = (
  axios: AxiosInstance,
  imageInput: ProtocolInput,
  protocolId: string,
  userId: string
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    new Compressor(imageInput.value as File, {
      quality: 0.6,
      async success(result) {
        const fileEntry: FileEntry | undefined = await uploadFileForProtocol(
          new File([result], (imageInput.value as File).name),
          protocolId,
          userId,
          FileType.IMAGE,
          axios
        );

        if (fileEntry) {
          imageInput.fileEntry = fileEntry;
        }

        resolve();
      },
      error(err) {
        reject(err);
      },
    });
  });

/**
 * Helper to download a csv based content to file
 * @param csvData data to store
 */
export const downloadCsv = (csvData: string): void => {
  let generatedCsvFileName = `export_protocol_${new Date().toLocaleDateString()}.csv`;
  const tempDownloadLink: HTMLAnchorElement = document.createElement("a");
  tempDownloadLink.href = `data:text/csv;charset=utf-8,${encodeURI(csvData)}`;
  tempDownloadLink.setAttribute("download", generatedCsvFileName);
  document.body.appendChild(tempDownloadLink);
  tempDownloadLink.click();
  tempDownloadLink.remove();
};
