import { useKeycloak } from "@react-keycloak/web";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { Fetcher } from "swr";

/**
 * Interface for Page for pagination.
 */
export interface Page<T> {
  content: T;
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
}

/**
 * Helper to check if given value is an iso date string
 *
 * @param value any form of value to check
 * @returns true if value is iso date string
 */
export const isIsoDateString = (value: any): boolean => {
  //regex to check for iso date YYYY-MM-DDThh:mm:ss.mmm+hh:mm from firebase
  const isoDateFormat =
    /\d{4}-[01]\d-[0-3]\d(?:T[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d{0,6})?)?$/;
  return value && typeof value === "string" && isoDateFormat.test(value);
};

/**
 * Axios response interceptor to convert data from server to client format.
 *
 * @param response the response from the server
 * @returns converted response
 */
export const handleResponseConversion = <T extends AxiosResponse<unknown>>(
  response: T
): T => {
  // convert data
  response.data = handleResponseData(response.data);

  return response;
};

/**
 * converts server date and returns local date
 *
 * @returns local Date
 */
const getCorrectDateForServerDate = (date: string | Date): Date | null => {
  const givenDate: Date = new Date(date);
  if (isNaN(givenDate.getTime())) return null;

  return givenDate;
};

/**
 * Convert data to a server compatible format (data should go to server).
 *
 * @param data the data should go to the server
 * @returns converted data
 */
export const handleResponseData = (data: unknown): unknown => {
  // check date string
  if (typeof data === "string" && isIsoDateString(data)) {
    return getCorrectDateForServerDate(data);
  }

  // check type which can not be converted
  if (data === null || data === undefined || typeof data !== "object")
    return data;

  // checking all object types
  if (Array.isArray(data)) {
    // arrays mapping
    return data.map((value) => handleResponseData(value));
  } else if (data?.constructor === Object.prototype.constructor) {
    // plain object

    // iterate over all keys and convert them
    for (const [key, value] of Object.entries(data)) {
      (data as Record<string, unknown>)[key] = handleResponseData(value);
    }

    return data;
  } else {
    // something else (unknown)
    return data;
  }
};

/**
 * Creates a prefconfigured axios wrapper that also talks with
 * the keycloak instance.
 */
export const useAxios = (): {
  axios: AxiosInstance;
  fetcher: Fetcher<any>;
} => {
  const { keycloak, initialized } = useKeycloak();
  const baseURL = process.env.REACT_APP_SERVICE_URL;

  return useMemo(() => {
    const instance = axios.create({
      baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${keycloak.token}` : undefined,
        "Content-Type": "application/json;charset=UTF-8",
      },
    });

    // --- Axios Interceptors ---
    instance.interceptors.response.use(handleResponseConversion);

    return {
      axios: instance,
      fetcher: (url: string) => instance.get(url).then((res) => res.data),
    };
  }, [baseURL, initialized, keycloak.token]);
};
