import React, { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  PopupComponent,
  TextInputComponent,
} from "sfm-component-library";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { updateGroupPowerBI, useGroups } from "../../utils/group/Group.utils";
import {
  generateNewPowerBIConfig,
  PowerBIConfiguration,
} from "../../utils/powerbi/PowerBI.types";
import {
  createNewPowerBIConfig,
  deletePowerBIConfig,
  updatePowerBIConfig,
} from "../../utils/powerbi/PowerBIOverview.utils";
import { UserAction } from "../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { GroupSelect } from "../administration/groupselect/GroupSelect";
import DeletePopUp from "../deletepopup/DeletePopUp";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/delete.svg";
import "./EditPowerBIConfigPopUpStyles.scss";
interface EditPowerBIConfigPopUpProps {
  onSubmit(newConfig: PowerBIConfiguration): void;
  onDelete?(): void;
  configToEdit?: PowerBIConfiguration;
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

const EditPowerBIConfigPopUp: FC<EditPowerBIConfigPopUpProps> = ({
  configToEdit,
  setIsOpen,
  onSubmit,
  onDelete,
  isOpen,
}) => {
  const { user } = useContext(UserContext);
  const [config, setConfig] = useState<PowerBIConfiguration | undefined>(
    configToEdit
  );
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [deleteTaskIsLoading, setDeleteTaskIsLoading] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { axios } = useAxios();
  const groups = useGroups();

  /**
   * sets config, when isOpen, user or configToEdit, is changed
   */
  useEffect(() => {
    if (isOpen && user) {
      setConfig({
        ...(configToEdit || generateNewPowerBIConfig(user)),
        lastUpdatedBy: user.id!,
      });
    }
  }, [isOpen, user, configToEdit]);

  /**
   * handles submit, updates PowerBI Config, if configToEdit exist, else creates new Powerbi Config
   */
  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    try {
      let success = false;
      let localConfig = config;

      if (configToEdit) {
        success = await updatePowerBIConfig(config!, axios);
      } else {
        localConfig = await createNewPowerBIConfig(config!, axios);
        success = true;
      }

      if (success) {
        success = await updateGroupPowerBI(
          axios,
          (localConfig as PowerBIConfiguration).id!,
          (localConfig as PowerBIConfiguration).groups
        );
      }

      if (success) {
        generateNotificationWithTranslations("success");
        onSubmit(localConfig as PowerBIConfiguration);
      } else {
        generateNotificationWithTranslations("warning");
      }
    } catch (err) {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * handles delete Config, creates Notification
   */
  const handleDelete = (): void => {
    setDeleteTaskIsLoading(true);
    deletePowerBIConfig(
      { deletionId: configToEdit!.id!, deleterId: user!.id! },
      axios
    ).then((success) => {
      if (success) {
        generateNotificationWithTranslations("success");
        onDelete?.();
      } else {
        generateNotificationWithTranslations("warning");
      }
      setDeleteTaskIsLoading(false);
      setOpenDeletePopUp(false);
    });
  };

  return config ? (
    <>
      <PopupComponent
        isOpen={isOpen}
        toggleOpen={setIsOpen}
        footerButtons={[
          {
            title: t("general.buttons.cancel"),
            onClick: () => {
              setIsOpen(false);
              setConfig(undefined);
            },
            borderColor: "black",
            className: "color-white",
          },
          {
            title: t("general.buttons.save"),
            borderColor: "#A9FAA2",
            type: "submit",
            form: "powerbi-edit-form",
          },
        ]}
      >
        <form id="powerbi-edit-form" onSubmit={handleSubmit}>
          <div className={"powerbi-edit__header"}>
            <h2>
              {t(`powerBI.config.${configToEdit ? "edit" : "create"}.title`)}
            </h2>
            {isUserAllowedToDoThis(UserAction.DELETE_POWERBI_CONFIG, user) &&
              configToEdit && (
                <ButtonComponent
                  title={
                    <div className="powerbi-edit__header__button-content">
                      <p>{t("general.buttons.delete")}</p>
                      <DeleteIcon />
                    </div>
                  }
                  borderColor="#F86B6B"
                  type="button"
                  onClick={() => setOpenDeletePopUp(true)}
                />
              )}
          </div>
          <div>
            <TextInputComponent
              label={t("powerBI.config.edit.boardName")}
              value={config.name || ""}
              onChange={(newValue) => setConfig({ ...config, name: newValue })}
            />
            <TextInputComponent
              label={t("powerBI.config.edit.viewKey")}
              value={config.viewKey || ""}
              onChange={(newValue) =>
                setConfig({ ...config, viewKey: newValue })
              }
            />
            <TextInputComponent
              label={t("powerBI.config.edit.color")}
              value={config.color || ""}
              onChange={(newValue) => setConfig({ ...config, color: newValue })}
              type="color"
            />
            <hr />
            <p>{t("administration.group.title")}</p>
            <GroupSelect
              assignedGroups={config.groups}
              groups={groups ?? []}
              onUpdate={(newGroups) => {
                setConfig({ ...config, groups: newGroups });
              }}
              dropdownPortal={document.body}
            />
            <CheckboxComponent
              checked={config.showGroupsOnly ?? false}
              onCheck={(showGroupsOnly) =>
                setConfig({ ...config, showGroupsOnly })
              }
              value={t("administration.group.showGroupsOnly")}
            />
          </div>
        </form>
      </PopupComponent>
      <DeletePopUp
        isOpen={openDeletePopUp}
        toggleOpen={setOpenDeletePopUp}
        onDelete={handleDelete}
        deleteIsLoading={deleteTaskIsLoading}
        content={<p>{t("powerBI.config.deleteDescription")}</p>}
      />
    </>
  ) : (
    <></>
  );
};

export default EditPowerBIConfigPopUp;
