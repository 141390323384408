import { LayoutComponent } from "sfm-component-library";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";

interface IndustrialTrucksAnalysisPageProps {}

const IndustrialTrucksAnalysisPage: React.FC<IndustrialTrucksAnalysisPageProps> =
  () => {
    return (
      <LayoutComponent
        {...NavigationConfiguration(PageType.INDUSTRIALTRUCKSANALYSIS)}
        title="Industrial Trucks Analysis Page"
      >
        <h1>Industrial Trucks Analysis Page</h1>
      </LayoutComponent>
    );
  };

export default IndustrialTrucksAnalysisPage;
