import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  TextInputComponent,
} from "sfm-component-library";
import { createEmptyApiKey } from "../../../utils/company/Company.types";
import { convertToDateForInput } from "../../../utils/GeneralUtils";
import DeletePopUp from "../../deletepopup/DeletePopUp";
import { ApiKeyEditFormProps } from "./ApiKeyEditForm.types";

export const ApiKeyEditForm: React.FC<ApiKeyEditFormProps> = ({
  apiKey = createEmptyApiKey(),
  setApiKey,
  isLoading,
  onSubmit = () => {},
  onDelete = () => {},
  type = "update",
}) => {
  const { t } = useTranslation();
  const [deletePopup, toggleDeletePopup] = useState<boolean>(false);

  return (
    <>
      <DeletePopUp
        isOpen={deletePopup}
        toggleOpen={() => toggleDeletePopup(false)}
        onDelete={() => {
          onDelete();
          toggleDeletePopup(false);
        }}
        content={
          <>
            {t("administration.apikey.deletePopup", {
              replace: { apikey: apiKey.key },
            })}
          </>
        }
      />

      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          onSubmit();
        }}
      >
        <p>
          {t("administration.apikeyForm.key")} <strong>{apiKey.key}</strong>
        </p>
        <TextInputComponent
          label={t("administration.apikeyForm.displayName")}
          value={apiKey.displayName}
          onChange={(value) => setApiKey({ ...apiKey, displayName: value })}
        />
        <TextInputComponent
          label={t("administration.apikeyForm.description")}
          type="multiline"
          value={apiKey.description}
          onChange={(value) => setApiKey({ ...apiKey, description: value })}
        />
        <CheckboxComponent
          checked={!!apiKey.disabled}
          onCheck={(checked) => setApiKey({ ...apiKey, disabled: checked })}
          value={t("administration.apikeyForm.deactivated")}
        />
        <TextInputComponent
          label={t("administration.apikeyForm.until")}
          type="date"
          value={convertToDateForInput(apiKey.validUntil)}
          onChange={(value) =>
            setApiKey({ ...apiKey, validUntil: new Date(value) })
          }
        />
        <div className="group-edit-form--footer">
          {type === "update" && (
            <ButtonComponent
              type="button"
              title={t("general.buttons.delete")}
              onClick={() => toggleDeletePopup(true)}
              borderColor="#f86b6b"
            />
          )}
          <ButtonComponent
            title={
              type === "update"
                ? t("general.buttons.save")
                : t("general.buttons.create")
            }
            isLoading={isLoading}
            type="submit"
          />
        </div>
      </form>
    </>
  );
};
