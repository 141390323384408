import ReactGridLayout from "react-grid-layout";

import "./Dashboard.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  availableWidgets,
  WidgetConfigComponent,
  WidgetInfo,
  WidgetType,
} from "../../utils/user/Dashboard.types";
import { v4 } from "uuid";
import { Widget } from "./Widget";
import { PopupComponent } from "sfm-component-library";
import { useTranslation } from "react-i18next";
import { useResize } from "../../utils/hooks/ResizeHook";

export interface DashboardGridProps {
  editable: boolean;
  widgets: WidgetInfo[];

  setWidgets(widgets: WidgetInfo[]): void;
  setEditable(editable: boolean): void;
}

export const DashboardGrid: React.FC<DashboardGridProps> = ({
  editable,
  widgets,
  setWidgets,
  setEditable,
}) => {
  const { t } = useTranslation();
  const wrapper = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [isMobile, setMobile] = useState<boolean>(window.innerWidth <= 800);
  const [widgetToEdit, setWidgetToEdit] = useState<WidgetInfo>();

  /**
   * Resize handler: Automatically adjust grid width to wrapper width
   */
  useResize(
    () => {
      if (!wrapper.current) return;
      if (window.innerWidth <= 800) {
        if (!isMobile) setMobile(true);
      } else {
        if (isMobile) setMobile(false);
      }
      setWidth(wrapper.current.clientWidth);
    },
    undefined,
    [wrapper, isMobile, setMobile]
  );

  /**
   * Generate the widgets based on window size (isMobil prop).
   * If
   */
  const generatedWidgets = useMemo(() => {
    let localWidgets = widgets;

    if (isMobile) {
      /**
       * sort the list row based.
       * 1. row all from left to right.
       * 2. row all from left to right.
       * and so on... util all widgets are sorted
       *
       * This is needed because on mobile layout there is only one column.
       */
      localWidgets.sort((a, b) =>
        a.gridData.y === b.gridData.y
          ? a.gridData.x - b.gridData.x
          : a.gridData.y - b.gridData.y
      );

      /**
       * Change grid placement to fit on mobile devices.
       * Additionally append a "m" to the key to seperate widgets between normal and mobile for easier updating.
       */
      localWidgets = localWidgets.map((widget, index) => {
        return {
          ...widget,
          key: widget.key + "m",
          gridData: {
            ...widget.gridData,
            w: 1,
            x: 0,
            y: index,
            i: widget.gridData.i + "m",
          },
        };
      });
    }

    return localWidgets.map((widget) => {
      /**
       * If the widget is text and is static then its the default "welcome" widget which is showing at first time.
       * This widget can be clicked, thats because we need to identify it.
       */
      const isDefaultWidget =
        WidgetType.TEXT && widget.gridData.static && !isMobile;

      return (
        <div
          key={widget.key}
          data-grid={{ ...widget.gridData, i: widget.key }}
          style={{
            backgroundColor: widget.color,
            cursor: isDefaultWidget ? "pointer" : undefined,
          }}
          // widget is clickable if it is the default ("welcome") widget
          onClick={isDefaultWidget ? () => setEditable(true) : undefined}
        >
          <Widget
            info={widget}
            editable={editable && !isMobile}
            onRemove={() =>
              setWidgets(widgets.filter((item) => item.key !== widget.key))
            }
            setWidgetToEdit={setWidgetToEdit}
          />
        </div>
      );
    });
  }, [widgets, isMobile, editable, setWidgets, setEditable]);

  /**
   * Handle layout change and submit converted dashboard widgets.
   *
   * This event is fired when the widgets are drag'n'dropped
   */
  const handleLayoutChange = useCallback(
    (layout: ReactGridLayout.Layout[]) => {
      if (isMobile) return;

      const updatedWidgets = widgets
        .map((widget) => {
          const itemLayout = layout.find((item) => item.i === widget.key);
          if (!itemLayout) return undefined;
          return { ...widget, gridData: itemLayout };
        })
        .filter((item) => item !== undefined) as WidgetInfo[];

      setWidgets(updatedWidgets);
    },
    [widgets, setWidgets, isMobile]
  );

  /**
   * Finds the correct widget config components based on the widget type.
   */
  const WidgetConfig = useMemo(() => {
    if (!widgetToEdit) return undefined;
    if (!Object.keys(availableWidgets).includes(widgetToEdit.type))
      return undefined;
    return availableWidgets[widgetToEdit.type]
      ?.config as WidgetConfigComponent<WidgetType>;
  }, [widgetToEdit]);

  /**
   * Handle saving the widget config (configuration popup)
   */
  const saveWidgetToEdit = useCallback(() => {
    if (!widgetToEdit) return;

    const newWidgets = [
      ...widgets.filter((item) => item.key !== widgetToEdit.key),
      widgetToEdit,
    ];
    setWidgets(newWidgets);
    setWidgetToEdit(undefined);
  }, [widgetToEdit, widgets, setWidgets]);

  /**
   * The handler for the drop event which occurred when drag'n'drop a new widget from the widget store.
   */
  const onWidgetDrop = useCallback(
    (
      layout: ReactGridLayout.Layout[],
      item: ReactGridLayout.Layout,
      evt: DragEvent
    ) => {
      // load the widget type from the dataTransfer of the dropped widget
      const widgetType = evt.dataTransfer?.getData("widget") as
        | WidgetType
        | undefined;
      if (!widgetType) return;
      // create a new unique id for the new widget
      const id = v4();
      setWidgets([
        ...widgets,
        {
          gridData: {
            ...item,
            i: id,
            isDraggable: undefined,
          },
          key: id,
          type: widgetType,
          color: "#F86B6B",
          options: { [widgetType]: {} },
        } as WidgetInfo,
      ]);
    },
    [widgets, setWidgets]
  );

  return (
    <div className="dashboard--grid" ref={wrapper}>
      {width !== 0 && (
        <ReactGridLayout
          width={width}
          rowHeight={150}
          cols={isMobile ? 1 : 5}
          isDraggable={editable && !isMobile}
          isResizable={editable && !isMobile}
          isDroppable={editable && !isMobile}
          autoSize
          compactType="vertical"
          onLayoutChange={handleLayoutChange}
          onDrop={onWidgetDrop}
          droppingItem={{ i: "drop", h: 1, w: 1 }}
        >
          {generatedWidgets}
        </ReactGridLayout>
      )}
      {!!widgetToEdit && !!WidgetConfig && (
        <PopupComponent
          className="dashboard--widget-config"
          isOpen
          toggleOpen={() => setWidgetToEdit(undefined)}
          footerButtons={[
            {
              title: t("general.buttons.cancel"),
              onClick: () => setWidgetToEdit(undefined),
            },
            {
              title: t("general.buttons.modify"),
              onClick: () => saveWidgetToEdit(),
            },
          ]}
          title={t("dashboard.edit.title", {
            replace: { type: t(`dashboard.widgets.${widgetToEdit.type}`) },
          })}
        >
          <WidgetConfig
            info={widgetToEdit}
            options={widgetToEdit.options[widgetToEdit.type]}
            mutateOptions={(options) =>
              setWidgetToEdit({
                ...widgetToEdit,
                options: {
                  [widgetToEdit.type]: {
                    ...widgetToEdit.options[widgetToEdit.type],
                    ...options,
                  },
                },
              })
            }
            setOptions={(options) =>
              setWidgetToEdit({
                ...widgetToEdit,
                options: { [widgetToEdit.type]: options },
              } as WidgetInfo)
            }
            mutateInfo={(info) => setWidgetToEdit({ ...widgetToEdit, ...info })}
            setInfo={(infoWithoutOptions) =>
              setWidgetToEdit({
                ...infoWithoutOptions,
                options: widgetToEdit.options,
              })
            }
          />
        </PopupComponent>
      )}
    </div>
  );
};
