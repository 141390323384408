import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  DropdownComponent,
  generateNotification,
  Option,
  PopupComponent,
  TextInputComponent,
} from "sfm-component-library";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  createEmptyEnergyItem,
  EnergyItem,
  EnergyUploadHelper,
} from "../../utils/energy/Energy.types";
import {
  saveCSVWithEnergyOnServer,
  saveEnergyUploadHelperOnServer,
} from "../../utils/energy/Energy.utils";
import { convertToDateForInput } from "../../utils/GeneralUtils";
import { AddEnergyItemPopupProps } from "./AddEnergyItemPopup.types";
import "./EditEnergyBoardConfigPopUp.styles.scss";

const AddEnergyItemPopup: FC<AddEnergyItemPopupProps> = ({
  open,
  toogleOpen,
  item = createEmptyEnergyItem(""),
  onSuccess = () => {},
  minDate,
  minTime,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { axios } = useAxios();
  const [localItem, setLocalItem] = useState<EnergyItem>(item);
  const [localHour, setLocalHour] = useState<number>(
    item.timestamp.getHours() ?? new Date().getHours()
  );
  const [localDate, setLocalDate] = useState<Date>(
    item.timestamp ?? new Date()
  );

  const [uploadedFile, setUploadedFile] = useState<File>();
  type AddModeType = "manually" | "upload";
  const [addMode, setAddMode] = useState<AddModeType>("manually");
  const [isLoading, toggleLoading] = useState<boolean>(false);

  /**
   * Helper to change add mode of the popup
   * @param option - new option to set
   */
  const handleAddModeChange = (option: Option): void => {
    setAddMode(option.value as AddModeType);
  };

  /**
   * Helper to generate the dropdownoptions for selection of add mode
   * @returns - array of options
   */
  const getDropDownOptions = (): Option[] => {
    return [
      { value: "manually", label: t("energy.add.manually") },
      { value: "upload", label: t("energy.add.upload") },
    ];
  };

  /**
   * Helper to store and return an energy item
   */
  const storeEnergyItem = (): void => {
    if (!user?.company) {
      generateNotification(t("energy.add.missingCompanyId"), "danger");
      return;
    }
    const localTimestamp: Date = new Date(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localHour,
      0 - new Date().getTimezoneOffset(),
      0,
      0
    );
    localItem.timestamp = localTimestamp;
    if (localItem.value === 0) {
      generateNotification(t("energy.add.missingValue"));
      return;
    }
    const localEnergyUploadHelper: EnergyUploadHelper = {
      companyId: user?.companyId,
      energyBoardId: item.energyBoardId,
      itemsToStore: [localItem],
    };

    saveEnergyUploadHelperOnServer(axios, localEnergyUploadHelper).then(
      (success) => {
        if (success) {
          onSuccess([
            {
              ...localItem,
              timestamp: new Date(
                localDate.getFullYear(),
                localDate.getMonth(),
                localDate.getDate(),
                localHour,
                0,
                0,
                0
              ),
            },
          ]);
          toogleOpen(false);
        } else generateNotification(t("energy.add.failedUpload"), "danger");
      }
    );
  };

  /**
   * Helper to change value of the hour input
   * @param newValue new value string to set
   */
  const onChangeHourValue = (newValue: string): void => {
    let localNewValue: number = Number(Number(newValue).toFixed(0));
    // check if minimum energy specifications are in use
    const currentMinimum: number =
      localDate.toISOString().split("T")[0] === minDate ? minTime ?? 0 : 0;
    if (localNewValue < currentMinimum) localNewValue = currentMinimum;
    if (localNewValue > 23) localNewValue = 23;
    setLocalHour(localNewValue);
  };

  /**
   * Helper to take uploaded csv file and send it to the server for processing
   */
  const handleSendingOfUploadedFile = (): void => {
    if (!uploadedFile) return;
    toggleLoading(true);
    saveCSVWithEnergyOnServer(axios, uploadedFile, item.energyBoardId).then(
      (items: EnergyItem[]) => {
        toggleLoading(false);
        if (items && items.length > 0) {
          onSuccess([...items]);
          toogleOpen(false);
        } else generateNotification(t("energy.add.failedUpload"), "danger");
        setUploadedFile(undefined);
      }
    );
  };

  return (
    <PopupComponent isOpen={open} toggleOpen={toogleOpen}>
      <div className="addEnergyItemPopup-header">
        <h2>{t("energy.add.title")}</h2>
        <DropdownComponent
          selectedOption={addMode}
          onChange={handleAddModeChange}
          options={getDropDownOptions()}
        />
      </div>
      {addMode === "manually" && (
        <>
          <TextInputComponent
            min={0}
            type="number"
            step="any"
            label={t("energy.add.consumptionValue")}
            value={localItem.value}
            onChange={(newValue) =>
              setLocalItem({ ...localItem, value: Number(newValue) })
            }
          />
          <TextInputComponent
            min={0}
            type="number"
            step="any"
            label={t("energy.add.productionValue")}
            value={localItem.productionValue}
            onChange={(newValue) =>
              setLocalItem({ ...localItem, productionValue: Number(newValue) })
            }
          />
          <TextInputComponent
            type="date"
            min={minDate}
            label={t("energy.add.date")}
            value={convertToDateForInput(localDate)}
            onChange={(newValue) => setLocalDate(new Date(newValue))}
          />
          <TextInputComponent
            // check if minimum energy specifications are in use
            min={
              localDate.toISOString().split("T")[0] === minDate ? minTime : 0
            }
            max={23}
            type="number"
            step="1"
            label={t("energy.add.time")}
            value={localHour}
            onChange={onChangeHourValue}
          />
          <ButtonComponent
            title={t("general.buttons.save")}
            type="button"
            onClick={storeEnergyItem}
          />
        </>
      )}
      {addMode === "upload" && (
        <>
          {uploadedFile && (
            <div className="fileName-wrapper">
              <p>
                {t("energy.detail.upload.fileName")} {uploadedFile.name}
              </p>
              <p onClick={() => setUploadedFile(undefined)}>Löschen</p>
            </div>
          )}
          <ButtonComponent
            type="file"
            title={t("general.buttons.select")}
            accept=".csv"
            multiple={false}
            setFiles={(files) => setUploadedFile(files[0])}
          />

          <ButtonComponent
            onClick={handleSendingOfUploadedFile}
            title={t("general.buttons.upload")}
            disabled={!uploadedFile}
            isLoading={isLoading}
          />

          <a href="/example.csv" className="download-example">
            {t("general.buttons.exampleDownload")}
          </a>
        </>
      )}
    </PopupComponent>
  );
};

export default AddEnergyItemPopup;
