import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  generateNotification,
  LoaderComponent,
  PopupComponent,
  TableComponent,
} from "sfm-component-library";
import "../../styles/AdministrationPage.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import { ReactComponent as AddIcon } from "./../../assets/icons/add.svg";
import { ReactComponent as EditIcon } from "./../../assets/icons/edit.svg";
import {
  ApiKey,
  Company,
  createEmptyApiKey,
} from "../../utils/company/Company.types";
import {
  fetchSingleCompany,
  updateApiKeysForCompany,
} from "../../utils/company/CompanyUtils";
import { ApiKeyEditForm } from "../../components/administration/apikey/ApiKeyEditForm";
import { convertToReadDate } from "../../utils/GeneralUtils";
import { UserContext } from "../../pages/App";

export const ApiKeyAdmin: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const [company, setCompany] = useState<Company>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingPopup, toggleLoadingPopup] = useState<boolean>(false);
  const [selectedApiKey, setSelectedApiKey] = useState<{
    apiKey: ApiKey;
    type: "update" | "create";
  }>();

  /**
   * Load groups and company
   */
  useEffect(() => {
    if (!user) return;
    fetchSingleCompany(axios, user.companyId).then(setCompany);
    setLoading(false);
  }, [user, axios]);

  /**
   * Helper to update or create an {@link ApiKey} for company
   */
  const createOrUpdateApiKey = (): void => {
    if (!company) return;
    let localCompany: Company = { ...company };
    toggleLoadingPopup(true);
    switch (selectedApiKey?.type) {
      case "create":
        localCompany.apiKeys.push(selectedApiKey.apiKey);
        break;
      case "update":
        let foundIndex: number = localCompany.apiKeys.findIndex(
          (item) => item.key === selectedApiKey.apiKey.key
        );
        if (foundIndex === -1) return;
        localCompany.apiKeys[foundIndex] = selectedApiKey.apiKey;
        break;
    }
    updateApiKeysForCompany(axios, localCompany).then((success) => {
      if (success) {
        setSelectedApiKey(undefined);
        setCompany(localCompany);
      } else
        generateNotification(
          t("administration.apikey.notification.wrongUpdate"),
          "danger"
        );
      toggleLoadingPopup(false);
    });
  };

  /**
   * Generate the needed apiKey entries for the table component
   */
  const apiKeyEntries = useMemo(
    () =>
      company?.apiKeys.map((apiKey) => ({
        content: [
          apiKey.displayName,
          convertToReadDate(apiKey.validUntil),
          apiKey.disabled ? "x" : " ",
          <EditIcon
            onClick={() => {
              setSelectedApiKey({ type: "update", apiKey: apiKey });
            }}
          />,
        ],
        id: apiKey.key,
      })) ?? [],
    [company?.apiKeys]
  );

  return (
    <>
      <PopupComponent
        isOpen={!!selectedApiKey?.apiKey}
        toggleOpen={() => setSelectedApiKey(undefined)}
      >
        <ApiKeyEditForm
          apiKey={selectedApiKey?.apiKey}
          setApiKey={(apiKey) =>
            setSelectedApiKey({ ...selectedApiKey!, apiKey: apiKey })
          }
          type={selectedApiKey?.type ?? "create"}
          onSubmit={createOrUpdateApiKey}
          isLoading={isLoadingPopup}
        />
      </PopupComponent>
      <div className="default-admin">
        <div className="default-admin--header">
          {
            <ButtonComponent
              onClick={() => {
                setSelectedApiKey({
                  apiKey: createEmptyApiKey(),
                  type: "create",
                });
              }}
              borderColor={"black"}
              title={
                <div className="default-admin--header--add">
                  <p>{t("administration.apikey.add")}</p>
                  <AddIcon fill="white" />
                </div>
              }
            />
          }
        </div>

        {isLoading ? (
          <LoaderComponent />
        ) : (
          <TableComponent
            header={t("administration.apikey.tableHeader", {
              returnObjects: true,
            })}
            listEntries={apiKeyEntries}
            customTag={[{ flex: 4 }, { flex: 2 }, { flex: 1 }]}
            searchPlaceholder={t("administration.apikey.searchPlaceholder")}
            searchButtonTitle={t("general.buttons.search")}
            showIndex
            searchable
          />
        )}
      </div>
    </>
  );
};
