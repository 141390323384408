import { History } from "history";
import i18next from "i18next";
import {
  BoxComponent,
  BoxType,
  RetractableComponent,
} from "sfm-component-library";
import { ReactComponent as CopyIcon } from "../../assets/icons/clone.svg";
import { ReactComponent as PinAddIcon } from "../../assets/icons/pin_add.svg";
import { ViewMode } from "../../pages/features/EnergyManagementPage";
import { EnergyBoard } from "../energy/Energy.types";
import { Group } from "../group/Group.types";
import { PowerBIConfiguration } from "../powerbi/PowerBI.types";
import { Protocol } from "../protocol/Protocol.types";
import { ShopfloorBoardConfiguration } from "../sfboard/SfBoard.types";
import { User, UserAction } from "../user/User.types";
import { isUserAllowedToDoThis } from "../user/User.utils";

/**
 * Method that generates an array of lists that are filled with entries with the same groupId
 * @param user User to check if user is allowed to modify entries
 * @param history History to use push-functionality
 * @param entries ShopfloorBoardConfiguration[] | PowerBIConfiguration[] array of entries which will be used to determine groups and the filled into groups
 * @param loadedGroups Group[] list of groups that will be used to assign names to the groupIds for each unique groupId
 * @param setConfigToEdit React.Dispatch<ShopfloorBoardConfiguration | PowerBIConfiguration> useState setter that is used to set value onClick
 * @param setConfigToCopy React.Dispatch<ShopfloorBoardConfiguration | PowerBIConfiguration> useState setter that is used to set value on copy click
 * @param setShowEditConfig React.Dispatch<boolean> used to trigger boolean change onClick
 * @returns JSX.Element existing of a div that contains Retractable-components each containing either Shopfloor-Items or PowerBi-Items
 * @tested
 */
export const generateListEntries = <
  T extends
    | ShopfloorBoardConfiguration
    | PowerBIConfiguration
    | EnergyBoard
    | Protocol
>({
  type,
  user,
  history,
  entries,
  loadedGroups,
  setConfigToEdit,
  setConfigToCopy,
  setShowEditConfig,
  onClick,
  viewMode,
  configId,
}: {
  type: "ShopfloorBoard" | "PowerBi" | "Energy" | "Protocol";
  user: User | undefined;
  history: History;
  entries: T[];
  loadedGroups: Group[];
  setConfigToEdit?: React.Dispatch<T>;
  setShowEditConfig?: React.Dispatch<boolean>;
  setConfigToCopy?: React.Dispatch<T>;
  onClick?: (entry: T) => void;
  viewMode?: ViewMode;
  configId?: string;
}): JSX.Element => {
  const groups: string[] = [];
  entries.forEach(
    (
      entry:
        | PowerBIConfiguration
        | ShopfloorBoardConfiguration
        | EnergyBoard
        | Protocol
    ) => {
      entry.groups.forEach((group) => groups.push(group));
    }
  );
  const lastGroup = sessionStorage.getItem("lastUsedGroup");
  const uniqueGroupIds: string[] = groups.filter(
    (element, index) => groups.indexOf(element) === index
  );
  uniqueGroupIds.push(i18next.t("administration.group.noGroup"));
  const groupKeeper: JSX.Element[] = [];
  uniqueGroupIds.forEach((groupId) => {
    let currentGroupName: string =
      loadedGroups.filter((group) => group.id === groupId)[0]?.name || groupId;
    if (groupId === i18next.t("administration.group.noGroup"))
      currentGroupName = i18next.t("administration.group.noGroup");
    let groupColor: string = "#fff";
    let localPath: string = determineCorrectPath(type);
    let isUserAllowed: boolean = false;
    switch (type) {
      case "Energy":
        isUserAllowed = isUserAllowedToDoThis(
          UserAction.EDIT_ENERGY_CONFIG,
          user
        );
        break;
      case "Protocol":
        isUserAllowed = isUserAllowedToDoThis(
          UserAction.EDIT_ENERGY_CONFIG,
          user
        );
        break;

      case "PowerBi":
        isUserAllowed = isUserAllowedToDoThis(
          UserAction.EDIT_POWERBI_CONFIG,
          user
        );
        break;

      case "ShopfloorBoard":
        isUserAllowed = isUserAllowedToDoThis(UserAction.EDIT_BOARD, user);
        break;
    }
    const groupEntries: JSX.Element[] = [];
    entries.forEach((entry: T) => {
      if (
        entry.groups.includes(groupId) ||
        (groupId === i18next.t("administration.group.noGroup") &&
          entry.groups.length === 0)
      ) {
        groupEntries.push(
          <BoxComponent
            listView
            background={entry.color}
            type={BoxType.PACKAGE}
            key={`box-config-component-item-${entry.id}`}
            onClick={() => {
              if (onClick) onClick(entry);
              else {
                sessionStorage.setItem("lastUsedGroup", groupId);
                if (viewMode) sessionStorage.setItem("lastUsedMode", viewMode);
                history.push(`${localPath}/detail`, {
                  configId: entry.id,
                  lastViewMode: viewMode,
                });
              }
            }}
            title={entry.name}
            onClickConfig={() => {
              if (isUserAllowed) {
                setConfigToEdit?.(entry);
                if (
                  setShowEditConfig &&
                  (type === "PowerBi" ||
                    type === "Energy" ||
                    type === "Protocol")
                )
                  setShowEditConfig(true);
              }
            }}
            hideConfig={!isUserAllowed}
            buttons={[
              {
                icon: <CopyIcon />,
                hidden: !(user && (type === "PowerBi" || type === "Energy")
                  ? isUserAllowedToDoThis(
                      UserAction.CREATE_POWERBI_CONFIG,
                      user
                    )
                  : isUserAllowedToDoThis(UserAction.CREATE_BOARD, user)),
                onClick: () => setConfigToCopy?.(entry),
              },
              {
                icon: <PinAddIcon />,
                hidden:
                  !(
                    user && isUserAllowedToDoThis(UserAction.CREATE_TASK, user)
                  ) ||
                  type === "PowerBi" ||
                  type === "Energy" ||
                  type === "Protocol",
                onClick: () => {
                  if (type === "PowerBi" || type === "Energy") return;
                  history.push(`${localPath}/task`, {
                    configId: entry.id,
                  });
                },
              },
            ]}
          />
        );
      }
    });
    groupKeeper.push(
      <RetractableComponent
        type="default"
        key={`retractable-component-${groupId}`}
        title={currentGroupName}
        children={groupEntries}
        color={groupColor}
        open={groupId === lastGroup}
      />
    );
  });
  return <>{groupKeeper}</>;
};

/**
 * Helper to determine the correct path for shopfloorboard and powerbi uses
 * @param type "ShopfloorBoard" | "PowerBi" | "Energy"
 * @returns string with the specific path-part for various cases
 * @tested
 */
export const determineCorrectPath = (
  type: "ShopfloorBoard" | "PowerBi" | "Energy" | "Protocol"
): string => {
  switch (type) {
    default:
    case "ShopfloorBoard":
      return `/sfboard`;
    case "PowerBi":
      return `/bi`;
    case "Energy":
      return `/energy`;
    case "Protocol":
      return `/protocol`;
  }
};
