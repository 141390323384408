import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxComponent, TextInputComponent } from "sfm-component-library";
import { ShopfloorBoardConfiguration } from "../../../utils/sfboard/SfBoard.types";

export interface BoardEditFormVisualTabProps {
  config: ShopfloorBoardConfiguration;
  setConfig(config: ShopfloorBoardConfiguration): void;
}

export const BoardEditFormVisualTab: FC<BoardEditFormVisualTabProps> = ({
  config,
  setConfig,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="input-with-checkbox">
        <TextInputComponent
          onChange={(value) =>
            setConfig({
              ...config,
              visualConfig: {
                ...config.visualConfig,
                maxColumns: Math.abs(parseInt(value) || 1),
              },
            })
          }
          disabled={(config.visualConfig?.maxColumns ?? -3) <= 0}
          value={
            (config.visualConfig?.maxColumns ?? -3) <= 0
              ? (t("shopfloorboard.config.edit.maxColumnsAuto") as string)
              : Math.abs(config.visualConfig?.maxColumns ?? -3)
          }
          type={
            (config.visualConfig?.maxColumns ?? -3) <= 0 ? "text" : "number"
          }
          min={1}
          max={12}
          label={t("shopfloorboard.config.edit.maxColumns")}
        />
        <CheckboxComponent
          checked={(config.visualConfig?.maxColumns ?? -3) <= 0}
          value={t("shopfloorboard.config.edit.maxColumnsAuto")}
          onCheck={(checked) =>
            checked
              ? setConfig({
                  ...config,
                  visualConfig: {
                    ...config.visualConfig,
                    maxColumns:
                      Math.abs(config.visualConfig?.maxColumns ?? -3) * -1,
                  },
                })
              : setConfig({
                  ...config,
                  visualConfig: {
                    ...config.visualConfig,
                    maxColumns: Math.abs(config.visualConfig?.maxColumns ?? -3),
                  },
                })
          }
        />
      </div>
      <div className="input-with-checkbox">
        <TextInputComponent
          onChange={(value) =>
            setConfig({
              ...config,
              visualConfig: {
                ...config.visualConfig,
                dayOffset: parseInt(value),
              },
            })
          }
          disabled={(config.visualConfig?.dayOffset ?? -1) <= 0}
          value={
            (config.visualConfig?.dayOffset ?? -1) <= 0
              ? 0
              : config.visualConfig?.dayOffset ?? 1
          }
          type="number"
          min={0}
          label={t("shopfloorboard.config.edit.dayOffset")}
        />
        <CheckboxComponent
          checked={(config.visualConfig?.dayOffset ?? -1) > 0}
          value={t("general.buttons.active")}
          onCheck={(checked) =>
            setConfig({
              ...config,
              visualConfig: {
                ...config.visualConfig,
                dayOffset:
                  Math.abs(config.visualConfig?.dayOffset || -1) *
                  (checked ? 1 : -1),
              },
            })
          }
        />
      </div>
      <hr />
      <p>{t("shopfloorboard.config.edit.kioskHeader")}</p>
      <TextInputComponent
        onChange={(value) =>
          setConfig({
            ...config,
            visualConfig: {
              ...config.visualConfig,
              kioskColumnDuration: parseInt(value),
            },
          })
        }
        type="number"
        value={config.visualConfig?.kioskColumnDuration ?? 5}
        label={t("shopfloorboard.config.edit.kioskSpeed")}
        min={1}
        max={600}
      />
    </>
  );
};
