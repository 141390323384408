import axios, { AxiosInstance } from "axios";
import { groupBy } from "lodash";

import { BoardCopyRequest } from "../sfboard/SfBoard.types";
import {
  AccumulatedEnergySpecification,
  DateRange,
  EnergyBoard,
  EnergyItem,
  EnergySpecification,
  EnergyUploadHelper,
  WeatherData,
} from "./Energy.types";
import { Page } from "../AxiosUtil";
import i18n from "../../i18n";
import { DeletionRequest } from "../apidata/DataApi.types";

/**
 * API METHOD - to store an energy item for specific data
 * @param axios network instance
 * @param helper helper data object which store all uplaoded data
 * @returns true if status code 201 was recieved
 */
export const saveEnergyUploadHelperOnServer = async (
  axios: AxiosInstance,
  helper: EnergyUploadHelper
): Promise<boolean> => {
  return axios
    .post("/data/energy/item/", helper)
    .then((resp) => resp.status === 201)
    .catch((exc) => {
      console.error("Error during energy uploading", exc);
      return false;
    });
};

/**
 * API Method to take csv file containing {@link EnergyItem}s and upload them to the server
 * @param axios - the axios instance
 * @param csvFile - the csv file to upload
 * @param energyBoardId - the id of the energy board to upload to
 * @returns - list of added {@link EnergyItem}s
 */
export const saveCSVWithEnergyOnServer = async (
  axios: AxiosInstance,
  csvFile: File,
  energyBoardId: string
): Promise<EnergyItem[]> => {
  let uploadFormData = new FormData();

  uploadFormData.append("energyBoardId", energyBoardId);
  uploadFormData.append("csvFile", csvFile);
  return axios
    .post("/data/energy/item/csv/", uploadFormData)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during csv energy uploading", exc);
      return [];
    });
};

/**
 * API METHOD - to load all stored energy specifcations
 * @param axios network instance
 * @returns array full of all loaded specification. Empty array if error occured
 */
export const loadAllEnergySpecifications = async (
  axios: AxiosInstance,
  paginationUrl?: string,
  energyBoardId?: string
): Promise<Page<EnergySpecification[]>> => {
  return axios
    .get(`/data/energy/specification/all/${paginationUrl || ""}`, {
      params: { energyBoardId: energyBoardId },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during Energy Specification loading", exc);
      return [];
    });
};
/**
 * API METHOD - to load all stored energy specifcations in a given date range
 * @param axios network instance
 * @returns array full of all loaded specification. Empty array if error occured
 */
export const loadAllEnergySpecificationsInRange = async (
  axios: AxiosInstance,
  range: DateRange,
  energyBoardId?: string
): Promise<EnergySpecification[]> => {
  return axios
    .get("/data/energy/specification/range/", {
      params: {
        start: range.start,
        end: range.end,
        energyBoardId: energyBoardId,
      },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during Energy Specification loading", exc);
      return [];
    });
};

/**
 * API METHOD - to load all stored energy specifcations with predictions values
 * @param axios network instance
 * @returns array full of all loaded specification with prediciton values. Empty array if error occured
 */
export const loadAllEnergySpecificationsWithPredictionValues = async (
  axios: AxiosInstance
): Promise<EnergySpecification[]> => {
  return axios
    .get("/data/energy/specification/predictions/all/")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during Energy Specification loading", exc);
      return [];
    });
};

/**
 * API METHOD - to load all energy items for a specific board
 * @param axios network instance
 * @param energyBoadId oid of board to load
 * @returns array of all loaded items. Empty array if error occured
 */
export const loadAllEnergyItems = async (
  axios: AxiosInstance,
  energyBoadId: string
): Promise<EnergyItem[]> => {
  return axios
    .get("/data/energy/item/all/", { params: { energyBoadId: energyBoadId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during Energy Item loading", exc);
      return [];
    });
};

/**
 * API METHOD - to load specific timestamp based energy items
 * @param axios network instance
 * @param range timestamp range to load
 * @param energyBoadId id of board to load
 * @returns loaded items
 */
export const loadAllEnergyItemsInRange = async (
  axios: AxiosInstance,
  range: DateRange,
  energyBoadId: string
): Promise<EnergyItem[]> => {
  return axios
    .get("/data/energy/item/range/", {
      params: {
        energyBoadId: energyBoadId,
        start: range.start,
        end: range.end,
      },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during Energy Item in range loading", exc);
      return [];
    });
};

/**
 * API METHOD - copy a Energy Board config by id
 *
 * @param copy the copy request
 * @param axios the axios instance
 * @returns the newly copied {@link EnergyBoard}
 */
export const copyEnergyBoard = async (
  copy: BoardCopyRequest,
  axios: AxiosInstance
): Promise<EnergyBoard | undefined> => {
  return axios
    .post("/data/energy/copy/", copy)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during PowerBI config upload", exc);
      return undefined;
    });
};

/**
 * API METHOD - updates an existing Energy Board config column for a company
 *
 * @param updatedConfig
 * @param axios
 * @returns boolean if it was successful
 */
export const updateEnergyBoardConfig = async (
  updatedConfig: EnergyBoard,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/energy/update/", updatedConfig)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during config update!", exc);
      return false;
    });
};

/**
 * API METHOD - creates a new Energy Board config for a company
 *
 * @param newConfig the config to create
 * @param axios the axios instance
 * @returns the newly created {@link EnergyBoard}
 */
export const createNewEnergBoard = async (
  newConfig: EnergyBoard,
  axios: AxiosInstance
): Promise<EnergyBoard> => {
  return axios
    .post("/data/energy/", newConfig)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during Energy Board config upload", exc);
      return null;
    });
};

/**
 * API METHOD - deletes Energy Board on backend service
 *
 * @param deletionRequest containing relevant info for deletion
 * @param axios network instance
 * @returns true if deletion was successful
 */
export const deleteEnergyBoard = async (
  deletionRequest: DeletionRequest,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/energy/delete/", deletionRequest)
    .then((resp) => resp.status === 200)
    .catch((exc) => {
      console.error("Error during Energy Board deletion", exc);
      return false;
    });
};

/**
 * fetches correct energy item for specification timestamp
 * @param timestamp data to check against
 * @param loadedEnergyItems list of energy items to check
 * @returns found energy item or null
 */
export const getEnergyItemForTimestamp = (
  timestamp: Date,
  loadedEnergyItems: EnergyItem[]
): EnergyItem | null => {
  const localFilteredList: EnergyItem[] = loadedEnergyItems.filter(
    (energyItem) => {
      return (
        new Date(energyItem.timestamp).getTime() ===
        new Date(timestamp).getTime()
      );
    }
  );
  if (localFilteredList.length === 1) return localFilteredList[0];
  else return null;
};

/**
 * Util function to create data for accumulated energy
 * @param listEnergySpecs - list of energy specifications
 * @param listEnergyItems - list of energy items
 * @returns - accumulated energy specification
 */
export const createAccumulatedEnergy = (
  listEnergySpecs: EnergySpecification[]
): AccumulatedEnergySpecification => {
  const amountOfDays: number = Object.keys(
    groupBy(
      listEnergySpecs.map((spec) => ({
        ...spec,
        timestamp: new Date(spec.timestamp).toLocaleDateString("de-DE"),
      })),
      "timestamp"
    )
  ).length;

  let totalConsumption: number = listEnergySpecs.reduce((acc, energySpec) => {
    if (energySpec.energyItem) {
      return acc + (energySpec.energyItem.value ?? 0);
    } else {
      return acc;
    }
  }, 0);
  //create map reduce function for average consumption
  let averageConsumption: number = totalConsumption / amountOfDays;

  let totalCosts: number = listEnergySpecs.reduce((acc, energySpec) => {
    if (energySpec.energyItem) {
      return (
        acc +
        Number(energySpec.value / 1000) * (energySpec.energyItem.value ?? 0)
      );
    } else {
      return acc;
    }
  }, 0);

  let averageCosts: number = totalCosts / amountOfDays;

  let lowestPrice: number = Math.min(
    ...listEnergySpecs.flatMap((spec) => Number(spec.value))
  );
  let highestPrice: number = Math.max(
    ...listEnergySpecs.flatMap((spec) => Number(spec.value))
  );

  const energyItemsWithoutFuture: EnergyItem[] = listEnergySpecs
    .filter(
      (energySpec) =>
        !!energySpec.energyItem &&
        new Date(energySpec.energyItem.timestamp) < new Date()
    )
    .flatMap((energySpec) => energySpec.energyItem as EnergyItem);

  let lowestConsumption: number =
    energyItemsWithoutFuture.length > 0
      ? Math.min(
          ...energyItemsWithoutFuture
            .flatMap((energyItem) => energyItem.value)
            .filter((value: number | undefined) => !!value)
        )
      : 0;
  let highestConsumption: number =
    energyItemsWithoutFuture.length > 0
      ? Math.max(
          ...(energyItemsWithoutFuture
            .flatMap((item) => item.value)
            .filter(
              (value: number | undefined) => value !== undefined
            ) as number[])
        )
      : 0;

  let lowestConsumptionDate: string =
    energyItemsWithoutFuture.length > 0
      ? new Date(
          energyItemsWithoutFuture.find(
            (item) => item.value === lowestConsumption
          )?.timestamp || new Date()
        ).toLocaleString("de-DE")
      : i18n.t("energy.detail.overview.tableHeader.noConsumption");

  let highestConsumptionDate: string =
    energyItemsWithoutFuture.length > 0
      ? new Date(
          energyItemsWithoutFuture.find(
            (item) => item.value === highestConsumption
          )?.timestamp || new Date()
        ).toLocaleString("de-DE")
      : i18n.t("energy.detail.overview.tableHeader.noConsumption");

  let lowestPriceDate: Date =
    new Date(
      listEnergySpecs.find((spec) => spec.value === lowestPrice)?.timestamp ||
        new Date()
    ) || new Date();

  let highestPriceDate: Date =
    new Date(
      listEnergySpecs.find((spec) => spec.value === highestPrice)?.timestamp ||
        new Date()
    ) || new Date();

  return {
    totalConsumption: totalConsumption,
    averageConsumption: averageConsumption,
    totalCosts: totalCosts,
    averageCosts: averageCosts,
    weightedPrice: isNaN(totalCosts / totalConsumption)
      ? 0
      : totalCosts / totalConsumption,
    lowestPriceWithTimeStamp: {
      value: lowestPrice / 1000,
      timestamp: lowestPriceDate,
    },
    highestPriceWithTimeStamp: {
      value: highestPrice / 1000,
      timestamp: highestPriceDate,
    },
    lowestConsumptionWithTimeStamp: {
      value: lowestConsumption,
      timestamp: lowestConsumptionDate,
    },
    highestConsumptionWithTimeStamp: {
      value: highestConsumption,
      timestamp: highestConsumptionDate,
    },
  };
};

/**
 * Helper to get the current weather and the forecast combined
 * @param lat @param lon coordinates to fetch the weather
 * @returns  {@link WeatherData} for coordinates
 */
export const fetchWeatherData = async (
  lat: number,
  lon: number
): Promise<WeatherData | null> => {
  const currentDate: Date = new Date();
  const offsetHours = -currentDate.getTimezoneOffset() / 60;
  const formattedDate = new Date(
    currentDate.getTime() - currentDate.getTimezoneOffset() * 60 * 1000
  )
    .toISOString()
    .replace(
      "Z",
      `${offsetHours < 0 ? "-" : "+"}${
        Math.abs(offsetHours) < 10 ? "0" : ""
      }${Math.abs(offsetHours)}:00`
    );
  return Promise.all([
    axios.get("https://api.brightsky.dev/current_weather", {
      params: { lon: lon, lat: lat },
    }),

    axios.get("https://api.brightsky.dev/weather", {
      params: { lon: lon, lat: lat, date: formattedDate },
    }),
  ])
    .then(([currentWeather, weatherForecast]) => ({
      weatherForecast: weatherForecast.data,
      currentWeather: currentWeather.data,
    }))
    .catch((error) => {
      console.error("Could not fetch weather", error);
      return null;
    });
};
