import { useCallback, useState } from "react";
import { ButtonComponent, TextInputComponent } from "sfm-component-library";
import { Group, NewGroup } from "../../../utils/group/Group.types";
import { createNewGroup } from "../../../utils/group/Group.utils";
import "./GroupEditFormStyles.scss";
import { useTranslation } from "react-i18next";
import DeletePopUp from "../../deletepopup/DeletePopUp";

export interface GroupEditFormProps {
  group?: Group;
  companyId: string;
  onSave?(group: NewGroup | Group): void;
  onDelete?(group: Group): void;
}

export function GroupEditForm({
  group,
  companyId,
  onSave,
  onDelete,
}: GroupEditFormProps): JSX.Element {
  const { t } = useTranslation();
  const [localGroup, setLocalGroup] = useState<Group | NewGroup>(
    group || createNewGroup("", companyId)
  );
  const [deletePopup, setDeletePopup] = useState(false);

  /**
   * Callback to save the group
   */
  const saveGroup = useCallback(() => {
    onSave?.(localGroup);
  }, [localGroup, onSave]);

  /**
   * Callback to delete the group
   */
  const deleteGroup = useCallback(() => {
    onDelete?.(localGroup as Group);
  }, [localGroup, onDelete]);

  return (
    <>
      {deletePopup && (
        <DeletePopUp
          isOpen
          toggleOpen={() => setDeletePopup(false)}
          onDelete={deleteGroup}
          content={<>{t("administration.group.deletePopup")}</>}
        />
      )}
      <div className="group-edit-form">
        <TextInputComponent
          value={localGroup.name}
          onChange={(name) => setLocalGroup((local) => ({ ...local, name }))}
        />
        <div className="group-edit-form--footer">
          {group && onDelete && (
            <ButtonComponent
              title={t("general.buttons.delete")}
              onClick={() => setDeletePopup(true)}
              borderColor="#f86b6b"
            />
          )}
          <ButtonComponent
            title={
              group ? t("general.buttons.save") : t("general.buttons.create")
            }
            onClick={saveGroup}
            borderColor="#a9faa2"
          />
        </div>
      </div>
    </>
  );
}
