import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonComponent } from "sfm-component-library";
import { useAxios } from "../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { WidgetInfo } from "../../utils/user/Dashboard.types";
import {
  convertBackendWidgetToWidget,
  convertWidgetToBackendWidget,
  generatePlaceholderWidget,
} from "../../utils/user/Dashboard.utils";
import { User } from "../../utils/user/User.types";
import { updateUserDashboardOnBackend } from "../../utils/user/User.utils";
import { DashboardGrid } from "./DashboardGrid";
import { DashboardWidgetStore } from "./DashboardWidgetStore";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

export interface DashboardProps {
  user: User;
  isLoading: boolean;
  setUser(user: User): void;
  setLoading(loading: boolean): void;
}

export const Dashboard: React.FC<DashboardProps> = ({
  user,
  isLoading,
  setLoading,
  setUser,
}) => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const [editable, setEditable] = useState(false);
  const [localWidgets, setLocalWidgets] = useState<WidgetInfo[]>();

  /**
   * Workaround when cancel the edit proccess, the grid needs to be unmounted and remounted with previous widgets
   */
  useEffect(() => {
    if (!localWidgets)
      setLocalWidgets(
        user.dashboard?.widgets?.map(convertBackendWidgetToWidget) ?? [
          generatePlaceholderWidget(),
        ]
      );
  }, [localWidgets, user]);

  /**
   * Handler for saving the dashboard. Calls the backend to save the dashboard data.
   */
  const handleSave = useCallback(async () => {
    // no need to save because widgets was not changed.
    if (
      isEqual(
        user.dashboard?.widgets?.map(convertBackendWidgetToWidget),
        localWidgets
      ) ||
      !localWidgets
    ) {
      setEditable(false);
      return;
    }

    const newUser: User = {
      ...user,
      dashboard: {
        ...user.dashboard,
        widgets: localWidgets.map(convertWidgetToBackendWidget),
      },
    };

    setLoading(true);
    try {
      await updateUserDashboardOnBackend(newUser, axios);
      setUser(newUser);
      generateNotificationWithTranslations("success");
      setEditable(false);
    } catch {
      generateNotificationWithTranslations("warning");
    }
    setLoading(false);
  }, [localWidgets, user, setUser, axios, setLoading]);

  /**
   * Handler for cancel the edit and restore the widgets
   */
  const handleCancel = useCallback(() => {
    setEditable(false);
    setLocalWidgets(undefined);
  }, []);

  return (
    <div className="dashboard--wrapper">
      <div className="dashboard">
        <div className="dashboard--header">
          <h1>
            {t("dashboard.welcome")} {user.firstname}
          </h1>
          {!editable && (
            <EditIcon
              className="dashboard--header--edit"
              onClick={() => setEditable(true)}
            />
          )}
        </div>
        {localWidgets && (
          <DashboardGrid
            widgets={localWidgets}
            editable={editable}
            setWidgets={setLocalWidgets}
            setEditable={setEditable}
          />
        )}
      </div>
      {editable && <div className="dashboard--sidebar-placeholder" />}
      <div className="dashboard--sidebar--wrapper">
        {editable && (
          <div className="dashboard--sidebar">
            <div className="dashboard--sidebar--buttons">
              <ButtonComponent
                title={t("general.buttons.save")}
                borderColor="#a9faa2"
                onClick={handleSave}
                isLoading={isLoading}
              />
              <ButtonComponent
                title={t("general.buttons.cancel")}
                borderColor="#f86b6b"
                onClick={handleCancel}
                isLoading={isLoading}
              />
            </div>
            <DashboardWidgetStore />
          </div>
        )}
      </div>
    </div>
  );
};
