import { AxiosInstance } from "axios";
import { Protocol, ProtocolFileRequest } from "./Protocol.types";
import { BoardCopyRequest } from "../sfboard/SfBoard.types";
import { FileEntry, FileType } from "../tasks/Tasks.types";
import { Page } from "../AxiosUtil";

/**
 * API method to load all protocols for company and groups
 * @param axios  - axios instance
 * @param companyId  - company id
 * @param userId  - user id
 * @returns  all protocols for company and groups, can be empty
 */
export const loadAllProtocolsForCompanyAndGroups = (
  axios: AxiosInstance,
  companyId: string,
  userId: string
): Promise<Protocol[]> =>
  axios
    .get("/data/protocol/company/group/", {
      params: {
        companyId,
        userId,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      return [];
    });

/**
 * API method to update a protocol
 * @param axios  axios instance
 * @param protocol  protocol to update
 * @returns  true if successful, false otherwise
 */
export const updateProtocol = (
  axios: AxiosInstance,
  protocol: Protocol,
  userId: string
): Promise<boolean> =>
  axios
    .post("/data/protocol/update/", {
      ...protocol,
      lastUpdatedBy: userId,
    } as Protocol)
    .then((response) => response.status === 200)
    .catch((error) => {
      console.log(error);
      return false;
    });

/**
 * API method to create a protocol
 * @param axios  axios instance
 * @param protocol  protocol to create
 * @returns  the created protocol or null if unsuccessful
 */
export const createProtocol = (
  axios: AxiosInstance,
  protocol: Protocol,
  userId: string
): Promise<Protocol | null> =>
  axios
    .post("/data/protocol/", {
      ...protocol,
      lastUpdatedBy: userId,
      createdBy: userId,
    } as Protocol)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      return null;
    });

/* API METHOD - copy a Energy Board config by id
 *
 * @param copy the copy request
 * @param axios the axios instance
 * @returns the newly copied {@link EnergyBoard}
 */
export const copyProtocol = async (
  copy: BoardCopyRequest,
  axios: AxiosInstance
): Promise<Protocol | undefined> => {
  return axios
    .post("/data/protocol/copy/", copy)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during Protocol Copy", exc);
      return undefined;
    });
};

/**
 * API METHOD - deletes Protocol on backend service
 *
 * @param protocolId id of protocol
 * @param axios network instance
 * @returns true if deletion was successful
 */
export const deleteProtocol = async (
  protocolId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/protocol/delete/", protocolId)
    .then((resp) => resp.status === 200)
    .catch((exc) => {
      console.error("Error during protocol deletion", exc);
      return false;
    });
};

/**
 * API METHOD - deletes Protocol Document on backend service
 *
 * @param protocolId id of protocol
 * @param documentId id of protocol document to delete
 * @param axios network instance
 * @returns true if deletion was successful
 */
export const deleteProtocolDocument = async (
  protocolId: string,
  documentId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/protocol/delete/document/", {
      protocolId: protocolId,
      documentId: documentId,
    })
    .then((resp) => resp.status === 200)
    .catch((exc) => {
      console.error("Error during protocol deletion", exc);
      return false;
    });
};

/**
 * API method to send a filled protocol to the backend
 * @param protocol  protocol to send
 * @param axios  axios instance
 * @returns  true if successful, false otherwise
 */
export const completeProtocol = async (
  protocol: Protocol,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/protocol/completed/", protocol)
    .then((resp) => resp.status === 201)
    .catch((exc) => {
      console.error("Error during protocol completion", exc);
      return false;
    });
};

/**
 * Uploads file for a protocol to the backend
 * @param file  the file to upload
 * @param protocolId  the id of the protocol
 * @param userId  the user id of the protocol
 * @param fileType  the type of the file
 * @param axios  axios instance
 * @returns the file entry or undefined if unsuccessful
 */
export const uploadFileForProtocol = async (
  file: File,
  protocolId: string,
  userId: string,
  fileType: FileType,
  axios: AxiosInstance
): Promise<FileEntry | undefined> => {
  // build the request
  let uploadFormData = new FormData();

  const request: ProtocolFileRequest = {
    protocolId: protocolId,
    userId: userId,
    fileType: fileType,
  };

  const protocolRequestBlob = new Blob([JSON.stringify(request)], {
    type: "application/json",
  });
  uploadFormData.append("protocolFileRequest", protocolRequestBlob);

  uploadFormData.append("fileData", file);
  // run the axios command
  return axios
    .post("/data/protocol/file/add/", uploadFormData)
    .then((fileResponse) => fileResponse.data)
    .catch((exc) => {
      console.error("Error during uploading file!", exc);
      return undefined;
    });
};

/**
 * API method to download an image for a protocol from the backend
 * @param protocolId  the protocol id
 * @param inputId  the input id
 * @param fileEntry  the file entry
 * @param axios axios instance
 * @returns the image or undefined if unsuccessful
 */
export const downloadFileForProtocol = async (
  protocolId: string,
  fileEntry: FileEntry,
  axios: AxiosInstance,
  inputId?: string,
  documentId?: string
): Promise<File | undefined> => {
  return axios
    .get("/data/protocol/file/", {
      params: {
        protocolId: protocolId,
        inputId: inputId,
        documentId: documentId,
      },
      responseType: "blob",
    })
    .then(
      (fileResponse) =>
        new File([fileResponse.data], fileEntry.fileName, {
          type: documentId ? "application/pdf" : fileEntry.type,
        })
    )
    .catch((exc) => {
      console.error("Error during download file!", exc);
      return undefined;
    });
};

/**
 * API METHOD - to load all protocols paginated
 * @param axios network instance
 * @returns array full of all loaded filled protocols. Empty array if error occured
 */
export const loadAllFilledProtocols = async (
  axios: AxiosInstance,
  paginationUrl: string,
  originId: string,
  userId: string,
  companyId: string
): Promise<Page<Protocol[]>> => {
  return axios
    .get(`/data/protocol/filled/company/group/${paginationUrl || ""}`, {
      params: { originId: originId, userId: userId, companyId: companyId },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during loading of filled out protocols", exc);
      return [];
    });
};

/**
 * NETWORK METHOD - to load specific protocol by id
 * @param axios network instance
 * @param id id to load from server
 * @returns loaded protocol or null if error occured
 */
export const loadProtocol = async (
  axios: AxiosInstance,
  id: string
): Promise<Protocol> => {
  return axios
    .get(`/data/protocol/filled/`, {
      params: { id: id },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during loading protocol", exc);
      return null;
    });
};

/**
 * NETWORK METHOD - to fetch a csv export from server of all protocols
 * @param axios network instance
 * @param companyId id of the company to load
 * @param userId id of the current user
 * @returns loaded data or null on error
 */
export const exportProtocol = async (
  axios: AxiosInstance,
  companyId: string,
  userId: string
): Promise<string> => {
  return axios
    .get(`/data/protocol/export/`, {
      params: { companyId: companyId, userId: userId },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during exporting all protocols", exc);
      return null;
    });
};

/**
 * NETWORK METHOD - to fetch a csv export from server of a single protocol
 * @param axios network instance
 * @param companyId id of the company to load
 * @param userId id of the current user
 * @param protocolId id of the protocol to load
 * @returns loaded data or null on error
 */
export const exportSingleProtocol = async (
  axios: AxiosInstance,
  companyId: string,
  userId: string,
  protocolId: string
): Promise<string> => {
  return axios
    .get(`/data/protocol/export/single/`, {
      params: { companyId: companyId, userId: userId, protocolId: protocolId },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during exporting specific protocol", exc);
      return null;
    });
};
