import {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ColumnChartComponent, PopupComponent } from "sfm-component-library";
import { ColumnChartData } from "sfm-component-library/build/ChartComponent/ChartComponent.types";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArchiveIcon } from "../../../assets/icons/archive.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { UserContext } from "../../../pages/App";
import {
  compareDate,
  convertTimeToZero,
  shortenNumber,
} from "../../../utils/GeneralUtils";
import {
  ConnectorType,
  ShopfloorBoardColumn,
  ShopfloorBoardPerformanceEntry,
} from "../../../utils/sfboard/SfBoard.types";
import {
  AvgPerfomanceEntryValueResult,
  getAvgPerfomanceEntryValue,
  getMaxPerformanceEntry,
} from "../../../utils/sfboard/SfBoard.utils";
import {
  getCorrectColumnColor,
  getTargetValue,
} from "../../../utils/sfboard/SfBoardColumn.utils";
import { useEvent } from "../../../utils/socket/SocketUtils";
import { EventAction } from "../../../utils/socket/SocketUtils.types";
import { Task, TaskStatus } from "../../../utils/tasks/Tasks.types";
import { convertTaskDates } from "../../../utils/tasks/TasksUtil";
import { User, UserAction } from "../../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../../utils/user/User.utils";
import ShopFloorPerformanceChartPopUp from "../ShopfloorPerformanceChartPopUp";
import ShopfloorPerformanceEntryEdit from "../ShopfloorPerformanceEntryEdit";
import ShopfloorTask from "../task/ShopfloorTask";
import { ReactComponent as TrendingDownIcon } from "./../../../assets/icons/trending_down.svg";
import { ReactComponent as TrendingUpIcon } from "./../../../assets/icons/trending_up.svg";
import { ReactComponent as WarningIcon } from "./../../../assets/icons/warning.svg";
import "./ShopfloorColumnStyles.scss";

export interface ShopfloorColumnProps {
  boardId: string;
  column: ShopfloorBoardColumn;
  columnPerformanceEntries?: ShopfloorBoardPerformanceEntry[];
  openColumnEditPopup(): void;
  tasks: Task[];
  updateTasksForBoard(taskList: Task[]): void;
  updatePerformanceEntries(updatedEntry: ShopfloorBoardPerformanceEntry): void;
  onNewTask(columnId: string): void;
  users: User[];
  dayOffset?: number;
  initiallyOpenTaskId?: string;
}

/**
 * Type of PopUp that should be opened
 */
enum PopUpType {
  EDIT = "EDIT",
  CHART = "CHART",
}

const ShopfloorColumn: React.FC<ShopfloorColumnProps> = ({
  column,
  columnPerformanceEntries,
  updatePerformanceEntries,
  openColumnEditPopup,
  tasks,
  boardId,
  updateTasksForBoard,
  onNewTask,
  users,
  dayOffset,
  initiallyOpenTaskId,
}) => {
  const [todayPerformanceEntry, setTodayPerformanceEntry] =
    useState<ShopfloorBoardPerformanceEntry>();
  const [showManualPopup, setShowManualPopup] = useState<PopUpType>();
  const defaultTargetValue = 20;
  const [showAllTasks, toggleAllTasks] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [titleIsOverflowed, setTitleIsOverflowed] = useState(false);
  const titleRef = useRef<HTMLElement>(null);

  const offsetTime = useMemo(() => {
    const localOffset = dayOffset ?? -1;
    return localOffset <= 0 ? 0 : localOffset * 86400000;
  }, [dayOffset]);

  /**
   * checks if title is overflowed. if true, gives the title an overflow animation
   */
  useEffect(() => {
    if (titleRef.current) {
      setTitleIsOverflowed(
        titleRef.current.offsetWidth < titleRef.current.scrollWidth
      );
    }
  }, []);

  /**
   * sets local copy of performance entry
   */
  useEffect(() => {
    setTodayPerformanceEntry(getNewestPerformanceEntry());
    // eslint-disable-next-line
  }, [columnPerformanceEntries]);

  /**
   * Listen to a shopfloorboard column event.
   * This automatically updates the tasks when the socket send a event
   */
  useEvent<{ "action:task": EventAction<Task> }>(
    `sfb_col:${column.id}`,
    (on) => {
      on("action:task", (action) => {
        action.item = convertTaskDates(action.item);

        switch (action.type) {
          case "CREATE":
          case "UPDATE": {
            updateTask(action.item);
            break;
          }
          case "DELETE": {
            deleteTask(action.item.id!);
            break;
          }
        }
      });
    },
    undefined,
    [tasks]
  );

  /**
   * Helper to update (or create if missing) a task in tasks list
   *
   * @param updatedTask
   */
  const updateTask = (updatedTask: Task): void => {
    const localTasks = [...tasks];
    const taskIndex = localTasks.findIndex(
      (task) => task.id === updatedTask.id
    );
    if (taskIndex === -1) {
      localTasks.push(updatedTask);
    } else {
      const currentDate = new Date(
        localTasks[taskIndex].lastUpdated ||
          localTasks[taskIndex].createDate ||
          0
      );
      const updatedDate = new Date(
        updatedTask.lastUpdated || updatedTask.createDate || Infinity
      );
      if (currentDate.getTime() < updatedDate.getTime()) {
        localTasks[taskIndex] = updatedTask;
      } else return;
    }
    updateTasksForBoard(localTasks);
  };

  /**
   * Helper to remove task inside local task list
   * @param taskId id of task, that has to be removed
   */
  const deleteTask = (taskId: string): void => {
    const localTasks = [...tasks];
    updateTasksForBoard(
      localTasks.filter((localTask) => localTask.id !== taskId)
    );
  };

  /**
   * maps performanaceEntries to a ColumnChartObject
   * @returns ColumnChartData list
   */
  const mapDataToColumnChartEntry = (): ColumnChartData[] => {
    let chartEntries: ColumnChartData[] = [];

    const tempValue = getMaxHeightofChart();

    for (let index = 14; index >= 0; index--) {
      const dateToCheck = new Date(Date.now() - 86400000 * index - offsetTime);
      const foundPerformanceEntryForDate:
        | ShopfloorBoardPerformanceEntry
        | undefined = columnPerformanceEntries?.find((entry) =>
        compareDate(new Date(entry.createDate), dateToCheck)
      );
      if (foundPerformanceEntryForDate) {
        chartEntries.push({
          value:
            foundPerformanceEntryForDate.currentValue * 4 >
            foundPerformanceEntryForDate.targetValue
              ? foundPerformanceEntryForDate.currentValue
              : foundPerformanceEntryForDate.targetValue / 4,
          date: new Date(foundPerformanceEntryForDate.createDate),
          goal: foundPerformanceEntryForDate.targetValue,
        });
      } else {
        /**
         * adds fake dots for empty values, so it looks better
         */
        chartEntries.push({
          value: tempValue / 4,
          goal: tempValue,
          date: dateToCheck,
        });
      }
    }

    return chartEntries;
  };

  /**
   * @returns max height for chartcomponent
   */
  const getMaxHeightofChart = (): number =>
    columnPerformanceEntries &&
    getMaxPerformanceEntry(columnPerformanceEntries) !== -1
      ? getMaxPerformanceEntry(columnPerformanceEntries)
      : defaultTargetValue;

  /**
   * Helper to filter current performance entries
   *
   * @param columnId
   * @returns found entry or undefined
   */
  const getNewestPerformanceEntry = ():
    | ShopfloorBoardPerformanceEntry
    | undefined => {
    if (!columnPerformanceEntries || columnPerformanceEntries.length === 0)
      return;

    return (
      columnPerformanceEntries.filter((entry) =>
        compareDate(
          new Date(entry.createDate),
          new Date(Date.now() - offsetTime)
        )
      )[0] || undefined
    );
  };

  /**
   * Helper to filter local {@link Task} list
   *
   * @param showAll
   * @returns filtered list of {@link Task}
   */
  const filterLocalTaskList = (showAll: boolean): Task[] => {
    return tasks.filter(
      (entry) =>
        showAll ||
        (entry.status !== TaskStatus.FINISHED_NOT_SUCCESSFUL &&
          entry.status !== TaskStatus.FINISHED_SUCCESSFUL &&
          !entry.archived)
    );
  };

  /**
   * @returns correct icon and average performance Entry
   */
  const renderAvgValueComponent = (): ReactElement => {
    const avgPerformance: AvgPerfomanceEntryValueResult =
      getAvgPerfomanceEntryValue(
        (columnPerformanceEntries || []).filter((entry) => {
          const compareDate = convertTimeToZero(
            new Date(Date.now() - offsetTime)
          );
          const created = convertTimeToZero(new Date(entry.createDate));

          return compareDate.getTime() >= created.getTime();
        }),
        column,
        new Date(Date.now() - offsetTime)
      );
    let iconToRender: ReactElement;
    switch (avgPerformance.isTrending) {
      case "down":
        iconToRender = <TrendingDownIcon />;
        break;
      case "danger":
        iconToRender = <WarningIcon />;
        break;

      default:
        iconToRender = <TrendingUpIcon />;
        break;
    }
    return (
      <div>
        <p>{`${(avgPerformance.achievedPercentage * 100).toFixed(0)}%`}</p>
        <div
          className="shopfloor-column--subheader--right-icon"
          style={{ color: avgPerformance.color }}
        >
          {iconToRender}
        </div>
      </div>
    );
  };

  return (
    <>
      <PopupComponent
        isOpen={!!showManualPopup}
        toggleOpen={(isOpen) => !isOpen && setShowManualPopup(undefined)}
        key={`performance-entry-popup-${column.targetValue}-${
          todayPerformanceEntry?.currentValue || 0
        }`}
        footerButtons={[
          ...(showManualPopup === PopUpType.EDIT
            ? [
                {
                  title: t("general.buttons.save"),
                  borderColor: "#A9FAA2",
                  form: "column-edit-form-performance-entry",
                },
              ]
            : []),
        ]}
      >
        {showManualPopup === PopUpType.EDIT ? (
          <ShopfloorPerformanceEntryEdit
            boardId={boardId}
            closePopup={() => setShowManualPopup(undefined)}
            column={column}
            performanceEntryToEdit={todayPerformanceEntry}
            updatePerformanceEntry={
              isUserAllowedToDoThis(UserAction.EDIT_COLUMN, user)
                ? updatePerformanceEntries
                : () => {}
            }
          />
        ) : (
          <ShopFloorPerformanceChartPopUp
            columnPerformanceEntries={columnPerformanceEntries ?? []}
            filterWeekendDays={!column.visualConfig?.chartShowWeekend}
          />
        )}
      </PopupComponent>
      <div
        className="shopfloor-column"
        style={{
          background: getCorrectColumnColor(
            getTargetValue(column, todayPerformanceEntry),
            todayPerformanceEntry?.currentValue,
            column.visualConfig
          ),
        }}
      >
        {isUserAllowedToDoThis(UserAction.EDIT_COLUMN, user) && (
          <>
            <div
              className="shopfloor-column--cog-icon-click-wrapper"
              onClick={() => openColumnEditPopup()}
            />
            <div className="shopfloor-column--cog-icon">
              <SettingsIcon />
            </div>
          </>
        )}

        <div
          className={`shopfloor-column__title ${
            titleIsOverflowed ? "shopfloor-column__title--overflowed" : ""
          }`}
        >
          <span ref={titleRef}>{column.name}</span>
        </div>
        <div className={`shopfloor-column__unit`}>
          <span>{column.unit ?? ""}</span>
        </div>
        <div
          className={[
            "shopfloor-column--header",
            column.connectorType === ConnectorType.MANUAL
              ? "clickable"
              : undefined,
          ].join(" ")}
          onClick={() =>
            column.connectorType === ConnectorType.MANUAL &&
            isUserAllowedToDoThis(UserAction.UPDATE_PERFORMANCE_ENTRY, user) &&
            setShowManualPopup(PopUpType.EDIT)
          }
        >
          <div className="shopfloor-column--header-item-wrapper">
            <div className="shopfloor-column--header-item-wrapper-item">
              <div className="shopfloor-column--header-item-wrapper-item-text">
                {t("shopfloorboard.column.currentValueIndicator")}
              </div>
              <div>
                <span className="current">
                  {shortenNumber(todayPerformanceEntry?.currentValue || 0)}
                </span>
              </div>
            </div>
            /
            <div className="shopfloor-column--header-item-wrapper-item">
              <div className="shopfloor-column--header-item-wrapper-item-text">
                {t("shopfloorboard.column.targetValueIndicator")}
              </div>
              <div>
                {shortenNumber(getTargetValue(column, todayPerformanceEntry))}
              </div>
            </div>
          </div>
          <div className="shopfloor-column--subheader">
            <p className="shopfloor-column--subheader--left-icon">Ø</p>
            {renderAvgValueComponent()}
          </div>
        </div>
        <div
          className={"shopfloor-column__chart"}
          onClick={() => setShowManualPopup(PopUpType.CHART)}
        >
          <ColumnChartComponent
            data={mapDataToColumnChartEntry()}
            dataLimit={10}
            lineColor="black"
            height={80}
            hideDate
            hideTooltip
            columnWidthRatio={0.8}
            filterWeekendDays={!column.visualConfig?.chartShowWeekend}
            maxValue={getMaxHeightofChart()}
            maxColumnWidth={25}
            goalLineAttr={{
              length: 1,
              color: "black",
              thickness: 1,
            }}
          />
        </div>
        <div className="shopfloor-column--tasks">
          <div className="shopfloor-column--tasks__new-task">
            <AddIcon onClick={() => onNewTask(column.id)} />
          </div>
          {filterLocalTaskList(showAllTasks)
            .sort(
              (taskA: Task, taskB: Task) =>
                new Date(taskB.lastUpdated || taskB.createDate || 0).getTime() -
                new Date(taskA.lastUpdated || taskA.createDate || 0).getTime()
            )
            .map((task, taskIndex) => (
              <ShopfloorTask
                users={users}
                key={`shopfloor-task-${taskIndex}`}
                task={task}
                updateTask={(updatedTask) => updateTask(updatedTask)}
                onDelete={() => deleteTask(task.id!)}
                initiallyOpenTaskId={initiallyOpenTaskId}
              />
            ))}
        </div>
        {isUserAllowedToDoThis(UserAction.SHOW_ARCHIVE, user) && (
          <div
            className={"shopfloor-column--globe-icon-click-wrapper"}
            onClick={() => toggleAllTasks(!showAllTasks)}
          >
            <ArchiveIcon
              style={{
                color: showAllTasks
                  ? getCorrectColumnColor(
                      getTargetValue(column, todayPerformanceEntry),
                      todayPerformanceEntry?.currentValue,
                      column.visualConfig
                    )
                  : undefined,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ShopfloorColumn;
