/**
 * This is a polyfill for safari because safari dont support .scrollTo({behavior: "smooth"}).
 * With this polyfill safari support smooth scrolling.
 *
 * As of Safari 15.5 (17613.2.7.1.8) on macOS 12.4 (21F79) - 29.06.2022
 */
import smoothscroll from "smoothscroll-polyfill";

const needPolyfill = (): boolean => navigator.userAgent.indexOf("Safari") > -1;

if (needPolyfill() && !(window as any).__forceSmoothScrollPolyfill__) {
  (window as any).__forceSmoothScrollPolyfill__ = true;
  smoothscroll.polyfill();
}
