import { AxiosInstance } from "axios";
import i18next from "i18next";
import { generateNotification } from "sfm-component-library";
import { FileEntry, FileType, Task, TaskFileRequest } from "./Tasks.types";

/**
 * GET API - to fetch all tasks for a given companyId
 * @param axios the axios instance
 * @param companyId id of the company
 * @returns list of all tasks from a company
 */
export const fetchAllTasksForCompany = async (
  axios: AxiosInstance,
  companyId: string
): Promise<Task[]> =>
  axios
    .get("/data/task/get/company/", { params: { companyId } })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during task list fetch!", exc);
      return [];
    });

/**
 * GET API - to count all tasks for a given user and company
 * @param axios the axios instance
 * @param companyId id of the company
 * @param userId id of the user
 * @returns count of all tasks from a user and company
 */
export const loadCountOfTasksbyUserAndCompany = async (
  companyId: string,
  userId: string,
  axios: AxiosInstance
): Promise<Number | undefined> =>
  axios
    .get("/data/task/count/", { params: { companyId, userId } })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during task count fetch!", exc);
      return undefined;
    });

/**
 * POST API - uploads an image to the server
 * @param file file data
 * @param taskId id of Task
 * @param userId userID
 * @param fileType IMAGE or DOCUMENT
 * @param axios
 * @returns generated {@link FileEntry} on success, else returns undefined
 */
export const uploadOutputFile = async (
  file: File,
  taskId: string,
  userId: string,
  fileType: FileType,
  axios: AxiosInstance
): Promise<FileEntry | undefined> => {
  // build the request
  let uploadFormData = new FormData();

  const request: TaskFileRequest = {
    taskId: taskId,
    userId: userId,
    fileType: fileType,
  };

  const taskRequestBlob = new Blob([JSON.stringify(request)], {
    type: "application/json",
  });
  uploadFormData.append("taskfileRequest", taskRequestBlob);

  uploadFormData.append("fileData", file);
  // run the axios command
  return axios
    .post("/data/task/file/add/", uploadFormData)
    .then((fileResponse) => fileResponse.data)
    .catch((exc) => {
      console.error("Error during uploading file!", exc);
      return undefined;
    });
};

/**
 * GET API - fetches image for given fileName
 * @param taskId id of Task
 * @param fileName name of the file, that needs to be fetched
 * @param axios
 * @returns image as File, else return undefined
 */
export const downloadFile = async (
  taskId: string,
  fileName: string,
  axios: AxiosInstance
): Promise<File | undefined> => {
  let fileType: string = "";

  switch (fileName.split(".").pop()) {
    case "pdf":
      fileType = "application/pdf";
      break;

    case "jpg":
    case "jpeg":
    case "png":
    case "svg":
      fileType = "image/*";
      break;
    default:
      // default value for all cases, according to mozilla docs
      fileType = "application/octet-stream";
  }
  return axios
    .get("/data/task/get/file/", {
      params: { taskId: taskId, fileName: fileName },
      responseType: "blob",
    })
    .then(
      (fileResponse) =>
        new File([fileResponse.data], fileName, { type: fileType })
    )
    .catch((exc) => {
      console.error("Error during download file!", exc);
      return undefined;
    });
};

/**
 * DELETE API - deletes file inside Task
 * @param taskId id of Task
 * @param fileName name of the file, that needs to be deleted
 * @param axios
 * @returns if successful returns the name of the file, that was deleted, else returns undefined
 */
export const deleteFileInStorage = async (
  taskId: string,
  fileName: string,
  axios: AxiosInstance
): Promise<string | undefined> =>
  axios
    .post("/data/task/delete/file/", { taskId: taskId, fileName: fileName })
    .then((serverResp) => (serverResp.status === 200 ? fileName : ""))
    .catch((exc) => {
      console.error("Error during delete file!", exc);
      return undefined;
    });

/**
 * loops through all files, that needs to be added and post the file on the backend synchronously
 * @param imageFiles image files to add
 * @param documentFile documents to add
 * @param taskId id of task
 * @param userId userID
 * @param axios
 * @returns list of {@link FileEntry} that were added
 */
export const postAllFiles = async (
  imageFiles: File[],
  documentFile: File[],
  taskId: string,
  userId: string,
  axios: AxiosInstance
): Promise<FileEntry[]> => {
  let addedFiles: FileEntry[] = [];

  for (const currentFile of imageFiles) {
    const addedFile: FileEntry | undefined = await uploadOutputFile(
      currentFile,
      taskId,
      userId,
      FileType.IMAGE,
      axios
    );
    if (!addedFile) {
      generateFileNotificationWithTranslations(
        "warning",
        "save",
        currentFile.name
      );
    } else {
      addedFiles.push(addedFile);
    }
  }

  for (const currentFile of documentFile) {
    const addedFile: FileEntry | undefined = await uploadOutputFile(
      currentFile,
      taskId,
      userId,
      FileType.DOCUMENT,
      axios
    );
    if (!addedFile) {
      generateFileNotificationWithTranslations(
        "warning",
        "save",
        currentFile.name
      );
    } else {
      addedFiles.push(addedFile);
    }
  }
  return addedFiles;
};

/**
 *  delete all files, that are not part of the new fileList. This method deletes all files sychronously
 * @param newFileEntriesList fileList, with files already removed
 * @param existingfileEntries  old fileList
 * @param taskId id of task
 * @param axios
 * @returns list of fileNames, that were removed
 */
export const deleteAllRemovedFiles = async (
  newFileEntriesList: FileEntry[],
  existingfileEntries: FileEntry[],
  taskId: string,
  axios: AxiosInstance
): Promise<string[]> => {
  const filesToDelete: FileEntry[] = existingfileEntries.filter(
    (fileEntry) =>
      newFileEntriesList.filter(
        (fileToCheck) => fileEntry.fileName === fileToCheck.fileName
      ).length === 0
  );
  let deletedFiles: string[] = [];

  for (const fileToDelete of filesToDelete) {
    const deletedFile: string | undefined = await deleteFileInStorage(
      taskId,
      fileToDelete.fileName,
      axios
    );

    if (!deletedFile) {
      generateFileNotificationWithTranslations(
        "warning",
        "delete",
        fileToDelete.originalFileName
      );
    } else {
      deletedFiles.push(deletedFile);
    }
  }
  return deletedFiles;
};

/**
 * DELETE API - deletes all tasks for a column with given column id on shopfloorboard with given shopfloorboardId
 * @param columnId id of column to delete
 * @param shopfloorBoardId id of shopfloorboard with the specific column
 * @param axios
 * @returns true if all tasks got deleted successfully, else returns false
 */
export const deleteAllTasksForShopfloorBoardColumn = async (
  columnId: string,
  shopfloorBoardId: string,
  axios: AxiosInstance
): Promise<boolean> =>
  axios
    .post("/data/task/delete/column/id/", {
      sfColumnId: columnId,
      shopfloorBoardId: shopfloorBoardId,
    })
    .then((response) => response.status === 200)
    .catch((exc) => {
      console.error("Error during delete file!", exc);
      return false;
    });

/**
 * generates a downloadLink for given Document with originalFileName
 * @param taskId id if the Task
 * @param file file that need to be downloaded
 * @param originalFileName original name of the file
 */
export const generateDownloadDocument = async (
  file: File,
  originalFileName?: string
): Promise<void> => {
  let objectURL = URL.createObjectURL(file);
  let anchor = document.createElement("a");
  anchor.setAttribute("href", `${objectURL}`);
  anchor.setAttribute("download", originalFileName || file.name);
  document.body.appendChild(anchor);
  anchor.click();
  anchor.parentNode!.removeChild(anchor);
};

/**
 * generates a Notification with correct translations
 * @param status
 */
export const generateFileNotificationWithTranslations = (
  status: "success" | "warning",
  action: "save" | "delete" | "fetch",
  fileName: string
): void =>
  generateNotification(
    i18next.t(`shopfloorboard.task.notification.${action}.${status}.content`, {
      fileName: fileName,
    }),
    status,
    i18next.t(`general.notification.${status}.title`)
  );

/**
 * Convert all date fields of a task object to a date instance.
 * This mutates the given task
 *
 * @param task the task which are converted back
 * @returns the mutated task
 */
export const convertTaskDates = (task: Task): Task => {
  if (task.createDate) task.createDate = convertDate(task.createDate);
  if (task.lastUpdated) task.lastUpdated = convertDate(task.lastUpdated);
  if (task.targetDate) task.targetDate = convertDate(task.targetDate);
  return task;
};

/**
 * Helper to convert string, number or number array to a date object.
 * This is needed because the backend dont send consistent dates.
 *
 * @param value the object which should converted to a date
 * @returns a date object or throw error on failure
 */
export const convertDate = (value: any): Date => {
  if (value instanceof Date) {
    return value;
  } else {
    if (typeof value === "string") {
      if (!value.endsWith("Z")) {
        value = value + "Z";
      }
      return new Date(value);
    } else if (typeof value === "number" && !isNaN(value)) {
      return new Date(value);
    } else if (typeof value === "object" && Array.isArray(value)) {
      let dateString = "";
      if (value.length >= 3) {
        dateString += `${value[0]}-${
          value[1]?.toString()?.padStart(2, "0") || "01"
        }-${value[2]?.toString()?.padStart(2, "0") || "01"}`;
      }
      if (value.length >= 6) {
        dateString += `T${value[3]?.toString()?.padStart(2, "0") || "00"}:${
          value[4]?.toString()?.padStart(2, "0") || "00"
        }:${value[5]?.toString()?.padStart(2, "0") || "00"}.${
          value[6]?.toString()?.padEnd(3, "0")?.slice(0, 3) || "000"
        }`;
      }
      dateString += "Z";
      return new Date(dateString);
    } else throw new Error("Invalid date format: " + JSON.stringify(value));
  }
};
