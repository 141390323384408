/**
 * Enum to define the current page
 */
export enum PageType {
  SHOPFLOORDASHBOARD,
  SHOPFLOORBOARD,
  DASHBOARD,
  LPA,
  INDUSTRIALTRUCKSANALYSIS,
  PROFILE,
  NOTIFICATION,
  PLATFORMADMIN_COMPANY_PANEL,
  PLATFORMADMIN_COMPANY_EDIT,
  PLATFORMADMIN_COMPANY_USER_EDIT,
  PLATFORMADMIN_COMPANY_REVIEW,
  POWER_BI,
  POWER_BI_OVERVIEW,
  TASK_OVERVIEW,
  ADMINISTRATION,
  ENERGY,
  PROTOCOL,
  ML
}
