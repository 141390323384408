import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  BoxComponent,
  BoxType,
  ButtonComponent,
  LayoutComponent,
  PopupComponent,
} from "sfm-component-library";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/clone.svg";
import { ReactComponent as PredictionIcon } from "../../assets/icons/column-graph.svg";
import { ReactComponent as GridIcon } from "../../assets/icons/grip-solid.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list-solid.svg";
import { ReactComponent as WeatherIcon } from "../../assets/icons/temperature.svg";
import EditEnergyBoardConfigPopUp from "../../components/energy/EditEnergyBoardConfigPopUp";
import { BoardCopyForm } from "../../components/shopfloorboard/board/BoardCopyForm";
import { WeatherComponent } from "../../components/weather/WeatherComponent";
import "../../styles/EnergyPageStyles.scss";
import { useAxios } from "../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { EnergyBoard } from "../../utils/energy/Energy.types";
import { copyEnergyBoard } from "../../utils/energy/Energy.utils";
import { useEnergyBoard } from "../../utils/energy/useEnergyBoard";
import { useGroups } from "../../utils/group/Group.utils";
import { generateListEntries } from "../../utils/groupSort/GroupList.utils";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { BoardCopyRequest } from "../../utils/sfboard/SfBoard.types";
import { UserAction } from "../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { UserContext } from "../App";

interface EnergyManagementPageProps {}
/**
 * enum for different types of view
 */
export enum ViewMode {
  LIST = "list",
  DEFAULT = "default",
  PREDICTION = "prediction",
  WEATHER = "weather",
}

const EnergyManagementPage: React.FC<EnergyManagementPageProps> = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const history = useHistory();
  const lastModeString = sessionStorage.getItem("lastUsedMode");
  const lastMode: ViewMode = lastModeString
    ? (lastModeString as ViewMode)
    : ViewMode.DEFAULT;
  const [viewMode, setViewMode] = useState<ViewMode>(
    lastMode || ViewMode.DEFAULT
  );
  const { user } = useContext(UserContext);
  const [showEditConfig, setShowEditConfig] = useState<boolean>(false);
  const loadedGroups = useGroups() ?? [];
  const { EnergyBoards: loadedConfigs, mutate: setConfigs } = useEnergyBoard();
  const [configToEdit, setConfigToEdit] = useState<EnergyBoard>();
  const [configToCopy, setConfigToCopy] = useState<EnergyBoard>();
  const [isLoading, setLoading] = useState<boolean>(false);

  /**
   * Helper to copy a full board.
   *
   * @param copy the copy request
   * @returns promised void to detect when process is finished
   */
  const onCopyBoard = async (copy: BoardCopyRequest): Promise<void> => {
    try {
      const result = await copyEnergyBoard(copy, axios);
      if (!result) {
        generateNotificationWithTranslations("warning");
        return;
      }
      setConfigs([...loadedConfigs, result]);
      generateNotificationWithTranslations("success");
      setConfigToCopy(undefined);
    } catch {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * Method to determine which view should be displayed
   * @param listView boolean that determines the type of view
   * @param configs ShopfloorBoardConfiguration[] that contains entries
   * @param givenUser User object
   * @param givenHistory History used to push
   * @param givenGroups Group[] to determine names for list-view
   * @returns JSX.Element containing the chosen view
   */
  const getCorrectEntryView = (viewMode: ViewMode): JSX.Element => {
    switch (viewMode) {
      case "weather":
        return (
          <WeatherComponent
            lat={user?.company?.lat ?? 0.0}
            lon={user?.company?.lon ?? 0.0}
          />
        );
      case "list":
        return loadedConfigs.length === 0 ? (
          <p>{t("energy.nothingFound")}</p>
        ) : (
          <div className="energy--list">
            {generateListEntries<EnergyBoard>({
              type: "Energy",
              user,
              history,
              entries: loadedConfigs,
              setConfigToEdit,
              setConfigToCopy,
              loadedGroups,
              setShowEditConfig,
              viewMode,
            })}
          </div>
        );
      case "prediction":
        return (
          <div className="energy__overview--prediction">
            <ButtonComponent
              title={t("energy.overview.prediction")}
              onClick={() => history.push("/energy/prediction")}
            />
          </div>
        );
      case "default":
      default:
        return loadedConfigs.length === 0 ? (
          <p>{t("energy.nothingFound")}</p>
        ) : (
          <div className="energy__overview--grid">
            {loadedConfigs.map((config, index) => (
              <BoxComponent
                background={config.color}
                type={BoxType.PACKAGE}
                key={`box-config-component-item-${index}`}
                onClick={() =>
                  history.push(`/energy/detail`, {
                    configId: config.id,
                  })
                }
                title={config.name}
                onClickConfig={() => {
                  if (
                    isUserAllowedToDoThis(UserAction.EDIT_ENERGY_CONFIG, user)
                  ) {
                    setConfigToEdit(config);
                    setShowEditConfig(true);
                  }
                }}
                hideConfig={
                  !isUserAllowedToDoThis(UserAction.EDIT_ENERGY_CONFIG, user)
                }
                buttons={[
                  {
                    icon: <CopyIcon />,
                    hidden: !isUserAllowedToDoThis(
                      UserAction.CREATE_ENERGY_CONFIG,
                      user
                    ),
                    onClick: () => setConfigToCopy(config),
                  },
                ]}
              />
            ))}
          </div>
        );
    }
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.ENERGY)}
      title={t("energy.title")}
    >
      <div className="energy__overview">
        <EditEnergyBoardConfigPopUp
          configToEdit={configToEdit}
          onSubmit={(editedConfig) => {
            if (configToEdit) {
              setConfigs(
                loadedConfigs.map((oldConfig) =>
                  oldConfig.id === editedConfig.id ? editedConfig : oldConfig
                )
              );
              setConfigToEdit(undefined);
            } else {
              setConfigs([...loadedConfigs, editedConfig]);
            }
            setShowEditConfig(false);
          }}
          isOpen={showEditConfig}
          setIsOpen={(isOpen) => {
            setShowEditConfig(isOpen);
            if (!isOpen) {
              setConfigToEdit(undefined);
            }
          }}
          onDelete={() => {
            setConfigs(
              loadedConfigs.filter((config) => config.id! !== configToEdit!.id!)
            );
            setConfigToEdit(undefined);
            setShowEditConfig(false);
          }}
        />
        {configToCopy && (
          <PopupComponent
            isOpen
            title={t("shopfloorboard.config.copy.title", {
              replace: { name: configToCopy.name },
            })}
            toggleOpen={() => setConfigToCopy(undefined)}
            footerButtons={[
              {
                title: t("general.buttons.cancel"),
                onClick: () => setConfigToCopy(undefined),
                borderColor: "black",
                className: "color-white",
                isLoading: isLoading,
              },
              {
                title: t("general.buttons.copy"),
                borderColor: "#A9FAA2",
                type: "submit",
                form: "board-copy-form",
                isLoading: isLoading,
              },
            ]}
          >
            <BoardCopyForm
              board={configToCopy}
              onCopy={async (copy) => {
                setLoading(true);
                await onCopyBoard(copy);
                setLoading(false);
              }}
            />
          </PopupComponent>
        )}
        <div className="energy__overview--adding">
          <div className="energy__overview--adding-view">
            <GridIcon
              className={[
                "view-svg",
                viewMode === "default" ? "active" : "",
              ].join(" ")}
              onClick={() => setViewMode(ViewMode.DEFAULT)}
            />
            <ListIcon
              className={[
                "view-svg",
                "list-icon",
                viewMode === ViewMode.LIST ? "active" : "",
              ].join(" ")}
              onClick={() => setViewMode(ViewMode.LIST)}
            />
            <PredictionIcon
              className={[
                "view-svg",
                "list-icon",
                viewMode === ViewMode.PREDICTION ? "active" : "",
              ].join(" ")}
              onClick={() => history.push("/energy/prediction")}
            />
            {!!user?.company?.lon && !!user?.company?.lat && (
              <WeatherIcon
                className={[
                  "view-svg",
                  "list-icon",
                  viewMode === ViewMode.WEATHER ? "active" : "",
                ].join(" ")}
                onClick={() => setViewMode(ViewMode.WEATHER)}
              />
            )}
          </div>
          {isUserAllowedToDoThis(UserAction.CREATE_ENERGY_CONFIG, user) && (
            <AddIcon onClick={() => setShowEditConfig(true)} />
          )}
        </div>
        {getCorrectEntryView(viewMode)}
      </div>
    </LayoutComponent>
  );
};

export default EnergyManagementPage;
