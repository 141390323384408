import { AxiosInstance } from "axios";
import i18next from "i18next";
import { generateNotification, Option } from "sfm-component-library";
import { DataApiEntry } from "../apidata/DataApi.types";
import { Task } from "../tasks/Tasks.types";
import { User } from "../user/User.types";
import {
  AvgColumnConfiguration,
  ConnectorType,
  defaultAvgColumnConfiguration,
  defaultColumnColor,
  ShopfloorBoardColumn,
  ShopfloorBoardPerformanceEntry,
} from "./SfBoard.types";

/**
 * API METHOD - creates a new performance entry on the server
 *
 * @param newEntry
 * @param axios
 * @returns boolean if status code is 201 (CREATED)
 */
export const createNewPerformanceEntry = async (
  newEntry: ShopfloorBoardPerformanceEntry,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/data/sfboard", newEntry)
    .then((serverResp) => serverResp.status === 201)
    .catch((exc) => {
      console.error("Error during sfboard performance data fetch!", exc);
      return false;
    });
};

/**
 * API METHOD - to uplaod a csv file for importing several performance entries
 *
 * @param axios AxiosInstance for server communication
 * @param csvFile the data to import
 * @param sfBoardId id of the sf board which should import the csv data
 * @param sfBoardColumnId id of the current column to import data into
 * @param userId the id of the current logged in user
 * @returns boolean if server response is 200
 */
export const uploadCsvFileForImportingPerformanceEntries = async (
  axios: AxiosInstance,
  csvFile: File,
  sfBoardId: string,
  sfBoardColumnId: string,
  userId: string
): Promise<ShopfloorBoardPerformanceEntry[]> => {
  let uploadFormData = new FormData();
  const userIdBlob = new Blob([userId], {
    type: "application/json",
  });
  uploadFormData.append("userId", userIdBlob);
  const sfBoardColumnIdBlob = new Blob([sfBoardColumnId], {
    type: "application/json",
  });
  uploadFormData.append("sfBoardColumnId", sfBoardColumnIdBlob);

  const sfBoardIdBlob = new Blob([sfBoardId], {
    type: "application/json",
  });
  uploadFormData.append("sfBoardId", sfBoardIdBlob);
  uploadFormData.append("csvFile", csvFile);

  return axios
    .post("/data/data/sfboard/board/csv/", uploadFormData)
    .then((res) => res.data)
    .catch((exc) => {
      if (exc.status === 406) {
        generateCSVUploadNotificationWithTranslations("wrongFormat");
      } else {
        generateCSVUploadNotificationWithTranslations("warning");
      }
      console.error("Error during sfboard performance csv data import!", exc);
      return false;
    });
};

/**
 * API METHOD - loads a new performance entry on the server
 *
 * @param newEntry
 * @param axios
 * @returns list of performance entries for a board
 */
export const loadPerformanceDataForBoard = async (
  boardId: string,
  axios: AxiosInstance
): Promise<ShopfloorBoardPerformanceEntry[]> => {
  return axios
    .get("/data/data/sfboard/", { params: { boardId: boardId } })
    .then((serverResp) => serverResp.data)
    .catch((exc) =>
      console.error("Error during sfboard performance data fetch!", exc)
    );
};

/**
 * Helper to cut a text to be a specific max length
 *
 * @param textToCut
 * @param maxLength
 * @returns cutted text with three ending dots
 */
export const cutTextAfterMaxLength = (
  textToCut: string,
  maxLength: number = 150
): string => {
  if (!textToCut) return "";
  let cuttedText: string = "";
  const splittedText: string[] = textToCut.split(" ");
  let isTextCutted: boolean = false;
  for (let i = 0; i < splittedText.length; i++) {
    if (splittedText[i].length + cuttedText.length > maxLength) {
      isTextCutted = true;
      break;
    }
    cuttedText =
      cuttedText.length === 0
        ? splittedText[i]
        : [cuttedText, splittedText[i]].join(" ");
  }
  return `${cuttedText}${isTextCutted ? "..." : ""}`;
};

/**
 * API METHOD - for loading the count of all shopfloorboard by company
 *
 * @param companyId the company id
 * @param userId the user id
 * @param axios the axios instance
 * @returns number of shopfloorboards
 */
export const loadCountOfAllShopfloorboardsByCompany = async (
  companyId: string,
  userId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get("/data/config/sfboard/company/count/", {
      params: { companyId, userId },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during sfboard count fetch!", exc);
      return -1;
    });
};

/**
 * API METHOD - for loading all performance entries of a board
 *
 * @param sfBoardId
 * @param axios
 * @returns list of performance entries
 */
export const loadAllPerformanceEntryForShopfloorBoard = async (
  sfBoardId: string,
  axios: AxiosInstance
): Promise<ShopfloorBoardPerformanceEntry[]> => {
  return axios
    .get("/data/data/sfboard/board/all/", { params: { boardId: sfBoardId } })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during sfboard performance entries fetch!", exc);
      return [];
    });
};

/**
 * API METHOD - creates a performance entry for a board
 *
 * @param entry
 * @param axios
 * @returns newly created performance entry
 */
export const createNewPerformanceEntryForShopfloorBoard = async (
  entry: ShopfloorBoardPerformanceEntry,
  axios: AxiosInstance
): Promise<ShopfloorBoardPerformanceEntry> => {
  return axios
    .post("/data/data/sfboard/", entry)
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during sfboard performance entries creation!", exc);
      return [];
    });
};

/**
 * API METHOD - updates a performance entry on the server
 *
 * @param entry
 * @param axios
 * @returns true or false if it is successful
 */
export const updatePerformanceEntryForShopfloorBoard = async (
  entry: ShopfloorBoardPerformanceEntry,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/data/sfboard/update/", entry)
    .then((serverResp) => serverResp.status === 200)
    .catch((exc) => {
      console.error("Error during sfboard performance entries update!", exc);
      return false;
    });
};

/**
 * API METHOD - loads all Tasks for a board
 *
 * @param sfBoardId
 * @param axios
 * @returns list of Tasks
 */
export const loadAllTasksForShopfloorBoard = async (
  sfBoardId: string,
  axios: AxiosInstance
): Promise<Task[]> => {
  return axios
    .get("/data/task/get/sfboard/", { params: { sfBoardId } })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during sfboard task fetch!", exc);
      return [];
    });
};

/**
 * API METHOD - to create a new Task on the server
 *
 * @param task
 * @param axios
 * @returns new created Task or null
 */
export const saveTaskForShopfloorBoard = async (
  task: Task,
  axios: AxiosInstance
): Promise<Task> => {
  return axios
    .post("/data/task/", task)
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during task creation fetch!", exc);
      return null;
    });
};

/**
 * API METHOD - to delete Task on server
 * @param taskId id of Task, that needs to be deleted
 * @param axios
 * @returns true if successful, else returns false
 */
export const deleteTaskForShopfloorBoard = async (
  taskId: string,
  axios: AxiosInstance
): Promise<boolean> =>
  axios
    .post("/data/task/delete/", taskId)
    .then((serverResp) => serverResp.status === 200)
    .catch((exc) => {
      console.error("Error during task delete !", exc);
      return false;
    });

/**
 * API METHOD - to update a specific Task on the server
 *
 * @param task id of Task, that needs to be deleted
 * @param axios
 * @returns true if status code is 200 false otherwise
 */
export const updateTaskForShopfloorBoard = async (
  task: Task,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/task/update/", task)
    .then((serverResp) => serverResp.status === 200)
    .catch((exc) => {
      console.error("Error during task update fetch!", exc);
      return false;
    });
};

/**
 * Helper to generate a option list from all columns of sfboard
 *
 * @param columns
 * @returns list of options
 */
export const generateColumnsOptions = (
  columns: ShopfloorBoardColumn[]
): Option[] => {
  let generatedOptions: Option[] = [];
  columns.forEach((column) =>
    generatedOptions.push({ label: column.name, value: column.id })
  );
  return generatedOptions;
};

/**
 * Helper method to generate a SVG of the filled quarter circle
 *
 * @param filledQuarters Amount of quarters to be filled
 * @returns A SVG tag with the filled quarter circle
 */
export const createQuarterCircle = (
  filledQuarters: number
): React.ReactNode => {
  let fillPath: string = "";
  // the cast is needed because after an update the number is given as string
  switch (Number(filledQuarters)) {
    case 1:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 25,15 z";
      break;
    case 2:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 15,25 z";
      break;
    case 3:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 15,25 A10,10 1 0,1 5,15 z";
      break;
    case 4:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 15,25 A10,10 1 0,1 15,5 z";
  }
  return (
    <svg
      className={["pdca-quartered-circle", `status-${filledQuarters}`].join(
        " "
      )}
    >
      <circle cx="15" cy="15" r="10" />
      <path d={fillPath} />
    </svg>
  );
};

/**
 * checks all given performanceEntries and checks for the one with the maximum value
 * @param performanceEntries
 * @returns
 */
export const getMaxPerformanceEntry = (
  performanceEntries: ShopfloorBoardPerformanceEntry[]
): number =>
  performanceEntries.length === 0
    ? -1
    : Math.max(...performanceEntries.map((entry) => entry.currentValue));

/**
 * Helper to generate a list of user / ids for a dropdown component
 *
 * @param users
 * @returns option list
 */
export const generateOptionsForUsers = (users: User[]): Option[] => {
  return users.map((user) => {
    return { label: `${user.firstname} ${user.lastname}`, value: user.id! };
  });
};

/**
 * HELPER function to update or add a new performance Entry to and existing list of {@link ShopfloorBoardPerformanceEntry}
 * @param updatedOrNewEntry newly added or updated performance Entry
 * @param performanceEntries list with performance Entries
 * @returns updated list of performance Entries
 */
export const updatePerformanceEntries = (
  updatedOrNewEntry: ShopfloorBoardPerformanceEntry,
  performanceEntries: ShopfloorBoardPerformanceEntry[]
) => {
  if (
    performanceEntries.find(
      (performanceEntry) => performanceEntry.id === updatedOrNewEntry.id
    )
  ) {
    return performanceEntries.map((performanceEntry) => {
      if (updatedOrNewEntry.id === performanceEntry.id) {
        return updatedOrNewEntry;
      }
      return performanceEntry;
    });
  }
  return [...performanceEntries, updatedOrNewEntry];
};

/**
 * generates a Notification with correct translations
 * @param status of the response, which can be  "success", "warning" or "wrongFormat"
 */
export const generateCSVUploadNotificationWithTranslations = (
  status: "success" | "warning" | "wrongFormat"
): void =>
  generateNotification(
    i18next.t(
      status === "wrongFormat"
        ? `column.notification.wrongFormat.content`
        : `general.notification.${status}.content`
    ),
    status === "wrongFormat" ? "warning" : status,
    i18next.t(
      `general.notification.${
        status === "wrongFormat" ? "warning" : status
      }.title`
    )
  );

export interface AvgPerfomanceEntryValueResult {
  color: string;
  achievedPercentage: number;
  isTrending: "up" | "down" | "danger";
}

type HelperPerformanceEntry =
  | ShopfloorBoardPerformanceEntry
  | { currentValue: number; targetValue: number; createDate: Date };

/**
 * get average performance Entry value for a given column
 * @param performanceEntries performance Entry values of given column
 * @param column the column for which the average value is to be calculated
 * @param now the current date used to calculate the average value (default: current time)
 * @returns the average value in percentage, the right color to show and the trend
 * @tested
 */
export const getAvgPerfomanceEntryValue = (
  performanceEntries: ShopfloorBoardPerformanceEntry[],
  column: ShopfloorBoardColumn,
  now: Date = new Date()
): AvgPerfomanceEntryValueResult => {
  const avgConfig: AvgColumnConfiguration =
    column.avgCountConfig || defaultAvgColumnConfiguration;

  let listOfDatesToCheck: Date[] = [];

  let tempDate: Date = new Date(now);
  while (listOfDatesToCheck.length < avgConfig.datesToCount) {
    if (
      avgConfig.countWeekends ||
      tempDate.getDay() !== 6 ||
      tempDate.getDay() !== 0
    ) {
      listOfDatesToCheck.push(new Date(tempDate));
    }

    tempDate.setDate(tempDate.getDate() - 1);
  }

  const foundPerformanceEntries: HelperPerformanceEntry[] = listOfDatesToCheck
    .map(
      (dayToCheck) =>
        performanceEntries.find((performanceEntry) => {
          const createDate = new Date(performanceEntry.createDate);
          return (
            createDate.getDay() === dayToCheck.getDay() &&
            createDate.getMonth() === dayToCheck.getMonth() &&
            createDate.getFullYear() === dayToCheck.getFullYear()
          );
        }) || {
          currentValue: 0,
          targetValue: column.targetValue,
          createDate: dayToCheck,
        }
    )
    .sort((firstPerformanceEntry, secondPerformanceEntry) => {
      return (
        new Date(firstPerformanceEntry.createDate).getTime() -
        new Date(secondPerformanceEntry.createDate).getTime()
      );
    });

  const avg: number =
    foundPerformanceEntries.reduce(
      (avgSum: number, currentAvg: HelperPerformanceEntry) => {
        if (currentAvg.targetValue > 0) {
          return avgSum + currentAvg.currentValue / currentAvg.targetValue;
        }
        return avgSum + currentAvg.currentValue;
      },
      0
    ) / avgConfig.datesToCount;

  let activeColor: string | undefined;
  column.visualConfig?.colorThresholds.forEach((threshold, index) => {
    if (activeColor) return;

    let thresholdToCalc = 0;
    if (column.visualConfig?.colorThresholdIsPercentage) {
      thresholdToCalc = threshold / 100;
    } else if (!!column.visualConfig?.maxValue) {
      thresholdToCalc = threshold / column.visualConfig.maxValue;
    } else {
      const tempTargetValue =
        foundPerformanceEntries[foundPerformanceEntries.length - 1]
          ?.targetValue ?? 0;
      thresholdToCalc = tempTargetValue ? threshold / tempTargetValue : 0;
    }

    if (avg <= thresholdToCalc) {
      activeColor = column.visualConfig!.colorList.find(
        (columnColor) => columnColor.index === index
      )?.color;
    }
  });

  if (!activeColor) {
    activeColor = column.visualConfig
      ? column.visualConfig.colorList.find(
          (colorEntry) =>
            colorEntry.index === column.visualConfig!.colorList.length - 1
        )?.color
      : defaultColumnColor.colorList.find(
          (colorEntry) => colorEntry.index === 2
        )?.color;
  }

  let isTrending: "up" | "down" | "danger";
  if (
    avgConfig.enableMaxFailCount &&
    foundPerformanceEntries.filter(
      (performanceEntry) =>
        performanceEntry.currentValue < performanceEntry.targetValue
    ).length > avgConfig.maxFailCount
  ) {
    isTrending = "danger";
  } else if (foundPerformanceEntries.length < 2) {
    isTrending = "up";
  } else if (
    foundPerformanceEntries[foundPerformanceEntries.length - 1].currentValue >=
    foundPerformanceEntries[foundPerformanceEntries.length - 2].currentValue
  ) {
    isTrending = "up";
  } else {
    isTrending = "down";
  }
  return {
    color: activeColor!,
    achievedPercentage: Math.round(avg * 1000) / 1000,
    isTrending: isTrending,
  };
};

/**
 * Convert the dataApi entries to sfb performance entries which can be handled by the column viewer.
 *
 * @tested
 * @param param0 the dataApi entry
 * @param boardId the boardId
 * @param columnId the columnId
 * @returns converted sfb performance entry
 */
export const convertDataApiEntryToPerformanceEntry = (
  { currentValue, targetValue, date }: DataApiEntry,
  boardId: string,
  columnId: string
): ShopfloorBoardPerformanceEntry => ({
  boardId,
  columnId,
  createDate: date,
  createdBy: "",
  fulfillment: 0,
  currentValue,
  targetValue,
});

// TODO tests
/**
 * Helper to apply the target value to a list of shopfloorboard performance entries.
 * Because when in no target is specified the backend sent zero as target.
 * These zeros are replaced with the target value of the specific connector.
 * The Connector target can also be zero then it remain zero.
 *
 *
 * @param entries a list of shopfloorboard performance entries.
 * Its recommended that all entries belongs to the same column
 * @param column the column which the target data is extracted.
 * @returns the modified performance entries
 */
export const applyDefaultTarget = (
  entries: ShopfloorBoardPerformanceEntry[],
  column: ShopfloorBoardColumn
): ShopfloorBoardPerformanceEntry[] => {
  return entries.map((entry) => {
    if (!entry.targetValue) {
      switch (column.connectorType) {
        case ConnectorType.MANUAL:
        case ConnectorType.CSV_IMPORT:
          entry.targetValue = column.targetValue ?? 0;
          return entry;
        case ConnectorType.DATA_API:
          entry.targetValue = column.connectorInfo?.dataApi?.defaultTarget ?? 0;
          return entry;
        default:
          return entry;
      }
    } else return entry;
  });
};
