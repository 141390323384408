import { AxiosInstance } from "axios";
import { generateNotification } from "sfm-component-library";
import i18n from "../../i18n";

/**
 * POST API - marks all notifications as seen
 * @param axios
 * @param userId id of the current user
 * @param notifications notifications to mark. if not specified, marks all notifications of the current user
 * @returns true if successful, else returns false
 */
export const markNotificationsAsSeen = (
  axios: AxiosInstance,
  userId: string,
  notifications?: string[]
) =>
  axios
    .post("/data/notification/mark/seen/", {
      userId: userId,
      notificationList: notifications,
    })
    .then((res) => res.status === 200)
    .catch(() => {
      generateNotification(
        i18n.t("notifications.markAll.error"),
        "warning",
        i18n.t("notifications.general.warning")
      );
    });
