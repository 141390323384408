import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  LoaderComponent,
  PopupComponent,
  TableComponent,
} from "sfm-component-library";
import { RowEntry } from "sfm-component-library/build/TableComponent/TableComponent.types";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import { fetchSingleCompany } from "../../utils/company/CompanyUtils";
import {
  generateNotificationWithTranslations,
  useResource,
} from "../../utils/GeneralUtils";
import { createEmptyUser, User, UserRole } from "../../utils/user/User.types";
import {
  deleteUserFromBackend,
  loadAllUsersFromBackendByCompanyId,
  updateUserOnBackend,
} from "../../utils/user/User.utils";
import { ReactComponent as Person } from "../../assets/icons/person.svg";
import { ReactComponent as PersonOff } from "../../assets/icons/person_off.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as PersonAddIcon } from "../../assets/icons/person_add.svg";
import UserEditForm from "../shopfloorboard/administration/user/UserEditForm";
import DeletePopUp from "../deletepopup/DeletePopUp";

export interface UserAdminProps {
  companyId?: string; // needed for platform administration
}

export const UserAdmin: React.FC<UserAdminProps> = ({ companyId }) => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const [isLoading, setLoading] = useState<
    "users" | "update" | "delete" | undefined
  >("users");
  const [loadedUsers, setLoadedUsers] = useState<User[]>([]);
  const [userToEdit, setUserToEdit] = useState<User>();
  const [isDeletePopup, setDeletePopup] = useState<boolean>(false);

  /**
   * Fetch to current company of the logged in user.
   * Expect if the user is a platform admin then fetch the companyId which is given via the component props
   *
   * @return a {@link Company} or undefined while loading
   */
  const currentCompany = useResource(async (axios, { user }) => {
    if (user.role === UserRole.PLATFORM_ADMIN) {
      if (!companyId) return;
      return await fetchSingleCompany(axios, companyId);
    } else {
      return user.company;
    }
  });

  /**
   * Loads the user list from the backend
   */
  useEffect(() => {
    if (!currentCompany?.id) return;
    setLoading("users");
    loadAllUsersFromBackendByCompanyId(currentCompany.id, axios).then(
      (users) => {
        setLoadedUsers(users);
        setLoading(undefined);
      }
    );
  }, [currentCompany, axios]);

  /**
   * Update the enable status of a given user
   *
   * @param event the MouseEvent to automatically prevent default behavior of the event
   * @param updateUser the user which should be updated
   * @param enabled the new enabled prop of the user
   */
  const setUserEnabled = useCallback(
    async (
      event: React.MouseEvent,
      updateUser: User,
      enabled: boolean
    ): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();

      const updatedUser: User = { ...updateUser, enabled };
      const updatedUsers = loadedUsers.map((entry) =>
        entry.id === updateUser.id ? updatedUser : entry
      );
      const success = await updateUserOnBackend(updatedUser, axios);

      if (!success) {
        generateNotificationWithTranslations("warning");
        return;
      }

      setLoadedUsers(updatedUsers);
      generateNotificationWithTranslations("success");
    },
    [axios, loadedUsers]
  );

  /**
   * Render the correct person svg based on user enable status with a toggle function.
   * If user is current logged in user no function will passed
   *
   * @param userInList the user to use
   * @returns a svg based on enabled status, or with lower opacity for own user
   */
  const renderUserEnabledIcon = useCallback(
    (userInList: User): JSX.Element => {
      if (user?.id === userInList.id)
        return <Person className="user-admin--self" />;

      const PersonIcon = userInList.enabled ? Person : PersonOff;
      return (
        <PersonIcon
          onClick={(evt) =>
            setUserEnabled(evt, userInList, !userInList.enabled)
          }
        />
      );
    },
    [user, setUserEnabled]
  );

  /**
   * Open the user editor.
   *
   * @param event the MouseEvent to automatically prevent default behavior of the event
   * @param editUser the user to edit
   */
  const openUserEditor = useCallback(
    (event: React.MouseEvent, editUser: User) => {
      event.preventDefault();
      event.stopPropagation();
      setUserToEdit(editUser);
    },
    []
  );

  /**
   * Generate the user entries for the table component
   *
   * @return a list of {@link RowEntry}s
   */
  const userEntries: RowEntry[] = useMemo(
    () =>
      loadedUsers.map((listedUser) => ({
        content: [
          listedUser.firstname,
          listedUser.lastname,
          listedUser.mail,
          renderUserEnabledIcon(listedUser),
          listedUser.lastLogin
            ? `${new Date(listedUser.lastLogin).toLocaleString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })} (${listedUser.loginAmount || 0})`
            : `${t("administration.noLoginYet")}`,
          <EditIcon onClick={(evt) => openUserEditor(evt, listedUser)} />,
        ],
        id: listedUser.id!,
      })),
    [renderUserEnabledIcon, openUserEditor, loadedUsers, t]
  );

  /**
   * Delete a given user on the backend
   *
   * @param deleteUser the user which should be deleted
   */
  const deleteUser = useCallback(
    (deleteUser: User) => {
      if (!deleteUser.id) return;
      setLoading("delete");
      deleteUserFromBackend(deleteUser.id, axios).then((success) => {
        if (!success) {
          generateNotificationWithTranslations("warning");
          setLoading(undefined);
          return;
        }
        setUserToEdit(undefined);
        setDeletePopup(false);
        setLoading(undefined);
        setLoadedUsers((list) =>
          list.filter((entry) => entry.id !== deleteUser.id)
        );
        generateNotificationWithTranslations("success");
      });
    },
    [axios]
  );

  /**
   * Update the user list and add/replace the given user
   *
   * @param updatedUser the updated user
   */
  const updateUsers = useCallback(
    (updatedUser: User) => {
      // check if user already exists or is new
      if (loadedUsers.find((entry) => entry.id === updatedUser.id)) {
        setLoadedUsers((list) =>
          list.map((entry) =>
            entry.id === updatedUser.id ? updatedUser : entry
          )
        );
      } else {
        setLoadedUsers((list) => [...list, updatedUser]);
      }
    },
    [loadedUsers]
  );

  return (
    <>
      {userToEdit && (
        <PopupComponent
          isOpen
          toggleOpen={() => setUserToEdit(undefined)}
          footerButtons={[
            {
              title: t("general.buttons.delete"),
              type: "button",
              disabled: !userToEdit?.id,
              onClick: () => setDeletePopup(true),
              borderColor: "#f86b6b",
              isLoading: isLoading === "update",
            },
            {
              title: t("general.buttons.save"),
              form: "user-edit-from",
              isLoading: isLoading === "update",
              borderColor: "#a9faa2",
            },
          ]}
        >
          <UserEditForm
            userToEdit={userToEdit}
            closePopup={() => setUserToEdit(undefined)}
            isLoading={(loading) => setLoading(loading ? "update" : undefined)}
            updateUser={updateUsers}
            portal={document.body}
          />
        </PopupComponent>
      )}
      <DeletePopUp
        isOpen={!!userToEdit && isDeletePopup}
        title={t("administration.userDeleteConfirmation")}
        toggleOpen={() => setDeletePopup(false)}
        content={
          <p className="default-admin--delete-popup">
            {t("administration.deleteUser", {
              replace: {
                username: `${userToEdit?.firstname} ${userToEdit?.lastname}`,
              },
            })}
          </p>
        }
        deleteIsLoading={isLoading === "delete"}
        onDelete={() => userToEdit?.id && deleteUser(userToEdit)}
      />
      <div className="default-admin">
        <div className="default-admin--header">
          <ButtonComponent
            title={
              <div className="default-admin--header--add">
                <p>{t("administration.addUser")}</p>
                <PersonAddIcon />
              </div>
            }
            borderColor={"#000000"}
            onClick={() =>
              setUserToEdit(createEmptyUser(currentCompany?.id || ""))
            }
          />
        </div>
        {isLoading === "users" ? (
          <LoaderComponent />
        ) : (
          <TableComponent
            listEntries={userEntries}
            customTag={[
              { flex: 4 },
              { color: "black", flex: 4 },
              { flex: 10 },
              { flex: 1 },
              { flex: 5 },
              { flex: 1 },
            ]}
            searchPlaceholder={t("general.searchPlaceholder")}
            searchButtonTitle={t("general.buttons.search")}
            showIndex
            searchable
          />
        )}
      </div>
    </>
  );
};
