import { useEffect } from "react";

/**
 * Hook to listen on a resize event of a given element (default: window)
 *
 * @param trigger the listener with event and element
 * @param listenOn optional element to listen on (default: window)
 */
export const useResize = <T extends HTMLElement | (Window & typeof globalThis)>(
  trigger: (event: Event, element: T) => void,
  listenOn: T | null | undefined = window as T,
  deps?: React.DependencyList | undefined
): void => {
  useEffect(() => {
    if (!listenOn) return;
    const listener: EventListenerOrEventListenerObject = (evt: Event) =>
      trigger(evt, listenOn);
    listener(new Event("resize"));

    listenOn.addEventListener("resize", listener);
    return () => listenOn.removeEventListener("resize", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenOn, trigger, ...(deps ?? [])]);
};
