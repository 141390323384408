import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LayoutComponent,
  LoaderComponent,
  PopupComponent,
  TableComponent,
  TextInputComponent,
} from "sfm-component-library";
import { useAxios } from "../../utils/AxiosUtil";
import { Company } from "../../utils/company/Company.types";
import {
  activateCompany,
  deleteCompany,
  fetchAllPendingCompanies,
} from "../../utils/company/CompanyUtils";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import "./../../styles/CompanyReviewPageStyles.scss";
import {
  generateNotificationWithTranslations,
  useResource,
} from "../../utils/GeneralUtils";
import { loadAllUsersFromBackendByCompanyId } from "../../utils/user/User.utils";
import { User } from "../../utils/user/User.types";
import { RowEntry } from "sfm-component-library/build/TableComponent/TableComponent.types";

/**
 * Fields of company which are showing in the detail page
 */
const detailsCompanyFields: (keyof Company)[] = [
  "name",
  "street",
  "zip",
  "city",
  "country",
];

/**
 * Fields of user which are showing in the detail page
 */
const detailsUserFields: (keyof User)[] = [
  "firstname",
  "lastname",
  "mail",
  "username",
];

export const CompanyReviewPage: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const [isLoading, setLoading] = useState<"companies" | "action" | undefined>(
    "companies"
  );
  const [pendingCompanies, setPendingCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company>();

  /**
   * Load all pending companies
   */
  useEffect(() => {
    fetchAllPendingCompanies(axios).then((companies) => {
      setPendingCompanies(companies);
      setLoading(undefined);
    });
  }, [axios]);

  /**
   * Load all user within the sectected company (and change everything a new company is selected)
   */
  const selectedCompanyUsers = useResource(async () => {
    if (!selectedCompany) return;
    return loadAllUsersFromBackendByCompanyId(selectedCompany.id!, axios);
  }, [axios, selectedCompany]);

  /**
   * Helper function to delete the company locally in the table list
   *
   * @param companyId the id of the company
   */
  const removeCompanyLocally = useCallback((companyId: string) => {
    setPendingCompanies((current) =>
      current.filter((entry) => entry.id !== companyId)
    );
  }, []);

  /**
   * Accept a company - mark the company as active and send the user credentials
   *
   * @param companyId the id of the company
   */
  const acceptCompany = useCallback(
    (companyId: string) => {
      setLoading("action");
      activateCompany(axios, companyId)
        .then(() => {
          setLoading(undefined);
          removeCompanyLocally(companyId);
          generateNotificationWithTranslations("success");
        })
        .catch(() => {
          setLoading(undefined);
          generateNotificationWithTranslations("warning");
        });
    },
    [axios, removeCompanyLocally]
  );

  /**
   * Decline a company -> remove the company and the user gets notified
   *
   * @param companyId the id of the company
   */
  const declineCompany = useCallback(
    (companyId: string): void => {
      setLoading("action");
      deleteCompany(axios, companyId)
        .then(() => {
          setLoading(undefined);
          removeCompanyLocally(companyId);
          generateNotificationWithTranslations("success");
        })
        .catch(() => {
          setLoading(undefined);
          generateNotificationWithTranslations("warning");
        });
    },
    [axios, removeCompanyLocally]
  );

  /**
   * Generates the entry list for the table component.
   * If the pending companies are empty, a message will be showing
   */
  const tableEntries = useMemo<RowEntry[]>(
    () =>
      pendingCompanies.length === 0
        ? [
            {
              content: [
                t("superAdministration.companyReview.empty").toString(),
              ],
              id: "",
            },
          ]
        : pendingCompanies.map((company: Company) => ({
            content: [
              company.name,
              company.city,
              company.country,
              <div className="company-review--actions">
                <CheckIcon
                  fill="#a9faa2"
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    acceptCompany(company.id!);
                  }}
                />
                <CloseIcon
                  color="#f86b6b"
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    declineCompany(company.id!);
                  }}
                />
              </div>,
            ],
            id: company.id!,
          })),
    [acceptCompany, declineCompany, pendingCompanies, t]
  );

  /**
   * Render the correct company table
   *
   * @returns TableComponent with Companies, when fetch is finished, else returns a LoaderComponent
   */
  const renderCompanyTable = (): JSX.Element =>
    isLoading === "companies" ? (
      <LoaderComponent />
    ) : (
      <TableComponent
        listEntries={tableEntries}
        customTag={[{ flex: 4 }, { flex: 4 }, { flex: 4 }, { flex: 1 }]}
        onClick={(id) =>
          setSelectedCompany(pendingCompanies.find((entry) => entry.id === id))
        }
        searchPlaceholder={t("general.searchPlaceholder")}
        searchButtonTitle={t("general.buttons.search")}
        showIndex
        searchable
      />
    );

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.PLATFORMADMIN_COMPANY_REVIEW)}
    >
      <PopupComponent
        isOpen={!!selectedCompany}
        toggleOpen={() => setSelectedCompany(undefined)}
        title={selectedCompany?.name}
        className="company-review--details"
      >
        <h3>{t("general.company")}</h3>
        {detailsCompanyFields.map((key) => (
          <TextInputComponent
            readOnly
            value={selectedCompany?.[key] as string}
            label={t(`company.${key}`)}
          />
        ))}
        <h3>{t("general.users")}</h3>
        {(selectedCompanyUsers ?? []).map((user) => (
          <>
            {detailsUserFields.map((key) => (
              <TextInputComponent
                readOnly
                value={user?.[key] as string}
                label={t(`profile.${key}`)}
              />
            ))}
          </>
        ))}
      </PopupComponent>
      {renderCompanyTable()}
    </LayoutComponent>
  );
};
