import { v4 as uuidv4 } from "uuid";
import { FeatureConfiguration } from "../configuration/FeatureConfiguration.types";
import { getRandomColor } from "../GeneralUtils";
import { FeatureModule } from "../license/License.types";
import { User } from "../user/User.types";

export interface ShopfloorBoardConfiguration extends FeatureConfiguration {
  name: string;
  color: string;
  boardResponsible: string;
  groups: string[];
  showGroupsOnly: boolean;
  columns: ShopfloorBoardColumn[];
  visualConfig?: VisualBoardConfiguration;
}

/**
 * Configuration Object for configuring the Visual representation of a Shopfloor board
 */
export interface VisualBoardConfiguration {
  /**
   * The maximal number of columns showing per page.
   *
   * Negative number means auto mode
   * (manual value can easily restored by converting to positive number).
   */
  maxColumns?: number;

  /**
   * The number of seconds how long a single column is showing.
   *
   * Example: if duration = 1 (sec) and 3 columns are showing in one page
   * then the next page will appear in 3s
   * (duration x showing columns = 1s * 3columns = 3s until next page)
   */
  kioskColumnDuration?: number;

  /**
   * A day offset for the displayed data
   */
  dayOffset?: number;
}

/**
 * Column Object for columns inside a {@link ShopfloorBoardConfiguration}
 */
export interface ShopfloorBoardColumn {
  id: string;
  name: string;
  unit?: string;
  responsibleUser: string;
  boardConfigId?: string;
  connectorType: ConnectorType;
  connectorInfo: ConnectorInfo;
  lastTargetUpdate?: Date;
  targetValue: number;
  visualConfig?: VisualColumnConfiguration;
  avgCountConfig?: AvgColumnConfiguration;
}

/**
 * Configuration Object for configuring the Visual representation of a {@link ShopfloorBoardColumn}
 */
export interface VisualColumnConfiguration {
  colorList: ShopfloorColumnColor[];
  colorThresholds: number[];
  colorThresholdIsPercentage: boolean;
  maxValue?: number;
  useCustomMaxValue?: boolean;
  /** Indicates whether the weekend is shown in the chart */
  chartShowWeekend?: boolean;
}

/**
 * Configuration Object for configuring, how the average value of a {@link ShopfloorBoardColumn} should be calculated
 */
export interface AvgColumnConfiguration {
  datesToCount: number;
  maxFailCount: number;
  enableMaxFailCount: boolean;
  countWeekends: boolean;
}

/**
 * color of a {@link ShopfloorBoardColumn} with an index property
 */
export interface ShopfloorColumnColor {
  index: number;
  color: string;
}

/**
 * Defines the type which connector is used for a column.
 * Where comes the data.
 */
export enum ConnectorType {
  MANUAL = "MANUAL",
  CSV_IMPORT = "CSV_IMPORT",
  DATA_API = "DATA_API",
}

export interface ConnectorInfo {
  // Connectors
  dataApi?: DataApiConnectorInfo;

  // Common settings
  lastUpdated?: Date;
}

/**
 * Connector info of the data api connector.
 */
export interface DataApiConnectorInfo {
  id?: string;
  defaultTarget?: number;
}

/**
 * Generate new shopfloorboard with default values.
 *
 * @param createUser the user which created the board
 * @returns the generated {@link ShopfloorBoardConfiguration}
 */
export const generateNewShopfloorBoardConfig = (
  createUser: User
): ShopfloorBoardConfiguration => ({
  name: "",
  boardResponsible: createUser.id!,
  createdBy: createUser.id!,
  lastUpdatedBy: createUser.id!,
  columns: [],
  companyId: createUser.companyId,
  createDate: new Date(),
  featureModule: FeatureModule.SHOPFLOOR_BOARD,
  version: 0,
  color: getRandomColor(),
  groups: [],
  showGroupsOnly: false,
});

/**
 * Helper to generate a new column in a shopfloor board
 * @param userId to determine which user created it
 * @returns newly created column in shopfloor board
 */
export const generateNewShopfloorBoardColumn = (
  userId: string
): ShopfloorBoardColumn => ({
  id: uuidv4(),
  name: "",
  responsibleUser: userId,
  connectorType: ConnectorType.MANUAL,
  connectorInfo: {},
  targetValue: 0,
  visualConfig: defaultColumnColor,
  avgCountConfig: defaultAvgColumnConfiguration,
});

/**
 * a default {@link VisualColumnConfiguration} object, that is defined as a environment variable
 */
export const defaultColumnColor: VisualColumnConfiguration = {
  colorList: process.env
    .REACT_APP_VISUAL_COL_CONFIG_COLORLIST!.split(",")
    .map((color, currIndex) => ({ index: currIndex, color: color })),
  colorThresholds: process.env
    .REACT_APP_VISUAL_COL_CONFIG_COLORTHRESHOLDS!.split(",")
    .map((threshold) => parseInt(threshold)),
  colorThresholdIsPercentage:
    !!process.env.REACT_APP_VISUAL_COL_CONFIG_COLORTHRESHOLD_IS_PERCENTAGE,
};

/**
 * a default {@link AvgColumnConfiguration} object, that is defined as a environment variable
 */
export const defaultAvgColumnConfiguration: AvgColumnConfiguration = {
  datesToCount: parseInt(process.env.REACT_APP_AVG_COL_CONFIG_DATES_TO_COUNT!),
  maxFailCount: parseInt(process.env.REACT_APP_AVG_COL_CONFIG_MAX_FAIL_COUNT!),
  enableMaxFailCount:
    !!process.env.REACT_APP_AVG_COL_CONFIG_ENABLE_MAX_FAIL_COUNT,
  countWeekends: !!process.env.REACT_APP_AVG_COL_CONFIG_COUNT_WEEKENDS,
};

export interface ShopfloorBoardPerformanceEntry {
  id?: string;
  boardId: string;
  columnId: string;

  createDate: Date;
  createdBy: string;

  lastUpdated?: Date;
  lastUpdatedBy?: string;

  targetValue: number;
  currentValue: number;
  fulfillment: number;
}

/**
 * Helper to create an empty ShopfloorBoardPerformanceEntry
 *
 * @param boardId id of the current board
 * @param columnId id of the current column
 * @param userId id of the current user
 * @returns empty ShopfloorBoardPerformanceEntry
 */
export const createEmptyShopfloorBoardPerformanceEntry = (
  boardId: string,
  columnId: string,
  userId?: string
): ShopfloorBoardPerformanceEntry => ({
  boardId: boardId,
  columnId: columnId,
  createDate: new Date(),
  createdBy: userId || "",
  lastUpdatedBy: userId || "",
  lastUpdated: new Date(),
  currentValue: 0,
  fulfillment: 0,
  targetValue: 0,
});

/**
 *  The request, which is used to copy a board (shopfloorboard or powerbi)
 */
export interface BoardCopyRequest {
  /**
   * The board id to copy
   */
  origin: string;
  /**
   * The new name of the copied board
   */
  name: string;
  /**
   * The new color of the copied board
   */
  color: string;
  /**
   * performer of copying, will be set as creator and last updater
   */
  performer: string;
}
