import { Dispatch, SetStateAction } from "react";
import { Option } from "sfm-component-library";

export interface NotificationRotationProps {
  config: NotificationRotationConfig;
  setConfig?: Dispatch<SetStateAction<NotificationRotationConfig>>;
  submitConfig: () => void;
  userOptions: Option[];
}

/**
 * unit in which rotation will occur
 */
export enum RotationType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

/**
 * weekdays as enum
 */
export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

/**
 * describes iteration of weekday inside month
 */
export enum MonthStartType {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD = "THIRD",
  FOURTH = "FOURTH",
  LAST = "LAST",
}

/**
 * months as enum
 */
export enum Month {
  JANUARY = "JANUARY",
  FEBRUARY = "FEBRUARY",
  MARCH = "MARCH",
  APRIL = "APRIL",
  MAY = "MAY",
  JUNE = "JUNE",
  JULY = "JULY",
  AUGUST = "AUGUST",
  SEPTEMBER = "SEPTEMBER",
  OCTOBER = "OCTOBER",
  NOVEMBER = "NOVEMBER",
  DECEMBER = "DECEMBER",
}

/**
 * config for notification rotation of protocol
 */
export interface NotificationRotationConfig {
  /**
   * user that should get notification
   */
  userIds: string[];
  /**
   * begin of notifications
   */
  start: Date;
  /**
   * end of notifications, will be forever if unspecified
   */
  until?: Date;
  /**
   * rules for timing of notification
   */
  rules: TimingRule[];
}

export interface TimingRule {
  /**
   * id for easier handling
   */
  id: string;
  /**
   * type of rotation that is configured
   */
  type: RotationType;
  /**
   * every given {@link RotationType} the notification should be send
   */
  rotation: number;
  /**
   * when true, relative days like first sunday of month are
   * used instead of absolute dates like 3rd of July
   */
  useRelativeDay: boolean;
  /**
   * day of month to send notification
   */
  dayOfMonth: number;
  /**
   * type of day to send notification, only valid with month start type
   */
  daysOfWeek: DayOfWeek[];
  /**
   * this type specifies in which iteration within the month the relative type is used, only Valid with day of week
   */
  monthStartType: MonthStartType;
  /**
   * in what month notification should be send
   */
  month: Month;
  /**
   * last date reminder was sent
   */
  wasLastSent?: Date;
}

/**
 * Helper to create new config
 * @returns new notificationRotationConfig
 */
export const createNewNotificationRotationConfig =
  (): NotificationRotationConfig => ({
    userIds: [],
    start: new Date(),
    rules: [createNewTimingRule()],
  });

/**
 * Helper to create new timing rule
 * @returns new timingRule
 */
export const createNewTimingRule = (): TimingRule => ({
  id: (Math.random() * new Date().getTime()).toString(),
  daysOfWeek: [DayOfWeek.MONDAY],
  rotation: 1,
  dayOfMonth: 1,
  type: RotationType.WEEKLY,
  month: Month.JANUARY,
  monthStartType: MonthStartType.FIRST,
  useRelativeDay: false,
});
