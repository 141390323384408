import { FeatureConfiguration } from "../configuration/FeatureConfiguration.types";

/**
 * values from spot market
 */
export interface EnergySpecification {
  id?: string;
  value: number;
  timestamp: Date;
  predictedValues: number[];
  energyItem?: EnergyItem;
}

/**
 * feature set for energy
 */
export interface EnergyBoard extends FeatureConfiguration {
  name: string;
  boardResponsible: string;
  color: string;
  groups: string[];
  coordinates?: {
    lat: number;
    lon: number;
  };
}

/**
 * item from customer to set current consumation
 */
export interface EnergyItem {
  id?: string;
  energyBoardId: string;
  // value for consumption
  value: number;
  productionValue: number;
  timestamp: Date;
}

/**
 * Helper to generate empty energy item for adding
 * @param boardId if of energy board
 * @returns empty item
 */
export const createEmptyEnergyItem = (boardId: string): EnergyItem => {
  let now: Date = new Date();
  let usedDate: Date = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    0,
    0,
    0
  );

  return {
    energyBoardId: boardId,
    timestamp: usedDate,
    value: 0,
    productionValue: 0,
  };
};

/**
 * Helper object to upload to backend
 * KEEP IN SYNC WITH DATA SERVICE
 */
export interface EnergyUploadHelper {
  itemsToStore: EnergyItem[];
  companyId: string;
  energyBoardId: string;
}

/**
 * Possible types of filter in selection
 */
export enum EnergyFilterType {
  ALL = "ALL",
  CURRENT_MONTH = "CURRENT_MONTH",
  LAST_MONTH = "LAST_MONTH",
  INDIVIDUAL = "INDIVIDUAL",
}

/**
 * Interface for filter selection
 */
export interface AccumulatedEnergySpecification {
  totalConsumption: number; // total consumption in kWh
  averageConsumption: number; // average consumption in kWh
  totalCosts: number; // total costs in €
  averageCosts: number; // average costs in €
  lowestPriceWithTimeStamp: {
    value: number;
    timestamp: Date;
  }; // lowest costs in €
  highestPriceWithTimeStamp: {
    value: number;
    timestamp: Date;
  }; // highest costs in € with date,
  lowestConsumptionWithTimeStamp: {
    value: number;
    timestamp: string;
  }; // lowest consumption in kWh with date
  highestConsumptionWithTimeStamp: {
    value: number;
    timestamp: string;
  }; // highest consumption in kWh with date
  weightedPrice: number;
}

/**
 * Interface for filter selection and fetch in range
 */
export interface DateRange {
  start: Date;
  end: Date;
}

/**
 * Interface for response of  api for currentWeather
 */
export interface WeatherResponse {
  weather: Weather;
  sources: WeatherSource[];
}
/**
 * Interface for weather data that are provided by the api
 * more data available, docu at https://brightsky.dev/docs/#get-/current_weather
 */
export interface Weather {
  solar_10: number;
  solar_30: number;
  solar_60: number;
  icon:
    | string
    | "cloudy"
    | "wind"
    | "rain"
    | "fog"
    | "sleet"
    | "snow"
    | "hail"
    | "thunderstorm";
  temperature: number;
  sunshine_10: number;
  sunshine_30: number;
  sunshine_60: number;
  wind_direction_10: number;
  wind_direction_30: number;
  wind_direction_60: number;
  wind_speed_10: number;
  wind_speed_30: number;
  wind_speed_60: number;
  condition: string | "dry";
  cloud_cover: number;
}
/**
 * weather stations that measured the data
 * and the distance from the requested coordinates
 */
export interface WeatherSource {
  station_name: string;
  distance: number;
}
/**
 * Interface for response of  api for weather forecast
 */
export interface WeatherForecast {
  weather: Forecast[];
  sources: WeatherSource[];
}
/**
 * Interface for forecast data that are provided by the api
 * more data available, docu at https://brightsky.dev/docs/#/operations/getWeather
 */
export interface Forecast {
  condition: string;
  temperature: number;
  precipitation_probability: number;
  timestamp: string;
  sunshine: number;
  wind_direction: number;
  solar: number;
  precipitation: number;
}
/**
 * interface for forecast and current weather combined
 */
export interface WeatherData {
  weatherForecast: WeatherForecast;
  currentWeather: WeatherResponse;
}
