import { isEqual } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  ButtonComponentProps,
  CheckboxComponent,
  LoaderComponent,
  PopupComponent,
  TabComponent,
} from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { fetchAllDataApiForCompanyWithinGroups } from "../../../utils/apidata/DataApi.utils";
import { useAxios } from "../../../utils/AxiosUtil";
import {
  generateNotificationWithTranslations,
  useResource,
} from "../../../utils/GeneralUtils";
import {
  ConnectorType,
  generateNewShopfloorBoardColumn,
  ShopfloorBoardColumn,
  ShopfloorBoardConfiguration,
  ShopfloorBoardPerformanceEntry,
} from "../../../utils/sfboard/SfBoard.types";
import { uploadCsvFileForImportingPerformanceEntries } from "../../../utils/sfboard/SfBoard.utils";
import { updateShopfloorBoardColumns } from "../../../utils/sfboard/SfBoardOverview.utils";
import { deleteAllTasksForShopfloorBoardColumn } from "../../../utils/tasks/TasksUtil";
import { UserAction } from "../../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../../utils/user/User.utils";
import DeletePopUp from "../../deletepopup/DeletePopUp";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/delete.svg";
import ColumnEditFormGeneralTab from "./ColumnEditFormGeneralTab";
import ColumnEditFormVisualTab from "./ColumnEditFormVisualTab";

export interface ColumnEditFormProps {
  columnToEdit?: ShopfloorBoardColumn;
  shopfloorConfig: ShopfloorBoardConfiguration;
  closePopup(options?: {
    updatedConfig?: ShopfloorBoardConfiguration;
    updatedPerformanceEntries?: ShopfloorBoardPerformanceEntry[];
    updatedColumn?: ShopfloorBoardColumn;
    changedConnector?: boolean;
  }): void;
  show: boolean;
}

const ColumnEditForm: React.FC<ColumnEditFormProps> = ({
  show,
  columnToEdit,
  closePopup,
  shopfloorConfig,
}) => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const [column, setColumn] = useState<ShopfloorBoardColumn>();
  const [checkbox, setCheckbox] = useState(true);
  const { user } = useContext(UserContext);
  const [localFile, setLocalFile] = useState<File>();
  const [localFileUploading, setLocalFileUploading] = useState(false);
  const [updateColumnIsLoading, setUpdateColumnIsLoading] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(0);
  const popUpRef = useRef<HTMLDivElement>(null);
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [deleteColumnIsLoading, setDeleteTaskIsLoading] =
    useState<boolean>(false);
  /**
   * sets local column or generate new column
   */
  useEffect(() => {
    if (!user) return;
    if (columnToEdit) {
      setColumn(columnToEdit);
    } else {
      setColumn(generateNewShopfloorBoardColumn(user.id!));
    }
  }, [columnToEdit, shopfloorConfig.columns, user]);

  /**
   * save and closes the popup
   */
  const saveColumnAndClosePopup = (): void => {
    setUpdateColumnIsLoading(true);

    let connectorChanged = false;

    let updatedColumns: ShopfloorBoardColumn[];
    if (!columnToEdit) {
      updatedColumns = [...shopfloorConfig.columns, column!];
      connectorChanged = true;
    } else {
      updatedColumns = shopfloorConfig.columns.map((columnToUpdate) => {
        if (columnToUpdate.id === column!.id) {
          if (
            columnToUpdate.connectorType !== column?.connectorType ||
            !isEqual(columnToUpdate.connectorInfo, column?.connectorInfo)
          )
            connectorChanged = true;
          return column!;
        }
        return columnToUpdate;
      });
    }
    const updatedConfig = { ...shopfloorConfig, columns: updatedColumns };
    updateShopfloorBoardColumns(updatedConfig, axios).then(async (success) => {
      if (!success) {
        generateNotificationWithTranslations("warning");
        setUpdateColumnIsLoading(false);
        return;
      }
      if (column?.connectorType !== ConnectorType.CSV_IMPORT || !localFile) {
        if (connectorChanged) {
          closePopup({
            updatedConfig,
            changedConnector: true,
            updatedColumn: column!,
          });
        } else {
          closePopup({ updatedConfig });
        }
        setUpdateColumnIsLoading(false);
        return;
      }
      // just upload it if a new file is provided
      setLocalFileUploading(true);
      uploadCsvFileForImportingPerformanceEntries(
        axios,
        localFile,
        shopfloorConfig.id!,
        column.id,
        user!.id!
      ).then((updatedPerformanceEntries) => {
        setLocalFileUploading(false);
        setUpdateColumnIsLoading(false);
        setLocalFile(undefined);
        closePopup({ updatedConfig, updatedPerformanceEntries });
      });
    });
  };

  /**
   *  deletes this column and closes the popup
   */
  const deleteColumnAndClosePopUp = (): void => {
    setDeleteTaskIsLoading(true);
    const updatedConfig = {
      ...shopfloorConfig,
      columns: shopfloorConfig.columns.filter(
        (columnToFilter) => columnToFilter.id !== column!.id
      ),
    };
    updateShopfloorBoardColumns(updatedConfig, axios).then((success) => {
      if (!success) {
        generateNotificationWithTranslations("warning");
        resetLoadingStates();
        return;
      }
      if (checkbox) {
        deleteAllTasksForShopfloorBoardColumn(
          column!.id!,
          shopfloorConfig.id!,
          axios
        ).then((success) => {
          resetLoadingStates();
          if (!success) {
            generateNotificationWithTranslations("warning");
            return;
          }

          closePopup({ updatedConfig });
          generateNotificationWithTranslations("success");
        });
      } else {
        resetLoadingStates();
        closePopup({ updatedConfig });
        generateNotificationWithTranslations("success");
      }
    });
  };

  /**
   * sets all loading sate to false and closes DeletePopUp
   */
  const resetLoadingStates = () => {
    setUpdateColumnIsLoading(false);
    setOpenDeletePopUp(false);
    setDeleteTaskIsLoading(false);
  };

  /**
   * Helper to generate Buttons for Column Delete PopUp
   * @returns  configured buttons
   */
  const generateButtonsForDeletePopUp = (): ButtonComponentProps[] => [
    {
      title: t("general.buttons.cancel"),
      onClick: () => {
        setOpenDeletePopUp(false);
        setCheckbox(true);
      },
      borderColor: "black",
      className: "color-white",
    },
    {
      title: (
        <div className="checkbox-button-combo-wrapper">
          <div
            className="checkbox-button-combo-wrapper--title"
            onClick={() => deleteColumnAndClosePopUp()}
          >
            {t("general.buttons.delete")}
          </div>
          <CheckboxComponent
            checked={checkbox}
            onCheck={setCheckbox}
            value={t("shopfloorboard.config.edit.deleteAllTask")}
          />
        </div>
      ),
      isLoading: updateColumnIsLoading,
      borderColor: "#F86B6B",
    },
  ];

  /**
   * Helper to generate a button list for the edit config popup
   *
   * @returns configured buttons
   */
  const generateButtonsForEditConfig = (): ButtonComponentProps[] => {
    return [
      {
        title: t("general.buttons.cancel"),
        onClick: () => {
          setOpenDeletePopUp(false);
          closePopup();
        },
        borderColor: "black",
        className: "color-white",
      },
      {
        title: t("general.buttons.save"),
        form: "column-edit-form",
        borderColor: "#a9faa2",
        isLoading: updateColumnIsLoading,
      },
    ];
  };

  /**
   * Load all available data apis based on company and groups
   */
  const dataApis =
    useResource(
      async (axios) => {
        if (column?.connectorType !== ConnectorType.DATA_API) return undefined;
        return await fetchAllDataApiForCompanyWithinGroups(
          axios,
          shopfloorConfig.companyId,
          shopfloorConfig.groups
        );
      },
      [column?.connectorType]
    ) ?? [];

  /**
   *  checks which tab is active and returns the correct Components
   * @returns active Tab components
   */
  const renderCorrectTab = (): JSX.Element => {
    if (!column) return <></>;
    switch (activeTab) {
      case 0:
        return (
          <ColumnEditFormGeneralTab
            file={localFile}
            column={column}
            dataApis={dataApis}
            onColumnChange={setColumn}
            onFileChange={setLocalFile}
            localFileUploading={localFileUploading}
            portal={popUpRef.current}
          />
        );
      case 1:
        return (
          <ColumnEditFormVisualTab
            popupContentElement={popUpRef.current}
            column={column}
            onColumnChange={setColumn}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <PopupComponent
      isOpen={show}
      toggleOpen={(isOpen) => !isOpen && closePopup()}
      footerButtons={generateButtonsForEditConfig()}
    >
      <DeletePopUp
        isOpen={openDeletePopUp}
        toggleOpen={(isOpen) => {
          setOpenDeletePopUp(isOpen);
          setCheckbox(true);
        }}
        onDelete={deleteColumnAndClosePopUp}
        deleteIsLoading={deleteColumnIsLoading}
        content={<p>{t("shopfloorboard.column.deleteDescription")}</p>}
        customButtons={generateButtonsForDeletePopUp()}
      />
      <div ref={popUpRef}>
        {column ? (
          <form
            id={"column-edit-form"}
            onSubmit={(evt) => {
              evt.preventDefault();
              saveColumnAndClosePopup();
            }}
          >
            <div className="shopfloor-column__edit__header">
              <TabComponent
                tabs={t("shopfloorboard.column.edit.tabs", {
                  returnObjects: true,
                })}
                onClick={setActiveTab}
                activeTab={activeTab}
              />
              {isUserAllowedToDoThis(UserAction.DELETE_COLUMN, user) &&
                columnToEdit && (
                  <ButtonComponent
                    title={
                      <div className="shopfloor-column__edit__header__button-content">
                        <p>{t("general.buttons.delete")}</p>
                        <DeleteIcon />
                      </div>
                    }
                    borderColor="#F86B6B"
                    type="button"
                    onClick={() => setOpenDeletePopUp(true)}
                  />
                )}
            </div>
            {renderCorrectTab()}
          </form>
        ) : (
          <LoaderComponent />
        )}
      </div>
    </PopupComponent>
  );
};

export default ColumnEditForm;
