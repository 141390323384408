import { AxiosError, AxiosInstance } from "axios";
import i18next from "i18next";
import { generateNotification, Option } from "sfm-component-library";
import i18n from "../../i18n";
import { generateNotificationWithTranslations } from "../GeneralUtils";
import { User, UserAction, UserRole } from "./User.types";

/**
 * API METHOD - updates an user on the server
 *
 * @param user the user instance (dashboard will be removed to save bandwidth, for dashboard update use the seperate endpoint)
 * @param axios the axios instance
 * @returns true if success, false in case of error
 */
export const updateUserOnBackend = async (
  user: User,
  axios: AxiosInstance
): Promise<boolean> =>
  axios
    .post("/user/update/", { ...user, dashboard: undefined })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.log("Error during user update!", err);
      return false;
    });

/**
 * API METHOD - creates an user on the server
 *
 * @param user the new user instance (dashboard will be removed to save bandwidth, for dashboard update use the seperate endpoint)
 * @param axios the axios instance
 * @returns a result object which the user or null and a error string in case of a error
 */
export const createUserOnBackend = async (
  user: User,
  axios: AxiosInstance
): Promise<{
  user: User | null;
  error?: "email_used" | "username_used";
}> =>
  axios
    .post("/user/", { ...user, dashboard: undefined })
    .then((res) => ({
      user: res.data,
    }))
    .catch((err: AxiosError) => {
      console.error("Error during user creation!", err);
      switch (err.response?.status) {
        case 409:
          return { user: null, error: "email_used" } as {
            user: User | null;
            error?: "email_used" | "username_used";
          };
        case 412:
          return { user: null, error: "username_used" } as {
            user: User | null;
            error?: "email_used" | "username_used";
          };
        default:
          return { user: null };
      }
    });

/**
 * API METHOD - update a users dashboard on the backend
 *
 * @param user the user with dashboard to update (only needs "id" and "dashboard")
 * @param axios the axios instance
 * @returns true if success else false
 */
export const updateUserDashboardOnBackend = async (
  user: User,
  axios: AxiosInstance
): Promise<boolean> =>
  axios
    .post("/user/update/dashboard/", { id: user.id, dashboard: user.dashboard })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error during user dashboard update!", err);
      return false;
    });

/**
 * API METHOD - fetches a new user on the server
 *
 * @param newUser
 * @param axios
 * @returns the loaded user or null
 */
export const loadUserFromBackend = async (
  userId: string,
  axios: AxiosInstance
): Promise<User> => {
  return axios
    .get("/user/id/", { params: { userId: userId } })
    .then((resp) => resp.data)
    .catch((exc) => console.error("Error during user loading", exc));
};

/**
 * API METHOD - fetches user and tracks login
 * @param userId of currently logged in user
 * @param sessionId of current session
 * @param axios for network call
 * @returns user for given userId with updated login track
 */
export const trackLoginForUser = async (
  userId: string,
  sessionId: string,
  axios: AxiosInstance
): Promise<User> => {
  return axios
    .post("/user/tracklogin/", { userId, sessionId })
    .then((resp) => resp.data)
    .catch((exc) => console.error("Error during tracking this login", exc));
};

/**
 * API METHOD - for loading the count of users by company
 *
 * @param companyId the company id
 * @param axios the axios instance
 * @returns number of users
 */
export const loadCountOfUsersByCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get("/user/all/company/count/", {
      params: { companyId },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during user count fetch!", exc);
      return -1;
    });
};

/**
 * API METHOD - deletes user with given userId
 * @param userId
 * @param axios
 * @returns true if successful, else return false
 */
export const deleteUserFromBackend = async (
  userId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .get("/user/delete/", { params: { userId: userId } })
    .then(() => true)
    .catch((exc) => {
      console.error("Error during user delete", exc);
      return false;
    });
};

/**
 * API METHOD - loads all users for a given company
 *
 * @param companyId
 * @param axios
 * @returns loaded user list
 */
export const loadAllUsersFromBackendByCompanyId = async (
  companyId: string,
  axios: AxiosInstance
): Promise<User[]> => {
  return axios
    .get("/user/all/company/", { params: { companyId: companyId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during user loading", exc);
      return [];
    });
};

/**
 * Helper to generate a option list with or without the platfrom admin
 *
 * @param withPlatformAdmin boolean if the platform admin should be included or not
 * @returns option list for dropdown
 */
export const generateUserRoleOptions = (
  withPlatformAdmin?: boolean
): Option[] => {
  return Object.keys(UserRole)
    .filter((role) => withPlatformAdmin || role !== UserRole.PLATFORM_ADMIN)
    .map((role) => {
      return { label: i18next.t(`enum.userRole.${role}`), value: role };
    });
};

/**
 * checks if user is allows to perform the given action
 * @param user given user to testify
 * @param action action, that needs to be performed
 * @returns true if the user is allowed to perform this action, else returns false
 */
export const isUserAllowedToDoThis = (
  action: UserAction,
  user?: User
): boolean => {
  if (!user) return false;

  switch (user.role) {
    case UserRole.PLATFORM_ADMIN:
      return false;
    case UserRole.ADMIN:
      return !![
        UserAction.CREATE_BOARD,
        UserAction.EDIT_BOARD,
        UserAction.DELETE_BOARD,

        UserAction.CREATE_COLUMN,
        UserAction.EDIT_COLUMN,
        UserAction.DELETE_COLUMN,
        UserAction.SHOW_ARCHIVE,

        UserAction.UPDATE_PERFORMANCE_ENTRY,

        UserAction.CREATE_TASK,
        UserAction.EDIT_TASK,
        UserAction.DELETE_TASK,

        UserAction.CREATE_POWERBI_CONFIG,
        UserAction.EDIT_POWERBI_CONFIG,
        UserAction.DELETE_POWERBI_CONFIG,

        UserAction.CREATE_ENERGY_CONFIG,
        UserAction.EDIT_ENERGY_CONFIG,
        UserAction.DELETE_ENERGY_CONFIG,

        UserAction.CREATE_PROTOCOL_CONFIG,
        UserAction.EDIT_PROTOCOL_CONFIG,
        UserAction.DELETE_PROTOCOL_CONFIG,

        UserAction.VIEW_FILLED_PROTOCOL,
        UserAction.EXPORT_PROTOCOL_CONFIG
      ].find((permission) => permission === action);
    case UserRole.EMPLOYEE:
      return !![
        UserAction.CREATE_COLUMN,
        UserAction.EDIT_COLUMN,
        UserAction.DELETE_COLUMN,
        UserAction.SHOW_ARCHIVE,

        UserAction.UPDATE_PERFORMANCE_ENTRY,

        UserAction.CREATE_TASK,
        UserAction.EDIT_TASK,
        UserAction.DELETE_TASK,

        UserAction.CREATE_POWERBI_CONFIG,
        UserAction.EDIT_POWERBI_CONFIG,
        UserAction.DELETE_POWERBI_CONFIG,

        UserAction.CREATE_ENERGY_CONFIG,
        UserAction.EDIT_ENERGY_CONFIG,

        UserAction.CREATE_PROTOCOL_CONFIG,
        UserAction.EDIT_PROTOCOL_CONFIG,

        UserAction.VIEW_FILLED_PROTOCOL,
      ].find((permission) => permission === action);
    case UserRole.VISITOR:
      return false;
    default:
      return false;
  }
};

/**
 * Generate error notifications based on user creation error result
 *
 * @param result the result from the {@link createUserOnBackend}.
 */
export const generateUserCreationErrorNotifications = (result: {
  user: null | User;
  error?: string;
}): void => {
  if (result.user) return;
  switch (result.error) {
    case "email_used":
    case "username_used":
      generateNotification(i18n.t(`profile.errors.${result.error}`), "warning");
      return;
    default:
      generateNotificationWithTranslations("warning");
      return;
  }
};
