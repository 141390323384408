import { useContext, useMemo, useState } from "react";
import {
  LayoutComponent,
  LoaderComponent,
  TabComponent,
} from "sfm-component-library";
import { AdministrationTab } from "../components/administration/Administration.types";
import { DataApiAdmin } from "../components/administration/DataApiAdmin";
import { GeneralAdmin } from "../components/administration/GeneralAdmin";
import { GroupAdmin } from "../components/administration/GroupAdmin";
import { UserAdmin } from "../components/administration/UserAdmin";
import { NavigationConfiguration } from "../utils/navigation/NavigationConfiguration";
import { PageType } from "../utils/navigation/NavigationConfiguration.types";
import { UserContext } from "./App";
import "../styles/AdministrationPage.scss";
import { ApiKeyAdmin } from "../components/administration/ApiKeyAdmin";
import { useTranslation } from "react-i18next";

export const AdministrationPage: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [tab, setTab] = useState<number>(0);

  /**
   * Generate the correct component to render based on tab selected
   */
  const selectedTab = useMemo(() => {
    switch (tab) {
      case AdministrationTab.GENERAL:
        return <GeneralAdmin />;
      case AdministrationTab.USER:
        return <UserAdmin />;
      case AdministrationTab.GROUP:
        return <GroupAdmin />;
      case AdministrationTab.DATA_API:
        return (
          <div className="data-api-group">
            <h2>{t("administration.apikey.apikey")}</h2>
            <ApiKeyAdmin />
            <h2>{t("administration.apidata.apidata")}</h2>
            <DataApiAdmin />
          </div>
        );
    }
  }, [tab, t]);

  return (
    <LayoutComponent {...NavigationConfiguration(PageType.ADMINISTRATION)}>
      {!!user ? (
        <div className="administration-page">
          <TabComponent
            tabs={["Allgemein", "Nutzer", "Gruppen", "API Daten"]}
            activeTab={tab}
            onClick={setTab}
          />
          {selectedTab}
        </div>
      ) : (
        <LoaderComponent />
      )}
    </LayoutComponent>
  );
};
