import { v4 } from "uuid";
import { BackendWidgetInfo, WidgetInfo, WidgetType } from "./Dashboard.types";
import i18n from "../../i18n";

/**
 * Generates a placeholder widget for user which dont have configured the dashboard yet
 *
 * @returns a text widget with translated text
 */
export const generatePlaceholderWidget = (): WidgetInfo<WidgetType.TEXT> => {
  const id = v4();

  return {
    color: "#969afa",
    type: WidgetType.TEXT,
    gridData: {
      h: 1,
      w: 3,
      x: 1,
      y: 1,
      i: id,
      static: true,
    },
    key: id,
    options: { text: { text: i18n.t("dashboard.placeholderWidget.text") } },
  };
};

/**
 * Convert a backend widget info to normal widget.
 * Parse the gridData as json
 *
 * @param widget the backend widget
 * @returns a normal widget
 */
export const convertBackendWidgetToWidget = <T extends WidgetType>(
  widget: BackendWidgetInfo<T>
): WidgetInfo<T> => ({
  ...widget,
  gridData: {
    ...{
      isBounded: undefined,
      isDraggable: undefined,
      isResizable: undefined,
      maxH: undefined,
      maxW: undefined,
      minH: undefined,
      minW: undefined,
      moved: undefined,
      resizeHandles: undefined,
      static: undefined,
    },
    ...JSON.parse(widget.gridData),
  },
  type: widget.type.toLowerCase() as T,
});

/**
 * Convert a normal widget info to backend widget.
 * Stringify the gridData from json
 *
 * @param widget the normal widget
 * @returns a backend widget
 */
export const convertWidgetToBackendWidget = <T extends WidgetType>(
  widget: WidgetInfo<T>
): BackendWidgetInfo<T> => ({
  ...widget,
  gridData: JSON.stringify(widget.gridData),
  type: widget.type.toUpperCase(),
});
