import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInputComponent } from "sfm-component-library";
import { EnergyBoard } from "../../../utils/energy/Energy.types";
import { PowerBIConfiguration } from "../../../utils/powerbi/PowerBI.types";
import {
  BoardCopyRequest,
  ShopfloorBoardConfiguration,
} from "../../../utils/sfboard/SfBoard.types";
import { Protocol } from "../../../utils/protocol/Protocol.types";
import { FeatureModule } from "../../../utils/license/License.types";
import { UserContext } from "../../../pages/App";

export interface BoardCopyFormProps {
  board:
    | ShopfloorBoardConfiguration
    | PowerBIConfiguration
    | EnergyBoard
    | Protocol;
  onCopy(copy: BoardCopyRequest): void;
}

export const BoardCopyForm: React.FC<BoardCopyFormProps> = ({
  board,
  onCopy,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [copy, setCopy] = useState<BoardCopyRequest>({
    origin: board.id ?? "",
    name: t("shopfloorboard.config.copy.initName", {
      replace: { name: board.name },
    }),
    color: board.color,
    performer: "",
  });

  //sets performer as soon as user is loaded
  useEffect(() => {
    if (user)
      setCopy((currentCopyRequest) => ({
        ...currentCopyRequest,
        performer: user.id!,
      }));
  }, [user]);

  return (
    <form
      className="board-copy-form"
      id="board-copy-form"
      onSubmit={(evt) => {
        evt.preventDefault();
        onCopy(copy);
      }}
    >
      <TextInputComponent
        label={
          board.featureModule === FeatureModule.PROTOCOL
            ? t("protocol.config.create.title")
            : t("shopfloorboard.config.create.boardName")
        }
        value={copy.name}
        onChange={(name) => setCopy({ ...copy, name })}
        required
      />
      <TextInputComponent
        label={t("shopfloorboard.config.create.boardColor")}
        value={copy.color}
        onChange={(color) => setCopy({ ...copy, color })}
        type="color"
      />
    </form>
  );
};
