import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInputComponent } from "sfm-component-library";
import { UserContext } from "../../pages/App";
import "../../styles/ShopfloorBoardStyle.scss";
import { useAxios } from "../../utils/AxiosUtil";
import {
  createEmptyShopfloorBoardPerformanceEntry,
  ShopfloorBoardColumn,
  ShopfloorBoardPerformanceEntry,
} from "../../utils/sfboard/SfBoard.types";
import {
  createNewPerformanceEntryForShopfloorBoard,
  updatePerformanceEntryForShopfloorBoard,
} from "../../utils/sfboard/SfBoard.utils";

interface ShopfloorPerformanceEntryEditProps {
  performanceEntryToEdit?: ShopfloorBoardPerformanceEntry;
  updatePerformanceEntry(
    updatedPerformanceEntry: ShopfloorBoardPerformanceEntry
  ): void;
  boardId: string;
  column: ShopfloorBoardColumn;
  closePopup(): void;
}

const ShopfloorPerformanceEntryEdit: React.FC<
  ShopfloorPerformanceEntryEditProps
> = ({
  performanceEntryToEdit,
  updatePerformanceEntry,
  column,
  closePopup,
  boardId,
}) => {
  const { user } = useContext(UserContext);
  const { axios } = useAxios();
  const { t } = useTranslation();
  const [localPerformanceEntry, setLocalPerformanceEntry] =
    useState<ShopfloorBoardPerformanceEntry>();

  /**
   * save and closes the popup
   */
  const saveColumnAndClosePopup = (): void => {
    if (!localPerformanceEntry) return;
    if (!!performanceEntryToEdit?.id) {
      updatePerformanceEntryForShopfloorBoard(
        localPerformanceEntry,
        axios
      ).then((result) => {
        if (result) {
          updatePerformanceEntry({ ...localPerformanceEntry });
          closePopup();
        }
      });
    } else {
      createNewPerformanceEntryForShopfloorBoard(
        localPerformanceEntry,
        axios
      ).then((createdEntry) => {
        if (createdEntry) {
          updatePerformanceEntry({ ...createdEntry });
          closePopup();
        }
      });
    }
  };

  /**
   * Checks if it is an update or creation of a performance entry.
   * If it is an update dont set createdBy or
   * clear the data of existing performance entry
   */
  useEffect(() => {
    if (!user) return;
    setLocalPerformanceEntry({
      ...(!!performanceEntryToEdit?.id
        ? performanceEntryToEdit
        : {
            ...createEmptyShopfloorBoardPerformanceEntry(
              boardId,
              column.id,
              user.id
            ),
          }),
      targetValue: column.targetValue,
    });

    // eslint-disable-next-line
  }, [performanceEntryToEdit, user]);

  return (
    <div className="column-edit-form-wrapper">
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          saveColumnAndClosePopup();
        }}
        id={"column-edit-form-performance-entry"}
      >
        {localPerformanceEntry && user && (
          <TextInputComponent
            type="number"
            step="any"
            defaultValue={localPerformanceEntry.currentValue}
            onChange={(newValue) =>
              setLocalPerformanceEntry({
                ...localPerformanceEntry,
                currentValue: Number(newValue),
                lastUpdatedBy: user.id,
              })
            }
            label={t("shopfloorboard.performanceEntry.currentValue")}
          />
        )}
      </form>
    </div>
  );
};

export default ShopfloorPerformanceEntryEdit;
