import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EnergyChartComponent,
  LayoutComponent,
  LoaderComponent,
  TableComponent,
  TextInputComponent,
  RetractableComponent,
  CheckboxComponent,
  ButtonComponent,
} from "sfm-component-library";
import { Page, useAxios } from "../../utils/AxiosUtil";
import {
  AccumulatedEnergySpecification,
  createEmptyEnergyItem,
  EnergyFilterType,
  EnergyItem,
  EnergySpecification,
} from "../../utils/energy/Energy.types";
import {
  createAccumulatedEnergy,
  loadAllEnergySpecifications,
  loadAllEnergySpecificationsInRange,
} from "../../utils/energy/Energy.utils";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import "../../styles/EnergyPageStyles.scss";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as ListIcon } from "../../assets/icons/list-solid.svg";
import { ReactComponent as GridIcon } from "../../assets/icons/grip-solid.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/chevron_left.svg";
import "../../styles/EnergyManagementDetail.styles.scss";
import AddEnergyItemPopup from "../../components/energy/AddEnergyItemPopup";
import { EnergyChartData } from "sfm-component-library/build/ChartComponent/ChartComponent.types";
import { convertToDateForInput } from "../../utils/GeneralUtils";
import useSWRInfinite from "swr/infinite";
import useSWR from "swr";
import { RowEntry } from "sfm-component-library/build/TableComponent/TableComponent.types";
import { WeatherComponent } from "../../components/weather/WeatherComponent";
import { useSingleEnergyBoard } from "../../utils/energy/useEnergyBoard";
import { ViewMode } from "./EnergyManagementPage";
interface EnergyManagementDetailPageProps {}

const EnergyManagementDetailPage: React.FC<
  EnergyManagementDetailPageProps
> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { axios } = useAxios();
  const location = useLocation<{
    configId: string;
  }>();
  const [viewType, setViewType] = useState<"list" | "graph">("list");
  const [showAddPopup, toogleAddPopup] = useState<boolean>(false);
  const [selectedEnergyItem, setSelectedEnergyItem] = useState<EnergyItem>();
  const [energyBoardId, setEnergyBoardId] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<EnergyFilterType>(
    EnergyFilterType.CURRENT_MONTH
  );
  const [isLoaded, toggleLoaded] = useState<boolean>(false);
  const [showPreviousMonth, togglePreviousMonth] = useState<boolean>(false);
  const [selectedIndividualTime, setSelectedIndividualTime] = useState<{
    start: Date;
    end: Date;
  }>({ end: new Date(), start: new Date() });
  const configId: string = location.state?.configId;
  const { energyBoard } = useSingleEnergyBoard(configId);
  /**
   * Helper to save viewMode to the last used on click
   */
  const handleGoBack = () => {
    history.push("/energy", { configId: configId });
  };

  /**
   * SWR helper to get key for paginated loading of energy specifications
   * @param pageIndex the current page index
   * @param previousPageData the previous page data
   * @returns the key for the current page
   */
  const getKey = (
    pageIndex: number,
    previousPageData: Page<EnergySpecification[]>
  ) => {
    if (
      (previousPageData && previousPageData.size === 0) ||
      !axios ||
      !energyBoardId
    )
      return null; // reached the end
    // reached the end
    return `?page=${pageIndex}&size=1000`; // SWR key
  };
  const { data, size, setSize, mutate } = useSWRInfinite(
    getKey,
    (paginationUrl) =>
      loadAllEnergySpecifications(axios, paginationUrl, energyBoardId)
  );

  /**
   * this useEffect  loads all needed data and checks for energyboard id
   */
  useEffect(() => {
    if (!location.state?.configId) history.push("/energy");
    setEnergyBoardId(location.state?.configId);
    // eslint-disable-next-line
  }, [location.state?.configId]);

  /**
   * Prepares the network request for SWR
   * @returns the promise for the SWR
   */
  const handleSWRCallByMonth = (): Promise<EnergySpecification[]> => {
    let startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 2,
      1
    );
    let endDate = new Date();
    return loadAllEnergySpecificationsInRange(
      axios,
      {
        start: startDate,
        end: endDate,
      },
      energyBoardId
    );
  };

  const energyItemsCurrentAndPreviousMonth = useSWR(
    !axios || !energyBoardId ? null : "energyItemsCurrentAndPreviousMonth",
    handleSWRCallByMonth
  );

  const energyItemsIndividualFrame = useSWR(
    !axios || !energyBoardId || selectedFilter !== EnergyFilterType.INDIVIDUAL
      ? null
      : "energyItemsIndividualFrame",
    () =>
      loadAllEnergySpecificationsInRange(
        axios,
        {
          start: new Date(
            selectedIndividualTime.start.getFullYear(),
            selectedIndividualTime.start.getMonth(),
            selectedIndividualTime.start.getDate(),
            1,
            0,
            0,
            0
          ),
          end: new Date(
            selectedIndividualTime.end.getFullYear(),
            selectedIndividualTime.end.getMonth(),
            selectedIndividualTime.end.getDate(),
            23,
            59,
            59,
            999
          ),
        },
        energyBoardId
      )
  );

  /**
   * this useEffect checks if all needed data is loaded and sets the loaded state to true
   */
  useEffect(() => {
    if (
      !energyItemsCurrentAndPreviousMonth.data ||
      !data ||
      (selectedFilter === EnergyFilterType.INDIVIDUAL &&
        !energyItemsIndividualFrame.data)
    )
      return;
    toggleLoaded(true);
  }, [
    energyItemsCurrentAndPreviousMonth.data,
    energyItemsIndividualFrame.data,
    data,
    selectedFilter,
  ]);

  /**
   * checks whether selected filter is individual time frame and if dates have been set
   */
  useEffect(() => {
    if (!energyItemsIndividualFrame.data || !selectedIndividualTime) return;
    energyItemsIndividualFrame.mutate();
  }, [selectedIndividualTime, energyItemsIndividualFrame]);

  const currentMonthDataTableRows = useMemo(
    () =>
      (energyItemsCurrentAndPreviousMonth.data ?? [])
        .filter(
          (item) =>
            new Date(item.timestamp).getMonth() === new Date().getMonth()
        )
        .map((item, itemIndex) => ({
          content: [
            item.energyItem?.value ?? "-",
            item.energyItem?.productionValue ?? "-",
            `${(
              Number(item.value / 1000) * (item.energyItem?.value ?? 0)
            ).toFixed(2)} €`,
            new Date(item.timestamp).toLocaleString("de-DE"),
            `${Number(item.value / 1000).toFixed(4)} €`,
            t("energy.detail.unit"),
          ],
          id: `${itemIndex}`,
        })) ?? [],
    [energyItemsCurrentAndPreviousMonth.data, t]
  );
  const previousMonthDataTableRows = useMemo(
    () =>
      (energyItemsCurrentAndPreviousMonth.data ?? [])
        .filter(
          (item) =>
            new Date(item.timestamp).getMonth() === new Date().getMonth() - 1
        )
        .map((item, itemIndex) => ({
          content: [
            item.energyItem?.value ?? "-",
            item.energyItem?.productionValue ?? "-",
            `${(
              Number(item.value / 1000) * (item.energyItem?.value ?? 0)
            ).toFixed(2)} €`,
            new Date(item.timestamp).toLocaleString("de-DE"),
            `${Number(item.value / 1000).toFixed(4)} €`,
            t("energy.detail.unit"),
          ],
          id: `${itemIndex}`,
        })) ?? [],
    [energyItemsCurrentAndPreviousMonth.data, t]
  );
  const individualFrameDataTableRows = useMemo(
    () =>
      (energyItemsIndividualFrame.data ?? []).map((item, itemIndex) => ({
        content: [
          item.energyItem?.value ?? "-",
          item.energyItem?.productionValue ?? "-",
          `${(
            Number(item.value / 1000) * (item.energyItem?.value ?? 0)
          ).toFixed(2)} €`,
          new Date(item.timestamp).toLocaleString("de-DE"),
          `${Number(item.value / 1000).toFixed(4)} €`,
          t("energy.detail.unit"),
        ],
        id: `${itemIndex}`,
      })) ?? [],
    [energyItemsIndividualFrame.data, t]
  );

  /**
   * Gets the correct {@link EnergySpecification} data for the selected filter
   */
  const getCorrectData = useCallback((): EnergySpecification[] => {
    switch (selectedFilter) {
      case EnergyFilterType.CURRENT_MONTH:
        return (energyItemsCurrentAndPreviousMonth.data ?? []).filter(
          (item) =>
            new Date(item.timestamp).getMonth() === new Date().getMonth()
        );
      case EnergyFilterType.LAST_MONTH:
        return (energyItemsCurrentAndPreviousMonth.data ?? []).filter(
          (item) =>
            new Date(item.timestamp).getMonth() === new Date().getMonth() - 1
        );
      case EnergyFilterType.INDIVIDUAL:
        return energyItemsIndividualFrame.data ?? [];
      default:
        return [];
    }
  }, [
    energyItemsCurrentAndPreviousMonth.data,
    energyItemsIndividualFrame.data,
    selectedFilter,
  ]);

  /**
   * this useMemo creates the data for the overview
   */
  const accumulatedData: AccumulatedEnergySpecification = useMemo(() => {
    return createAccumulatedEnergy(getCorrectData());
  }, [getCorrectData]);

  /**
   * Generate the needed energy specification entries for the table component
   */
  const allEnergySpecsTableRows = useMemo(
    () =>
      (data?.flatMap((page) => page.content) ?? []).map((item, itemIndex) => ({
        content: [
          item.energyItem?.value ?? "-",
          item.energyItem?.productionValue ?? "-",
          `${(
            Number(item.value / 1000) * (item.energyItem?.value ?? 0)
          ).toFixed(2)} €`,
          new Date(item.timestamp).toLocaleString("de-DE"),
          `${Number(item.value / 1000).toFixed(4)} €`,
          t("energy.detail.unit"),
        ],
        id: `${itemIndex}`,
      })) ?? [],
    [data, t]
  );

  /**
   * Generate the needed energy specification entries for the energy chart component
   */
  const currentMonthVisualView = useMemo(
    () =>
      (energyItemsCurrentAndPreviousMonth.data ?? [])
        .filter(
          (item) =>
            new Date(item.timestamp).getMonth() === new Date().getMonth()
        )
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
        .map(
          (item) =>
            ({
              date: new Date(item.timestamp),
              consumption: item.energyItem?.value ?? 0,
              production: item.energyItem?.productionValue ?? 0,
              spot: Number(Number(item.value / 1000).toFixed(2)),
            } as EnergyChartData)
        ) ?? [],
    [energyItemsCurrentAndPreviousMonth]
  );
  const previousMonthVisualView = useMemo(
    () =>
      (energyItemsCurrentAndPreviousMonth.data ?? [])
        .filter(
          (item) =>
            new Date(item.timestamp).getMonth() === new Date().getMonth() - 1
        )
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
        .map(
          (item) =>
            ({
              date: new Date(item.timestamp),
              consumption: item.energyItem?.value ?? 0,
              production: item.energyItem?.productionValue ?? 0,
              spot: Number(Number(item.value / 1000).toFixed(2)),
            } as EnergyChartData)
        ) ?? [],
    [energyItemsCurrentAndPreviousMonth]
  );
  const beforePreviousMonthVisualView = useMemo(
    () =>
      (energyItemsCurrentAndPreviousMonth.data ?? [])
        .filter(
          (item) =>
            new Date(item.timestamp).getMonth() === new Date().getMonth() - 2
        )
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
        .map(
          (item) =>
            ({
              date: new Date(item.timestamp),
              consumption: item.energyItem?.value ?? 0,
              production: item.energyItem?.productionValue ?? 0,
              spot: Number(Number(item.value / 1000).toFixed(2)),
            } as EnergyChartData)
        ) ?? [],
    [energyItemsCurrentAndPreviousMonth]
  );
  const individualTimeFrameVisualView = useMemo(
    () =>
      (energyItemsIndividualFrame.data ?? [])
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
        .map(
          (item) =>
            ({
              date: new Date(item.timestamp),
              consumption: item.energyItem?.value ?? 0,
              production: item.energyItem?.productionValue ?? 0,
              spot: Number(Number(item.value / 1000).toFixed(2)),
            } as EnergyChartData)
        ) ?? [],
    [energyItemsIndividualFrame.data]
  );

  /**
   * Generate the needed energy item entries for the energy chart component, for all entries
   */
  const allEnergyItemsVisualView = useMemo(
    () =>
      (data?.flatMap((page) => page.content) ?? [])
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
        .map(
          (item) =>
            ({
              date: new Date(item.timestamp),
              consumption: item.energyItem?.value ?? 0,
              production: item.energyItem?.productionValue ?? 0,
              spot: Number(Number(item.value / 1000).toFixed(2)),
            } as EnergyChartData)
        ) ?? [],
    [data]
  );

  /**
   * Returns the correct list entries for the table
   * @returns {RowEntry[]}
   */
  const getCorrectListEntries = (): RowEntry[] => {
    switch (selectedFilter) {
      case EnergyFilterType.CURRENT_MONTH:
        return currentMonthDataTableRows;
      case EnergyFilterType.LAST_MONTH:
        return previousMonthDataTableRows;
      case EnergyFilterType.INDIVIDUAL:
        return individualFrameDataTableRows;
      default:
        return allEnergySpecsTableRows;
    }
  };

  /**
   * Returns the correct visual view for the energy chart
   * @param showPrevious - If the previous month should be shown
   * @returns {EnergyChartData[]}
   */
  const getCorrectVisualView = (showPrevious?: boolean): EnergyChartData[] => {
    switch (selectedFilter) {
      case EnergyFilterType.CURRENT_MONTH:
        return showPrevious ? previousMonthVisualView : currentMonthVisualView;
      case EnergyFilterType.LAST_MONTH:
        return showPrevious
          ? beforePreviousMonthVisualView
          : previousMonthVisualView;
      case EnergyFilterType.INDIVIDUAL:
        return individualTimeFrameVisualView;
      default:
        return allEnergyItemsVisualView;
    }
  };

  /**
   * In case of new energy item locally adds it, to the correct list
   * @param item  - The new energy item
   */
  const addEnergyItemLocally = (item: EnergyItem): void => {
    switch (selectedFilter) {
      case EnergyFilterType.CURRENT_MONTH:
      case EnergyFilterType.LAST_MONTH:
        energyItemsCurrentAndPreviousMonth.mutate(
          energyItemsCurrentAndPreviousMonth.data?.map((spec) => ({
            ...spec,
            energyItem:
              new Date(spec.timestamp).getTime() ===
              new Date(item.timestamp).getTime()
                ? item
                : spec.energyItem,
          })),
          { revalidate: false }
        );
        break;
      case EnergyFilterType.INDIVIDUAL:
        energyItemsIndividualFrame.mutate(
          energyItemsIndividualFrame.data?.map((spec) => ({
            ...spec,
            energyItem:
              new Date(spec.timestamp).getTime() ===
              new Date(item.timestamp).getTime()
                ? item
                : spec.energyItem,
          })),
          { revalidate: false }
        );
        break;
      case EnergyFilterType.ALL:
        mutate();
    }
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.ENERGY)}
      title={t("energy.title")}
      scrollToTop
    >
      <div className="energy-detail--wrapper">
        <div className="energy-detail--wrapper_menu">
          <div className="energy-detail--wrapper_menu--item filter-wrapper">
            <div
              onClick={() => setSelectedFilter(EnergyFilterType.ALL)}
              className={
                selectedFilter === EnergyFilterType.ALL ? "active" : undefined
              }
            >
              {t("energy.detail.filter.all")}
            </div>
            <div
              onClick={() => setSelectedFilter(EnergyFilterType.CURRENT_MONTH)}
              className={
                selectedFilter === EnergyFilterType.CURRENT_MONTH
                  ? "active"
                  : undefined
              }
            >
              {t("energy.detail.filter.currentMonth")}
            </div>
            <div
              onClick={() => setSelectedFilter(EnergyFilterType.LAST_MONTH)}
              className={
                selectedFilter === EnergyFilterType.LAST_MONTH
                  ? "active"
                  : undefined
              }
            >
              {t("energy.detail.filter.lastMonth")}
            </div>
            <div
              onClick={() => setSelectedFilter(EnergyFilterType.INDIVIDUAL)}
              className={
                selectedFilter === EnergyFilterType.INDIVIDUAL
                  ? "active"
                  : undefined
              }
            >
              {t("energy.detail.filter.individual")}
            </div>
          </div>
          <div className="energy-detail--go-back" onClick={handleGoBack}>
            <ArrowLeft width={30} />
          </div>
        </div>
        <div className="energy-detail--wrapper_menu">
          <div className="energy-detail--wrapper_menu--item filter-wrapper">
            <TextInputComponent
              type="date"
              placeholder="Von"
              value={convertToDateForInput(selectedIndividualTime.start)}
              onChange={(newValue) => {
                try {
                  const parsedDate = new Date(newValue);
                  setSelectedIndividualTime({
                    ...selectedIndividualTime,
                    start: parsedDate,
                  });
                } catch (e) {
                  console.error("Error parsing date");
                }
              }}
              disabled={selectedFilter !== EnergyFilterType.INDIVIDUAL}
            />
            <TextInputComponent
              type="date"
              placeholder="Bis"
              value={convertToDateForInput(selectedIndividualTime.end)}
              onChange={(newValue) => {
                try {
                  const parsedDate = new Date(newValue);
                  setSelectedIndividualTime({
                    ...selectedIndividualTime,
                    end: parsedDate,
                  });
                } catch (e) {
                  console.error("Error parsing date");
                }
              }}
              disabled={selectedFilter !== EnergyFilterType.INDIVIDUAL}
            />
          </div>
        </div>
        <div className="energy-detail--wrapper_menu kpi-wrapper">
          {energyBoard?.coordinates && (
            <RetractableComponent title={t("energy.weather.general")}>
              <WeatherComponent
                lat={energyBoard.coordinates.lat}
                lon={energyBoard.coordinates.lon}
              />
            </RetractableComponent>
          )}
          <RetractableComponent title={t("energy.detail.overview.title")}>
            <div className="energy-detail--wrapper_menu kpi-wrapper overview-wrapper">
              <div className="overview-totals">
                <div>
                  <h4>
                    {t("energy.detail.overview.tableHeader.totalConsumption")}
                  </h4>
                  <p>
                    {accumulatedData.totalConsumption.toFixed(2)}
                    {t("energy.detail.overview.units.kwh")}
                  </p>
                </div>

                <div>
                  <h4>{t("energy.detail.overview.tableHeader.totalCosts")}</h4>

                  <p>
                    {accumulatedData.totalCosts.toFixed(2)}
                    {t("energy.detail.overview.units.value")}
                  </p>
                </div>
              </div>
              <div className="overview-totals">
                <div>
                  <h4>
                    {t("energy.detail.overview.tableHeader.averageConsumption")}
                  </h4>

                  <p>
                    {accumulatedData.averageConsumption.toFixed(2)}
                    {t("energy.detail.overview.units.kwh")}
                  </p>
                </div>
                <div>
                  <h4>
                    {t("energy.detail.overview.tableHeader.averageCosts")}
                  </h4>
                  <p>
                    {accumulatedData.averageCosts.toFixed(2)}
                    {t("energy.detail.overview.units.value")}
                  </p>
                </div>
                <div>
                  <h4>
                    {t("energy.detail.overview.tableHeader.averagePrice")}
                  </h4>

                  <p>
                    {accumulatedData.weightedPrice.toFixed(2)}
                    {t("energy.detail.overview.units.value")}
                  </p>
                </div>
              </div>
              <div className="overview-totals">
                <div>
                  <h4>
                    {t("energy.detail.overview.tableHeader.highestPrice")}
                  </h4>
                  <p>
                    {accumulatedData.highestPriceWithTimeStamp.value.toFixed(2)}
                    {t("energy.detail.overview.units.value")}
                  </p>
                  <p>
                    {new Date(
                      accumulatedData.highestPriceWithTimeStamp.timestamp
                    ).toLocaleString("de-DE")}
                  </p>
                </div>
                <div>
                  <h4>{t("energy.detail.overview.tableHeader.lowestPrice")}</h4>
                  <p>
                    {accumulatedData.lowestPriceWithTimeStamp.value.toFixed(2)}
                    {t("energy.detail.overview.units.value")}
                  </p>
                  <p>
                    {new Date(
                      accumulatedData.lowestPriceWithTimeStamp.timestamp
                    ).toLocaleString("de-DE")}
                  </p>
                </div>
              </div>
              <div className="overview-totals">
                <div>
                  <h4>
                    {t("energy.detail.overview.tableHeader.highestConsumption")}
                  </h4>
                  <p>
                    {accumulatedData.highestConsumptionWithTimeStamp.value.toFixed(
                      2
                    )}
                    {t("energy.detail.overview.units.kwh")}
                  </p>
                  <p>
                    {accumulatedData.highestConsumptionWithTimeStamp.timestamp}
                  </p>
                </div>
                <div>
                  <h4>
                    {t("energy.detail.overview.tableHeader.lowestConsumption")}
                  </h4>
                  <p>
                    {accumulatedData.lowestConsumptionWithTimeStamp.value.toFixed(
                      2
                    )}
                    {t("energy.detail.overview.units.kwh")}
                  </p>
                  <p>
                    {accumulatedData.lowestConsumptionWithTimeStamp.timestamp}
                  </p>
                </div>
              </div>
            </div>
          </RetractableComponent>
          <div className="energy-detail--wrapper_menu--item filter-wrapper"></div>
        </div>

        <div className="energy-detail--wrapper_menu">
          <div className="energy-detail--wrapper_menu--item">
            <div
              className={[
                viewType === "graph" ? "active" : undefined,
                "grap-distance",
              ].join(" ")}
              onClick={() => setViewType("graph")}
            >
              <GridIcon />
            </div>
            <div
              className={viewType === ViewMode.LIST ? "active" : undefined}
              onClick={() => setViewType(ViewMode.LIST)}
            >
              <ListIcon />
            </div>
          </div>
          <div
            className="energy-detail--wrapper_menu--item"
            onClick={() => {
              setSelectedEnergyItem(createEmptyEnergyItem(energyBoardId));
              toogleAddPopup(true);
            }}
          >
            <AddIcon />
          </div>
        </div>

        {isLoaded ? (
          <>
            <AddEnergyItemPopup
              minDate={
                new Date(data?.[0].content[0].timestamp ?? Date.now())
                  .toISOString()
                  .split("T")[0]
              }
              minTime={new Date(
                data?.[0].content[0].timestamp ?? Date.now()
              ).getHours()}
              open={showAddPopup && !!selectedEnergyItem}
              toogleOpen={toogleAddPopup}
              item={selectedEnergyItem!}
              onSuccess={(items) => addEnergyItemLocally(items[0])}
            />
            <div
              id="scrollTarget"
              className="energy-detail--wrapper--content-wrapper"
            >
              {viewType === "list" ? (
                <>
                  <TableComponent
                    listEntries={getCorrectListEntries()}
                    searchable
                    showIndex
                    searchPlaceholder={t("energy.detail.search")}
                    customTag={[
                      { flex: 1 },
                      { flex: 1 },
                      { flex: 1 },
                      { flex: 1 },
                      { flex: 1 },
                    ]}
                    searchButtonTitle={t("general.buttons.search")}
                    header={t("energy.detail.tableHeader", {
                      returnObjects: true,
                    })}
                  />
                  {selectedFilter === EnergyFilterType.ALL && (
                    <ButtonComponent
                      title={t("general.buttons.loadMore")}
                      onClick={() => setSize(size + 1)}
                    />
                  )}
                </>
              ) : (
                <>
                  {(selectedFilter === EnergyFilterType.CURRENT_MONTH ||
                    selectedFilter === EnergyFilterType.LAST_MONTH) && (
                    <CheckboxComponent
                      value={t("energy.detail.previousMonth")}
                      checked={showPreviousMonth}
                      onCheck={() => togglePreviousMonth(!showPreviousMonth)}
                    />
                  )}
                  <EnergyChartComponent
                    data={getCorrectVisualView()}
                    lineColor={"#407bfd"}
                    titles={{
                      yCons: t("energy.detail.axis.yCons"),
                      ySpot: t("energy.detail.axis.ySpot"),
                    }}
                  />
                  {showPreviousMonth && (
                    <EnergyChartComponent
                      data={getCorrectVisualView(true)}
                      lineColor={"#407bfd"}
                      titles={{
                        yCons: t("energy.detail.axis.yCons"),
                        ySpot: t("energy.detail.axis.ySpot"),
                      }}
                    />
                  )}
                </>
              )}
              <div className="energy-detail--wrapper--content-wrapper--source-wrapper">
                <h5>{t("energy.detail.source.title")}</h5>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  href="https://transparency.entsoe.eu/"
                >
                  <p>{t("energy.detail.source.entsoe")}</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  href="https://www.awattar.de/"
                >
                  <p>{t("energy.detail.source.awattar")}</p>
                </a>
              </div>
            </div>
          </>
        ) : (
          <LoaderComponent />
        )}
      </div>
    </LayoutComponent>
  );
};

export default EnergyManagementDetailPage;
