import { useContext, useEffect, useMemo, useState } from "react";
import { TableComponent } from "sfm-component-library";
import { RowEntry } from "sfm-component-library/build/TableComponent/TableComponent.types";
import { UserContext } from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { ExtendedGroup } from "../../../utils/group/Group.types";
import { User } from "../../../utils/user/User.types";
import { loadAllUsersFromBackendByCompanyId } from "../../../utils/user/User.utils";

export interface GroupMemberOverviewProps {
  group: ExtendedGroup;
}

export const GroupMemberOverview: React.FC<GroupMemberOverviewProps> = ({
  group,
}) => {
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const [loadedUsers, setLoadedUsers] = useState<User[]>([]);

  /**
   * Memorize the group members and map them to a {@link RowEntry} list.
   */
  const memberList = useMemo<RowEntry[]>(
    () =>
      group.members
        .map((userId) => {
          const user = loadedUsers.find((item) => item.id === userId);
          if (!user) return undefined;
          return {
            id: userId,
            content: [`${user.firstname} ${user.lastname}`, user.mail],
          };
        })
        .filter((item) => !!item) as RowEntry[],
    [group, loadedUsers]
  );

  /**
   * Load all users by company.
   * This is needed for the memberList to display first-, lastname and mail
   */
  useEffect(() => {
    if (!user) return;
    loadAllUsersFromBackendByCompanyId(user.companyId, axios).then((users) => {
      setLoadedUsers(users);
    });
  }, [axios, user]);

  return <TableComponent listEntries={memberList} customTag={[]} />;
};
