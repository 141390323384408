import { useTranslation } from "react-i18next";
import { TextInputComponent } from "sfm-component-library";
import {
  WidgetComponent,
  WidgetConfigComponent,
  WidgetType,
} from "../../../utils/user/Dashboard.types";
import "./Widgets.styles.scss";

/**
 *  Text widget which only display a given text
 */
export const TextWidget: WidgetComponent<WidgetType.TEXT> = ({ text }) => {
  return (
    <div className="text-widget">
      <h3>{text}</h3>
    </div>
  );
};

export const TextWidgetConfig: WidgetConfigComponent<WidgetType.TEXT> = ({
  info,
  options,
  mutateOptions,
  mutateInfo,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("dashboard.edit.color")}</p>
      <TextInputComponent
        type="color"
        value={info.color}
        onChange={(color) => mutateInfo({ color })}
      />
      <p>{t("dashboard.widget.text.input")}</p>
      <TextInputComponent
        value={options?.text}
        onChange={(text) => mutateOptions({ text })}
      />
    </>
  );
};
