import { AxiosInstance } from "axios";
import { DataApi } from "./DataApi.types";

/**
 * API METHOD - load a {@link DataApi}
 *
 * @param axios axios instance
 * @param dataApiId id of the dataApi
 * @returns the dataApi
 */
export const fetchDataApiById = async (
  axios: AxiosInstance,
  dataApiId: string
): Promise<DataApi | undefined> =>
  axios
    .get("/data/api/", { params: { dataApiId } })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error during fetching data api for company!", err);
      return undefined;
    });

/**
 * API METHOD - loads all {@link DataApi} as simple
 * @param axios network instance
 * @param companyId id of the company
 * @returns array of loaded data or empty array
 */
export const fetchAllDataApiForCompany = async (
  axios: AxiosInstance,
  companyId: string
): Promise<DataApi[]> => {
  return axios
    .get("/data/api/all/", { params: { companyId: companyId } })
    .then((userResp) => userResp.data)
    .catch((exc) => {
      console.error("Error during fetching data api for company!", exc);
      return [];
    });
};

/**
 * API METHOD - loads all {@link DataApi} by company and groups as simple
 * @param axios network instance
 * @param companyId id of the company
 * @returns array of loaded data or empty array
 */
export const fetchAllDataApiForCompanyWithinGroups = async (
  axios: AxiosInstance,
  companyId: string,
  groupIds: string[]
): Promise<DataApi[]> => {
  return axios
    .get("/data/api/group/all/", {
      params: { companyId, groupIds: groupIds.join(",") },
    })
    .then((userResp) => userResp.data)
    .catch((exc) => {
      console.error("Error during fetching data api!", exc);
      return [];
    });
};

/**
 * API METHOD - for loading the count of all data apis by company
 *
 * @param companyId the company id
 * @param userId the user id
 * @param axios the axios instance
 * @returns number of data apis
 */
export const loadCountOfAllDataApisByCompany = async (
  companyId: string,
  userId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get("/data/api/count/", {
      params: { companyId, userId },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during data api count fetch!", exc);
      return -1;
    });
};

/**
 * API METHOD - updates {@link DataApi} on the server.
 * Just for the meta data.
 * @param axios network instance
 * @param dataApi api from the api
 * @returns boolean if status code 200 is recieved
 */
export const updateDataApiForCompany = async (
  axios: AxiosInstance,
  dataApi: DataApi
): Promise<boolean> => {
  return axios
    .post("/data/api/update/", dataApi)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during updating data api!", exc);
      return false;
    });
};
