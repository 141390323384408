import { FC } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import "./CenterHeaderButtonStyles.scss";

export interface CenterHeaderButtonProps {
  title: string;
  onClick(): void;
}

/**
 * button in center of a subpage
 * @param title  title that is shown in the button
 * @param onClick method that runs on click
 */
const CenterHeaderButton: FC<CenterHeaderButtonProps> = ({
  title,
  onClick,
}) => (
  <div className="header-button-wrapper">
    <div className="header-button-wrapper-title">
      <div className="header-button-wrapper-title-text">{title}</div>
      <div className="header-button-wrapper-title-icon" onClick={onClick}>
        <CloseIcon />
      </div>
    </div>
  </div>
);
export default CenterHeaderButton;
