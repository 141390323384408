import { useEffect, useState } from "react";

/**
 * React-function to determine the size of an element with a given id
 * @param elementId id of the element that should be checked
 * @returns width of element in pixels
 */
export const useElementWidth = (elementId: string): number => {
  const [elementWidth, setElementWidth] = useState<number>(150);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      if (typeof document !== "undefined")
        // Set window width/height to state
        setElementWidth(document.getElementById(elementId)!.offsetWidth);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [elementId]); // Empty array ensures that effect is only run on mount

  return elementWidth;
};
