import { useEffect, useState } from "react";

export const useOnScreen = (ref: React.RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  let options = {
    rootMargin: "0px",
    threshold: 0.5,
  };
  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    options
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [ref]);

  return isIntersecting;
};
