import { useMemo } from "react";
import {
  availableWidgets,
  WidgetComponent,
  WidgetInfo,
  WidgetType,
} from "../../utils/user/Dashboard.types";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as DeleteButton } from "../../assets/icons/close.svg";

export interface WidgetProps {
  info: WidgetInfo;
  editable: boolean;
  onRemove?(): void;
  setWidgetToEdit?(info?: WidgetInfo): void;
}

export const Widget: React.FC<WidgetProps> = ({
  info,
  editable,
  onRemove,
  setWidgetToEdit,
}) => {
  /**
   * The calculated widget from type in availableWidgets
   */
  const WidgetBody = useMemo(() => {
    if (!Object.keys(availableWidgets).includes(info.type)) return undefined;
    const WidgetBody = availableWidgets[info.type]?.body as
      | WidgetComponent<WidgetType>
      | undefined;
    return WidgetBody;
  }, [info]);

  return (
    <div className="dashboard--widget">
      <div
        className="config-button"
        hidden={!editable}
        onClick={() => setWidgetToEdit?.(info)}
      >
        <SettingsIcon />
      </div>
      <div className="delete-button" hidden={!editable} onClick={onRemove}>
        <DeleteButton />
      </div>
      {WidgetBody !== undefined && <WidgetBody {...info.options[info.type]} />}
    </div>
  );
};
