import { useKeycloak } from "@react-keycloak/web";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  LayoutComponentProps,
  LinkType,
  SideMenuItem,
  UserMenuItem,
} from "sfm-component-library";
import { ReactComponent as AdminUsersIcon } from "../../assets/icons/admin-users.svg";
import { ReactComponent as ChecklistIcon } from "../../assets/icons/checklist.svg";
import { ReactComponent as CubeIcon } from "../../assets/icons/cube.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as PowerBIIcon } from "../../assets/icons/powerbi.svg";
import { ReactComponent as LightbulbIcon } from "../../assets/icons/lightbulb.svg";
import { ReactComponent as ProtocolIcon } from "../../assets/icons/protocol.svg";
import { ReactComponent as SuperAdminPanelIcon } from "../../assets/icons/super-admin-panel.svg";
import { ReactComponent as CompanyReview } from "../../assets/icons/building-circle-check.svg";
import { ReactComponent as MLIcon } from "../../assets/icons/scan.svg";
import { AppDataContext, UserContext } from "../../pages/App";
import { FeatureModule } from "../license/License.types";
import { useNotification } from "../notification/useNotification";
import { UserRole } from "../user/User.types";
import { PageType } from "./NavigationConfiguration.types";

/**
 * Helper to determine the right configuration for the LayoutComponent
 *
 * @param type
 * @returns LayoutConfiguration Object
 */
export const NavigationConfiguration = (
  type: PageType,
  showSideMenu: boolean = true
): LayoutComponentProps => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { logo } = useContext(AppDataContext);
  const { keycloak } = useKeycloak();
  const { notificationCount } = useNotification();
  const { t } = useTranslation();

  // menu entries, which hover in top right corner
  const usermenuItems: UserMenuItem[] = [
    { type: LinkType.HOME, url: "/dashboard" },
    {
      type: LinkType.NOTIFICATON,
      url: "/notifications",
      notificationCount: notificationCount,
    },
    { type: LinkType.PROFILE, url: "/profile" },
  ];

  // Side Menu Items for customer Users, depending on the purchased Feature
  const mapLicensesToMenuItemsForCompanyUsers: SideMenuItem[] =
    user?.company?.license.features.map(({ feature }) => {
      switch (feature) {
        case FeatureModule.SHOPFLOOR_BOARD:
          return {
            active: type === PageType.SHOPFLOORDASHBOARD,
            icon: <CubeIcon />,
            url: "/sfboard",
            title: t("general.navigation.SHOPFLOOR_BOARD"),
          };
        case FeatureModule.POWERBI:
          return {
            active:
              type === PageType.POWER_BI || type === PageType.POWER_BI_OVERVIEW,
            icon: <PowerBIIcon />,
            url: "/bi",
            title: t("general.navigation.POWERBI"),
          };
        case FeatureModule.ENERGY:
          return {
            active: type === PageType.ENERGY,
            icon: <LightbulbIcon />,
            url: "/energy",
            title: t("general.navigation.ENERGY"),
          };
        case FeatureModule.PROTOCOL:
          return {
            active: type === PageType.PROTOCOL,
            icon: <ProtocolIcon />,
            url: "/protocol",
            title: t("general.navigation.PROTOCOL"),
          };
        case FeatureModule.ML:
          return {
            active: type === PageType.ML,
            icon: <MLIcon />,
            url: "/ml",
            title: t("general.navigation.ML"),
          };
        case FeatureModule.LPA:
        case FeatureModule.INDUSTRIALTRUCKSANALYSIS:
        default:
          return { icon: <></> };
      }
    }) || [];

  switch (user?.role) {
    case UserRole.VISITOR:
      return {
        version: t("general.version"),
        logo,
        navigate: (url) => history.push(url),
        showMenu: showSideMenu,
        sidemenuItems: [
          ...mapLicensesToMenuItemsForCompanyUsers,
          {
            icon: <LogoutIcon />,
            onClick: () =>
              keycloak.logout({
                redirectUri: process.env.REACT_APP_CLIENT_URL,
              }),
            title: t("general.navigation.Logout"),
          },
        ],
        usermenuItems: usermenuItems,
      };
    case UserRole.EMPLOYEE:
      return {
        version: t("general.version"),
        logo,
        navigate: (url) => history.push(url),
        showMenu: showSideMenu,
        sidemenuItems: [
          ...mapLicensesToMenuItemsForCompanyUsers,
          {
            icon: <LogoutIcon />,
            onClick: () =>
              keycloak.logout({
                redirectUri: process.env.REACT_APP_CLIENT_URL,
              }),
            title: t("general.navigation.Logout"),
          },
        ],
        usermenuItems: usermenuItems,
      };

    case UserRole.ADMIN:
      return {
        version: t("general.version"),
        logo,
        navigate: (url) => history.push(url),
        showMenu: showSideMenu,
        sidemenuItems: [
          ...mapLicensesToMenuItemsForCompanyUsers,
          {
            active: type === PageType.ADMINISTRATION,
            icon: <AdminUsersIcon />,
            url: "/admin",
            title: t("general.navigation.ADMINISTRATION"),
          },
          {
            active: type === PageType.TASK_OVERVIEW,
            icon: <ChecklistIcon />,
            url: "/taskoverview",
            title: t("general.navigation.TASK_OVERVIEW"),
          },
          {
            icon: <LogoutIcon />,
            onClick: () =>
              keycloak.logout({
                redirectUri: process.env.REACT_APP_CLIENT_URL,
              }),
            title: t("general.navigation.Logout"),
          },
        ],
        usermenuItems: usermenuItems,
      };
    case UserRole.PLATFORM_ADMIN:
      return {
        version: t("general.version"),
        navigate: (url) => history.push(url),
        showMenu: showSideMenu,
        sidemenuItems: [
          {
            active: type === PageType.PLATFORMADMIN_COMPANY_PANEL,
            icon: <SuperAdminPanelIcon />,
            url: "/admin/companies",
            title: t("general.navigation.PLATFORMADMIN_COMPANY_PANEL"),
          },
          {
            active: type === PageType.PLATFORMADMIN_COMPANY_REVIEW,
            icon: <CompanyReview />,
            url: "/admin/companies/review",
            title: t("general.navigation.PLATFORMADMIN_COMPANY_REVIEW"),
          },
          {
            icon: <LogoutIcon />,
            onClick: () =>
              keycloak.logout({
                redirectUri: process.env.REACT_APP_CLIENT_URL,
              }),
            title: t("general.navigation.Logout"),
          },
        ],
        usermenuItems: usermenuItems,
      };
    default:
      return {
        navigate: (url) => history.push(url),
        showMenu: false,
        sidemenuItems: [],
        usermenuItems: usermenuItems,
        version: t("general.version"),
      };
  }
};
