import dayjs from "dayjs";
import i18n from "../../i18n";

// load supported languages
import "dayjs/locale/de";

/**
 * change dayjs default language to i18n default language when i18n is initialized
 */
i18n.on("initialized", () => {
  dayjs.locale(i18n.language);
});
/**
 * change dayjs default language to i18n default language when i18n is changed
 */
i18n.on("languageChanged", () => {
  dayjs.locale(i18n.language);
});
