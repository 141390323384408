import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "sfm-component-library";
import { ReactComponent as DashboardIcon } from "./../assets/icons/dashboard.svg";
import { ReactComponent as LoginIcon } from "./../assets/icons/login.svg";
import { ReactComponent as LogoutIcon } from "./../assets/icons/logout.svg";
import DuckeneersLogo from "./../assets/images/duckeneers-logo.png";
import "./../styles/ErrorPageStyles.scss";
import { FallbackProps } from "react-error-boundary";

const ErrorPage: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <div className="error-page">
      <div className="error-page__header">
        <div className="error-page__header__logo">
          <img src={DuckeneersLogo} alt="logo" />
        </div>
        <div className="error-page__header__button__wrapper--mobile">
          {keycloak.authenticated ? (
            <ButtonComponent
              title={<LogoutIcon />}
              onClick={() => {
                keycloak.logout();
                resetErrorBoundary();
              }}
              borderColor="transparent"
              className={"color-white"}
            />
          ) : (
            <ButtonComponent
              title={<LoginIcon />}
              onClick={() => {
                history.push("/login");
                resetErrorBoundary();
              }}
              borderColor="transparent"
              className={"color-white"}
            />
          )}
          {keycloak.authenticated && (
            <ButtonComponent
              title={<DashboardIcon />}
              borderColor="transparent"
              onClick={() => {
                history.push("/dashboard");
                resetErrorBoundary();
              }}
            />
          )}
        </div>
        <div className="error-page__header__button__wrapper">
          {keycloak.authenticated ? (
            <ButtonComponent
              title="Dashboard"
              onClick={() => {
                history.push("/dashboard");
                resetErrorBoundary();
              }}
              borderColor="black"
              className={"color-white"}
            />
          ) : (
            <ButtonComponent
              title="Home"
              onClick={() => {
                history.push("/");
                resetErrorBoundary();
              }}
              borderColor="black"
              className={"color-white"}
            />
          )}

          {keycloak.authenticated ? (
            <ButtonComponent
              title="Logout"
              onClick={() => {
                keycloak.logout();
                resetErrorBoundary();
              }}
              borderColor="black"
              className={"color-white"}
            />
          ) : (
            <ButtonComponent
              title="Login"
              onClick={() => {
                history.push("/login");
                resetErrorBoundary();
              }}
              borderColor="black"
              className={"color-white"}
            />
          )}
        </div>
      </div>
      <div className="error-page__box error-page__box--1" />
      <div className="error-page__box error-page__box--2" />
      <div className="error-page__box error-page__box--3" />
      <div className="error-page__box error-page__box--4" />

      <div className={"error-page__content"}>
        <div className={"error-page__main__wrapper"}>
          <h1>{t("error.title")}</h1>
          <p>{`${error?.name}${error?.message ? " -" : ""} ${
            error?.message
          }`}</p>
          <p>{error?.stack}</p>
        </div>
      </div>

      <footer className={"error-page__footer"}>
        <div>
          <p>{t("landing.address.name")}</p>
          <p>{t("landing.address.street")}</p>
          <p>{t("landing.address.city")}</p>
        </div>
        <div className={"error-page__footer__contact"}>
          <p>{t("landing.contact.phone")}</p>
          <a href={`mailto:` + t("landing.contact.email")}>
            {t("landing.contact.email")}
          </a>
          <a href={t("landing.contact.website")}>
            {t("landing.contact.website")}
          </a>
        </div>
        <div className={"error-page__footer__contact"}>
          <a href="https://www.duckeneers.de/impressum/">
            {t("landing.impressum")}
          </a>
          <a href="https://www.duckeneers.de/privacy/">
            {t("landing.dataprivacy")}
          </a>
        </div>
      </footer>
    </div>
  );
};
export default ErrorPage;
