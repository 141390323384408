import { useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../../pages/App";
import { useAxios } from "../AxiosUtil";
import { PowerBIConfiguration } from "./PowerBI.types";

/**
 * SWR Hook - to fetch all the latest PowerBIs
 * @returns a list of {@link PowerBIConfiguration} when successful, else returns an empty list
 */
export const usePowerBIConfiguration = (): {
  powerBIConfigurations: PowerBIConfiguration[];
  isLoading: boolean;
  isError: any;
  mutate(PowerBIConfigurations: PowerBIConfiguration[]): void;
} => {
  const { user } = useContext(UserContext);
  const { axios, fetcher } = useAxios();
  const { data, error, mutate } = useSWR(
    !!axios && user
      ? `/data/config/powerbi/company/group/?companyId=${
          user.companyId
        }&userId=${user.id!}`
      : null,
    fetcher
  );
  return {
    powerBIConfigurations: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
