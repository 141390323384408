import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnChartComponent } from "sfm-component-library";
import { ColumnChartData } from "sfm-component-library/build/ChartComponent/ChartComponent.types";
import { compareDate, getAllDaysInMonth } from "../../utils/GeneralUtils";
import { ShopfloorBoardPerformanceEntry } from "../../utils/sfboard/SfBoard.types";
import { getMaxPerformanceEntry } from "../../utils/sfboard/SfBoard.utils";
import { ReactComponent as ChevronLeft } from "./../../assets/icons/chevron_left.svg";
import { ReactComponent as ChevronRight } from "./../../assets/icons/chevron_right.svg";

interface ShopFloorPerformanceChartPopUpProps {
  columnPerformanceEntries: ShopfloorBoardPerformanceEntry[];
  filterWeekendDays?: boolean;
}

const ShopFloorPerformanceChartPopUp: FC<
  ShopFloorPerformanceChartPopUpProps
> = ({ columnPerformanceEntries, filterWeekendDays }) => {
  const { t } = useTranslation();
  const [currentShownDate, setCurrentShownDate] = useState(
    new Date(Date.now())
  );

  /**
   * handle click on arrows
   * @param value the month to show ("next" or "previous")
   */
  const handleMonthChange = (value: "next" | "previous"): void => {
    const toAdd: number = value === "next" ? 1 : -1;
    setCurrentShownDate(
      new Date(currentShownDate.setMonth(currentShownDate.getMonth() + toAdd))
    );
  };

  /**
   * maps performance entries to ColumnChartData
   * @return array of ColumnChartData
   */
  const mapDataToColumnChartEntry = (): ColumnChartData[] => {
    const datesOfCurrentMonth = getAllDaysInMonth(
      currentShownDate.getMonth(),
      currentShownDate.getFullYear()
    );

    return datesOfCurrentMonth.map((day) => {
      const foundValue = columnPerformanceEntries.find(
        (columnPerformanceEntry) =>
          compareDate(day, columnPerformanceEntry.createDate)
      );

      /**
       * checks for existing performance Entry, if none exists, returns object with empy values
       */
      if (foundValue) {
        return {
          value: foundValue.currentValue,
          date: new Date(day),
          goal: foundValue.targetValue,
        };
      }
      return { value: 0, date: new Date(day), goal: 0 };
    });
  };

  return (
    <div className={"shopfloor-column__chart-pop-up"}>
      <div className={"shopfloor-column__chart-pop-up__header"}>
        <h1>{t("shopfloorboard.column.popUp.title")}</h1>
        <div className={"shopfloor-column__chart-pop-up__navigate-month"}>
          <ChevronLeft onClick={() => handleMonthChange("previous")} />
          <p>
            {currentShownDate.toLocaleString("de-DE", {
              month: "long",
              year: "numeric",
            })}
          </p>
          <ChevronRight onClick={() => handleMonthChange("next")} />
        </div>
        <div className={"shopfloor-column__chart-pop-up__legend"}>
          <div className={"shopfloor-column__chart-pop-up__legend__child"}>
            <div></div>
            <p>{t("shopfloorboard.column.popUp.goalNotReached")}</p>
          </div>
          <div className={"shopfloor-column__chart-pop-up__legend__child"}>
            <div></div>
            <p>{t("shopfloorboard.column.popUp.goalReached")}</p>
          </div>
        </div>
      </div>
      <ColumnChartComponent
        data={mapDataToColumnChartEntry()}
        goalAchievedColor={"#BDFD95"}
        filterWeekendDays={filterWeekendDays}
        hideValuesSmallerthan={0}
        maxValue={getMaxPerformanceEntry(columnPerformanceEntries) * 1.5}
        goalLineAttr={{
          length: 1,
          color: "gray",
          thickness: 4,
        }}
      />
    </div>
  );
};

export default ShopFloorPerformanceChartPopUp;
