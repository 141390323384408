import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  ButtonComponent,
  LayoutComponent,
  TableComponent,
} from "sfm-component-library";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { useProtocol } from "../../utils/protocol/useProtocol";
import { ReactComponent as LeftIcon } from "./../../assets/icons/chevron_left.svg";

import { RowEntry } from "sfm-component-library/build/TableComponent/TableComponent.types";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import DeletePopUp from "../../components/deletepopup/DeletePopUp";
import "../../styles/ProtocolFilledOutPage.scss";
import { useAxios } from "../../utils/AxiosUtil";
import { deleteProtocol } from "../../utils/protocol/Protocol.axios";
import { UserRole } from "../../utils/user/User.types";
import { UserContext } from "../App";

const ProtocolFilledOutPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<{ originId: string }>();
  const [originId, setOriginId] = useState<string>("");
  const { user } = useContext(UserContext);
  const [idToDelete, setIdToDelete] = useState<string>();
  const {
    Protocols: filledOutProtocols,
    size,
    setSize,
    hasMore,
    deleteFilled,
  } = useProtocol(originId);
  const userIsAdmin: boolean = user
    ? [UserRole.ADMIN, UserRole.PLATFORM_ADMIN].includes(user.role)
    : false;

  const { axios } = useAxios();
  /**
   * Check if the originId is set in the location state.
   */
  useEffect(() => {
    if (!location.state || !location.state.originId) history.push("/protocol");
    else setOriginId(location.state.originId);
  }, [location.state, history]);

  /**
   * memoized rows of filled out protocols
   */
  const protocolRows: RowEntry[] = useMemo((): RowEntry[] => {
    return filledOutProtocols.map((protocol) => ({
      content: [
        protocol.user
          ? `${protocol.user.firstname} ${protocol.user.lastname}`
          : protocol.userId || "",
        new Date(protocol.createDate).toLocaleString("de-DE") ??
          new Date().toLocaleTimeString("de-DE"),
        protocol.summaryEnabled
          ? (t(`protocol.fill.summaryTypes.${protocol.summaryType}`) as string)
          : (t("protocol.fill.summaryTypes.none") as string),
        ...(userIsAdmin
          ? [
              <DeleteIcon
                onClick={(evt) => {
                  evt.stopPropagation();
                  setIdToDelete(protocol.id!);
                }}
              />,
            ]
          : []),
      ],
      id: protocol.id || "",
    }));
  }, [filledOutProtocols, userIsAdmin, t]);

  /**
   * If protocol is clicked, navigate to the detail page to view
   * @param id  the id of the protocol
   */
  const handleRowClick = (id: string): void => {
    const foundProtocol = filledOutProtocols.find(
      (protocol) => protocol.id === id
    );
    if (foundProtocol) {
      history.push(`filled/${foundProtocol.id}`, {
        protocol: foundProtocol,
        view: true,
      });
    }
  };

  /**
   * deletes protocol with idToDelete on server and then updates local state
   */
  const localDeleteProtocol = (): void => {
    deleteProtocol(idToDelete!, axios).then(() => {
      deleteFilled(idToDelete!);
      setIdToDelete(undefined);
    });
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.PROTOCOL)}
      title={t("protocol.title")}
    >
      <DeletePopUp
        isOpen={!!idToDelete}
        toggleOpen={() => setIdToDelete(undefined)}
        content={t("protocol.filled.delete")}
        onDelete={localDeleteProtocol}
      />
      <div className="protocol-filled__wrapper">
        <div className="protocol-filled__header">
          <LeftIcon onClick={() => history.goBack()} />
        </div>
        {filledOutProtocols?.length > 0 ? (
          <>
            <TableComponent
              listEntries={protocolRows}
              searchable
              showIndex
              searchPlaceholder={t("protocol.filled.search")}
              customTag={[{ flex: 1 }, { flex: 1 }]}
              searchButtonTitle={t("general.buttons.search")}
              header={t(
                `protocol.filled.tableHeader${userIsAdmin ? "Admin" : ""}`,
                {
                  returnObjects: true,
                }
              )}
              onClick={handleRowClick}
            />
            {hasMore && (
              <ButtonComponent
                title={t("general.buttons.loadMore")}
                onClick={() => setSize?.((size ?? 0) + 1)}
              />
            )}
          </>
        ) : (
          <h2>{t("protocol.filled.noProtocols")}</h2>
        )}
      </div>
    </LayoutComponent>
  );
};

export default ProtocolFilledOutPage;
