import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonComponent, TabComponent } from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { ShopfloorBoardConfiguration } from "../../../utils/sfboard/SfBoard.types";
import {
  createEmptyTask,
  FileEntry,
  FileType,
  Task,
} from "../../../utils/tasks/Tasks.types";
import { downloadFile } from "../../../utils/tasks/TasksUtil";
import { User, UserAction } from "../../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../../utils/user/User.utils";
import DeletePopUp from "../../deletepopup/DeletePopUp";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/delete.svg";
import { ReactComponent as UnarchiveIcon } from "./../../../assets/icons/unarchive.svg";
import { ReactComponent as SaveIcon } from "./../../../assets/icons/save.svg";
import { TaskCreationDocumentTab } from "./TaskCreationDocumentTab";
import { TaskCreationInfoTab } from "./TaskCreationInfoTab";
import { TaskCreationMediaTab } from "./TaskCreationMediaTab";

interface TaskCreationEditProps {
  task?: Task;
  users: User[];
  columnId?: string;
  sfConfig?: ShopfloorBoardConfiguration;
  popup?: HTMLElement;
  isLoading?: boolean;
  setTask(task: Task): void;
  setFiles(files: { imageFiles: File[]; documentFiles: File[] }): void;
  onDelete?(task: Task): Promise<void>;
  onSave?(): void;
}

const TaskCreationEdit: React.FC<TaskCreationEditProps> = ({
  task: initTask,
  users,
  columnId,
  sfConfig,
  setTask,
  setFiles,
  onDelete,
  onSave,
  popup,
  isLoading,
}) => {
  const { user } = useContext(UserContext);
  const { axios } = useAxios();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [documentFiles, setDocumentFiles] = useState<File[]>([]);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [localTask, setLocalTask] = useState<Task>(
    initTask ||
      createEmptyTask(
        sfConfig?.id || "",
        sfConfig?.companyId || "",
        user?.id || "",
        columnId
      )
  );

  const tabs: string[] = t("shopfloorboard.task.tabs", {
    returnObjects: true,
  });

  /**
   * This effect push changed to parent when local media/doc files was changed.
   */
  useEffect(() => {
    setFiles({ imageFiles, documentFiles });
  }, [setFiles, imageFiles, documentFiles]);

  /**
   * This effect push changed to parent when local task was changed.
   */
  useEffect(() => {
    setTask(localTask);
  }, [setTask, localTask]);

  /**
   * if task already exists and image does not exist, loads image files attaches it to the fileEntry
   */
  useEffect(() => {
    Promise.all(
      localTask.fileEntries.map((taskFile) => {
        if (taskFile.file) {
          return taskFile.file;
        }
        if (taskFile.type === FileType.IMAGE) {
          return downloadFile(localTask.id || "", taskFile.fileName, axios);
        }
        return undefined;
      })
    ).then((receivedFiles) => {
      const fileEntriesWithImages: FileEntry[] = localTask.fileEntries.map(
        (fileEntry) => {
          const receivedFile: File | undefined = receivedFiles.find(
            (fileToFind) => fileToFind?.name === fileEntry.fileName
          );
          return { ...fileEntry, file: receivedFile };
        }
      );
      setLocalTask({
        ...localTask,
        fileEntries: fileEntriesWithImages,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  /**
   * renders either information, media or document tab
   */
  const renderActiveTab = (): JSX.Element => {
    switch (activeTab) {
      case 0:
        return (
          <TaskCreationInfoTab
            task={localTask}
            onTaskChange={setLocalTask}
            popupContentElement={popup}
            users={users}
            sfConfig={sfConfig}
            editColumnDisabled={!!columnId}
          />
        );
      case 1:
        return (
          <TaskCreationMediaTab
            taskFileEntries={localTask.fileEntries.filter(
              (fileEntry) =>
                fileEntry.type === FileType.IMAGE && !!fileEntry.file
            )}
            newImageFiles={imageFiles}
            setNewImageFiles={setImageFiles}
            deleteFileEntry={(newFileList) =>
              setLocalTask({ ...localTask, fileEntries: newFileList })
            }
          />
        );
      case 2:
        return (
          <TaskCreationDocumentTab
            taskId={localTask.id}
            taskFileEntries={localTask.fileEntries}
            newDocumentFiles={documentFiles}
            setNewDocumentFiles={setDocumentFiles}
            deleteFileEntry={(newFileList) =>
              setLocalTask({ ...localTask, fileEntries: newFileList })
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <form
      id="task-creation-from-input"
      className="task-edit__form"
      onSubmit={(event) => {
        event.preventDefault();
        onSave?.();
      }}
    >
      <DeletePopUp
        isOpen={deletePopup}
        toggleOpen={setDeletePopup}
        onDelete={async () => {
          await onDelete?.(localTask);
          setDeletePopup(false);
        }}
        deleteIsLoading={isLoading}
        content={<p>{t("shopfloorboard.task.deleteDescription")}</p>}
      />
      <div className="task-edit__header">
        <TabComponent
          tabs={tabs}
          onClick={setActiveTab}
          activeTab={activeTab}
        />
        {localTask && (
          <div className="task-edit__header__button-wrapper">
            {isUserAllowedToDoThis(UserAction.CREATE_TASK, user) &&
              !localTask.id &&
              onSave && (
                <ButtonComponent
                  className="task-edit__header__button-content__save"
                  title={
                    <div className="task-edit__header__button-content">
                      <p>{t("general.buttons.save")}</p>
                      <SaveIcon />
                    </div>
                  }
                  type="submit"
                />
              )}
            {isUserAllowedToDoThis(UserAction.EDIT_TASK, user) && localTask.id && (
              <ButtonComponent
                title={
                  <div className="task-edit__header__button-content">
                    {localTask.archived ? (
                      <>
                        <p>{t("general.buttons.unarchive")}</p>
                        <UnarchiveIcon />
                      </>
                    ) : (
                      <>
                        <p>{t("general.buttons.archive")}</p>
                        <ArchiveIcon />
                      </>
                    )}
                  </div>
                }
                onClick={() =>
                  setLocalTask({ ...localTask, archived: !localTask.archived })
                }
              />
            )}
            {isUserAllowedToDoThis(UserAction.DELETE_TASK, user) &&
              localTask.id && (
                <ButtonComponent
                  title={
                    <div className="task-edit__header__button-content">
                      <p>{t("general.buttons.delete")}</p>
                      <DeleteIcon />
                    </div>
                  }
                  borderColor="#F86B6B"
                  type="button"
                  onClick={() => setDeletePopup(true)}
                />
              )}
          </div>
        )}
      </div>
      {renderActiveTab()}
    </form>
  );
};

export default TaskCreationEdit;
