import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonComponent, TableComponent } from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { formatBytes } from "../../../utils/GeneralUtils";
import { FileEntry, FileType } from "../../../utils/tasks/Tasks.types";
import {
  downloadFile,
  generateDownloadDocument,
} from "../../../utils/tasks/TasksUtil";
import { UserAction } from "../../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../../utils/user/User.utils";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/delete.svg";
import { ReactComponent as DownloadIcon } from "./../../../assets/icons/download.svg";

interface TaskCreationDocumentTabProps {
  taskId?: string;
  newDocumentFiles: File[];
  taskFileEntries: FileEntry[];
  setNewDocumentFiles(documentFiles: File[]): void;
  deleteFileEntry(fileEntry: FileEntry[]): void;
}

export const TaskCreationDocumentTab: FC<TaskCreationDocumentTabProps> = ({
  taskId,
  taskFileEntries,
  newDocumentFiles,
  setNewDocumentFiles,
  deleteFileEntry,
}) => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const [openedFile, setOpenedFile] = useState<File>();
  const { user } = useContext(UserContext);
  /**
   * @returns RowEntry for existing files
   */
  const generateDocRowEntryForExistingDocs = () =>
    taskFileEntries
      .filter((fileEntry) => fileEntry.type === FileType.DOCUMENT)
      .map((fileEntry: FileEntry) => ({
        content: [
          fileEntry.originalFileName,
          formatBytes(fileEntry.fileSize),
          new Date(fileEntry.createDate).toLocaleString(),
          <DownloadIcon onClick={() => handleDownloadFile(fileEntry)} />,
          ...(isUserAllowedToDoThis(UserAction.EDIT_TASK, user)
            ? [
                <DeleteIcon
                  onClick={() =>
                    deleteFileEntry(
                      taskFileEntries.filter(
                        (fileToFilter) =>
                          fileToFilter.fileName !== fileEntry.fileName
                      )!
                    )
                  }
                />,
              ]
            : []),
        ],
        id: fileEntry.fileName,
      }));

  /**
   * @returns RowEntry for new files
   */
  const generateDocRowEntryForNewDocs = () =>
    newDocumentFiles.map((file: File) => ({
      content: [
        file.name,
        formatBytes(file.size),
        new Date(Date.now()).toLocaleString(),
        <DownloadIcon className="task-edit__document__wrapper__download--disabled" />,
        <DeleteIcon
          onClick={() =>
            setNewDocumentFiles(
              newDocumentFiles.filter((fileToFilter) => fileToFilter !== file)
            )
          }
        />,
      ],
      id: file.name,
    }));

  /**
   * handles file Download. if file is a pdf Document, opens pdf reader instead
   * @param fileEntry file to download
   */
  const handleDownloadFile = (fileEntry: FileEntry): Promise<void> =>
    axios &&
    downloadFile(taskId!, fileEntry.fileName, axios).then((file) => {
      if (!file) return;
      if (file?.type === "application/pdf") {
        setOpenedFile(file);
      } else {
        generateDownloadDocument(file, fileEntry.originalFileName);
      }
    });

  return (
    <div className="task-edit__document">
      <div className="task-edit__document__wrapper">
        <div
          className={`task-edit__document__pdf-pop-up ${
            openedFile ? "" : "task-edit__document__pdf-pop-up--disabled"
          }`}
        >
          <div>
            <CloseIcon onClick={() => setOpenedFile(undefined)} />
          </div>
          {openedFile && (
            <object
              aria-label={openedFile.name}
              data={URL.createObjectURL(openedFile)}
              type={openedFile.type}
            />
          )}
        </div>
        {isUserAllowedToDoThis(UserAction.EDIT_TASK, user) && (
          <ButtonComponent
            type="file"
            title={t("general.buttons.upload")}
            multiple
            setFiles={(files) =>
              setNewDocumentFiles([...newDocumentFiles, ...files])
            }
            borderColor="black"
            accept={"*"}
          />
        )}
        {generateDocRowEntryForExistingDocs().length === 0 &&
          generateDocRowEntryForNewDocs().length === 0 && (
            <p className="task-edit__document__no-file">
              {t("general.noFileUploaded")}
            </p>
          )}
        <TableComponent
          listEntries={generateDocRowEntryForExistingDocs()}
          customTag={[
            { flex: 6 },
            { color: "black", flex: 2 },
            { flex: 4 },
            { flex: 1 },
            { flex: 1 },
          ]}
        />
        <TableComponent
          listEntries={generateDocRowEntryForNewDocs()}
          customTag={[
            { flex: 6 },
            { color: "black", flex: 2 },
            { flex: 4 },
            { flex: 1 },
          ]}
        />
      </div>
    </div>
  );
};
