import { isEqual } from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckboxComponent,
  DropdownComponent,
  TextInputComponent,
} from "sfm-component-library";
import { UserContext } from "../../../../pages/App";
import { useAxios } from "../../../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../../../utils/GeneralUtils";
import {
  updateGroupUser,
  useGroups,
} from "../../../../utils/group/Group.utils";
import { User, UserRole } from "../../../../utils/user/User.types";
import {
  createUserOnBackend,
  generateUserCreationErrorNotifications,
  generateUserRoleOptions,
  updateUserOnBackend,
} from "../../../../utils/user/User.utils";
import { GroupSelect } from "../../../administration/groupselect/GroupSelect";
import "./UserEditFormStyle.scss";

interface UserEditFormProps {
  userToEdit: User;
  updateUser(user: User): void;
  closePopup(): void;
  isLoading(loadingState: boolean): void;
  portal?: HTMLElement;
}

const UserEditForm: React.FC<UserEditFormProps> = ({
  userToEdit,
  updateUser,
  closePopup,
  isLoading,
  portal,
}) => {
  const [localUser, setLocalUser] = useState<User>(userToEdit);
  const { axios } = useAxios();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const loadedGroups = useGroups() ?? [];

  /**
   * Update the group on the backend.
   * This also checks if the groups are modified (skip update if not).
   *
   * @param groupIds the newly assigned groupIds
   * @param userId the id of the user (because newly created users dont have a id setted)
   * @returns promised boolean if success
   */
  const updateGroups = async (
    groupIds: string[],
    userId: string
  ): Promise<boolean> => {
    if (isEqual(user?.groups, groupIds)) return true; // skip update
    return updateGroupUser(axios, userId, groupIds);
  };

  /**
   * Update or creates user on backend and closes the popup
   */
  const saveUserAndClosePopup = (): void => {
    isLoading(true);
    if (localUser.id) {
      updateUserOnBackend(localUser, axios).then((updatedUser) => {
        isLoading(false);
        if (!updatedUser) {
          generateNotificationWithTranslations("warning");
          return;
        }
        updateUser(localUser);
        generateNotificationWithTranslations("success");
        closePopup();
      });
    } else {
      createUserOnBackend(localUser, axios).then(async (createdUser) => {
        if (!createdUser.user) {
          isLoading(false);
          generateUserCreationErrorNotifications(createdUser);
          return;
        }
        const groupSuccess = await updateGroups(
          localUser.groups,
          createdUser.user.id!
        );
        isLoading(false);
        if (!groupSuccess) {
          generateNotificationWithTranslations("warning");
          return;
        }
        updateUser(createdUser.user);
        generateNotificationWithTranslations("success");
        closePopup();
      });
    }
  };

  return (
    <div className="user-edit-form-wrapper">
      <h2>
        {localUser.id
          ? `${localUser.firstname} ${localUser.lastname} (${localUser.username})`
          : t("administration.user.newUser")}
      </h2>
      <form
        id="user-edit-from"
        onSubmit={(evt) => {
          evt.preventDefault();
          saveUserAndClosePopup();
        }}
      >
        <TextInputComponent
          onChange={(newValue) =>
            setLocalUser({ ...localUser, firstname: newValue })
          }
          value={localUser.firstname}
          label={t("administration.user.firstname")}
        />
        <TextInputComponent
          onChange={(newValue) =>
            setLocalUser({ ...localUser, lastname: newValue })
          }
          value={localUser.lastname}
          label={t("administration.user.lastname")}
        />
        <TextInputComponent
          required
          onChange={(newValue) =>
            setLocalUser({ ...localUser, username: newValue })
          }
          value={localUser.username}
          label={t("administration.user.username")}
        />
        <TextInputComponent
          type="email"
          required
          onChange={(newValue) =>
            setLocalUser({ ...localUser, mail: newValue })
          }
          value={localUser.mail}
          label={t("administration.user.mail")}
        />
        <DropdownComponent
          portal={portal}
          required
          label={t("administration.user.role")}
          options={generateUserRoleOptions()}
          onChange={(option) =>
            setLocalUser({ ...localUser, role: option.value as UserRole })
          }
          selectedOption={localUser.role}
        />
        <CheckboxComponent
          value={t("administration.user.enable")}
          checked={localUser.enabled}
          onCheck={() =>
            setLocalUser({ ...localUser, enabled: !localUser.enabled })
          }
        />
        <GroupSelect
          groups={loadedGroups ?? []}
          assignedGroups={localUser.groups ?? []}
          onUpdate={(groups) => {
            setLocalUser({ ...localUser, groups });
          }}
          dropdownPortal={portal}
        />
      </form>
    </div>
  );
};

export default UserEditForm;
