import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  ButtonComponent,
  CheckboxComponent,
  LayoutComponent,
  PopupComponent,
  TextInputComponent,
} from "sfm-component-library";
import { useAxios } from "../../utils/AxiosUtil";
import { Company, LicenseModule } from "../../utils/company/Company.types";
import {
  deleteCompany,
  fetchSingleCompany,
  updateCompany,
} from "../../utils/company/CompanyUtils";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { FeatureModule } from "../../utils/license/License.types";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { UserContext } from "../App";
import "./../../styles/CompanyEditPageStyles.scss";
interface CompanyEditPageProps {}

const CompanyEditPage: FC<CompanyEditPageProps> = () => {
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [companyToEdit, setCompanyToEdit] = useState<Company>();
  const adressFields = ["name", "street", "city", "country"];
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [company, setCompany] = useState<Company>();
  /**
   * fetcches the company, that needs to be edited
   */
  useEffect(() => {
    if (!!axios && user && id) {
      fetchSingleCompany(axios, id).then((fetchedCompany) => {
        setCompanyToEdit(fetchedCompany);
        setCompany(fetchedCompany);
      });
    }
  }, [axios, user, id]);

  /**
   * saves the changes in the backend and creates a notification
   */
  const handleSubmit = (): void => {
    if (companyToEdit) {
      updateCompany(companyToEdit!, axios).then((result) =>
        generateNotificationWithTranslations(result ? "success" : "warning")
      );
    }
  };

  /**
   *deletes company and returns to company overview if successful
   */
  const handleDelete = (): void => {
    deleteCompany(axios, id).then((result) => {
      setOpenPopUp(false);
      generateNotificationWithTranslations(result ? "success" : "warning");
      if (result) {
        history.push("/admin/companies");
      }
    });
  };

  return (
    <LayoutComponent
      title={t("superAdministration.companyEdit.title")}
      {...NavigationConfiguration(PageType.PLATFORMADMIN_COMPANY_EDIT)}
    >
      <PopupComponent
        isOpen={openPopUp}
        toggleOpen={setOpenPopUp}
        footerButtons={[
          {
            title: t("general.buttons.cancel"),
            borderColor: "#f86b6b",
            onClick: () => setOpenPopUp(false),
          },
          {
            title: t("general.buttons.delete"),
            borderColor: "black",
            className: "color-white",
            onClick: handleDelete,
          },
        ]}
      >
        <p>{t("superAdministration.companyEdit.delete.content")}</p>
      </PopupComponent>
      {companyToEdit && (
        <>
          {adressFields.map((field) => (
            <TextInputComponent
              key={field}
              placeholder={t(`company.${field}`)}
              value={(companyToEdit[field as keyof Company] as string) || ""}
              onChange={(newValue) =>
                setCompanyToEdit({ ...companyToEdit, [field]: newValue })
              }
            />
          ))}
          <TextInputComponent
            placeholder={t("company.zip")}
            value={companyToEdit.zip || ""}
            type="number"
            onChange={(newValue) =>
              setCompanyToEdit({ ...companyToEdit, zip: newValue })
            }
          />
          <TextInputComponent
            label="Longitude"
            placeholder={"Longitude"}
            value={companyToEdit.lon || 0.0}
            type="number"
            step={"0.1"}
            onChange={(newValue) =>
              setCompanyToEdit({
                ...companyToEdit,
                lon: parseFloat(newValue) || 0.0,
              })
            }
          />
          <TextInputComponent
            label="Latitude"
            placeholder={"Latitude"}
            value={companyToEdit.lat || 0.0}
            step={"0.1"}
            type="number"
            onChange={(newValue) =>
              setCompanyToEdit({
                ...companyToEdit,
                lat: parseFloat(newValue) || 0.0,
              })
            }
          />
          {Object.keys(FeatureModule).map((feature) => {
            const licenseModule = company?.license.features.find(
              (licenseModule) => licenseModule.feature === feature
            );
            const editedLicenseModule = companyToEdit.license.features.find(
              (licenseModule) => licenseModule.feature === feature
            );
            return (
              <div className={"company-edit__checkbox__wrapper"}>
                <CheckboxComponent
                  key={feature}
                  value={t(`license.${feature}`)}
                  checked={!!editedLicenseModule}
                  onCheck={(checked) =>
                    setCompanyToEdit({
                      ...companyToEdit,
                      license: {
                        features: checked
                          ? [
                              ...companyToEdit.license.features,
                              {
                                feature,
                                activatedAt: new Date(),
                              } as LicenseModule,
                            ]
                          : companyToEdit.license.features.filter(
                              (licenseToFilter) =>
                                licenseToFilter.feature !== feature
                            ),
                      },
                    })
                  }
                />
                {!!editedLicenseModule && (
                  <div className={"company-edit__checkbox__date"}>
                    {licenseModule === editedLicenseModule && (
                      <>
                        <p>
                          {t("superAdministration.companyEdit.activatedAt")}
                        </p>
                        <p>
                          {new Date(
                            licenseModule.activatedAt
                          ).toLocaleDateString()}
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
          <hr />
          <br />
          <div className="id-field">
            ID: <b>{companyToEdit.id}</b>
          </div>
          <CheckboxComponent
            value={t(`superAdministration.companyEdit.disable`)}
            checked={!!companyToEdit.enabled}
            onCheck={(checked) =>
              setCompanyToEdit({
                ...companyToEdit,
                enabled: checked,
              })
            }
            borderColor="black"
          />
        </>
      )}

      <div className={"horizontal-buttons"}>
        <ButtonComponent
          title={t("general.buttons.back")}
          borderColor="#f86b6b"
          onClick={() => history.goBack()}
        />

        <ButtonComponent
          title={t("general.buttons.delete")}
          borderColor="black"
          onClick={() => setOpenPopUp(true)}
          className={"color-white"}
        />
        <ButtonComponent
          title={t("superAdministration.companyEdit.editUser")}
          onClick={() => history.push(`/admin/company/user/${id}`)}
        />

        <ButtonComponent
          title={t("general.buttons.save")}
          borderColor="#A9FAA2"
          onClick={() => handleSubmit()}
        />
      </div>
    </LayoutComponent>
  );
};

export default CompanyEditPage;
