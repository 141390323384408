import { AxiosInstance } from "axios";
import {
  BoardCopyRequest,
  ShopfloorBoardColumn,
  ShopfloorBoardConfiguration,
} from "./SfBoard.types";
import { DeletionRequest } from "../apidata/DataApi.types";

/**
 * API METHOD - fetches all board configs for a company
 *
 * @param companyId
 * @param axios
 * @returns list of configs
 */
export const loadAllShopfloorBoardConfigsForCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<ShopfloorBoardConfiguration[]> => {
  return axios
    .get("/data/config/sfboard/company/", { params: { companyId: companyId } })
    .then((resp) => resp.data)
    .catch((exc) =>
      console.error("Error during shopfloor config load for company", exc)
    );
};

/**
 * API METHOD - fetches all board configs for a company in a simplified version
 *
 * @param companyId
 * @param axios
 * @returns list of configs
 */
export const loadAllShopfloorBoardConfigsForCompanySimplified = async (
  companyId: string,
  axios: AxiosInstance
): Promise<ShopfloorBoardConfiguration[]> => {
  return axios
    .get("/data/config/sfboard/company/simple/", {
      params: { companyId: companyId },
    })
    .then((resp) => resp.data)
    .catch((exc) =>
      console.error("Error during shopfloor config load for company", exc)
    );
};

/**
 * API METHOD - creates a new board config for a company
 *
 * @param newConfig the board config to create
 * @param axios the axios instance
 * @returns the newly created board or undefined if errored
 */
export const createNewShopfloorBoardConfig = async (
  newConfig: ShopfloorBoardConfiguration,
  axios: AxiosInstance
): Promise<ShopfloorBoardConfiguration | undefined> => {
  return axios
    .post("/data/config/sfboard/", newConfig)
    .then((res) => (res.status === 201 ? res.data : undefined))
    .catch((exc) => {
      console.error("Error during shopfloorboard config upload", exc);
      return undefined;
    });
};

/**
 * API METHOD - creates a new board config for a company
 *
 * @param copy the copy request
 * @param axios the axios instance
 * @returns the newly copied board or undefined if errored
 */
export const copyShopfloorBoardConfig = async (
  copy: BoardCopyRequest,
  axios: AxiosInstance
): Promise<ShopfloorBoardConfiguration | undefined> => {
  return axios
    .post("/data/config/sfboard/copy/", copy)
    .then((res) => (res.status === 201 ? res.data : undefined))
    .catch((exc) => {
      console.error("Error during shopfloorboard config copy", exc);
      return undefined;
    });
};

/**
 * API METHOD - loads a board config for a company
 *
 * @param configId
 * @param axios
 * @returns boolean if it was successful
 */
export const loadShopfloorBoardConfig = async (
  configId: string,
  axios: AxiosInstance
): Promise<ShopfloorBoardConfiguration> => {
  return axios
    .get("/data/config/sfboard/id/", {
      params: { configId: configId },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) =>
      console.error("Error during shopfloorboard config fetch", exc)
    );
};

/**
 * API METHOD - deletes a new board config for a company
 *
 * @param deletionRequest
 * @param axios
 * @returns boolean if it was successful
 */
export const deleteShopfloorBoardConfig = async (
  deletionRequest: DeletionRequest,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/config/sfboard/delete/id/", deletionRequest)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during shopfloorboard config upload", exc);
      return false;
    });
};

/**
 * API METHOD - updates a new board config column for a company
 *
 * @param updatedColumn
 * @param axios
 * @returns boolean if it was successful
 */
export const updateShopfloorBoardColumn = async (
  updatedColumn: ShopfloorBoardColumn,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/config/sfboard/update/column/", updatedColumn)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during column update!", exc);
      return false;
    });
};

/**
 * API METHOD - updates a new board config column for a company
 *
 * @param updatedConfig
 * @param axios
 * @returns boolean if it was successful
 */
export const updateShopfloorBoardConfig = async (
  updatedConfig: ShopfloorBoardConfiguration,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/config/sfboard/update/", updatedConfig)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during config update!", exc);
      return false;
    });
};

/**
 * API METHOD - updates columns for a board of a company
 *
 * @param updatedColumns
 * @param axios
 * @returns boolean if it was successful
 */
export const updateShopfloorBoardColumns = async (
  updatedConfig: ShopfloorBoardConfiguration,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/config/sfboard/update/column/config/", updatedConfig)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during config update!", exc);
      return false;
    });
};
