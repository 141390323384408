import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  BoxComponent,
  BoxType,
  LayoutComponent,
  PopupComponent,
} from "sfm-component-library";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list-solid.svg";
import { ReactComponent as GridIcon } from "../../assets/icons/grip-solid.svg";
import EditPowerBIConfigPopUp from "../../components/powerbi/EditPowerBIConfigPopUp";
import "../../styles/PowerBIPageStyles.scss";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { PowerBIConfiguration } from "../../utils/powerbi/PowerBI.types";
import { usePowerBIConfiguration } from "../../utils/powerbi/usePowerBIConfiguration";
import { UserAction } from "../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { UserContext } from "../App";
import { generateListEntries } from "../../utils/groupSort/GroupList.utils";
import { BoardCopyRequest } from "../../utils/sfboard/SfBoard.types";
import { useGroups } from "../../utils/group/Group.utils";
import { BoardCopyForm } from "../../components/shopfloorboard/board/BoardCopyForm";
import { useAxios } from "../../utils/AxiosUtil";
import { copyPowerBIConfig } from "../../utils/powerbi/PowerBIOverview.utils";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { ReactComponent as CopyIcon } from "../../assets/icons/clone.svg";

interface PowerBIOverviewPageProps {}

const PowerBIOverviewPage: FC<PowerBIOverviewPageProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const { powerBIConfigurations: loadedConfigs, mutate: setConfigs } =
    usePowerBIConfiguration();
  const [configToEdit, setConfigToEdit] = useState<PowerBIConfiguration>();
  const [configToCopy, setConfigToCopy] = useState<PowerBIConfiguration>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isListView, toggleListView] = useState<boolean>(false);
  const [showEditConfig, setShowEditConfig] = useState<boolean>(false);
  const loadedGroups = useGroups() ?? [];

  /**
   * Helper to copy a full board.
   *
   * @param copy the copy request
   * @returns promised void to detect when process is finished
   */
  const onCopyBoard = async (copy: BoardCopyRequest): Promise<void> => {
    try {
      const result = await copyPowerBIConfig(copy, axios);
      if (!result) {
        generateNotificationWithTranslations("warning");
        return;
      }
      setConfigs([...loadedConfigs, result]);
      generateNotificationWithTranslations("success");
      setConfigToCopy(undefined);
    } catch {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * Method to determine which view should be displayed
   * @param listView boolean that determines the type of view
   * @param configs ShopfloorBoardConfiguration[] that contains entries
   * @param givenUser User object
   * @param givenHistory History used to push
   * @param givenGroups Group[] to determine names for list-view
   * @returns JSX.Element containing the chosen view
   */
  const getCorrectEntryView = (listView: boolean): JSX.Element => {
    switch (listView) {
      case true:
        return (
          <div className="power-bi--list">
            {generateListEntries<PowerBIConfiguration>({
              type: "PowerBi",
              user,
              history,
              entries: loadedConfigs,
              setConfigToEdit,
              setConfigToCopy,
              loadedGroups,
              setShowEditConfig,
            })}
          </div>
        );
      case false:
      default:
        return (
          <div className="power-bi__overview--grid">
            {loadedConfigs.map((config, index) => (
              <BoxComponent
                background={config.color}
                type={BoxType.PACKAGE}
                key={`box-config-component-item-${index}`}
                onClick={() =>
                  history.push(`/bi/detail`, {
                    configId: config.id,
                  })
                }
                title={config.name}
                onClickConfig={() => {
                  if (
                    isUserAllowedToDoThis(UserAction.EDIT_POWERBI_CONFIG, user)
                  ) {
                    setConfigToEdit(config);
                    setShowEditConfig(true);
                  }
                }}
                hideConfig={
                  !isUserAllowedToDoThis(UserAction.EDIT_POWERBI_CONFIG, user)
                }
                buttons={[
                  {
                    icon: <CopyIcon />,
                    hidden: !isUserAllowedToDoThis(
                      UserAction.CREATE_POWERBI_CONFIG,
                      user
                    ),
                    onClick: () => setConfigToCopy(config),
                  },
                ]}
              />
            ))}
          </div>
        );
    }
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.POWER_BI_OVERVIEW)}
      title={t("powerBI.title")}
    >
      <div className="power-bi__overview">
        <EditPowerBIConfigPopUp
          configToEdit={configToEdit}
          onSubmit={(editedConfig) => {
            if (configToEdit) {
              setConfigs(
                loadedConfigs.map((oldConfig) =>
                  oldConfig.id === editedConfig.id ? editedConfig : oldConfig
                )
              );
              setConfigToEdit(undefined);
            } else {
              setConfigs([...loadedConfigs, editedConfig]);
            }
            setShowEditConfig(false);
          }}
          isOpen={showEditConfig}
          setIsOpen={(isOpen) => {
            setShowEditConfig(isOpen);
            if (!isOpen) {
              setConfigToEdit(undefined);
            }
          }}
          onDelete={() => {
            setConfigs(
              loadedConfigs.filter((config) => config.id! !== configToEdit!.id!)
            );
            setConfigToEdit(undefined);
            setShowEditConfig(false);
          }}
        />
        {configToCopy && (
          <PopupComponent
            isOpen
            title={t("shopfloorboard.config.copy.title", {
              replace: { name: configToCopy.name },
            })}
            toggleOpen={() => setConfigToCopy(undefined)}
            footerButtons={[
              {
                title: t("general.buttons.cancel"),
                onClick: () => setConfigToCopy(undefined),
                borderColor: "black",
                className: "color-white",
                isLoading: isLoading,
              },
              {
                title: t("general.buttons.copy"),
                borderColor: "#A9FAA2",
                type: "submit",
                form: "board-copy-form",
                isLoading: isLoading,
              },
            ]}
          >
            <BoardCopyForm
              board={configToCopy}
              onCopy={async (copy) => {
                setLoading(true);
                await onCopyBoard(copy);
                setLoading(false);
              }}
            />
          </PopupComponent>
        )}
        <div className="power-bi__overview--adding">
          <div className="power-bi__overview--adding-view">
            <GridIcon
              className={["view-svg", !isListView ? "active" : ""].join(" ")}
              onClick={() => toggleListView(false)}
            />
            <ListIcon
              className={[
                "view-svg",
                "list-icon",
                isListView ? "active" : "",
              ].join(" ")}
              onClick={() => toggleListView(true)}
            />
          </div>
          {isUserAllowedToDoThis(UserAction.CREATE_POWERBI_CONFIG, user) && (
            <AddIcon onClick={() => setShowEditConfig(true)} />
          )}
        </div>
        {loadedConfigs.length > 0 ? (
          getCorrectEntryView(isListView)
        ) : (
          <p>{t("powerBI.config.notFound")}</p>
        )}
      </div>
    </LayoutComponent>
  );
};

export default PowerBIOverviewPage;
