import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  LoaderComponent,
  TabComponent,
} from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../../utils/GeneralUtils";
import {
  generateNewShopfloorBoardConfig,
  ShopfloorBoardConfiguration,
} from "../../../utils/sfboard/SfBoard.types";
import {
  createNewShopfloorBoardConfig,
  deleteShopfloorBoardConfig,
  updateShopfloorBoardConfig,
} from "../../../utils/sfboard/SfBoardOverview.utils";
import { UserAction } from "../../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../../utils/user/User.utils";
import DeletePopUp from "../../deletepopup/DeletePopUp";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/delete.svg";
import { BoardEditFormGeneralTab } from "./BoardEditFormGeneralTab";
import { BoardEditFormVisualTab } from "./BoardEditFormVisualTab";
import "./BoardEditFormStyles.scss";
import { updateGroupSFBoard } from "../../../utils/group/Group.utils";

interface ColumnEditFormProps {
  boardConfig?: ShopfloorBoardConfiguration;
  isLoading?: boolean;
  onSaved(boardConfig: ShopfloorBoardConfiguration): void;
  onDeleted?(boardConfig: ShopfloorBoardConfiguration): void;
  onLoadingChange?(loading: boolean): void;
}

export const BoardEditForm: React.FC<ColumnEditFormProps> = ({
  boardConfig,
  isLoading,
  onSaved,
  onDeleted,
  onLoadingChange,
}) => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(0);
  const [config, setConfig] = useState<ShopfloorBoardConfiguration>();
  const [requestedDelete, setRequestedDelete] = useState<boolean>(false);
  const [requestedDeleteWithTasks, setRequestedDeleteWithTasks] =
    useState<boolean>(true);

  /**
   * Set current boardConfig if exists else it generates a new one
   */
  useEffect(() => {
    if (!user) return;
    if (!boardConfig) {
      setConfig(generateNewShopfloorBoardConfig(user));
    } else {
      setConfig({ ...boardConfig, lastUpdatedBy: user.id! });
    }
  }, [boardConfig, user]);

  /**
   * Handler to save a shopfloorboard
   *
   * @returns promised boolean indicates success
   */
  const saveBoard = async (): Promise<boolean> => {
    if (!config) return false;

    let success = false;
    let localConfig = config;
    onLoadingChange?.(true);

    if (config.id) {
      success = await updateShopfloorBoardConfig(config, axios);
    } else {
      const response = await createNewShopfloorBoardConfig(config, axios);
      if (response) localConfig = response;
      success = true;
    }

    if (success && localConfig.id) {
      success = await updateGroupSFBoard(axios, localConfig.id, config.groups);
    }

    onLoadingChange?.(false);
    generateNotificationWithTranslations(success ? "success" : "warning");

    return success;
  };

  /**
   * Handler to delete a shopfloorboard
   *
   * @returns promised boolean indicates success
   */
  const deleteBoard = async (): Promise<boolean> => {
    if (!config) return false;
    onLoadingChange?.(true);

    const success = await deleteShopfloorBoardConfig(
      {
        deletionId: config.id || "",
        deleterId: user!.id!,
        deleteAllTasks: requestedDeleteWithTasks,
      },
      axios
    );

    onLoadingChange?.(false);
    return success;
  };

  /**
   * Gets the currently selected tab.
   *
   * @returns {JSX.Element} current tab
   */
  const renderTab = (): JSX.Element => {
    if (!config) return <></>;
    switch (activeTab) {
      case 0:
        return (
          <BoardEditFormGeneralTab config={config} setConfig={setConfig} />
        );
      case 1:
        return <BoardEditFormVisualTab config={config} setConfig={setConfig} />;
      default:
        return <></>;
    }
  };

  return (
    <div>
      {requestedDelete && config && (
        <DeletePopUp
          isOpen
          className="board-edit-form--delete"
          title={t("shopfloorboard.config.edit.delete")}
          content={
            <div>
              <CheckboxComponent
                checked={requestedDeleteWithTasks}
                value={t("shopfloorboard.config.edit.deleteAllTask")}
                onCheck={(value) => setRequestedDeleteWithTasks(value)}
              />
            </div>
          }
          toggleOpen={() => setRequestedDelete(false)}
          deleteIsLoading={isLoading}
          onDelete={async () => {
            await deleteBoard();
            setRequestedDelete(false);
            onDeleted?.(config);
          }}
        />
      )}
      {config ? (
        <form
          id={"board-edit-form"}
          onSubmit={async (evt) => {
            evt.preventDefault();
            await saveBoard();
            onSaved(config);
          }}
        >
          <div className="board-edit-form--header">
            <TabComponent
              tabs={t("shopfloorboard.config.edit.tabs", {
                returnObjects: true,
              })}
              onClick={setActiveTab}
              activeTab={activeTab}
            />
            {isUserAllowedToDoThis(UserAction.DELETE_COLUMN, user) && (
              <ButtonComponent
                title={
                  <div className="board-edit-form--header--button-content">
                    <p>{t("general.buttons.delete")}</p>
                    <DeleteIcon />
                  </div>
                }
                borderColor="#F86B6B"
                type="button"
                onClick={() => setRequestedDelete(true)}
              />
            )}
          </div>
          <div className="board-edit-form--content">{renderTab()}</div>
        </form>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};
