import i18next from "i18next";
import { Option } from "sfm-component-library";
import {
  ConnectorType,
  defaultColumnColor,
  ShopfloorBoardColumn,
  ShopfloorBoardPerformanceEntry,
  ShopfloorColumnColor,
  VisualColumnConfiguration,
} from "./SfBoard.types";

/**
 * Helper to generate a list of possible connector type values
 *
 * @returns list of options
 */
export const generateConnectorTypeOptions = (): Option[] => {
  return Object.keys(ConnectorType).map((type) => {
    return {
      label: i18next.t(`enum.connectorType.${type}`),
      value: type,
    };
  });
};

/**
 *  update column: change value of colorThresholdIsPercentage inside {@link VisualColumnConfiguration} and recalculate existing thresholds, depending on colorThresholdIsPercentage attribute
 * @param checked new Value
 * @param column to update
 * @tested
 * @returns update {@link ShopfloorBoardColumn}
 */
export const updateColumnCheckIsPercentage = (
  checked: boolean,
  column: ShopfloorBoardColumn
): ShopfloorBoardColumn => {
  if (
    !column.visualConfig ||
    column.visualConfig.colorThresholdIsPercentage === checked
  )
    return column;

  let updatedThreshholds: number[] = column.visualConfig!.colorThresholds;
  let updateColumnColors: ShopfloorColumnColor[] =
    column.visualConfig!.colorList;
  if (
    checked &&
    ((!column.visualConfig!.maxValue && column.targetValue === 0) ||
      column.visualConfig!.maxValue! === 0)
  ) {
    updatedThreshholds = [0];
    updateColumnColors = [
      column.visualConfig!.colorList[0] || defaultColumnColor.colorList[0],
      column.visualConfig!.colorList[1] || defaultColumnColor.colorList[1],
    ];
  } else if (checked && column.visualConfig!.maxValue) {
    updatedThreshholds = column.visualConfig!.colorThresholds.map(
      (threshhold) =>
        Math.round((threshhold / column.visualConfig!.maxValue!) * 100)
    );
  } else if (checked) {
    updatedThreshholds = column.visualConfig!.colorThresholds.map(
      (threshhold) => Math.round((threshhold / column.targetValue) * 100)
    );
  } else if (
    (column.visualConfig!.useCustomMaxValue &&
      (!column.visualConfig!.maxValue || column.visualConfig!.maxValue <= 1)) ||
    column.targetValue <= 1
  ) {
    updatedThreshholds = [0];
    updateColumnColors = [
      column.visualConfig!.colorList[0] || defaultColumnColor.colorList[0],
      column.visualConfig!.colorList[1] || defaultColumnColor.colorList[1],
    ];
  } else if (column.visualConfig!.useCustomMaxValue) {
    updatedThreshholds = column.visualConfig!.colorThresholds.map(
      (threshhold) =>
        Math.round((threshhold / 100) * column.visualConfig!.maxValue!)
    );
  } else {
    updatedThreshholds = column.visualConfig!.colorThresholds.map(
      (threshhold) => Math.round((threshhold / 100) * column.targetValue)
    );
  }
  return {
    ...column,
    visualConfig: {
      ...column.visualConfig,
      colorThresholdIsPercentage: checked,
      colorThresholds: updatedThreshholds,
      colorList: updateColumnColors,
    },
  };
};

/**
 * update column: adds a color threshold to {@link VisualColumnConfiguration} and also adds a new track
 * @param column to update
 * @param newThumb value of new treshold
 * @tested
 * @returns updated {@link ShopfloorBoardColumn}
 */
export const updateColumnAddThumb = (
  column: ShopfloorBoardColumn,
  newThumb: number
): ShopfloorBoardColumn => {
  if (!column.visualConfig) return column;

  return {
    ...column,
    visualConfig: {
      ...column.visualConfig,
      colorThresholds: [
        ...column.visualConfig!.colorThresholds!,
        newThumb,
      ].sort((firstValue, secondValue) => firstValue - secondValue),
      colorList: [
        ...column.visualConfig!.colorList,
        {
          index: column.visualConfig!.colorList.length,
          color:
            column.visualConfig!.colorList[
              column.visualConfig!.colorList.length - 1
            ].color,
        },
      ],
    },
  };
};

/**
 * update column: changes the value of a color threshold inside {@link VisualColumnConfiguration}
 * @param column to update
 * @param thumbs list of all tresholds
 * @tested
 * @returns updated {@link ShopfloorBoardColumn}
 */
export const updateColumnUpdateThumb = (
  column: ShopfloorBoardColumn,
  thumbs: number[]
): ShopfloorBoardColumn => {
  if (!column.visualConfig) {
    return column;
  }

  return {
    ...column,
    visualConfig: {
      ...column.visualConfig!,
      colorThresholds: thumbs,
      colorList: matchColorListToThresholds(
        thumbs,
        column.visualConfig!.colorList
      ),
    },
  };
};

/**
 * update column: removes a color threshold inside {@link VisualColumnConfiguration} and also removes a track
 * @param column  to update
 * @param thumbToRemove value of threshold to remove
 * @tested
 * @returns updated {@link ShopfloorBoardColumn}
 */
export const updateColumnRemoveThumb = (
  column: ShopfloorBoardColumn,
  thumbToRemove: number
): ShopfloorBoardColumn => {
  if (!column.visualConfig) {
    return column;
  }
  const indexOfColor =
    column.visualConfig!.colorThresholds.indexOf(thumbToRemove);

  const newColumnList = column
    .visualConfig!.colorList.filter(
      (colorObj) => indexOfColor !== colorObj.index
    )
    .sort((firstValue, secondValue) => firstValue.index - secondValue.index)
    .map((colorObj, index) => ({ index: index, color: colorObj.color }));

  return {
    ...column,
    visualConfig: {
      ...column.visualConfig!,
      colorThresholds: column.visualConfig!.colorThresholds.filter(
        (treshold) => treshold !== thumbToRemove
      ),
      colorList: newColumnList,
    },
  };
};

/**
 * update column: changes the color of a track inside {@link VisualColumnConfiguration}
 * @param column to update
 * @param newColor new color of the track
 * @param index of the track, that needs to be updated
 * @tested
 * @returns updated {@link ShopfloorBoardColumn}
 */
export const updateColumnUpdateColor = (
  column: ShopfloorBoardColumn,
  newColor: string,
  index: number
): ShopfloorBoardColumn => {
  if (!column.visualConfig) {
    return column;
  }
  return {
    ...column,
    visualConfig: {
      ...column.visualConfig,
      colorList: column.visualConfig.colorList.map((columnColor) => {
        if (columnColor.index === index) {
          return {
            color: newColor,
            index: index,
          };
        }
        return columnColor;
      }),
    },
  };
};

/**
 * update column: changes max value inside {@link VisualColumnConfiguration}
 * @param newValue new max value
 * @param column to update
 * @tested
 * @returns updated {@link ShopfloorBoardColumn}
 */
export const updateColumnChangeMaxValue = (
  newValue: string,
  column: ShopfloorBoardColumn
): ShopfloorBoardColumn => {
  if (!column.visualConfig) {
    return column;
  }

  return {
    ...column,
    visualConfig: {
      ...column.visualConfig!,
      maxValue: isNaN(parseInt(newValue)) ? 0 : parseInt(newValue),
    },
  };
};

/**
 * matches the colorList to given thresholds
 * @param colorThresholds given thresholds
 * @param colorList
 * @tested
 * @returns updated list of {@link ShopfloorBoardColumnColor}
 */
export const matchColorListToThresholds = (
  colorThresholds: number[],
  colorList: ShopfloorColumnColor[]
): ShopfloorColumnColor[] => {
  if (colorThresholds.length + 1 < colorList.length) {
    return colorThresholds.map(
      (_, thresholdIndex) =>
        colorList.find((color) => color.index === thresholdIndex)!
    );
  }
  if (colorList.length === colorThresholds.length + 1) {
    return colorList;
  }
  let updatedList = colorList;
  do {
    updatedList.push({
      color: colorList[colorList.length - 1].color,
      index: colorList.length,
    });
  } while (colorList.length < colorThresholds.length + 1);
  return updatedList;
};

/**
 * Helper to get correct color for a column
 * @param targetValue targetValue of column
 * @param performanceEntryValue current performance Entry value
 * @param visualConfig {@link VisualColumnConfiguration} of column
 * @tested
 * @returns  ColumnColor for percent
 */
export const getCorrectColumnColor = (
  targetValue: number,
  performanceEntryValue?: number,
  visualConfig?: VisualColumnConfiguration
): string => {
  const colorList: ShopfloorColumnColor[] =
    visualConfig?.colorList || defaultColumnColor.colorList;

  const colorThresholds: number[] =
    visualConfig?.colorThresholds || defaultColumnColor.colorThresholds;

  const isPercentage: boolean = visualConfig
    ? visualConfig.colorThresholdIsPercentage
    : defaultColumnColor.colorThresholdIsPercentage;

  const defaultColor: string = visualConfig
    ? visualConfig.colorList[visualConfig.colorList.length - 1].color
    : defaultColumnColor.colorList[defaultColumnColor.colorList.length - 1]
        .color;

  let activeColor: string | undefined;

  const currentPerformance = performanceEntryValue || 0;

  if (!performanceEntryValue && !visualConfig) return defaultColor;

  colorThresholds.forEach((threshold, index) => {
    if (activeColor) return;

    let valueToCompare: number;

    if (isPercentage) {
      valueToCompare = (currentPerformance / targetValue) * 100;
    } else {
      valueToCompare = currentPerformance;
    }

    if (valueToCompare <= threshold) {
      activeColor = colorList.find(
        (columnColor) => columnColor.index === index
      )?.color;
    }
  });

  return activeColor || defaultColor;
};

/**
 * Get the todays target value based on the connector type.
 *
 * @tested
 * @param column the column to use
 * @param todayPerformanceEntry the todays performance entry
 * @returns the target value as number
 */
export const getTargetValue = (
  column: ShopfloorBoardColumn,
  todayPerformanceEntry?: ShopfloorBoardPerformanceEntry
): number => {
  switch (column.connectorType) {
    case ConnectorType.MANUAL:
      return todayPerformanceEntry?.targetValue ?? column.targetValue;
    case ConnectorType.CSV_IMPORT:
      return todayPerformanceEntry?.targetValue ?? column.targetValue;
    case ConnectorType.DATA_API:
      return (
        todayPerformanceEntry?.targetValue ??
        column.connectorInfo.dataApi?.defaultTarget ??
        0
      );
  }
};
