import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { LayoutComponent, LoaderComponent } from "sfm-component-library";
import { UserAdmin } from "../../components/administration/UserAdmin";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";

export const CompanyUserEditPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();

  /**
   * Check if a id is given via the router params
   * If id is empty then redirect to the start page
   */
  useEffect(() => {
    if (!id) {
      history.replace("/");
    }
  }, [id, history]);

  return (
    <LayoutComponent
      title={t("superAdministration.companyEdit.title")}
      {...NavigationConfiguration(PageType.PLATFORMADMIN_COMPANY_USER_EDIT)}
    >
      {id ? <UserAdmin companyId={id} /> : <LoaderComponent />}
    </LayoutComponent>
  );
};
