import { useCallback, useContext, useEffect, useState } from "react";
import {
  ButtonComponent,
  LoaderComponent,
  PopupComponent,
} from "sfm-component-library";
import { ReactComponent as AddFileIcon } from "../../assets/icons/file_add.svg";
import { ReactComponent as AddPinIcon } from "../../assets/icons/pin_add.svg";
import { ReactComponent as ChalkboardIcon } from "../../assets/icons/chalkboard.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  ConnectorType,
  ShopfloorBoardColumn,
  ShopfloorBoardConfiguration,
  ShopfloorBoardPerformanceEntry,
} from "../../utils/sfboard/SfBoard.types";
import {
  applyDefaultTarget,
  convertDataApiEntryToPerformanceEntry,
  loadAllPerformanceEntryForShopfloorBoard,
  loadAllTasksForShopfloorBoard,
  updatePerformanceEntries,
} from "../../utils/sfboard/SfBoard.utils";
import { loadShopfloorBoardConfig } from "../../utils/sfboard/SfBoardOverview.utils";
import { Task } from "../../utils/tasks/Tasks.types";
import { User, UserAction } from "../../utils/user/User.types";
import {
  isUserAllowedToDoThis,
  loadAllUsersFromBackendByCompanyId,
} from "../../utils/user/User.utils";
import ColumnEditForm, { ColumnEditFormProps } from "./column/ColumnEditForm";
import TaskCreationPopup from "./task/TaskCreationPopup";
import { ColumnSlider } from "./column/ColumnSlider";
import { BoardEditForm } from "./board/BoardEditForm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { fetchDataApiById } from "../../utils/apidata/DataApi.utils";

interface ShopFloorBoardProps {
  configId: string;
  onConfigLoaded(config: ShopfloorBoardConfiguration): void;
  initiallyOpenTaskId?: string;
}

const ShopFloorBoard: React.FC<ShopFloorBoardProps> = ({
  configId,
  onConfigLoaded,
  initiallyOpenTaskId,
}) => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const history = useHistory();
  const [columnEditPopupIsOpen, toggleColumnEditPopup] =
    useState<boolean>(false);
  const [columnToEdit, setColumnToEdit] = useState<ShopfloorBoardColumn>();
  const [loadedTasks, setLoadedTasks] = useState<Task[]>([]);
  const [loadedPerformanceEntry, setLoadedPerformanceEntry] =
    useState<ShopfloorBoardPerformanceEntry[]>();
  const [showTaskCreationPopup, toggleTaskCreationPopup] = useState<
    boolean | string
  >(false);
  const [loadedUsers, setLoadedUsers] = useState<User[]>([]);
  const { user } = useContext(UserContext);
  const [loadedConfig, setLoadedConfig] =
    useState<ShopfloorBoardConfiguration>();
  const [showLoader, setShowLoader] = useState(true);
  const [kioskMode, setKioskMode] = useState<boolean>(false);
  const [showBoardConfig, setShowBoardConfig] = useState<boolean>();
  const [kioskSupported, setKioskSupported] = useState<boolean>(false);
  /**
   * Helper to fetch board infomation.
   */
  useEffect(
    () => {
      setShowLoader(true);
      Promise.all([
        loadShopfloorBoardConfig(configId, axios),
        loadAllTasksForShopfloorBoard(configId, axios),
      ])
        .then(([configResp, tasksResp]) => {
          onConfigLoaded(configResp);
          setLoadedConfig(configResp);
          setLoadedTasks(tasksResp);

          return Promise.all([
            loadPerformanceEntries(configResp),
            loadAllUsersFromBackendByCompanyId(configResp.companyId, axios),
          ]);
        })
        .then(([loadedPerformanceEntries, loadedUsers]) => {
          setLoadedPerformanceEntry(loadedPerformanceEntries);
          setLoadedUsers(loadedUsers);
          setShowLoader(false);
        });
    },
    // eslint-disable-next-line
    [axios]
  );

  /**
   * Loads the correct performance entries depends on column connector type.
   *
   * @param config the newest shopfloorboard configuration
   * @param onlyColumnId optional column it if only one column performance should be loaded
   */
  const loadPerformanceEntries = useCallback(
    async (
      config: ShopfloorBoardConfiguration,
      onlyColumnId?: string
    ): Promise<ShopfloorBoardPerformanceEntry[]> => {
      let defaultPerformanceEntries:
        | ShopfloorBoardPerformanceEntry[]
        | undefined;
      const entries: ShopfloorBoardPerformanceEntry[] = [];
      const columnsToLoad = !!onlyColumnId
        ? config.columns.filter((column) => column.id === onlyColumnId)
        : config.columns;

      for (const column of columnsToLoad) {
        switch (column.connectorType) {
          case ConnectorType.MANUAL:
          case ConnectorType.CSV_IMPORT:
            if (!defaultPerformanceEntries)
              defaultPerformanceEntries =
                await loadAllPerformanceEntryForShopfloorBoard(
                  config.id!,
                  axios
                );

            entries.push(
              ...defaultPerformanceEntries.filter(
                (entry) =>
                  entry.boardId === config.id && entry.columnId === column.id
              )
            );
            break;
          case ConnectorType.DATA_API:
            const dataApi = await fetchDataApiById(
              axios,
              column.connectorInfo.dataApi?.id ?? ""
            );
            if (!dataApi) break;
            entries.push(
              ...dataApi.data.map((entry) =>
                convertDataApiEntryToPerformanceEntry(
                  entry,
                  config.id!,
                  column.id
                )
              )
            );
        }
      }

      return entries;
    },
    [axios]
  );

  /**
   * sets columnToEdit and columnIndexToUpdate when
   * cog Icon in ShopfloorColumn is clicked
   *
   * @param column column that should be edited
   */
  const prepareColumnEditPopup = (column?: ShopfloorBoardColumn): void => {
    if (column) {
      setColumnToEdit(column);
    }
    toggleColumnEditPopup(true);
  };

  /**
   * saves the edited Column on close of the popup
   */
  const saveColumnAfterPopup: ColumnEditFormProps["closePopup"] = ({
    updatedConfig,
    updatedPerformanceEntries,
    updatedColumn,
    changedConnector,
  } = {}): void => {
    if (updatedConfig) {
      setLoadedConfig(updatedConfig);

      if (changedConnector && updatedColumn) {
        loadPerformanceEntries(updatedConfig, updatedColumn.id).then(
          (newEntries) => {
            const newLoaded = [
              ...(
                updatedPerformanceEntries ??
                loadedPerformanceEntry ??
                []
              ).filter((entry) => entry.columnId !== updatedColumn.id),
              ...newEntries,
            ];
            setLoadedPerformanceEntry(newLoaded);
          }
        );
      }
    }
    if (updatedPerformanceEntries && !changedConnector)
      setLoadedPerformanceEntry(updatedPerformanceEntries);
    setColumnToEdit(undefined);
    toggleColumnEditPopup(false);
  };

  /**
   * Helper to filter current performance entries for single Column
   *
   * @param column the shopfloorboard column
   * @returns found entries
   */
  const getPerformanceEntryForColumn = (
    column: ShopfloorBoardColumn
  ): ShopfloorBoardPerformanceEntry[] | undefined => {
    const found = !loadedPerformanceEntry
      ? undefined
      : loadedPerformanceEntry.filter((entry) => entry.columnId === column.id);
    if (!found) return undefined;
    return applyDefaultTarget(found, column);
  };

  /**
   * Helper to update loaded task list
   *
   * @param taskList newly created task list
   * @param columnId id of the colum to update
   */
  const updateTaskForColumnId = (taskList: Task[], columnId: string): void => {
    let localTaskList: Task[] = loadedTasks.filter(
      (curTask) => curTask.shopfloorBoardColumnId !== columnId
    );
    localTaskList = localTaskList.concat(taskList);
    setLoadedTasks([...localTaskList]);
  };

  /**
   * Helper to add a new {@link Task} in the global list
   *
   * @param newTask the new {@link Task}
   */
  const addTaskInList = (newTask: Task): void => {
    setLoadedTasks([...loadedTasks, newTask]);
  };

  return (
    <>
      {loadedConfig && showTaskCreationPopup && (
        <TaskCreationPopup
          sfConfig={loadedConfig}
          users={loadedUsers}
          isOpen={!!showTaskCreationPopup}
          toogleOpenState={toggleTaskCreationPopup}
          setTask={(newTask) => addTaskInList(newTask)}
          columnId={
            typeof showTaskCreationPopup === "string"
              ? showTaskCreationPopup
              : undefined
          }
        />
      )}

      {showBoardConfig && loadedConfig && (
        <PopupComponent
          isOpen
          toggleOpen={() => setShowBoardConfig(false)}
          footerButtons={[
            {
              title: t("general.buttons.cancel"),
              onClick: () => {
                setShowBoardConfig(false);
              },
              borderColor: "black",
              className: "color-white",
              isLoading: showLoader,
            },
            {
              title: t("general.buttons.save"),
              borderColor: "#A9FAA2",
              type: "submit",
              form: "board-edit-form",
              isLoading: showLoader,
            },
          ]}
        >
          <BoardEditForm
            boardConfig={loadedConfig}
            onSaved={(updatedConfig) => {
              setLoadedConfig(updatedConfig);
              onConfigLoaded(updatedConfig);
              setShowBoardConfig(false);
            }}
            onDeleted={() => {
              history.replace("/sfboard");
            }}
            onLoadingChange={setShowLoader}
          />
        </PopupComponent>
      )}

      <div className="shopfloor-board-detail--wrapper">
        {kioskSupported && (
          <ButtonComponent
            className="desktop-only"
            title={
              <>
                <p>{t("shopfloorboard.headerButtons.kioskMode")}</p>
                <ChalkboardIcon fill={kioskMode ? "#ff0000" : "#000000"} />
              </>
            }
            onClick={() => setKioskMode(!kioskMode)}
          />
        )}
        {isUserAllowedToDoThis(UserAction.CREATE_COLUMN, user) && (
          <ButtonComponent
            title={
              <>
                <p>{t("shopfloorboard.headerButtons.createColumn")}</p>
                <AddFileIcon />
              </>
            }
            onClick={() => prepareColumnEditPopup()}
          />
        )}
        {isUserAllowedToDoThis(UserAction.CREATE_TASK, user) && (
          <ButtonComponent
            title={
              <>
                <p>{t("shopfloorboard.headerButtons.createTask")}</p>
                <AddPinIcon />
              </>
            }
            onClick={() => toggleTaskCreationPopup(true)}
          />
        )}
        {isUserAllowedToDoThis(UserAction.EDIT_BOARD, user) && (
          <ButtonComponent
            title={
              <>
                <p>{t("shopfloorboard.headerButtons.boardConfig")}</p>
                <SettingsIcon onClick={() => setShowBoardConfig(true)} />
              </>
            }
          />
        )}
      </div>

      <div className="shopfloor-board--wrapper">
        {columnEditPopupIsOpen && loadedConfig && (
          <ColumnEditForm
            show={columnEditPopupIsOpen}
            columnToEdit={columnToEdit}
            shopfloorConfig={loadedConfig}
            closePopup={saveColumnAfterPopup}
          />
        )}

        <div className="shopfloor-board--column-wrapper">
          {loadedConfig && !showLoader ? (
            <ColumnSlider
              columns={loadedConfig.columns.map((column) => ({
                users: loadedUsers,
                boardId: loadedConfig.id!,
                columnPerformanceEntries: getPerformanceEntryForColumn(column),
                column: column,
                openColumnEditPopup() {
                  prepareColumnEditPopup(column);
                },
                tasks: loadedTasks.filter(
                  (entry) => entry.shopfloorBoardColumnId === column.id
                ),
                updateTasksForBoard(newTaskList) {
                  updateTaskForColumnId(newTaskList, column.id);
                },
                updatePerformanceEntries(updatedOrNewEntry) {
                  setLoadedPerformanceEntry(
                    updatePerformanceEntries(
                      updatedOrNewEntry,
                      loadedPerformanceEntry ?? []
                    )
                  );
                },
                onNewTask: toggleTaskCreationPopup,
                dayOffset: loadedConfig.visualConfig?.dayOffset ?? 0,
              }))}
              kioskMode={kioskMode}
              initiallyOpenTaskId={initiallyOpenTaskId}
              onKioskExit={() => setKioskMode(false)}
              boardConfig={loadedConfig}
              onKioskSupported={() => setKioskSupported(true)}
            />
          ) : (
            <LoaderComponent />
          )}
        </div>
      </div>
    </>
  );
};

export default ShopFloorBoard;
