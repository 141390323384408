import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { LayoutComponent, LoaderComponent } from "sfm-component-library";
import CenterHeaderButton from "../../components/centerheaderbutton/CenterHeaderButton";
import "../../styles/PowerBIPageStyles.scss";
import { useAxios } from "../../utils/AxiosUtil";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { PowerBIConfiguration } from "../../utils/powerbi/PowerBI.types";
import { loadPowerBIConfig } from "../../utils/powerbi/PowerBIOverview.utils";

interface PowerBIPageProps {}

const PowerBIPage: React.FC<PowerBIPageProps> = () => {
  const history = useHistory();
  const location = useLocation<{ configId: string }>();
  const [loadedConfig, setLoadedConfig] = useState<PowerBIConfiguration>();
  const { axios } = useAxios();
  const [showLoader, setShowLoader] = useState(false);
  const { t } = useTranslation();

  /**
   * Checks for needed config or redirects to overview page
   */
  useEffect(() => {
    if (!location.state?.configId) history.push("/bi");
    if (!!axios) {
      setShowLoader(true);
      loadPowerBIConfig(location.state.configId, axios).then((configResp) => {
        setLoadedConfig(configResp);
        setShowLoader(false);
      });
    }
    // eslint-disable-next-line
  }, [location.state?.configId, axios]);

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.POWER_BI)}
      title={
        <CenterHeaderButton
          title={loadedConfig?.name || ""}
          onClick={() => history.push("/bi")}
        />
      }
    >
      {showLoader ? (
        <LoaderComponent />
      ) : loadedConfig ? (
        <PowerBIEmbed
          key={`${loadedConfig.viewKey}-${showLoader}`}
          cssClassName="power-bi__embedded-wrapper"
          embedConfig={{
            type: "report",
            id: undefined,
            embedUrl: `${process.env.REACT_APP_POWERBI_URL}${loadedConfig.viewKey}`,
            accessToken: undefined,
            tokenType: models.TokenType.Embed,
          }}
        />
      ) : (
        <p>{t("powerBI.config.notFound")}</p>
      )}
    </LayoutComponent>
  );
};

export default PowerBIPage;
