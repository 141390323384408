import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  LoaderComponent,
  Option,
  PopupComponent,
  TextInputComponent,
  UploadComponent,
} from "sfm-component-library";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  ExtractValue,
  Protocol,
  ProtocolDocument,
  ProtocolInput,
  ProtocolInputPriority,
  ProtocolInputType,
  createEmptyProtocol,
  createNewProtocolDocument,
  createNewProtocolInput,
} from "../../utils/protocol/Protocol.types";
import {
  createProtocolInputPriorityDropdownOptions,
  createProtocolInputTypeDropdownOptions,
} from "../../utils/protocol/Protocol.utils";
import { loadAllUsersFromBackendByCompanyId } from "../../utils/user/User.utils";
import NotificationRotation from "../notificationrotation/NotificationRotation";
import {
  NotificationRotationConfig,
  RotationType,
  TimingRule,
  createNewNotificationRotationConfig,
} from "../notificationrotation/NotificationRotation.types";
import { ReactComponent as DownIcon } from "./../../assets/icons/chevron-down.svg";
import { ReactComponent as UpIcon } from "./../../assets/icons/chevron-up.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "./../../assets/icons/edit.svg";
import { ReactComponent as FileAdd } from "./../../assets/icons/file_add.svg";
import { ReactComponent as LinkIcon } from "./../../assets/icons/link.svg";

interface EditProtocolFieldFormProps {
  protocol: Protocol;
  setProtocol: React.Dispatch<React.SetStateAction<Protocol>>;
  onChangeProtocol: (partialProtocol: Partial<ProtocolInput>) => void;
  onChangeProtocolDocument: (
    partialDocument: Partial<ProtocolDocument>
  ) => void;
  handleUpdateOfProtocolConfig: () => void;
  handleProtocolDocumentDeletion: (documentId: string) => void;
  toggleEditMode: (toggled: boolean) => void;
}
const EditProtocolFieldForm: React.FC<EditProtocolFieldFormProps> = ({
  onChangeProtocol,
  protocol,
  setProtocol,
  handleUpdateOfProtocolConfig,
  handleProtocolDocumentDeletion,
  onChangeProtocolDocument,
  toggleEditMode,
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [showRotationPopup, toggleRotationPopup] = useState<boolean>(false);
  const [currentNotificationConfig, setCurrentNotificationConfig] =
    useState<NotificationRotationConfig>(createNewNotificationRotationConfig());
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const [loading, toggleLoading] = useState<boolean>(false);

  const { axios } = useAxios();

  /**
   * Loads the user list from the backend
   */
  useEffect(() => {
    if (!user?.companyId) return;
    toggleLoading(true);
    loadAllUsersFromBackendByCompanyId(user.companyId, axios).then((users) => {
      setUserOptions(
        users.map((user) => ({
          value: user.id!,
          label: `${user.lastname}, ${user.firstname}`,
        }))
      );
      toggleLoading(false);
    });
  }, [user?.companyId, axios]);

  /**
   * Helper to add a ProtocolInput to Protocol
   */
  const addProtocolInput = (): void => {
    const newProtocolInput: ProtocolInput = createNewProtocolInput();
    setProtocol((oldProtocol) => ({
      ...(oldProtocol || createEmptyProtocol(user)),
      inputs: [...(oldProtocol?.inputs || []), newProtocolInput],
    }));
  };

  /**
   * Helper to add a new ProtocolDocument to Protocol
   */
  const addProtocolDocument = (): void => {
    const newProtocolDocument: ProtocolDocument = createNewProtocolDocument();
    setProtocol((oldProtocol) => ({
      ...(oldProtocol || createEmptyProtocol(user)),
      documents: [...(oldProtocol?.documents || []), newProtocolDocument],
    }));
  };

  /**
   * Helper to remove a document that has been added locally and is not yet persisted
   * @param documentId - the id of the document to remove
   */
  const removeLocalDocument = (documentId: string): void => {
    setProtocol((oldProtocol) => ({
      ...oldProtocol,
      documents: oldProtocol?.documents?.filter(
        (document) => document.id !== documentId
      ),
    }));
  };

  /**
   * Helper to render ProtocolInput controls to move up/down
   * @param index  index of ProtocolInput
   * @returns  Controls to move up/down
   */
  const addFieldControls = (index: number): JSX.Element => {
    if ((protocol?.inputs?.length ?? 0) === 0) return <></>;
    if (index === 0)
      return (
        <div className="protocol-edit__controls-container">
          <div className="protocol-edit__up-down-container">
            <DownIcon onClick={() => changePosition(index, "down")} />
          </div>
          <DeleteIcon onClick={() => handleDeleteProtocolInput(index)} />
        </div>
      );
    else if (index === (protocol?.inputs?.length ?? 0) - 1)
      return (
        <div className="protocol-edit__controls-container">
          <div className="protocol-edit__up-down-container">
            <UpIcon onClick={() => changePosition(index, "up")} />
          </div>
          <DeleteIcon onClick={() => handleDeleteProtocolInput(index)} />
        </div>
      );
    else {
      return (
        <div className="protocol-edit__controls-container">
          <div className="protocol-edit__up-down-container">
            <UpIcon onClick={() => changePosition(index, "up")} />
            <DownIcon onClick={() => changePosition(index, "down")} />
          </div>
          <DeleteIcon onClick={() => handleDeleteProtocolInput(index)} />
        </div>
      );
    }
  };

  /**
   * Helper to change position of ProtocolInput
   * @param index  index of ProtocolInput
   * @param direction  direction to move
   */
  const changePosition = (index: number, direction: "up" | "down"): void => {
    setProtocol((oldProtocol) => {
      const inputs = oldProtocol?.inputs || [];
      const inputToMove = inputs[index];
      const newIndex = direction === "up" ? index - 1 : index + 1;
      inputs[index] = inputs[newIndex];
      inputs[newIndex] = inputToMove;
      return { ...(oldProtocol || createEmptyProtocol(user)), inputs };
    });
  };

  /**
   * Helper to delete a row of ProtocolInput
   * @param index  index of ProtocolInput to delete
   */
  const handleDeleteProtocolInput = (index: number): void => {
    setProtocol((oldProtocol) => {
      const inputs = oldProtocol?.inputs || [];
      inputs.splice(index, 1);
      return { ...(oldProtocol || createEmptyProtocol(user)), inputs };
    });
  };

  /**
   * Helper to get human readable representation for rule
   * @param rule to get string for
   * @returns human readable string representing rule
   */
  const getTimingStringForRule = (rule: TimingRule): string => {
    const timingString: string = `${t(
      "protocol.config.notificationRotationConfigs.every"
    )} ${rule.rotation} ${t(
      `protocol.config.notificationRotationConfigs.unit.${rule.type}`
    )}`;
    switch (rule.type) {
      case RotationType.DAILY:
        return `${timingString}.`;
      case RotationType.WEEKLY:
        return `${timingString} ${t(
          "protocol.config.notificationRotationConfigs.at"
        )} ${rule.daysOfWeek
          .map((day) => t(`enum.daysOfWeek.${day}`))
          .join(", ")}.`;
      case RotationType.MONTHLY:
        if (rule.useRelativeDay)
          return `${timingString} ${t(
            "protocol.config.notificationRotationConfigs.at"
          )} ${t(
            `enum.monthStartType.${rule.monthStartType}`
          )} ${rule.daysOfWeek
            .map((day) => t(`enum.daysOfWeek.${day}`))
            .join(", ")}.`;
        else
          return `${timingString} ${t(
            "protocol.config.notificationRotationConfigs.at"
          )} ${rule.dayOfMonth}.`;
      case RotationType.YEARLY:
        if (rule.useRelativeDay)
          return `${timingString} ${t(
            "protocol.config.notificationRotationConfigs.at"
          )} ${t(
            `enum.monthStartType.${rule.monthStartType}`
          )} ${rule.daysOfWeek
            .map((day) => t(`enum.daysOfWeek.${day}`))
            .join(", ")} ${t(
            "protocol.config.notificationRotationConfigs.of"
          )} ${t(`enum.month.${rule.month}`)}.`;
        else
          return `${timingString} ${t(
            "protocol.config.notificationRotationConfigs.at"
          )} ${rule.dayOfMonth}.${t(`enum.month.${rule.month}`)}`;
    }
  };

  /**
   * submits a config, creates new one when editIndex is -1 and update config at editIndex otherwise
   */
  const submitConfig = (): void => {
    setProtocol((protocol) => {
      const updatedNotificationConfigs = [...protocol.notificationRotations];
      if (editIndex === -1)
        updatedNotificationConfigs.push(currentNotificationConfig);
      else updatedNotificationConfigs[editIndex] = currentNotificationConfig;
      return {
        ...protocol,
        notificationRotations: updatedNotificationConfigs,
      };
    });
    setCurrentNotificationConfig(createNewNotificationRotationConfig());
    toggleRotationPopup(false);
  };

  return (
    <div className="protocol-edit__wrapper">
      <div className="protocol-edit__box">
        <div className="protocol-edit__inputs-wrapper">
          <h3 className="protocol-edit__inputs-header">
            {t("protocol.config.fields")}
          </h3>
          {protocol.inputs?.map((input, index) => (
            <div className="protocol-edit__input-flex-wrapper">
              <div key={index} className="protocol-edit__input-flex">
                <div className="protocol-edit__input-flex__upper">
                  <DropdownComponent
                    label={t("protocol.config.inputType")}
                    options={createProtocolInputTypeDropdownOptions()}
                    selectedOption={
                      input.inputType?.toString() || ProtocolInputType.TEXT
                    }
                    onChange={(key) =>
                      onChangeProtocol({
                        inputType: key.value as ProtocolInputType,
                        id: input.id,
                      })
                    }
                  />
                  <DropdownComponent
                    label={t("protocol.config.priority")}
                    options={createProtocolInputPriorityDropdownOptions()}
                    selectedOption={
                      input.priority?.toString() || ProtocolInputPriority.MEDIUM
                    }
                    onChange={(key) =>
                      onChangeProtocol({
                        priority: key.value as ProtocolInputPriority,
                        id: input.id,
                      })
                    }
                  />
                  <CheckboxComponent
                    value={t("protocol.config.required")}
                    checked={input.required}
                    onCheck={() =>
                      onChangeProtocol({
                        required: !input.required,
                        id: input.id,
                      })
                    }
                  />
                  {addFieldControls(index)}
                </div>

                <div className="protocol-edit__input-flex__lower">
                  <TextInputComponent
                    label={t("protocol.config.inputName")}
                    value={input.name}
                    onChange={(name) =>
                      onChangeProtocol({ name: name, id: input.id })
                    }
                  />

                  {input.inputType === ProtocolInputType.DROPDOWN && (
                    <div className="protocol-edit__dropdown-values">
                      <TextInputComponent
                        label={t("protocol.config.dropdownOptions")}
                        value={
                          input.value as ExtractValue<ProtocolInputType.DROPDOWN>
                        }
                        onChange={(value) =>
                          onChangeProtocol({ value: value, id: input.id })
                        }
                        tagInput
                        tags={input.options}
                        onTagChange={(options) =>
                          onChangeProtocol({
                            id: input.id,
                            options: options,
                          })
                        }
                        onEnter={() => {
                          onChangeProtocol({
                            id: input.id,
                            options: [
                              ...(input.options || []),
                              (input.value as ExtractValue<ProtocolInputType.DROPDOWN>) ||
                                "",
                            ],
                          });
                          onChangeProtocol({ id: input.id, value: "" });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="protocol-edit__summary-wrapper">
            <CheckboxComponent
              checked={protocol.summaryEnabled}
              onCheck={() =>
                setProtocol((oldProtocol) => ({
                  ...oldProtocol,
                  summaryEnabled: !oldProtocol?.summaryEnabled || false,
                }))
              }
              value={t("protocol.config.summaryEnabled")}
            />
          </div>
          <ButtonComponent
            onClick={addProtocolInput}
            title={t("protocol.config.addInput")}
            type="button"
          />
        </div>
        <div className="protocol-edit__document-config-wrapper">
          <div className="protocol-edit__supervisers-wrapper">
            <h3 className="protocol-edit__inputs-header">
              {t("protocol.config.supervisers")}
            </h3>
            <div className="user-tags">
              {protocol.superviserIds.length > 0 ? (
                protocol.superviserIds.map((userId) => (
                  <div>
                    {userOptions.find((option) => option.value === userId)
                      ?.label || t("protocol.config.userNotFound")}
                    <DeleteIcon
                      onClick={() =>
                        setProtocol((protocol) => {
                          let updatedProtocol = { ...protocol };
                          updatedProtocol.superviserIds =
                            updatedProtocol.superviserIds.filter(
                              (currentUserId) => userId !== currentUserId
                            );
                          return updatedProtocol;
                        })
                      }
                    />
                  </div>
                ))
              ) : loading ? (
                <LoaderComponent />
              ) : (
                <p>{t("protocol.config.noSupervisersSelected")}</p>
              )}
            </div>
            <div>
              <div>{t("protocol.config.addUser")}</div>
              <DropdownComponent
                placeholder={t("protocol.config.addUser")}
                onChange={(option) =>
                  setProtocol((protocol) => ({
                    ...protocol,
                    superviserIds: [...protocol.superviserIds, option.value],
                  }))
                }
                options={userOptions.filter(
                  (option) => !protocol.superviserIds.includes(option.value)
                )}
                selectedOption={""}
                searchable
              />
            </div>
          </div>
          <div className="protocol-edit__documents-wrapper">
            <h3 className="protocol-edit__inputs-header">
              {t("protocol.config.documents")}
            </h3>

            {protocol.documents?.map((document, index) => (
              <div className="protocol-edit__document-wrapper">
                <TextInputComponent
                  label={t("protocol.config.documentName")}
                  value={document.name}
                  onChange={(name) =>
                    onChangeProtocolDocument({ name: name, id: document.id })
                  }
                />
                <div className="protocol-edit__document-type">
                  {!!document.fileEntry ? (
                    <ButtonComponent
                      onClick={() =>
                        handleProtocolDocumentDeletion(document.id)
                      }
                      title={<DeleteIcon />}
                      className="icon-button"
                      type="button"
                    />
                  ) : (
                    <>
                      {document.link ? (
                        <>
                          <TextInputComponent
                            label={t("protocol.config.documentLink")}
                            value={document.linkContent}
                            onChange={(link) =>
                              onChangeProtocolDocument({
                                linkContent: link,
                                id: document.id,
                              })
                            }
                          />
                          <ButtonComponent
                            onClick={() =>
                              onChangeProtocolDocument({
                                link: false,
                                id: document.id,
                                linkContent: "",
                              })
                            }
                            title={<FileAdd />}
                            className="icon-button"
                          />
                          <ButtonComponent
                            onClick={() =>
                              document.local
                                ? removeLocalDocument(document.id)
                                : handleProtocolDocumentDeletion(document.id)
                            }
                            title={<DeleteIcon />}
                            className="icon-button"
                            type="button"
                          />
                        </>
                      ) : (
                        <>
                          <UploadComponent
                            disabled={!!document.fileEntry}
                            buttonContent={t("protocol.config.chooseDocument")}
                            files={document.file ? [document.file] : []}
                            accept="application/pdf"
                            addFiles={(files) =>
                              onChangeProtocolDocument({
                                file: files[0],
                                id: document.id,
                              })
                            }
                            removeFile={() =>
                              onChangeProtocolDocument({
                                file: undefined,
                                id: document.id,
                              })
                            }
                          />
                          <ButtonComponent
                            onClick={() =>
                              onChangeProtocolDocument({
                                link: true,
                                file: undefined,
                                id: document.id,
                              })
                            }
                            className="icon-button"
                            title={<LinkIcon />}
                          />
                          <ButtonComponent
                            onClick={() =>
                              document.local
                                ? removeLocalDocument(document.id)
                                : handleProtocolDocumentDeletion(document.id)
                            }
                            title={<DeleteIcon />}
                            className="icon-button"
                            type="button"
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
            <ButtonComponent
              onClick={() => addProtocolDocument()}
              title={t("protocol.config.addDocument")}
              type="button"
            />
          </div>
          <div className="protocol-edit__config-wrapper">
            <h3 className="protocol-edit__inputs-header">
              {t("protocol.config.notificationRotationConfigs.title")}
            </h3>
            <ul>
              {protocol.notificationRotations?.map((rotation, index) => (
                <li>
                  <div>
                    <div>
                      {t(
                        "protocol.config.notificationRotationConfigs.overview",
                        {
                          replace: {
                            start: new Date(
                              rotation.start
                            ).toLocaleDateString(),
                            until: rotation.until
                              ? rotation.until.toLocaleDateString()
                              : t(
                                  "protocol.config.notificationRotationConfigs.forever"
                                ),
                            amountUsers: rotation.userIds.length,
                          },
                        }
                      )}{" "}
                      {rotation.rules
                        .map((rule) => getTimingStringForRule(rule))
                        .join(
                          ` ${t(
                            "protocol.config.notificationRotationConfigs.and"
                          )} `
                        )}
                    </div>
                    <div>
                      <EditIcon
                        onClick={() => {
                          setCurrentNotificationConfig(rotation);
                          toggleRotationPopup(true);
                          setEditIndex(index);
                        }}
                      />
                      <DeleteIcon
                        onClick={() => {
                          setProtocol((protocol) => ({
                            ...protocol,
                            notificationRotations:
                              protocol.notificationRotations.filter(
                                (_, configIndex) => index !== configIndex
                              ),
                          }));
                        }}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <ButtonComponent
              onClick={() => {
                toggleRotationPopup(true);
                setCurrentNotificationConfig(
                  createNewNotificationRotationConfig()
                );
                setEditIndex(-1);
              }}
              title={t("protocol.config.notificationRotationConfigs.addConfig")}
              type="button"
            />

            <PopupComponent
              isOpen={showRotationPopup}
              toggleOpen={toggleRotationPopup}
              title={t("protocol.config.notificationRotationConfigs.addConfig")}
            >
              <NotificationRotation
                config={currentNotificationConfig}
                setConfig={setCurrentNotificationConfig}
                submitConfig={submitConfig}
                userOptions={userOptions}
              />
            </PopupComponent>
          </div>
        </div>
      </div>

      <div className="protocol-edit__button-container">
        <ButtonComponent
          onClick={() => handleUpdateOfProtocolConfig()}
          title={t("general.buttons.save")}
          type="button"
          borderColor="#a9faa2"
        />
        <ButtonComponent
          onClick={() => toggleEditMode(false)}
          title={t("general.buttons.back")}
          type="button"
        />
      </div>
    </div>
  );
};

export default EditProtocolFieldForm;
