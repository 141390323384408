import { PercentCrop, PixelCrop } from "react-image-crop";

const TO_RADIANS = Math.PI / 180;

/**
 * Helper that takes a crop and renders the image inside a canvas
 * @param image
 * @param canvas
 * @param crop
 * @param scale
 * @param rotate
 */
export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop | PercentCrop,
  scale = 1,
  rotate = 0
) {
  const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX: number = image.naturalWidth / image.width;
  const scaleY: number = image.naturalHeight / image.height;
  const pixelRatio: number = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX: number = crop.x * scaleX;
  const cropY: number = crop.y * scaleY;

  const rotateRads: number = rotate * TO_RADIANS;
  const centerX: number = image.naturalWidth / 2;
  const centerY: number = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

/**
 * Takes canvases and converts them to blobs
 * @param canvases canvases to convert
 * @returns array of promises that resolve to blobs
 */
export const convertCanvasesToBlobs = (
  canvases: HTMLCanvasElement[]
): Promise<Blob>[] => {
  return canvases.map(
    (canvas) =>
      new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            throw new Error("No blob");
          }
          resolve(blob);
        });
      })
  );
};
