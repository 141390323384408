import { useKeycloak } from "@react-keycloak/web";
import { KeycloakInstance } from "keycloak-js";
import { useCallback, useEffect, useMemo } from "react";
import { io, Socket } from "socket.io-client";
import { ClientEmit, ClientListen, EventPayload } from "./SocketUtils.types";
import { normalize } from "path";

class SocketConnection {
  static socket: Socket<ClientListen, ClientEmit>;

  /**
   * Get the socket connection (or create new connection if there isn't one)
   *
   * @param keycloak the keycloak instance to authenticate against the server.
   * @returns a {@link Socket} instance
   */
  static getSocket(keycloak: KeycloakInstance) {
    if (!SocketConnection.socket) {
      const url = new URL(process.env.REACT_APP_SERVICE_URL || "");

      SocketConnection.socket = io(
        `${url.protocol}//${url.hostname}:${url.port}/`,
        {
          auth: (cb) => {
            cb({ token: keycloak.token });
          },
          path: normalize(`${url.pathname}/socket/socket.io`),
        }
      );
    }
    return SocketConnection.socket;
  }
}

/**
 * A Hook to receive the socket connection.
 *
 * @returns a {@link Socket} instance
 */
export const useSocket = (): Socket<ClientListen, ClientEmit> => {
  const { keycloak } = useKeycloak();

  return useMemo(() => {
    return SocketConnection.getSocket(keycloak);
  }, [keycloak]);
};

/**
 * A Hook to subscribe to a socket event.
 * Also can listen to specific type within a event (see example below).
 *
 * Example:
 * ```ts
 * useEvent("eventId", (onType, eventId, event) => {
 *   onType("name", (value) => {
 *     console.log(value)
 *   });
 *   onType("foo", (value) => {
 *     console.log(value)
 *   });
 * }, (successful, eventId) => {
 *   console.log(sucessful ? "subscribe successful" : "subscribe error")
 * }, [dep1, dep2,...]);
 * ```
 *
 * Or using generic for Type-safeness:
 * ```ts
 * useEvent<{typeName: string, "foo:bar": { date: Date, n: number }}>("eventId", (onType, eventId, event) => {
 *   // onType can now only have the types specificed via generics (= "typeName" | "foo:bar")
 *   onType("typeName", (value) => {
 *     // value is type = string
 *     console.log(value)
 *   });
 *   onType("foo:bar", (value) => {
 *     // value is type = { date: Date, n: number }
 *     console.log(value)
 *   });
 *
 *   // TYPE ERROR HERE
 *   onType("foo", (value) => {
 *     console.log(value)
 *   });
 * });
 * ```
 *
 * @param eventId the id of the event (can be any string)
 * @param onEvent the callback when a event was emitted.
 * @param onSubscribe the callback when the socket subscribed to the event
 * @param dependencies for the internal useEffects
 */
export const useEvent = <
  M extends { [key: string]: any },
  I extends string = string
>(
  eventId: I,
  onEvent: (
    onType: <T extends keyof M>(
      type: T,
      callback: (value: M[T]) => void
    ) => void,
    eventId: I,
    event: EventPayload
  ) => void,
  onSubscribe?: (success: boolean, eventId: I) => void,
  dependencies: any[] = []
) => {
  const socket = useSocket();

  const callbackEvent = useCallback(
    (id: string, event: EventPayload) => {
      if (id === eventId)
        onEvent(
          (type, callback) => {
            if (event.type === type) callback(event.value as any);
          },
          eventId,
          event
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId, ...dependencies]
  );

  const callbackSubscribe = useCallback(
    (success: boolean, eventId: string) => {
      if (onSubscribe) onSubscribe(success, eventId as I);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId, ...dependencies]
  );

  useEffect(() => {
    socket.on("event", callbackEvent);
    socket.emit("subscribe", eventId, callbackSubscribe);

    return () => {
      socket.off("event", callbackEvent);
      socket.emit("unsubscribe", eventId, callbackSubscribe);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, socket, callbackEvent, callbackSubscribe]);
};
