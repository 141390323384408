import { AxiosInstance } from "axios";
import i18next from "i18next";
import { useContext, useEffect, useState } from "react";
import { generateNotification } from "sfm-component-library";
import { UserContext } from "../pages/App";
import { useAxios } from "./AxiosUtil";
import { User } from "./user/User.types";

/**
 * Helper to return a random color string
 *
 * @returns random hex code string
 */
export const getRandomColor = (): string => {
  const randomColors: string[] = [
    "#F86B6B",
    "#969AFA",
    "#758BFD",
    "#61FF53",
    "#FDD875",
    "#4B4B4B",
  ];
  return randomColors[Math.floor(Math.random() * randomColors.length)];
};

/**
 * Helper to determine if two dates are the same
 *
 * @param date
 * @param dateToCompare if not specified, takes current date
 * @returns boolean
 */
export const compareDate = (date: Date, dateToCompare?: Date): boolean => {
  let toCompare: Date = dateToCompare ? new Date(dateToCompare) : new Date();

  return (
    toCompare.getFullYear() === date.getFullYear() &&
    toCompare.getMonth() === date.getMonth() &&
    toCompare.getDate() === date.getDate()
  );
};

/**
 * Helper to format a given date
 *
 * @param date
 * @returns empty string or formatted date
 */
export const convertToDateForInput = (date?: Date): string => {
  if (!date) return "";
  const localDate: Date = new Date(date);
  if (isNaN(localDate.getTime())) return "";
  return localDate.toISOString().split("T")[0];
};

/**
 * Helper to generate readable date to display
 * @param date data to convert is optional.
 * @returns converted string
 */
export const convertToReadDate = (date?: Date): string => {
  if (!date) return "-";
  const localDate: Date = new Date(date);
  return localDate.toLocaleDateString("de-DE");
};

/**
 *
 * @param month
 * @param year
 * @returns all days in specified month as an Date array
 */
export const getAllDaysInMonth = (month: number, year: number): Date[] => {
  let date = new Date(year, month, 1);
  let days = [];
  if (month < 0 || year < 0) return [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

/**
 * generates a Notification with correct translations
 * @param status
 */
export const generateNotificationWithTranslations = (
  status: "success" | "warning"
): void =>
  generateNotification(
    i18next.t(`general.notification.${status}.content`),
    status,
    i18next.t(`general.notification.${status}.title`)
  );

/**
 * formats fileSize to human readable string
 * @param bytes
 * @param decimals
 * @returns human readable byte string
 */
export const formatBytes = (bytes: number, decimals = 1): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * Short a number if the number is longer then wanted after the dot (0.xxx)
 *
 * @tested
 * @param num The actual number to be shorten
 * @param digits The number of digits which are showed when shorted (default: 3)
 * @param threshold The threshold of digits after comma when the number should be shorted (default: 4)
 * @param suffix The suffix which appends at the end when this number is shorted (default: "...")
 * @returns {string} the number shorted as string
 */
export const shortenNumber = (
  num: number,
  digits = 3,
  threshold = 3,
  suffix = "...",
  locales?: Parameters<typeof Number.prototype.toLocaleString>[0]
): string => {
  return (num.toString().split(".")[1] ?? "").length > threshold
    ? Number(num.toFixed(digits)).toLocaleString(locales, {
        maximumFractionDigits: digits,
      }) + suffix
    : num.toLocaleString(locales, { maximumFractionDigits: threshold });
};

/**
 * Detects if the current device is supporting touch.
 *
 * @returns true if the current device supports touch.
 */
export const isTouchDevice = (): boolean => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

/**
 * Simplifies resource loading.
 *
 * @param resolver the function which returns the promised resource data (with injected axios and user)
 * @param deps optional dependecies of the resource. On dependecies change, the resolver will be called again
 * @returns the data which the resolver returns or undefined while resolving
 */
export const useResource = <T>(
  resolver: (axios: AxiosInstance, more: { user: User }) => Promise<T>,
  deps?: React.DependencyList | undefined
): T | undefined => {
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const [resource, setResource] = useState<T>();

  useEffect(() => {
    if (!user) return;
    resolver(axios, { user }).then((data) => setResource(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps ?? []), user]);

  return resource;
};

/**
 * Helper which sets the hours, minutes, seconds and milliseconds to zero.
 * Used to get the date without time.
 *
 * @tested
 * @param date the date to convert
 * @retuns the date without hours, minutes, seconds and milliseconds.
 */
export const convertTimeToZero = (date: Date) => {
  const localDate = new Date(date);
  localDate.setHours(0);
  localDate.setMinutes(0);
  localDate.setSeconds(0);
  localDate.setMilliseconds(0);
  return localDate;
};

/**
 * Try to generate an object url for a file.
 * @param file  the file to generate the url for
 * @returns   the url or undefined if it fails
 */
export const tryGeneratingObjectURL = (
  file: File | Blob
): string | undefined => {
  try {
    return URL.createObjectURL(file);
  } catch (e) {
    return undefined;
  }
};

/**
 * Helper to cut text by given length.
 * @param text string to cut
 * @param length optional 20 is default
 * @param addFileEnding if it is a file just add last after dot
 * @returns converted string
 */
export const cutTextByLength = (
  text: string,
  length: number = 20,
  addFileEnding: boolean = false
): string => {
  if (!text) return "";

  let usedLength = length - (addFileEnding ? 4 : 0);
  let foundFileEnding = "";

  if (addFileEnding) {
    const splittedText = text.split(".");
    foundFileEnding = `.${splittedText[splittedText.length - 1]}`;
  }

  if (text.length > usedLength)
    return `${text.substring(0, usedLength)}..${
      addFileEnding ? foundFileEnding : "."
    }`;
  return text;
};
