import { AxiosInstance } from "axios";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../pages/App";
import { useAxios } from "../AxiosUtil";
import { ExtendedGroup, Group, NewGroup } from "./Group.types";

/**
 * Create a empty new group.
 *
 * @param name of the group
 * @param companyId of the group
 * @returns newly generated {@link NewGroup} (same as {@link Group} but without an id)
 */
export const createNewGroup = (name: string, companyId: string): NewGroup => ({
  name,
  companyId,
  createdAt: new Date(),
  updatedAt: new Date(),
});

/**
 * Convert the group received by the backend to its native types.
 * Because the backend can't send a native date object instead it sends a string.
 * This helper converts it back to a native date object
 *
 * @param incomingGroup the group object from the backend
 * @returns a native {@link Group}/{@link ExtendedGroup} object
 */
export const convertGroupDate = <T extends Group | ExtendedGroup>(
  incomingGroup?: Omit<T, "createdAt" | "updatedAt"> & {
    createdAt: string;
    updatedAt: string;
  }
): T | undefined => {
  if (!incomingGroup) return undefined;
  return {
    ...incomingGroup,
    createdAt: new Date(incomingGroup.createdAt || ""),
    updatedAt: new Date(incomingGroup.updatedAt || ""),
  } as T;
};

/**
 * API METHOD - get a given group by id.
 *
 * @param axios The Axios instance to use.
 * @param groupId The id of the group to receive.
 * @param extended If the result should be extended ({@link ExtendedGroup}).
 * A extended group also contains all members by there ids.
 * This should only be used if needed because this need more proccessing on the backend
 *
 * @returns {@link ExtendedGroup}/{@link Group} based on extended property. Can be undefined if not found.
 */
export const getByGroupId = <T extends boolean>(
  axios: AxiosInstance,
  groupId: string,
  extended: T = false as T
): Promise<(T extends true ? ExtendedGroup : Group) | undefined> => {
  return axios
    .get("/user/group/", {
      params: { id: groupId, extended },
    })
    .then((res) =>
      convertGroupDate<T extends true ? ExtendedGroup : Group>(res.data)
    )
    .catch((err) => {
      console.error("Error while receiving a group", err);
      return undefined;
    });
};

/**
 * API METHOD - get all group by a comnpany.
 *
 * @param axios The Axios instance to use.
 * @param companyId The id of the company which the groups belongs.
 * @param extended If the result should be extended ({@link ExtendedGroup}).
 * A extended group also contains all members by there ids.
 * This should only be used if needed because this need more proccessing on the backend
 *
 * @returns a {@link ExtendedGroup}/{@link Group} list based on extended property. Can be undefined if not found.
 */
export const getGroupsByCompany = <T extends boolean = false>(
  axios: AxiosInstance,
  companyId: string,
  extended: T = false as T
): Promise<(T extends true ? ExtendedGroup[] : Group[]) | undefined> => {
  return axios
    .get("/user/group/company/", {
      params: { id: companyId, extended },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while receiving a group", err);
      return undefined;
    });
};

/**
 * API METHOD - for loading the count of groups by company
 *
 * @param companyId the company id
 * @param axios the axios instance
 * @returns number of groups
 */
export const countGroupsByCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get("/user/group/company/count/", {
      params: { companyId },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during group count fetch!", exc);
      return -1;
    });
};

/**
 * API METHOD - create a new group.
 *
 * @param axios The Axios instance to use.
 * @param group The {@link NewGroup} which should be saved on the backend
 *
 * @returns a newly created {@link Group}. Can be undefined in case of a error.
 */
export const createGroup = (
  axios: AxiosInstance,
  group: NewGroup
): Promise<Group | undefined> => {
  return axios
    .post("/user/group/", group)
    .then((res) => (res.status !== 201 ? undefined : res.data))
    .catch((err) => {
      console.error("Error while creating a new group", err);
      return undefined;
    });
};

/**
 * API METHOD - update a given group.
 *
 * @param axios The Axios instance to use.
 * @param group The {@link Group} which should be update on the backend.
 * Only the name property can be changed.
 * @param extended If the result should be extended ({@link ExtendedGroup}).
 * A extended group also contains all members by there ids.
 * This should only be used if needed because this need more proccessing on the backend
 *
 * @returns the newly updated {@link Group}/{@link ExtendedGroup} (based on extended property). Can be undefined in case of a error.
 */
export const updateGroup = <T extends boolean = false>(
  axios: AxiosInstance,
  group: Group,
  extended: T = false as T
): Promise<(T extends true ? ExtendedGroup : Group) | undefined> => {
  return axios
    .post("/user/group/update/", group, { params: { extended } })
    .then((res) => (res.status !== 200 ? undefined : res.data))
    .catch((err) => {
      console.error("Error while creating a new group", err);
      return undefined;
    });
};

/**
 * API METHOD - delete a given group.
 *
 * @param axios The Axios instance to use.
 * @param groupId The id of the {@link Group}.
 *
 * @returns a boolean which indicates if success
 */
export const deleteGroup = (
  axios: AxiosInstance,
  groupId: string
): Promise<boolean> => {
  return axios
    .post("/user/group/delete/", groupId)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while deleting a group", err);
      return false;
    });
};

/**
 * API METHOD - update the groups of a {@link User}.
 *
 * @param axios The Axios instance to use.
 * @param userId The id of the {@link User} to update.
 * @param groupIds A list of groupId which are setted for the user.
 *
 * @returns a boolean which indicates if success
 */
export const updateGroupUser = (
  axios: AxiosInstance,
  userId: string,
  groupIds: string[]
): Promise<boolean> => {
  return axios
    .post("/user/group/update/user/", groupIds, { params: { userId } })
    .then((res) => {
      if (res.status !== 200) {
        console.error("Error while updating user groups", res.status, res.data);
        return false;
      }
      return true;
    })
    .catch((err) => {
      console.error("Error while updating user groups", err);
      return false;
    });
};

/**
 * API METHOD - update the groups of a SFBoard.
 *
 * @param axios The Axios instance to use.
 * @param boardId The id of the SFBoard to update.
 * @param groupIds A list of groupId which are setted for the board.
 *
 * @returns a boolean which indicates if success
 */
export const updateGroupSFBoard = (
  axios: AxiosInstance,
  boardId: string,
  groupIds: string[]
): Promise<boolean> => {
  return axios
    .post("/user/group/update/sfboard/", groupIds, { params: { boardId } })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while updating shopfloorboard groups", err);
      return false;
    });
};

/**
 * API METHOD - update the groups of a PowerBI Board.
 *
 * @param axios The Axios instance to use.
 * @param boardId The id of the PowerBI Board to update.
 * @param groupIds A list of groupId which are setted for the board.
 *
 * @returns a boolean which indicates if success
 */
export const updateGroupPowerBI = (
  axios: AxiosInstance,
  powerBiId: string,
  groupIds: string[]
): Promise<boolean> => {
  return axios
    .post("/user/group/update/powerbi/", groupIds, { params: { powerBiId } })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while updating PowerPI groups", err);
      return false;
    });
};

/**
 * API METHOD - update the groups of a PowerBI Board.
 *
 * @param axios The Axios instance to use.
 * @param boardId The id of the PowerBI Board to update.
 * @param groupIds A list of groupId which are setted for the board.
 *
 * @returns a boolean which indicates if success
 */
export const updateGroupEnergy = (
  axios: AxiosInstance,
  energyBoardId: string,
  groupIds: string[]
): Promise<boolean> => {
  return axios
    .post("/user/group/update/energy/", groupIds, { params: { energyBoardId } })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while updating Energy Board groups", err);
      return false;
    });
};

/**
 * API METHOD - update the groups of a Protocol
 *
 * @param axios The Axios instance to use.
 * @param protocolId The id of the Protocol to update.
 * @param groupIds A list of groupId which are setted for the board.
 *
 * @returns a boolean which indicates if success
 */
export const updateGroupProtocol = (
  axios: AxiosInstance,
  protocolId: string,
  groupIds: string[]
): Promise<boolean> => {
  return axios
    .post("/user/group/update/protocol/", groupIds, { params: { protocolId } })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while updating Protocol groups", err);
      return false;
    });
};

/**
 * A hook to receive all available groups for the current users company.
 *
 * @returns a list of groups. Can be undefined if request is pending.
 */
export const useGroups = (): Group[] | undefined => {
  const { axios } = useAxios();
  const { user } = useContext(UserContext);

  const [loadedGroups, setLoadedGroups] = useState<Group[]>();

  /**
   * Receive groups for the users company.
   */
  useEffect(() => {
    if (user) {
      getGroupsByCompany(axios, user.companyId).then(setLoadedGroups);
    }
  }, [user, axios]);

  return loadedGroups;
};
