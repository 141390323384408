import { useCallback, useContext, useState } from "react";
import { ButtonComponent } from "sfm-component-library";
import { AppDataContext, UserContext } from "../../pages/App";
import placeholderLogo from "./../../assets/images/duckeneers-logo.png";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as UploadIcon } from "./../../assets/icons/file_upload.svg";
import { useTranslation } from "react-i18next";
import {
  saveNewLogoForCompany,
  updateLogoForCompany,
} from "../../utils/company/CompanyUtils";
import { useAxios } from "../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";

export const GeneralAdmin: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const { user, setUser } = useContext(UserContext);
  const { logo, setLogo } = useContext(AppDataContext);
  const [uploadFile, setUploadFile] = useState<File>();
  const [isLoading, setLoading] = useState<boolean>(false);

  /**
   * Checks which image should be loaded in the preview
   *
   * @returns correct URL for image to preview
   */
  const renderCurrentLogo = useCallback((): string => {
    if (uploadFile) return URL.createObjectURL(uploadFile);
    if (logo) return URL.createObjectURL(logo);
    return placeholderLogo;
  }, [uploadFile, logo]);

  /**
   * Save the current logo and replace it locally
   */
  const saveLogo = useCallback(async (): Promise<void> => {
    if (!user || !uploadFile || !user.company?.id) return;

    setLoading(true);
    const newLogo = await (user.company?.logo
      ? updateLogoForCompany(axios, uploadFile, user.company.id, user.id!)
      : saveNewLogoForCompany(axios, uploadFile, user.company.id, user.id!));

    if (!newLogo) {
      generateNotificationWithTranslations("warning");
      return;
    }

    setUser({ ...user, company: { ...user.company, logo: newLogo } });
    setLogo(uploadFile);
    setUploadFile(undefined);
    setLoading(false);
    generateNotificationWithTranslations("success");
  }, [axios, setLogo, setUser, uploadFile, user]);

  return (
    <div className="general-admin">
      <h2>{t("administration.general.logo")}</h2>
      <div className="general-admin--logo">
        <img src={renderCurrentLogo()} alt={user?.company?.name || ""} />
        <div className="general-admin--logo--buttons">
          <div className="general-admin--logo--buttons--upload">
            <ButtonComponent
              title={
                uploadFile?.name || (
                  <div className="general-admin--logo--buttons--upload--content">
                    <UploadIcon />
                    <p>{t("general.buttons.upload")}</p>
                  </div>
                )
              }
              type="file"
              accept="image/*"
              isLoading={isLoading}
              setFiles={(files) => {
                setUploadFile(files[0]);
              }}
            />
            {uploadFile && (
              <ButtonComponent
                disabled={isLoading}
                borderColor="black"
                className="general-admin--logo--buttons--upload--remove"
                title={<CloseIcon />}
                onClick={() => setUploadFile(undefined)}
              />
            )}
          </div>
          {uploadFile && (
            <ButtonComponent
              disabled={isLoading}
              borderColor="#a9faa2"
              title={t("general.buttons.save")}
              onClick={saveLogo}
            />
          )}
        </div>
      </div>
    </div>
  );
};
