import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAxios } from "../utils/AxiosUtil";
import { PrivateRoute } from "../utils/PrivateRoute";
import { fetchLogoForCompany } from "../utils/company/CompanyUtils";
import { FeatureModule } from "../utils/license/License.types";
import "../utils/time/Dayjs";
import { User, UserRole } from "../utils/user/User.types";
import { trackLoginForUser } from "../utils/user/User.utils";
import { AdministrationPage } from "./AdministrationPage";
import DashboardPage from "./DashboardPage";
import ErrorPage from "./ErrorPage";
import LandingPage from "./LandingPage";
import NotificationPage from "./NotificationPage";
import { ProfilePage } from "./ProfilePage";
import RegisterPage from "./RegisterPage";
import EnergyManagementDetailPage from "./features/EnergyManagementDetailPage";
import EnergyManagementPage from "./features/EnergyManagementPage";
import EnergyManagementPredictionPage from "./features/EnergyManagementPredictionPage";
import IndustrialTrucksAnalysisPage from "./features/IndustrialTrucksAnalysisPage";
import LpaPage from "./features/LpaPage";
import MLDetailPage from "./features/MLDetailPage";
import MLOverviewPage from "./features/MLOverviewPage";
import PowerBIOverviewPage from "./features/PowerBIOverviewPage";
import PowerBIPage from "./features/PowerBIPage";
import ProtocolDetailPage from "./features/ProtocolDetailPage";
import ProtocolFilledOutPage from "./features/ProtocolFilledOutPage";
import ProtocolOverviewPage from "./features/ProtocolOverviewPage";
import ShopfloorBoardOverviewPage from "./features/ShopfloorBoardOverviewPage";
import ShopfloorBoardPage from "./features/ShopfloorBoardPage";
import ShopfloorBoardTaskCreation from "./features/ShopfloorBoardTaskCreation";
import TaskOverviewPage from "./features/TaskOverviewPage";
import CompanyEditPage from "./platformAdmin/CompanyEditPage";
import CompanyOverviewPage from "./platformAdmin/CompanyOverviewPage";
import { CompanyReviewPage } from "./platformAdmin/CompanyReview";
import { CompanyUserEditPage } from "./platformAdmin/CompanyUserEditPage";
import DuckImage from "../assets/images/duck.png";

export const UserContext = React.createContext<{
  user?: User;
  setUser(newUser: User): void;
  isLoading?: boolean;
  setIsLoading(isLoading: boolean): void;
}>({ setUser: () => {}, setIsLoading: () => {} });

export const AppDataContext = React.createContext<{
  logo?: File;
  setLogo(newFile: File | undefined): void;
  isLoading?: boolean;
  setIsLoading(isLoading: boolean): void;
}>({
  setLogo: () => {},
  setIsLoading: () => {},
});

const App = () => {
  const { keycloak } = useKeycloak();
  const { axios } = useAxios();
  const [loadedUser, setLoadedUser] = useState<User>();
  const [userIsLoading, setUserIsLoading] = useState(false);
  const [logo, setLogo] = useState<File>();
  const [logoIsLoading, setLogoIsLoading] = useState(false);

  // load the logged in user and the logo from the backend
  useEffect(() => {
    if (!!axios && keycloak.authenticated && !loadedUser) {
      initialLoading();
    }
    // eslint-disable-next-line
  }, [axios, keycloak]);

  /**
   * Helper what is to do on initial loading
   */
  const initialLoading = (): void => {
    setUserIsLoading(true);
    setLogoIsLoading(true);
    keycloak
      .loadUserProfile()
      .then((profile: any) => profile.attributes.serviceId[0])
      .then(async (userId: string) =>
        //since authenticated we can assume there is a session id available
        trackLoginForUser(userId, keycloak.sessionId!, axios)
      )
      .then(async (userResp: User) => {
        setUserIsLoading(false);
        setLoadedUser(userResp);
        if (!!userResp.company?.logo) {
          return userResp.company!.id;
        } else {
          let defaultLogoResponse = await getDefaultImageFile();
          setLogo(defaultLogoResponse);
          return undefined;
        }
      })
      .then((companyId?: string) => {
        if (!companyId) return undefined;
        return fetchLogoForCompany(axios, companyId!);
      })
      .then(async (fetchedLogo?: File) => {
        setLogoIsLoading(false);
        if (!!fetchedLogo) setLogo(fetchedLogo);
        else {
          let defaultLogoResponse = await getDefaultImageFile();
          setLogo(defaultLogoResponse);
        }
      });
  };

  /**
   * Helper to fetch defualt duck image for logo
   * @returns loaded logo
   */
  const getDefaultImageFile = async (): Promise<File> => {
    let defaultLogoResponse = await fetch(DuckImage);
    let defaultData = await defaultLogoResponse.blob();
    return new File([defaultData], "duck_logo.png", {
      type: "image/png",
    });
  };

  return (
    <BrowserRouter>
      <Switch>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Route path="/" component={LandingPage} exact />
          <Route path="/register" component={RegisterPage} exact />

          <UserContext.Provider
            value={{
              user: loadedUser,
              setUser: (user) => setLoadedUser(user),
              setIsLoading: (isLoading) => setUserIsLoading(isLoading),
              isLoading: userIsLoading,
            }}
          >
            <AppDataContext.Provider
              value={{
                logo,
                setLogo,
                setIsLoading: (isLoading) => setLogoIsLoading(isLoading),
                isLoading: logoIsLoading,
              }}
            >
              {loadedUser && keycloak.authenticated && (
                <>
                  <PrivateRoute path="/dashboard" Page={DashboardPage} />
                  <PrivateRoute path="/notifications" Page={NotificationPage} />
                  <PrivateRoute
                    path="/sfboard/detail"
                    Page={ShopfloorBoardPage}
                    feature={FeatureModule.SHOPFLOOR_BOARD}
                  />
                  <PrivateRoute
                    path="/sfboard"
                    Page={ShopfloorBoardOverviewPage}
                    feature={FeatureModule.SHOPFLOOR_BOARD}
                  />
                  <PrivateRoute
                    path="/protocol"
                    Page={ProtocolOverviewPage}
                    feature={FeatureModule.PROTOCOL}
                  />
                  <PrivateRoute
                    path="/protocol/detail/"
                    Page={ProtocolDetailPage}
                    feature={FeatureModule.PROTOCOL}
                  />
                  <PrivateRoute
                    path="/protocol/filled/:id"
                    Page={ProtocolDetailPage}
                    feature={FeatureModule.PROTOCOL}
                  />
                  <PrivateRoute
                    path="/protocol/filled"
                    Page={ProtocolFilledOutPage}
                    feature={FeatureModule.PROTOCOL}
                  />
                  <PrivateRoute
                    path="/ml"
                    Page={MLOverviewPage}
                    feature={FeatureModule.ML}
                  />
                  <PrivateRoute
                    path="/ml/detail"
                    Page={MLDetailPage}
                    feature={FeatureModule.ML}
                  />
                  <PrivateRoute
                    path="/sfboard/task"
                    Page={ShopfloorBoardTaskCreation}
                    feature={FeatureModule.SHOPFLOOR_BOARD}
                  />
                  <PrivateRoute
                    path="/taskoverview"
                    Page={TaskOverviewPage}
                    feature={FeatureModule.SHOPFLOOR_BOARD}
                  />
                  <PrivateRoute
                    path="/ita"
                    Page={IndustrialTrucksAnalysisPage}
                    feature={FeatureModule.INDUSTRIALTRUCKSANALYSIS}
                  />
                  <PrivateRoute
                    path="/energy"
                    Page={EnergyManagementPage}
                    feature={FeatureModule.ENERGY}
                  />
                  <PrivateRoute
                    path="/energy/detail"
                    Page={EnergyManagementDetailPage}
                    feature={FeatureModule.ENERGY}
                  />
                  <PrivateRoute
                    path="/energy/prediction"
                    Page={EnergyManagementPredictionPage}
                    feature={FeatureModule.ENERGY}
                  />
                  <PrivateRoute
                    path="/lpa"
                    Page={LpaPage}
                    feature={FeatureModule.LPA}
                  />
                  <PrivateRoute
                    path="/bi"
                    Page={PowerBIOverviewPage}
                    feature={FeatureModule.POWERBI}
                  />
                  <PrivateRoute
                    path="/bi/detail"
                    Page={PowerBIPage}
                    feature={FeatureModule.POWERBI}
                  />
                  <PrivateRoute path="/profile" Page={ProfilePage} />
                  <PrivateRoute path="/admin" Page={AdministrationPage} />
                  {loadedUser?.role === UserRole.PLATFORM_ADMIN && (
                    <>
                      <PrivateRoute
                        path="/admin/companies"
                        Page={CompanyOverviewPage}
                      />
                      <PrivateRoute
                        path="/admin/companies/review"
                        Page={CompanyReviewPage}
                      />
                      <PrivateRoute
                        path="/admin/company/:id"
                        Page={CompanyEditPage}
                      />
                      <PrivateRoute
                        path="/admin/company/user/:id"
                        Page={CompanyUserEditPage}
                      />
                    </>
                  )}
                </>
              )}
            </AppDataContext.Provider>
          </UserContext.Provider>
        </ErrorBoundary>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
