import { useCallback, useRef, useState } from "react";
import ReactGridLayout from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { useResize } from "../../utils/hooks/ResizeHook";
import { availableWidgets } from "../../utils/user/Dashboard.types";

export const DashboardWidgetStore: React.FC = () => {
  const { t } = useTranslation();
  const wrapper = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  /**
   * Resize handler: Automatically adjust grid width to wrapper width
   */
  useResize(
    () => {
      if (!wrapper.current) return;
      setWidth(wrapper.current.clientWidth);
    },
    undefined,
    [wrapper]
  );

  /**
   * Generates the widget which are showed in the sidebar widget selector
   *
   * @return a list of JSX.Elements (with data-grid prop) needed for the "react-grid-layout"
   */
  const generateWidgets = useCallback(() => {
    return Object.keys(availableWidgets).map((type, index) => {
      return (
        <div
          draggable
          onDragStart={(evt) => evt.dataTransfer.setData("widget", type)}
          key={type + index}
          data-grid={{
            x: 1,
            y: index + 1,
            h: 1,
            w: 1,
            i: type + index,
          }}
        >
          <div>
            <h3>
              {t(`dashboard.widgets.${type}`)}-{t("dashboard.widgetName")}
            </h3>
            <p>
              {t(`dashboard.widgetsDescription.${type}`, { defaultValue: "" })}
            </p>
          </div>
        </div>
      );
    });
  }, [t]);

  return (
    <div className="dashboard--widget-store" ref={wrapper}>
      {width !== 0 && (
        <ReactGridLayout
          cols={1}
          width={width}
          isDraggable={false}
          isResizable={false}
          isDroppable={false}
          compactType="vertical"
          autoSize
          rowHeight={125}
        >
          {generateWidgets()}
        </ReactGridLayout>
      )}
    </div>
  );
};
