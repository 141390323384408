import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../../utils/AxiosUtil";
import { useElementWidth } from "../../../utils/elementSize/ElementSizeUtils";
import { generateNotificationWithTranslations } from "../../../utils/GeneralUtils";
import {
  cutTextAfterMaxLength,
  deleteTaskForShopfloorBoard,
} from "../../../utils/sfboard/SfBoard.utils";
import { Task, TaskStatus } from "../../../utils/tasks/Tasks.types";
import { User } from "../../../utils/user/User.types";
import DeletePopUp from "../../deletepopup/DeletePopUp";
import "./ShopfloorTaskStyles.scss";
import TaskCreationPopup from "./TaskCreationPopup";

interface ShopfloorTaskProps {
  task: Task;
  updateTask(task: Task): void;
  onDelete(): void;
  users: User[];
  minified?: boolean;
  initiallyOpenTaskId?: string;
}

const ShopfloorTask: React.FC<ShopfloorTaskProps> = ({
  task,
  updateTask,
  users,
  onDelete,
  minified,
  initiallyOpenTaskId,
}) => {
  const [openTaskText, setOpenTaskText] = useState<boolean>(
    task.id === initiallyOpenTaskId
  );
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [deleteTaskIsLoading, setDeleteTaskIsLoading] =
    useState<boolean>(false);
  const { axios } = useAxios();
  const { t } = useTranslation();

  const [titleWidth, setTitleWidth] = useState<number>(150);

  const width: number = useElementWidth(
    `shopfloor-column-task-title-${task.title}`
  );

  /**
   * Helper to set the value for titleWidth using useElementWidth
   */
  useEffect(() => {
    setTitleWidth(width);
  }, [width]);

  /**
   * Helper to determien the correct color for a specific status
   *
   * @param status
   * @returns hex code as string
   */
  const getCorrectColorForStatus = (status?: TaskStatus): string => {
    switch (status) {
      case TaskStatus.NEW:
      case TaskStatus.FINISHED_SUCCESSFUL:
      case TaskStatus.IN_PROGRESS:
        return "green";
      case TaskStatus.FINISHED_NOT_SUCCESSFUL:
        return "#fd4a4a";
      case TaskStatus.DELAYED:
      default:
        return "#f7dc08";
    }
  };

  /**
   * handle Task deletion
   */
  const handleDeleteTask = (): void => {
    setDeleteTaskIsLoading(true);
    deleteTaskForShopfloorBoard(task.id!, axios).then((taskDeleted) => {
      setDeleteTaskIsLoading(false);
      if (taskDeleted) {
        onDelete();
        setOpenDeletePopUp(false);
        setOpenTaskText(false);
        generateNotificationWithTranslations("success");
      } else {
        generateNotificationWithTranslations("warning");
      }
    });
  };

  return (
    <div
      onClick={() => setOpenTaskText(true)}
      className={[
        "shopfloor-column-task",
        minified ? "shopfloor-column-task--minified" : "",
      ].join(" ")}
    >
      {/**
       * this check prevents it from fetching all images of all tasks at once. Images will be fetched, as soon as this component will be mounted
       */}
      {openTaskText && (
        <TaskCreationPopup
          users={users}
          isOpen={openTaskText}
          toogleOpenState={setOpenTaskText}
          task={task}
          setTask={(updatedTask) => updateTask(updatedTask)}
          onDelete={() => {
            setOpenTaskText(false);
            onDelete();
          }}
        />
      )}
      <DeletePopUp
        isOpen={openDeletePopUp}
        toggleOpen={setOpenDeletePopUp}
        onDelete={handleDeleteTask}
        deleteIsLoading={deleteTaskIsLoading}
        content={<p>{t("shopfloorboard.task.deleteDescription")}</p>}
      />

      <div
        className={[
          "shopfloor-column-task--icon-wrapper",
          minified ? "shopfloor-column-task--icon-wrapper--center" : "",
        ].join(" ")}
      >
        <div
          className="shopfloor-column-task--status"
          style={{ background: getCorrectColorForStatus(task.status) }}
        />
      </div>

      <div
        className={[
          "shopfloor-column-task--title",
          minified ? "shopfloor-column-task--title--minified" : "",
        ].join(" ")}
        id={`shopfloor-column-task-title-${task.title}`}
      >
        {minified
          ? task.title
          : cutTextAfterMaxLength(
              task.title,
              titleWidth /
                parseInt(process.env.REACT_APP_AVG_SIGN_SIZE_COEFFICIENT!)
            )}
      </div>
      <div
        className={[
          "shopfloor-column-task--content",
          minified ? "shopfloor-column-task--content--hide" : "",
        ].join(" ")}
      >
        {cutTextAfterMaxLength(task.description, 70)}
      </div>
    </div>
  );
};

export default ShopfloorTask;
