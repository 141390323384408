import { v4 as uuidv4 } from "uuid";
import { getRandomColor } from "../GeneralUtils";
import { FeatureConfiguration } from "../configuration/FeatureConfiguration.types";
import { FeatureModule } from "../license/License.types";
import { User } from "../user/User.types";
import { FileEntry, FileType } from "../tasks/Tasks.types";
import { NotificationRotationConfig } from "../../components/notificationrotation/NotificationRotation.types";

export interface Protocol extends FeatureConfiguration {
  originId?: string;
  userId?: string;
  user?: User;
  slugId: string;
  name: string;
  color: string;
  groups: string[];
  inputs: ProtocolInput[];
  showGroupsOnly?: boolean;
  documents?: ProtocolDocument[];
  summaryEnabled: boolean;
  summaryType?: ProtocolSummaryType;
  summary?: string;
  notificationRotations: NotificationRotationConfig[];
  superviserIds: string[];
}

/**
 * Specifies the type of summary the protocol has
 */
export enum ProtocolSummaryType {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
  SIX = "SIX",
}

export interface ProtocolDocument {
  id: string;
  name: string;
  fileEntry?: FileEntry;
  linkContent?: string;
  link: boolean;
  file?: File;
  local?: boolean;
}

export interface ProtocolInput {
  id: string;
  name: string;
  inputType: ProtocolInputType;
  value: ExtractValue<ProtocolInputType>;
  priority: ProtocolInputPriority;
  options: string[];
  fileEntry?: FileEntry;
  required: boolean;
}

export enum ProtocolInputType {
  CHECKBOX = "CHECKBOX",
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  NUMBER = "NUMBER",
  DROPDOWN = "DROPDOWN",
  BARCODE = "BARCODE",
}

/**
 * Helper type to get type of value for protocol input type
 */
export type ExtractValue<T extends ProtocolInputType> =
  T extends ProtocolInputType.CHECKBOX
    ? boolean
    : T extends ProtocolInputType.TEXT
    ? string
    : T extends ProtocolInputType.IMAGE
    ? File
    : T extends ProtocolInputType.NUMBER
    ? number
    : T extends ProtocolInputType.DROPDOWN
    ? string
    : T extends ProtocolInputType.BARCODE
    ? string
    : never;

/**
 * Describes the priority of a protocol input
 */
export enum ProtocolInputPriority {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export interface ProtocolFileRequest {
  protocolId: string;
  userId: string;
  fileType: FileType;
}

/**
 * Creates a new protocol input
 * @returns  a new protocol input
 */
export const createNewProtocolInput = (): ProtocolInput => ({
  id: uuidv4(),
  name: "",
  inputType: ProtocolInputType.TEXT,
  value: "",
  priority: ProtocolInputPriority.MEDIUM,
  options: [],
  required: true,
});

/**
 * Creates a new protocol document
 * @returns  a new protocol document
 */
export const createNewProtocolDocument = (): ProtocolDocument => ({
  id: uuidv4(),
  name: "",
  fileEntry: undefined,
  linkContent: undefined,
  link: false,
  file: undefined,
  local: true,
});

/**
 * Creates a new empty protocol
 * @param createUser  - user to extract company id from
 * @returns a new empty protocol
 */
export const createEmptyProtocol = (createUser?: User): Protocol => ({
  name: "",
  slugId: "",
  companyId: createUser?.companyId || "",
  createDate: new Date(),
  featureModule: FeatureModule.PROTOCOL,
  color: getRandomColor(),
  version: 0,
  groups: [],
  inputs: [],
  summaryEnabled: true,
  createdBy: createUser?.id || "",
  lastUpdatedBy: createUser?.id || "",
  notificationRotations: [],
  superviserIds: [],
});
