import { useTranslation } from "react-i18next";
import { TextInputComponent } from "sfm-component-library";
import {
  WidgetComponent,
  WidgetConfigComponent,
  WidgetType,
} from "../../../utils/user/Dashboard.types";

/**
 *  Empty widget, as the name says, it shows nothing. Can be used for styling because its only a colored box
 */
export const EmptyWidget: WidgetComponent<WidgetType.EMPTY> = () => {
  return <div />;
};

export const EmptyWidgetConfig: WidgetConfigComponent<WidgetType.EMPTY> = ({
  info,
  mutateInfo,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("dashboard.edit.color")}</p>
      <TextInputComponent
        type="color"
        value={info.color}
        onChange={(color) => mutateInfo({ color })}
      />
    </>
  );
};
