import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RetractableComponent } from "sfm-component-library";
import { ReactComponent as ThunderstormIcon } from "../../assets/icons/cloud-bolt-solid.svg";
import { ReactComponent as RainIcon } from "../../assets/icons/cloud-rain-solid.svg";
import { ReactComponent as SleetIcon } from "../../assets/icons/cloud-snow-rain-svgrepo-com.svg";
import { ReactComponent as DryIcon } from "../../assets/icons/cloud-sun-solid.svg";
import { ReactComponent as CloudIcon } from "../../assets/icons/cloud.svg";
import { ReactComponent as FogIcon } from "../../assets/icons/fog-solid.svg";
import { ReactComponent as HailIcon } from "../../assets/icons/hail-svgrepo-com.svg";
import { ReactComponent as SnowIcon } from "../../assets/icons/snowflake-regular.svg";
import { ReactComponent as SunIcon } from "../../assets/icons/sun-solid.svg";
import { ReactComponent as WindIcon } from "../../assets/icons/wind-solid.svg";
import {
  Forecast,
  WeatherForecast,
  WeatherResponse,
} from "../../utils/energy/Energy.types";
import { fetchWeatherData } from "../../utils/energy/Energy.utils";
import "./WeatherComponent.style.scss";
import { WeatherComponentProps } from "./WeatherComponent.types";

export const WeatherComponent: React.FC<WeatherComponentProps> = ({
  lat,
  lon,
}) => {
  const [weatherResponse, setWeatherResponse] = useState<WeatherResponse>();
  const [weatherForecast, setWeatherForecast] = useState<WeatherForecast>();
  const { t } = useTranslation();
  /**
   * Get current Weather from API and set {@link weatherResponse}
   */
  useEffect(() => {
    if (!lon || !lat) return;
    fetchWeatherData(lat, lon).then((data) => {
      if (!data) return;
      setWeatherForecast(data.weatherForecast);
      setWeatherResponse(data.currentWeather);
    });
  }, [lon, lat]);

  /**
   * Helper to get correct Icon for actual weather
   * @param icon from  {@link weatherResponse} that got fetched from API
   * @returns correct Icon
   */
  const getCorrectIcon = (
    icon:
      | string
      | "dry"
      | "cloudy"
      | "fog"
      | "wind"
      | "rain"
      | "sleet"
      | "snow"
      | "hail"
      | "thunderstorm"
  ): JSX.Element => {
    switch (icon) {
      case "cloudy":
        return <CloudIcon />;
      case "rain":
        return <RainIcon />;
      case "fog":
        return <FogIcon />;
      case "sleet":
        return <SleetIcon />;
      case "snow":
        return <SnowIcon />;
      case "hail":
        return <HailIcon />;
      case "thunderstorm":
        return <ThunderstormIcon />;
      case "dry":
        return <DryIcon />;
      default:
        return <></>;
    }
  };
  /**
   * Helper to get correct wind direction for actual weather
   * @param deg of the actual direction from {@link weatherResponse} that got fetched from API
   * @returns correct String for wind direction
   */
  const getWindDirection = (deg: number): string => {
    switch (true) {
      case deg >= 355 || deg <= 5:
        return "N";
      case deg > 5 && deg < 40:
        return "NNO";
      case deg >= 40 && deg <= 50:
        return "NO";
      case deg > 50 && deg < 85:
        return "ONO";
      case deg >= 85 && deg <= 95:
        return "O";
      case deg > 95 && deg < 130:
        return "OSO";
      case deg >= 130 && deg <= 140:
        return "SO";
      case deg > 140 && deg < 175:
        return "SSO";
      case deg >= 175 && deg <= 185:
        return "S";
      case deg > 185 && deg < 220:
        return "SSW";
      case deg >= 220 && deg < 230:
        return "SW";
      case deg >= 230 && deg < 265:
        return "WSW";
      case deg >= 265 && deg <= 275:
        return "W";
      case deg > 275 && deg < 310:
        return "WNW";
      case deg >= 310 && deg <= 320:
        return "NW";
      case deg > 320 && deg < 355:
        return "NNW";
      default:
        return "-";
    }
  };
  /**
   * Helper function to create preview entries
   * @param forecast to create entry from
   * @returns item to display
   */
  const generateForecastItem = (forecast: Forecast): JSX.Element => {
    const date = new Date(forecast.timestamp);
    const sunbarWidth = (forecast.sunshine / 60) * 100;
    return (
      <li
        key={`forecast-${forecast.timestamp}`}
        className="weather-forecast--entry"
      >
        <div className="weather-forecast--item-wrapper">
          <div className="weather-forecast--info-wrapper">
            <p className="weather-forecast--entry--text">
              {date.getHours()}:00
            </p>
            <div className="weather-forecast--entry--icon">
              {getCorrectIcon(forecast.condition)}
            </div>
            <p className="weather-forecast--entry--text">
              {forecast.temperature}°C
            </p>
          </div>
          <div className="weather-forecast--info-wrapper">
            <div className="sun-shine-overview">
              <SunIcon className="icon-small" />
              <div className="sunbar-wrapper">
                <div className="sunbar-container">
                  <div
                    className="sunbar"
                    style={{
                      width: `${sunbarWidth}%`,
                    }}
                  />
                  <div className="sunbar-outline"></div>
                </div>
                <p> {forecast.solar} kWh/m²</p>
              </div>
            </div>
            <div className="wind-overview">
              <WindIcon
                className="icon-small"
                style={{
                  transform: `rotate(${forecast.wind_direction - 90}deg)`,
                }}
              />
              <div>
                <p>{forecast.wind_direction}°</p>
                <p>{getWindDirection(forecast.wind_direction)}</p>
              </div>
            </div>
            <div className="rain-overview">
              <RainIcon className="icon-small" />
              <div>
                <p>{forecast.precipitation_probability}%</p>
                <p>{forecast.precipitation}mm</p>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  };
  return (
    <div>
      {weatherResponse ? (
        <div className="outer-wrapper">
          <div className="weather-component-wrapper">
            <div className="weather-component-wrapper--icon">
              {getCorrectIcon(weatherResponse.weather.condition)}
            </div>
            <div className="weather-component-wrapper--overview">
              <div className="weather-component-wrapper--temp">
                {weatherResponse.weather.temperature}°C
              </div>
              <p className="weather-component-wrapper--conditions">
                {t(
                  `energy.weather.condition.${weatherResponse.weather.condition}`
                )}
              </p>
            </div>

            <div className="wind-and-cloud">
              <CloudIcon className="icon-small" />
              <p>
                {weatherResponse.weather.cloud_cover}%{" "}
                {t("energy.weather.cloudy")}
              </p>
              <WindIcon
                className="icon-small"
                style={{
                  transform: `rotate(${
                    weatherResponse.weather.wind_direction_10 - 90
                  }deg)`,
                }}
              />
              <p>
                {weatherResponse.weather.wind_direction_10}°{" "}
                {getWindDirection(weatherResponse.weather.wind_direction_10)}
              </p>
            </div>
            <div>
              <div className="sun-shine-overview">
                <SunIcon className="icon-small" />
                <div>
                  <p>
                    {t("energy.weather.sunshineOverview", {
                      scale: "30",
                      amount: weatherResponse.weather.sunshine_30,
                    })}
                  </p>
                  <p>
                    {t("energy.weather.sunshineOverview", {
                      scale: "60",
                      amount: weatherResponse.weather.sunshine_60,
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="entry">{t("energy.weather.sources")}:</p>
            {weatherResponse.sources.map((source, index) => (
              <p className="entry" key={`source-${index}`}>
                {t("energy.weather.sourceList", {
                  name: source.station_name,
                  distance: Math.round(source.distance / 1000),
                })}
                {weatherResponse.sources.length > index + 1 && ","}
              </p>
            ))}
          </div>
          {weatherForecast && (
            <div className="weather-forecast">
              <ul>
                {weatherForecast.weather.slice(0, 3).map(generateForecastItem)}
                <RetractableComponent title={t("energy.weather.more")}>
                  {weatherForecast.weather
                    .slice(3, 24)
                    .map(generateForecastItem)}
                </RetractableComponent>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <div>{t("energy.weather.condition.null")}</div>
      )}
    </div>
  );
};
