import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BoxComponent,
  BoxType,
  ButtonComponent,
  CheckboxComponent,
  LayoutComponent,
  LoaderComponent,
  PopupComponent,
  UploadComponent,
} from "sfm-component-library";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { useTranslation } from "react-i18next";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera_add.svg";
import { useAxios } from "../../utils/AxiosUtil";
import {
  detectSubImages,
  detectText,
  getAllGateResults,
} from "../../utils/ml/ML.axios";
import { useResize } from "../../utils/hooks/ResizeHook";

import {
  getRandomColor,
  tryGeneratingObjectURL,
} from "../../utils/GeneralUtils";
import { Layer, Rect, Stage, Group } from "react-konva";

import "../../styles/MLOverviewPageStyles.scss";
import { GateResult, TextDetection, Vector4 } from "../../utils/ml/ML.types";
import { PixelCrop } from "react-image-crop";
import { canvasPreview, convertCanvasesToBlobs } from "../../utils/ml/ML.utils";
import { ReactComponent as ListIcon } from "../../assets/icons/list-solid.svg";
import { UserContext } from "../App";
import { useHistory } from "react-router-dom";

interface RectProps {
  x: number;
  y: number;
  width: number;
  height: number;
  opacity: number;
  fill: string;
}

const TextDetectionComponent: React.FC<{ detection: TextDetection }> = ({
  detection,
}) => {
  const { t } = useTranslation();
  return (
    <div className="text-detection__wrapper">
      <div>
        <div>{t("ml.textdetection.destination_type")}:</div>
        <div>{`${
          detection.destination_type
            ? detection.destination_type
            : t("ml.textdetection.noTextDetected")
        }`}</div>
      </div>
      <div>
        <div>{t("ml.textdetection.klt_sequence")}</div>:
        <div>{`${detection.klt_sequence}`}</div>
      </div>
      <div>
        <div>{t("ml.textdetection.order_number")}:</div>
        <div>{`${detection.order_number}`}</div>
      </div>
      <div>
        <div>{t("ml.textdetection.sort_number")}:</div>
        <div>{`${detection.sort_number}`}</div>
      </div>
      <div>
        <div>{t("ml.textdetection.station")}:</div>
        <div>{`${detection.station}`}</div>
      </div>
    </div>
  );
};

const MLOverviewPage: React.FC = () => {
  const { user } = useContext(UserContext);
  const [listViewType, setListViewType] = useState<"overview" | "detection">(
    "overview"
  );
  const { t } = useTranslation();
  const [imageFileToDetect, setImageToDetect] = useState<File>();
  const [htmlImage, setHtmlImage] = useState<HTMLImageElement>(new Image());
  const { axios } = useAxios();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const history = useHistory();

  const stageDivRef = useRef<HTMLDivElement>(null);

  const [imageRatio, setImageRatio] = useState<number>(0);
  const [vectors, setVectors] = useState<Vector4[]>([]);

  const [subImages, setSubImages] = useState<Blob[]>([]);
  const [subTexts, setSubTexts] = useState<TextDetection[]>([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(-1);
  const [selectedCardPopupVisible, toggleCardPopup] = useState<boolean>(false);

  const [cardDetection, toggleCardDetection] = useState<boolean>(true);
  const [textDetection, toggleTextDetection] = useState<boolean>(true);

  const [gateResults, setGateResults] = useState<GateResult[]>([]);

  /**
   * this useeffect fetches all gate result by unique gate name
   */
  useEffect(() => {
    if (!axios || !user) return;
    getAllGateResults(axios, user.companyId).then(setGateResults);
  }, [axios, user]);

  /**
   * Helper to upload an image to detect sub images, also detects text if selected
   */
  const uploadImage = async (): Promise<void> => {
    setVectors([]);
    setSubImages([]);
    setSubTexts([]);
    if (!axios || !imageFileToDetect) return;
    toggleLoading(true);
    const result = await detectSubImages(axios, imageFileToDetect);
    if (!result || result.length <= 0) {
      toggleLoading(false);
      return;
    }
    setVectors(result);
    const pixelCrops: PixelCrop[] = getCropsOfDetectedImages(result);
    const generatedCanvases = [];
    for await (const pixelcrop of pixelCrops) {
      const canvas = document.createElement("canvas");
      await canvasPreview(htmlImage, canvas, pixelcrop);
      generatedCanvases.push(canvas);
    }
    const { blobs, texts } = await handleCanvasImages(generatedCanvases);
    setSubImages(blobs);
    textDetection && setSubTexts(texts);
    toggleLoading(false);
  };

  /**
   * If only text detection is enabled, this function will be called
   */
  const primaryTextDetection = async (): Promise<void> => {
    setSubTexts([]);
    if (!axios || !imageFileToDetect) return;
    toggleLoading(true);
    const result = await detectText(axios, imageFileToDetect);
    if (!result) {
      setSubTexts([
        {
          error: true,
          destination_type: "",
          klt_sequence: "",
          order_number: "",
          sort_number: "",
          station: "",
        },
      ]);
      return;
    }
    setSubTexts([result]);
    toggleLoading(false);
    setVectors([]);
    setSubImages([]);
  };

  /**
   * Helper to draw the rectangles on the image that represent the subimages
   * @returns  a list of Konva Rectangles
   */
  const drawCards = (): JSX.Element[] => {
    let rectangles: JSX.Element[] = [];
    const mutatedResult: Vector4[] = vectors.map((vector) => {
      return [
        vector[0] * imageRatio,
        vector[1] * imageRatio,
        vector[2] * imageRatio,
        vector[3] * imageRatio,
      ];
    });
    mutatedResult.forEach((vector4: Vector4, index) => {
      const boxWidth: number = vector4[2] - vector4[0];
      const boxHeight: number = vector4[3] - vector4[1];
      const rectAttributes: RectProps = {
        x: 0,
        y: 0,
        width: boxWidth,
        height: boxHeight,
        fill: "#008000",
        opacity: 0.5,
      };
      rectangles.push(
        <Group x={vector4[0]} y={vector4[1]}>
          <Rect
            className={"rect-class"}
            {...rectAttributes}
            onMouseEnter={(evt: any) =>
              (evt.currentTarget.getStage()!.container().style.cursor =
                "pointer")
            }
            onMouseLeave={(evt: any) =>
              (evt.currentTarget.getStage()!.container().style.cursor =
                "default")
            }
            onClick={() => {
              setSelectedCardIndex(index);
              toggleCardPopup(true);
            }}
            onTap={() => {
              setSelectedCardIndex(index);
              toggleCardPopup(true);
            }}
          />
        </Group>
      );
    });
    return rectangles;
  };

  /**
   * Takes the vectors and converts them to pixel crops to extract the detected images out of the root image
   * @param vectors - the vectors of detected images
   * @returns  a list of pixel crops
   */
  const getCropsOfDetectedImages = (vectors: Vector4[]): PixelCrop[] =>
    vectors.map((vector4) => ({
      x: vector4[0],
      y: vector4[1],
      width: vector4[2] - vector4[0],
      height: vector4[3] - vector4[1],
      unit: "px",
    }));

  /**
   * Handles an selected imamge and prepares it for konva to draw
   * @param imageFile  the image file to handle
   */
  const handleImage = async (imageFile: File): Promise<void> => {
    toggleLoading(true);
    setImageToDetect(imageFile);
    const htmlImage = new Image();
    htmlImage.src = tryGeneratingObjectURL(imageFile) || "";
    const loadingImage = new Promise<HTMLImageElement>((resolve, reject) => {
      htmlImage.onload = () => resolve(htmlImage);
      htmlImage.onerror = () => reject();
    });
    setHtmlImage(await loadingImage);
    toggleLoading(false);
  };

  /**
   * Important to keep the images ratio on different screen sizes
   * @param containerWidth  the width of the container
   * @param containerHeight  the height of the container
   * @param width  the width of the image
   * @param height  the height of the image
   * @returns the ratio of the image
   */
  const getImageRatio = (
    containerWidth: number,
    containerHeight: number,
    width: number,
    height: number
  ): number => {
    const widthRatio: number = containerWidth / width,
      heightRatio: number = containerHeight / height;
    return Math.min(widthRatio, heightRatio, 1);
  };

  /**
   * Recalculates image ratio on resize
   */
  useResize(
    () => {
      if (!stageDivRef.current) return 1;
      const { width, height } = stageDivRef.current.getBoundingClientRect();
      setImageRatio(
        getImageRatio(width, height, htmlImage.width, htmlImage.height)
      );
    },
    undefined,
    [htmlImage, stageDivRef, stageDivRef.current]
  );

  /**
   * Helper method to turn canvases into blobs with their texts
   * @param canvases  the canvases to convert
   * @returns a list of blobs and their detected texts
   */
  const handleCanvasImages = async (
    canvases: HTMLCanvasElement[]
  ): Promise<{
    blobs: Blob[];
    texts: TextDetection[];
  }> => {
    if (canvases.length <= 0) return { blobs: [], texts: [] };
    const imagePromises: Promise<Blob>[] = convertCanvasesToBlobs(canvases);
    const imageBlobs: Blob[] = await Promise.all(imagePromises);
    const textPromises: Promise<any>[] = imageBlobs.map((blob) =>
      detectText(axios, new File([blob], "detect"))
    );
    const detectedTexts = await Promise.all(textPromises);
    return {
      blobs: imageBlobs,
      texts: detectedTexts,
    };
  };

  /**
   * Helper to get correct page content
   * @param listView decides which data should be shown
   * @returns generated JSX.Elements
   */
  const getCorrectEntryView = (
    listView: "overview" | "detection"
  ): JSX.Element => {
    switch (listView) {
      case "detection":
        return (
          <>
            <div className="ml-page__header">
              <h3>{t("ml.uploadImage")}</h3>
            </div>

            <UploadComponent
              buttonContent={<CameraIcon />}
              files={!!imageFileToDetect ? [imageFileToDetect] : []}
              accept="image/*"
              addFiles={(files) => handleImage(files[0])}
              multiple={false}
              removeFile={() => {
                setImageToDetect(undefined!);
                setVectors([]);
                setHtmlImage(new Image());
                setSubImages([]);
                setSubTexts([]);
              }}
            />
            <div className="ml-page__button-wrapper">
              <CheckboxComponent
                value={t("ml.carddetection")}
                checked={cardDetection}
                onCheck={() => toggleCardDetection(!cardDetection)}
              />
              <CheckboxComponent
                value={t("ml.textdetection.title")}
                checked={textDetection}
                onCheck={() => toggleTextDetection(!textDetection)}
              />
              <ButtonComponent
                disabled={!imageFileToDetect}
                onClick={() =>
                  !cardDetection && textDetection
                    ? primaryTextDetection()
                    : uploadImage()
                }
                title={t("ml.start")}
              />
            </div>
            {subTexts.length === 1 && textDetection && !cardDetection && (
              <div className="ml-page__text-detection">
                <div className="ml-page__popup-content">
                  <div className="ml-page__popup-text">
                    <h3>
                      {subTexts[0].error
                        ? t("ml.noTextDetected")
                        : t("ml.text")}
                    </h3>
                    {!subTexts[0].error && (
                      <TextDetectionComponent detection={subTexts[0]} />
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="ml-page__content-wrapper">
              <div ref={stageDivRef} className="ml-page__stage-wrapper">
                {isLoading ? (
                  <LoaderComponent />
                ) : (
                  <div className="ml-page__stage-content">
                    <Stage
                      className={"ml-page__stage"}
                      width={htmlImage.width * imageRatio}
                      height={htmlImage.height * imageRatio}
                    >
                      <Layer>
                        <Rect
                          width={
                            stageDivRef.current?.getBoundingClientRect().width
                          }
                          height={
                            stageDivRef.current?.getBoundingClientRect().height
                          }
                          fillPatternImage={htmlImage}
                          fillPatternScaleX={imageRatio}
                          fillPatternScaleY={imageRatio}
                          fillPatternRepeat="no-repeat"
                        />
                      </Layer>
                      <Layer className="detail-view-box-layer">
                        {drawCards()}
                      </Layer>
                    </Stage>
                  </div>
                )}
              </div>
            </div>
          </>
        );

      case "overview":
        return (
          <div className="gate__overview--grid">
            {gateResults.map((result, index) => (
              <BoxComponent
                background={getRandomColor()}
                type={BoxType.PACKAGE}
                key={`box-config-component-item-${index}`}
                onClick={() =>
                  history.push("/ml/detail", { gateName: result.gateName })
                }
                title={result.gateName}
              />
            ))}
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.ML)}
      title={t("ml.title")}
    >
      <div className="ml-page__wrapper">
        <div className="power-bi__overview--adding">
          <div className="power-bi__overview--adding-view">
            <ListIcon
              className={[
                "view-svg",
                listViewType === "overview" ? "active" : "",
              ].join(" ")}
              onClick={() => setListViewType("overview")}
            />
            <CameraIcon
              className={[
                "view-svg",
                "list-icon",
                listViewType === "detection" ? "active" : "",
              ].join(" ")}
              onClick={() => setListViewType("detection")}
            />
          </div>
        </div>

        {getCorrectEntryView(listViewType)}
      </div>

      <PopupComponent
        toggleOpen={(value) => toggleCardPopup(value)}
        isOpen={selectedCardPopupVisible}
      >
        {subImages.length > 0 && !!subImages[selectedCardIndex] && (
          <div className="ml-page__popup-image">
            <img
              src={tryGeneratingObjectURL(subImages[selectedCardIndex])}
              alt="popup"
            />
          </div>
        )}
        {subTexts.length > 0 && !!subTexts[selectedCardIndex] && (
          <div className="ml-page__popup-content">
            <div className="ml-page__popup-text">
              <h3>{t("ml.text")}</h3>
              <TextDetectionComponent detection={subTexts[selectedCardIndex]} />
            </div>
          </div>
        )}
      </PopupComponent>
    </LayoutComponent>
  );
};

export default MLOverviewPage;
