import { useTranslation } from "react-i18next";
import {
  generateNotification,
  LoaderComponent,
  PopupComponent,
  TableComponent,
} from "sfm-component-library";
import "../../styles/AdministrationPage.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import {
  fetchAllDataApiForCompany,
  updateDataApiForCompany,
} from "../../utils/apidata/DataApi.utils";
import { DataApi } from "../../utils/apidata/DataApi.types";
import { ReactComponent as EditIcon } from "./../../assets/icons/edit.svg";
import { DataApiEditForm } from "../../components/administration/dataapi/DataApiEditForm";
import { UserContext } from "../../pages/App";

export const DataApiAdmin: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { axios } = useAxios();
  const [isLoading, toggleLoading] = useState(true);
  const [isUpdating, toggleUpdating] = useState(false);
  const [loadedDataApi, setLoadedDataApi] = useState<DataApi[]>([]);
  const [selectedDataApi, setSelectedDataApi] = useState<DataApi>();

  /**
   * Load {@link DataApi} for company
   */
  useEffect(() => {
    if (!user) return;
    fetchAllDataApiForCompany(axios, user.companyId).then((respData) => {
      setLoadedDataApi(respData);
      toggleLoading(false);
    });
  }, [axios, user]);

  /**
   * Helper to store updated data to server
   */
  const updateDataApi = (): void => {
    toggleUpdating(true);
    updateDataApiForCompany(axios, selectedDataApi!).then((success) => {
      if (success) {
        let foundIndex: number = loadedDataApi.findIndex(
          (item) => item.id === selectedDataApi?.id
        );
        if (foundIndex === -1) {
          generateNotification(
            t("administration.apidata.notification.wrongUpdateIndex"),
            "danger"
          );
          return;
        }
        loadedDataApi[foundIndex] = selectedDataApi!;
        setLoadedDataApi([...loadedDataApi]);
        setSelectedDataApi(undefined);
      } else
        generateNotification(
          t("administration.apidata.notification.wrongUpdate"),
          "danger"
        );
      toggleUpdating(false);
    });
  };

  /**
   * Generate the needed dataApi entries for the table component
   */
  const dataApiEntries = useMemo(
    () =>
      loadedDataApi.map((curDataApi) => ({
        content: [
          curDataApi.key,
          curDataApi.displayName ?? "-",
          curDataApi.groups.length,
          <EditIcon onClick={() => setSelectedDataApi(curDataApi)} />,
        ],
        id: curDataApi.id ?? curDataApi.key,
      })) ?? [],
    [loadedDataApi]
  );

  return (
    <>
      <PopupComponent
        isOpen={!!selectedDataApi}
        toggleOpen={() => setSelectedDataApi(undefined)}
      >
        <DataApiEditForm
          setDataApi={setSelectedDataApi}
          dataApi={selectedDataApi!}
          isLoading={isUpdating}
          onSubmit={updateDataApi}
        />
      </PopupComponent>
      <div className="default-admin">
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <TableComponent
            header={t("administration.apidata.tableHeader", {
              returnObjects: true,
            })}
            listEntries={dataApiEntries}
            customTag={[{ flex: 4 }, { flex: 2 }, { flex: 1 }]}
            searchPlaceholder={t("administration.apidata.searchPlaceholder")}
            searchButtonTitle={t("general.buttons.search")}
            showIndex
            searchable
          />
        )}
      </div>
    </>
  );
};
