import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextInputComponent } from "sfm-component-library";
import { Company } from "../../utils/company/Company.types";
import { useOnScreen } from "../../utils/useOnScreen";
import { RegisterTab, User } from "../../utils/user/User.types";

interface RegisterFormTabProps {
  inputFields: (keyof Company | keyof User)[];
  tab: RegisterTab;
  currentVisibleTab: RegisterTab;
  user: User;
  onUserChange(key: string, value: string, tab: RegisterTab): void;
  onScrollTabChange(tab: RegisterTab): void;
}

const RegisterFormTab: FC<RegisterFormTabProps> = ({
  inputFields,
  tab,
  currentVisibleTab,
  user,
  onUserChange,
  onScrollTabChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  /**
   * scrolls to Tab, when currentVisibleTab is this Tab
   */
  useEffect(() => {
    if (ref.current && currentVisibleTab === tab) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [currentVisibleTab, tab]);

  /**
   *listens to scroll and changes passes visible Tab to parent
   */
  useEffect(
    () => (isVisible ? onScrollTabChange(tab) : () => {}),
    [isVisible, tab, onScrollTabChange]
  );

  return (
    <div className={"register__form-tab"} ref={ref}>
      <div>
        <p> {t(`${tab.toLowerCase()}.title`)}</p>
        {inputFields.map((key) => (
          <TextInputComponent
            required
            placeholder={t(`${tab.toLowerCase()}.${key}`)}
            value={
              (tab === RegisterTab.COMPANY
                ? (user.company![key as keyof Company] as string)
                : (user[key as keyof User] as string)) || ""
            }
            onChange={(newValue) => onUserChange(key, newValue, tab)}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};

export default RegisterFormTab;
