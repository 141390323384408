import { useKeycloak } from "@react-keycloak/web";
import { isEqual } from "lodash";
import { useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../../pages/App";
import { useAxios } from "../AxiosUtil";
import { Notification } from "./Notification.types";

/**
 * SWR Hook - to fetch all the latest notifications
 * @returns a list of {@link Notification} and a count ,when successful, else returns an empty list
 */
export const useNotification = (): {
  notifications: Notification[];
  notificationCount: number;
  isLoading: boolean;
  isError: any;
  mutate(notifications: Notification[]): void;
} => {
  const { user } = useContext(UserContext);
  const { axios, fetcher } = useAxios();
  const { keycloak } = useKeycloak();
  const { data, error, mutate } = useSWR(
    !!axios && user && !keycloak.isTokenExpired()
      ? `/data/notification/id/?userId=${user.id}`
      : null,
    fetcher,
    {
      compare: (
        oldNotifications: Notification[] | undefined,
        newNotifications: Notification[] | undefined
      ) => isEqual(oldNotifications, newNotifications),
    }
  );

  return {
    notifications: data || [],
    notificationCount:
      data?.filter((entry) => !entry.seenByUserIds.includes(user?.id || ""))
        .length || 0,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
