import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LayoutComponent, LoaderComponent } from "sfm-component-library";
import CenterHeaderButton from "../../components/centerheaderbutton/CenterHeaderButton";
import ShopFloorBoard from "../../components/shopfloorboard/ShopFloorBoard";
import "../../styles/ShopfloorBoardStyle.scss";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { ShopfloorBoardConfiguration } from "../../utils/sfboard/SfBoard.types";

interface ShopfloorBoardPageProps {}

const ShopfloorBoardPage: React.FC<ShopfloorBoardPageProps> = () => {
  const history = useHistory();
  const location = useLocation<{ configId: string; itemId: string }>();
  const [config, setConfig] = useState<ShopfloorBoardConfiguration>();
  /**
   * Checks for needed config or redirects to overview page
   */
  useEffect(() => {
    if (!location.state?.configId) history.push("/sfboard");
    // eslint-disable-next-line
  }, [location.state?.configId]);
  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.SHOPFLOORBOARD, false)}
      title={
        <CenterHeaderButton
          title={config?.name || "..."}
          onClick={() => history.push("/sfboard")}
        />
      }
    >
      {location.state?.configId ? (
        <ShopFloorBoard
          configId={location.state?.configId}
          initiallyOpenTaskId={location.state?.itemId}
          onConfigLoaded={setConfig}
        />
      ) : (
        <LoaderComponent />
      )}
    </LayoutComponent>
  );
};
export default ShopfloorBoardPage;
