import { FeatureModule } from "../license/License.types";
import { FileEntry } from "../tasks/Tasks.types";
import { v4 as uuidv4 } from "uuid";

/**
 * The company object as it is saved on the backend database
 */
export interface Company {
  id?: string;
  createDate?: Date;
  lastUpdated?: Date;
  name: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  lon: number;
  lat: number;
  license: License;
  enabled: boolean;
  logo?: FileEntry;
  apiKeys: ApiKey[];
  pending: boolean;
}

/**
 * Model of an {@link ApiKey} which allows access to store data on the Server
 */
export interface ApiKey {
  disabled?: boolean;
  validUntil?: Date;
  key: string;
  displayName: string;
  description?: string;
  [key: string]: string | Date | boolean | undefined;
}

/**
 * Helper to generate an empty {@link ApiKey}
 * @returns empty {@link ApiKey}
 */
export const createEmptyApiKey = (): ApiKey => ({
  displayName: "",
  key: uuidv4(),
});

/**
 * represents the license object in the. backend. Holds the features, that a company has enabled
 */
export interface License {
  features: LicenseModule[];
}

/**
 * represents a feature, that a company has enabled
 */
export interface LicenseModule {
  feature: FeatureModule;
  activatedAt: Date;
}
