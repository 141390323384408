import { FeatureModule } from "../license/License.types";

export interface Task {
  id?: string;
  version: number;
  createDate: Date;
  lastUpdated?: Date;
  featureModule?: FeatureModule;
  companyId: string;
  shopfloorBoardId: string;
  shopfloorBoardColumnId: string;
  action: string;
  title: string;
  description: string;
  status?: TaskStatus;
  respUserId: string;
  targetDate?: Date;
  creatorUserId: string;
  priority: number;
  active: boolean;
  pdcaBallStatus: number;
  fileEntries: FileEntry[];
  archived?: boolean;
}

/**
 * creates an empty Task
 * @returns empty Task
 */
export const createEmptyTask = (
  shopfloorBoardId: string,
  companyId: string,
  creatorUserId: string,
  columnId?: string
): Task => ({
  version: 0,
  companyId: companyId,
  shopfloorBoardId: shopfloorBoardId,
  shopfloorBoardColumnId: columnId || "",
  createDate: new Date(),
  action: "",
  title: "",
  description: "",
  respUserId: "",
  priority: 0,
  active: true,
  creatorUserId: creatorUserId,
  pdcaBallStatus: 0,
  fileEntries: [],
});

export enum TaskStatus {
  FINISHED_SUCCESSFUL = "FINISHED_SUCCESSFUL",
  FINISHED_NOT_SUCCESSFUL = "FINISHED_NOT_SUCCESSFUL",
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  DELAYED = "DELAYED",
}

export enum FileType {
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  LOGO = "LOGO",
}
/**
 * This interface decribes the post form data, for saving files
 */
export interface TaskFileRequest {
  taskId: string;
  userId: string;
  fileType: FileType;
}

/**
 * representation of the FileEntry Object inside the data-service
 */
export interface FileEntry {
  createDate: Date;
  lastUpdated: Date;
  createdBy: string;
  fileSize: number;
  fileName: string;
  originalFileName: string;
  type: FileType;
  file?: File;
}
