import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  BoxComponent,
  BoxType,
  LayoutComponent,
  PopupComponent,
} from "sfm-component-library";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list-solid.svg";
import { ReactComponent as GridIcon } from "../../assets/icons/grip-solid.svg";
import { BoardEditForm } from "../../components/shopfloorboard/board/BoardEditForm";
import "../../styles/ShopfloorBoardStyle.scss";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import {
  BoardCopyRequest,
  ShopfloorBoardConfiguration,
} from "../../utils/sfboard/SfBoard.types";
import { useSimpleShopfloorBoardConfiguration } from "../../utils/sfboard/useSimpleShopfloorBoardConfiguration";
import { UserAction } from "../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { UserContext } from "../App";
import { generateListEntries } from "../../utils/groupSort/GroupList.utils";
import { useGroups } from "../../utils/group/Group.utils";
import { ReactComponent as PinAddIcon } from "../../assets/icons/pin_add.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/clone.svg";
import { BoardCopyForm } from "../../components/shopfloorboard/board/BoardCopyForm";
import { useAxios } from "../../utils/AxiosUtil";
import { copyShopfloorBoardConfig } from "../../utils/sfboard/SfBoardOverview.utils";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";

interface ShopfloorBoardPageProps {}

const ShopfloorBoardOverviewPage: React.FC<ShopfloorBoardPageProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const { shopfloorBoardConfigurations: loadedConfigs, mutate: setConfigs } =
    useSimpleShopfloorBoardConfiguration();
  const [configToEdit, setConfigToEdit] =
    useState<ShopfloorBoardConfiguration | null>();
  const [configToCopy, setConfigToCopy] =
    useState<ShopfloorBoardConfiguration>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isListView, toggleListView] = useState<boolean>(false);
  const loadedGroups = useGroups() ?? [];

  /**
   * Helper to copy a full board.
   *
   * @param copy the copy request
   * @returns promised void to detect when process is finished
   */
  const onCopyBoard = async (copy: BoardCopyRequest): Promise<void> => {
    try {
      const result = await copyShopfloorBoardConfig(copy, axios);
      if (!result) {
        generateNotificationWithTranslations("warning");
        return;
      }
      setConfigs([...loadedConfigs, result]);
      generateNotificationWithTranslations("success");
      setConfigToCopy(undefined);
    } catch {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * Method to determine which view should be displayed
   * @param listView boolean that determines the type of view
   * @param configs ShopfloorBoardConfiguration[] that contains entries
   * @param givenUser User object
   * @param givenHistory History used to push
   * @param givenGroups Group[] to determine names for list-view
   * @returns JSX.Element containing the chosen view
   */
  const getCorrectEntryView = (listView: boolean): JSX.Element => {
    switch (listView) {
      case true:
        return (
          <div className="shopfloor-dashboard-wrapper--list">
            {generateListEntries<ShopfloorBoardConfiguration>({
              type: "ShopfloorBoard",
              user,
              history,
              entries: loadedConfigs,
              loadedGroups,
              setConfigToEdit,
              setConfigToCopy,
            })}
          </div>
        );
      case false:
      default:
        return (
          <div className="shopfloor-dashboard-wrapper--grid">
            {loadedConfigs.map((config, index) => (
              <BoxComponent
                background={config.color}
                type={BoxType.PACKAGE}
                key={`box-config-component-item-${index}`}
                onClick={() =>
                  history.push(`/sfboard/detail`, {
                    configId: config.id,
                  })
                }
                title={config.name}
                onClickConfig={() => {
                  if (isUserAllowedToDoThis(UserAction.EDIT_BOARD, user)) {
                    setConfigToEdit(config);
                  }
                }}
                hideConfig={!isUserAllowedToDoThis(UserAction.EDIT_BOARD, user)}
                buttons={[
                  {
                    icon: <CopyIcon />,
                    hidden: !isUserAllowedToDoThis(
                      UserAction.CREATE_BOARD,
                      user
                    ),
                    onClick: () => setConfigToCopy(config),
                  },
                  {
                    icon: <PinAddIcon />,
                    hidden: !isUserAllowedToDoThis(
                      UserAction.CREATE_TASK,
                      user
                    ),
                    onClick: () =>
                      history.push("/sfboard/task", { configId: config.id }),
                  },
                ]}
              />
            ))}
          </div>
        );
    }
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.SHOPFLOORDASHBOARD)}
      title={t("shopfloorboard.title")}
    >
      <div className="shopfloor-dashboard-wrapper">
        {configToEdit !== undefined && (
          <PopupComponent
            isOpen
            title={
              configToEdit === null
                ? t(`shopfloorboard.config.edit.titleNew`)
                : t(`shopfloorboard.config.edit.title`)
            }
            toggleOpen={() => setConfigToEdit(undefined)}
            footerButtons={[
              {
                title: t("general.buttons.cancel"),
                onClick: () => setConfigToEdit(undefined),
                borderColor: "black",
                className: "color-white",
                isLoading: isLoading,
              },
              {
                title: t("general.buttons.save"),
                borderColor: "#A9FAA2",
                type: "submit",
                form: "board-edit-form",
                isLoading: isLoading,
              },
            ]}
          >
            <BoardEditForm
              boardConfig={configToEdit || undefined}
              isLoading={isLoading}
              onSaved={(savedConfig) => {
                if (savedConfig.id) {
                  setConfigs([
                    ...loadedConfigs.filter(
                      (item) => item.id !== savedConfig.id
                    ),
                    savedConfig,
                  ]);
                } else {
                  setConfigs([...loadedConfigs, savedConfig]);
                }
                setConfigToEdit(undefined);
              }}
              onDeleted={(deletedConfig) => {
                setConfigs([
                  ...loadedConfigs.filter(
                    (item) => item.id !== deletedConfig.id
                  ),
                ]);
                setConfigToEdit(undefined);
              }}
              onLoadingChange={setLoading}
            />
          </PopupComponent>
        )}
        {configToCopy && (
          <PopupComponent
            isOpen
            title={t("shopfloorboard.config.copy.title", {
              replace: { name: configToCopy.name },
            })}
            toggleOpen={() => setConfigToCopy(undefined)}
            footerButtons={[
              {
                title: t("general.buttons.cancel"),
                onClick: () => setConfigToCopy(undefined),
                borderColor: "black",
                className: "color-white",
                isLoading: isLoading,
              },
              {
                title: t("general.buttons.copy"),
                borderColor: "#A9FAA2",
                type: "submit",
                form: "board-copy-form",
                isLoading: isLoading,
              },
            ]}
          >
            <BoardCopyForm
              board={configToCopy}
              onCopy={async (copy) => {
                setLoading(true);
                await onCopyBoard(copy);
                setLoading(false);
              }}
            />
          </PopupComponent>
        )}
        <div className="shopfloor-dashboard-wrapper--adding">
          <div className="power-bi__overview--adding-view">
            <GridIcon
              className={["view-svg", !isListView ? "active" : undefined].join(
                " "
              )}
              onClick={() => toggleListView(false)}
            />
            <ListIcon
              className={[
                "view-svg",
                "list-icon",
                isListView ? "active" : undefined,
              ].join(" ")}
              onClick={() => toggleListView(true)}
            />
          </div>
          {isUserAllowedToDoThis(UserAction.CREATE_BOARD, user) && (
            <AddIcon onClick={() => setConfigToEdit(null)} />
          )}
        </div>
        {loadedConfigs.length > 0 ? (
          getCorrectEntryView(isListView)
        ) : (
          <p>{t("shopfloorboard.config.notFound")}</p>
        )}
      </div>
    </LayoutComponent>
  );
};
export default ShopfloorBoardOverviewPage;
