import { useTranslation } from "react-i18next";
import { ButtonComponent, TextInputComponent } from "sfm-component-library";
import { useGroups } from "../../../utils/group/Group.utils";
import { GroupSelect } from "../groupselect/GroupSelect";
import { DataApiEditFormProps } from "./DataApiEditForm.types";

export const DataApiEditForm: React.FC<DataApiEditFormProps> = ({
  setDataApi,
  dataApi,
  isLoading,
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();
  const groups = useGroups();

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        onSubmit();
      }}
    >
      <p>
        {t("administration.apidataForm.key")} <strong>{dataApi.key}</strong>
      </p>
      <TextInputComponent
        label={t("administration.apidataForm.displayName")}
        value={dataApi.displayName}
        onChange={(value) => setDataApi({ ...dataApi, displayName: value })}
      />
      <TextInputComponent
        type="multiline"
        label={t("administration.apidataForm.description")}
        value={dataApi.description}
        onChange={(value) => setDataApi({ ...dataApi, description: value })}
      />
      <p>{t("administration.apidataForm.groupSelect")}</p>
      <GroupSelect
        assignedGroups={dataApi.groups}
        groups={groups ?? []}
        onUpdate={(newGroups) => {
          setDataApi({ ...dataApi, groups: newGroups });
        }}
        dropdownPortal={document.body}
      />
      <ButtonComponent
        title={t("general.buttons.save")}
        isLoading={isLoading}
        type="submit"
      />
    </form>
  );
};
