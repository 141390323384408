import { LayoutComponent } from "sfm-component-library";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";

interface LpaPageProps {}

const LpaPage: React.FC<LpaPageProps> = () => {
  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.LPA)}
      title="LPA Page"
    >
      <h1>LPA Page</h1>
    </LayoutComponent>
  );
};

export default LpaPage;
