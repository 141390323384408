import { Company } from "../company/Company.types";
import { FeatureModule } from "../license/License.types";
import { DashboardConfiguration } from "./Dashboard.types";

/**
 *  The user model with all configurations (including the complete dashboard)
 */
export interface User {
  id?: string;
  createDate?: Date;
  lastUpdated?: Date;
  enabled: boolean;
  companyId: string;
  role: UserRole;
  password?: string;
  username: string;
  firstname: string;
  lastname: string;
  mail: string;
  company?: Company;
  groups: string[];
  dashboard: DashboardConfiguration;
  latestSessionId?: string;
  lastLogin?: Date;
  loginAmount?: number;
}

/**
 * The role which a user belongs to
 */
export enum UserRole {
  PLATFORM_ADMIN = "PLATFORM_ADMIN",
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
  VISITOR = "VISITOR",
}

/**
 * A list of actions a user can perform. Used as a simple permission system.
 */
export enum UserAction {
  CREATE_BOARD = "CREATE_BOARD",
  EDIT_BOARD = "EDIT_BOARD",
  DELETE_BOARD = "DELETE_BOARD",

  CREATE_COLUMN = "CREATE_COLUMN",
  EDIT_COLUMN = "EDIT_COLUMN",
  DELETE_COLUMN = "DELETE_COLUMN",
  SHOW_ARCHIVE = "SHOW_ARCHIVE",

  UPDATE_PERFORMANCE_ENTRY = "UPDATE_PERFORMANCE_ENTRY",

  CREATE_TASK = "CREATE_TASK",
  EDIT_TASK = "EDIT_TASK",
  DELETE_TASK = "DELETE_TASK",

  CREATE_POWERBI_CONFIG = "CREATE_POWERBI_CONFIG",
  EDIT_POWERBI_CONFIG = "EDIT_POWERBI_CONFIG",
  DELETE_POWERBI_CONFIG = "DELETE_POWERBI_CONFIG",

  CREATE_ENERGY_CONFIG = "CREATE_ENERGY_CONFIG",
  EDIT_ENERGY_CONFIG = "EDIT_ENERGY_CONFIG",
  DELETE_ENERGY_CONFIG = "DELETE_ENERGY_CONFIG",

  CREATE_PROTOCOL_CONFIG = "CREATE_PROTOCOL_CONFIG",
  EDIT_PROTOCOL_CONFIG = "EDIT_PROTOCOL_CONFIG",
  DELETE_PROTOCOL_CONFIG = "DELETE_PROTOCOL_CONFIG",
  EXPORT_PROTOCOL_CONFIG = "EXPORT_PROTOCOL_CONFIG",

  VIEW_FILLED_PROTOCOL = "VIEW_FILLED_PROTOCOL",
}

/**
 * Helper to Check which Tab is active for registration process
 */
export enum RegisterTab {
  PROFILE = "PROFILE",
  COMPANY = "COMPANY",
}

/**
 * Helper to create a {@link User} for registration
 *
 * @param companyId
 * @returns a new user ready for registration
 */
export const createEmptyUserForRegistration = (): User => ({
  createDate: new Date(),
  enabled: true,
  companyId: "",
  role: UserRole.ADMIN,
  username: "",
  firstname: "",
  lastname: "",
  mail: "",
  company: {
    lat: 0.0,
    lon: 0.0,
    apiKeys: [],
    license: {
      features: [
        { feature: FeatureModule.SHOPFLOOR_BOARD, activatedAt: new Date() },
      ],
    },
    enabled: true,
    city: "",
    country: "",
    name: "",
    street: "",
    zip: "",
    createDate: new Date(),
    pending: true,
  },
  groups: [],
  dashboard: { widgets: [], updatedAt: new Date() },
});

/**
 * Helper to create an user for internal working.
 *
 * @param companyId
 * @returns defautl empty user
 */
export const createEmptyUser = (companyId: string): User => ({
  createDate: new Date(),
  enabled: true,
  companyId: companyId,
  role: UserRole.VISITOR,
  username: "",
  firstname: "",
  lastname: "",
  mail: "",
  groups: [],
  dashboard: { widgets: [], updatedAt: new Date() },
});
