import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxComponent, TextInputComponent } from "sfm-component-library";
import { useGroups } from "../../../utils/group/Group.utils";
import { ShopfloorBoardConfiguration } from "../../../utils/sfboard/SfBoard.types";
import { GroupSelect } from "../../administration/groupselect/GroupSelect";

export interface BoardEditFormGeneralTabProps {
  config: ShopfloorBoardConfiguration;
  setConfig(config: ShopfloorBoardConfiguration): void;
}

export const BoardEditFormGeneralTab: FC<BoardEditFormGeneralTabProps> = ({
  config,
  setConfig,
}) => {
  const { t } = useTranslation();
  const groups = useGroups();

  return (
    <>
      <TextInputComponent
        label={t("shopfloorboard.config.create.boardName")}
        value={config.name ?? ""}
        onChange={(name) => setConfig({ ...config, name })}
        required
      />
      <TextInputComponent
        label={t("shopfloorboard.config.create.boardColor")}
        value={config.color ?? ""}
        onChange={(color) => setConfig({ ...config, color })}
        type="color"
      />
      <hr />
      <p>{t("administration.group.title")}</p>
      <GroupSelect
        assignedGroups={config.groups}
        groups={groups ?? []}
        onUpdate={(newGroups) => {
          setConfig({ ...config, groups: newGroups });
        }}
        dropdownPortal={document.body}
      />
      <CheckboxComponent
        checked={config.showGroupsOnly ?? false}
        onCheck={(showGroupsOnly) => setConfig({ ...config, showGroupsOnly })}
        value={t("administration.group.showGroupsOnly")}
      />
    </>
  );
};
