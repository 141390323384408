import React from "react";
import LandingPageImage from "./../assets/images/landing.jpg";
import { ReactComponent as DuckeneersLogo } from "./../assets/icons/duckeneers.svg";
import "../styles/LandingPageStyles.scss";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { ButtonComponent } from "sfm-component-library";
import { useHistory } from "react-router-dom";

const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const history = useHistory();

  return (
    <div className="landing-page">
      <div className="clipped-wrapper">
        <div className="clipped">
          <img src={LandingPageImage} alt="landing" />
        </div>
      </div>
      <div className="landing-page__user-action__wrapper">
        <div className="landing-page__user-action__image-container">
          <DuckeneersLogo />
        </div>
        <div className="landing-page__user-action__button-container">
          {keycloak.authenticated ? (
            <>
              <ButtonComponent
                title={t("general.pages.dashboard")}
                onClick={() => history.push("/dashboard")}
                borderColor="black"
                className={"color-white"}
              />
              <ButtonComponent
                title={t("general.buttons.logout")}
                onClick={() => keycloak.logout()}
                borderColor="black"
                className={"color-white"}
              />
            </>
          ) : (
            <>
              <ButtonComponent
                title={t("general.buttons.register")}
                onClick={() => history.push("/register")}
                borderColor="black"
                className={"color-white"}
              />
              <ButtonComponent
                title={t("general.buttons.login")}
                onClick={() => keycloak.login()}
                borderColor="black"
                className={"color-white"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
