import { AxiosInstance } from "axios";
import { BoardCopyRequest } from "../sfboard/SfBoard.types";
import { PowerBIConfiguration } from "./PowerBI.types";
import { DeletionRequest } from "../apidata/DataApi.types";

/**
 * API METHOD - fetches all Power BI Board configs for a company
 *
 * @param companyId
 * @param axios
 * @returns list of configs
 */
export const loadAllPowerBIConfigsForCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<PowerBIConfiguration[]> => {
  return axios
    .get("/data/config/powerbi/company/", { params: { companyId: companyId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during PowerBI config load for company", exc);
      return [];
    });
};

/**
 * API METHOD - creates a new Power BI Board config for a company
 *
 * @param newConfig the config to create
 * @param axios the axios instance
 * @returns the newly created {@link PowerBIConfiguration}
 */
export const createNewPowerBIConfig = async (
  newConfig: PowerBIConfiguration,
  axios: AxiosInstance
): Promise<PowerBIConfiguration> => {
  return axios
    .post("/data/config/powerbi/", newConfig)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during PowerBI config upload", exc);
      return null;
    });
};

/**
 * API METHOD - copy a Power BI Board config by id
 *
 * @param copy the copy request
 * @param axios the axios instance
 * @returns the newly copied {@link PowerBIConfiguration}
 */
export const copyPowerBIConfig = async (
  copy: BoardCopyRequest,
  axios: AxiosInstance
): Promise<PowerBIConfiguration | undefined> => {
  return axios
    .post("/data/config/powerbi/copy/", copy)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during PowerBI config upload", exc);
      return undefined;
    });
};

/**
 * API METHOD - loads a Power BI Board config for a company
 *
 * @param configId
 * @param axios
 * @returns the loaded {@link PowerBIConfiguration}
 */
export const loadPowerBIConfig = async (
  configId: string,
  axios: AxiosInstance
): Promise<PowerBIConfiguration> => {
  return axios
    .get("/data/config/powerbi/id/", {
      params: { configId: configId },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during PowerBI config fetch", exc);
      return null;
    });
};

/**
 * API METHOD - deletes a Power BI Board config for a company
 *
 * @param deletionRequest containing relevant info for deletion
 * @param axios
 * @returns boolean if it was successful
 */
export const deletePowerBIConfig = async (
  deletionRequest: DeletionRequest,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/config/powerbi/delete/", deletionRequest)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during PowerBI config upload", exc);
      return false;
    });
};

/**
 * API METHOD - updates an existing Power BI Board config column for a company
 *
 * @param updatedConfig
 * @param axios
 * @returns boolean if it was successful
 */
export const updatePowerBIConfig = async (
  updatedConfig: PowerBIConfiguration,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/config/powerbi/update/", updatedConfig)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during config update!", exc);
      return false;
    });
};

/**
 * API METHOD - for loading the count of all power bis by company
 *
 * @param companyId the company id
 * @param userId the user id
 * @param axios the axios instance
 * @returns number of power bis
 */
export const loadCountOfAllPowerBiCompany = async (
  companyId: string,
  userId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get("/data/config/powerbi/company/count/", {
      params: { companyId, userId },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during power bi count fetch!", exc);
      return -1;
    });
};
