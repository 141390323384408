import { useKeycloak } from "@react-keycloak/web";
import { useContext, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { LoaderComponent } from "sfm-component-library";
import { UserContext } from "../pages/App";
import { FeatureModule } from "./license/License.types";
import { UserRole } from "./user/User.types";

export const PrivateRoute: React.FC<{
  Page: React.ComponentType<any>;
  path: string;
  feature?: FeatureModule;
}> = ({ Page, path, feature }) => {
  const { user, isLoading } = useContext(UserContext);
  const { initialized, keycloak } = useKeycloak();
  const history = useHistory();

  /**
   * checks if current user is allowed to visit the given page
   * @returns returns false if keycloak is not initialized,authenticated or user not defined/enabled.
   * If user is enabled and role is {@link UserRole.PLATFORM_ADMIN}, returns true.
   * If feature is not defined or user licenses includes feature, reutrns true.
   * Else returns false
   */
  const checkIfUserIsAllowedToVisit = (): boolean => {
    if (
      !initialized ||
      !keycloak ||
      !keycloak.authenticated ||
      !user ||
      !user.enabled
    )
      return false;
    if (user.role === UserRole.PLATFORM_ADMIN) return true;
    if (
      !feature ||
      !!user.company?.license.features.find(
        (licenseModuleToFind) => licenseModuleToFind.feature === feature
      )
    )
      return true;
    return false;
  };

  /**
   * In case user just got autheticated and is on the landing page, redirect to dashboard
   */
  useEffect(() => {
    if (
      keycloak.authenticated &&
      !!history &&
      history.location.pathname === "/" &&
      path === "/dashboard"
    )
      history.push("/dashboard");
  }, [keycloak.authenticated, history, path]);
  return (
    <Route path={path} exact>
      {!isLoading ? (
        checkIfUserIsAllowedToVisit() ? (
          <Page />
        ) : (
          <Redirect to="/" />
        )
      ) : (
        <LoaderComponent isFullscreen />
      )}
    </Route>
  );
};
