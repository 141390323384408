import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  LayoutComponent,
  LoaderComponent,
  TableComponent,
} from "sfm-component-library";
import { useAxios } from "../../utils/AxiosUtil";
import { Company } from "../../utils/company/Company.types";
import {
  fetchAllCompanies,
  mapLicensesToIcons,
} from "../../utils/company/CompanyUtils";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import "./../../styles/CompanyOverviewPageStyles.scss";

interface CompanyOverviewPageProps {}

const CompanyOverviewPage: FC<CompanyOverviewPageProps> = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();

  const [companiesAreFetching, setCompaniesAreFetching] =
    useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const history = useHistory();
  /**
   * fetches companies and saves them in state
   */
  useEffect(() => {
    if (!axios) return;
    setCompaniesAreFetching(true);

    fetchAllCompanies(axios).then((fetchedCompanies) => {
      setCompanies(fetchedCompanies);
      setCompaniesAreFetching(false);
    });
  }, [axios]);

  /**
   *
   * @returns TableComponent with Companies, when fetch is finished, else returns a LoaderComponent
   */
  const renderCompanyTable = (): JSX.Element =>
    companiesAreFetching ? (
      <LoaderComponent />
    ) : (
      <TableComponent
        listEntries={companies.map((company: Company) => ({
          content: [
            company.name,
            company.city,
            company.country,
            <div className="company-overview__license-wrapper">
              {mapLicensesToIcons(company.license)}
            </div>,
          ],
          id: company.id!,
        }))}
        customTag={[
          { flex: 8 },
          { color: "black", flex: 4 },
          { flex: 3 },
          { flex: 2 },
        ]}
        onClick={(id) => history.push(`/admin/company/${id}`)}
        searchPlaceholder={t("general.searchPlaceholder")}
        searchButtonTitle={t("general.buttons.search")}
        showIndex
        searchable
      />
    );

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.PLATFORMADMIN_COMPANY_PANEL)}
    >
      {renderCompanyTable()}
    </LayoutComponent>
  );
};

export default CompanyOverviewPage;
