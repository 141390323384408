import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  TextInputComponent,
} from "sfm-component-library";
import { DataApi } from "../../../utils/apidata/DataApi.types";
import {
  ConnectorType,
  defaultAvgColumnConfiguration,
  ShopfloorBoardColumn,
} from "../../../utils/sfboard/SfBoard.types";
import { generateConnectorTypeOptions } from "../../../utils/sfboard/SfBoardColumn.utils";

interface ColumnEditFormGeneralTabProps {
  file?: File;
  column: ShopfloorBoardColumn;
  dataApis: DataApi[];
  localFileUploading: boolean;
  onColumnChange(column: ShopfloorBoardColumn): void;
  onFileChange(file: File): void;
  onConnectorChange?(column: ShopfloorBoardColumn): void;
  portal?: HTMLElement | null;
}

const ColumnEditFormGeneralTab: FC<ColumnEditFormGeneralTabProps> = ({
  onColumnChange,
  onFileChange,
  file,
  column,
  dataApis,
  localFileUploading,
  portal,
}) => {
  const { t } = useTranslation();

  /**
   * add default avgCountConfig if no config exists.
   */
  useEffect(() => {
    if (!column.avgCountConfig)
      onColumnChange({
        ...column,
        avgCountConfig: defaultAvgColumnConfiguration,
      });
    // eslint-disable-next-line
  }, []);

  /**
   * Helper to generate all needed inputs and options for a specific connectorType
   *
   * @param type {@link ConnectorType} for the switch
   * @returns JSX.Element
   */
  const generateConnectorInputs = (type: ConnectorType): JSX.Element => {
    switch (type) {
      case ConnectorType.MANUAL:
        return (
          <TextInputComponent
            onChange={(newValue) =>
              onColumnChange({ ...column, targetValue: +newValue })
            }
            defaultValue={column.targetValue}
            step="any"
            type="number"
            label={t("shopfloorboard.column.targetValue")}
          />
        );
      case ConnectorType.CSV_IMPORT:
        return (
          <ButtonComponent
            title={file?.name || t("general.buttons.upload")}
            type="file"
            accept="text/csv"
            isLoading={localFileUploading}
            setFiles={(files) => onFileChange(files[0])}
          />
        );
      case ConnectorType.DATA_API:
        return (
          <>
            <DropdownComponent
              label={t("enum.connectorType.DATA_API")}
              onChange={(dataApi) => {
                onColumnChange({
                  ...column,
                  connectorInfo: {
                    ...column.connectorInfo,
                    dataApi: {
                      ...column.connectorInfo?.dataApi,
                      id: dataApi.value,
                    },
                  },
                });
              }}
              options={dataApis.map((data) => ({
                label: data.displayName || data.key,
                value: data.id!,
              }))}
              selectedOption={column.connectorInfo?.dataApi?.id ?? ""}
              required
            />
            <TextInputComponent
              type="number"
              label={t("shopfloorboard.column.edit.defaultTarget")}
              value={
                column.connectorInfo?.dataApi?.defaultTarget ??
                column.targetValue ??
                0
              }
              onChange={(value) =>
                onColumnChange({
                  ...column,
                  connectorInfo: {
                    ...column.connectorInfo,
                    dataApi: {
                      ...column.connectorInfo?.dataApi,
                      defaultTarget: parseFloat(value),
                    },
                  },
                })
              }
            />
          </>
        );
      default:
        return <p>{t("shopfloorboard.column.noConnector")}</p>;
    }
  };
  return (
    <>
      <TextInputComponent
        onChange={(newValue) => onColumnChange({ ...column, name: newValue })}
        value={column.name}
        label={t("shopfloorboard.column.name")}
      />
      <TextInputComponent
        onChange={(newValue) => onColumnChange({ ...column, unit: newValue })}
        value={column.unit}
        label={t("shopfloorboard.column.unit")}
      />
      <DropdownComponent
        required
        selectedOption={column.connectorType}
        onChange={(option) => {
          onColumnChange({
            ...column,
            connectorType: option.value as ConnectorType,
          });
        }}
        label={t("shopfloorboard.column.connectorType")}
        options={generateConnectorTypeOptions()}
        portal={portal ?? undefined}
      />
      {generateConnectorInputs(column.connectorType)}
      <hr />
      <p className={"shopfloor-column__general-tab__avg-title"}>
        {t("shopfloorboard.column.avgConfig.title")}
      </p>
      <TextInputComponent
        onChange={(newValue) =>
          onColumnChange({
            ...column,
            avgCountConfig: {
              ...column.avgCountConfig!,
              datesToCount: parseInt(newValue) || 0,
            },
          })
        }
        type="number"
        value={column.avgCountConfig?.datesToCount || 0}
        label={t("shopfloorboard.column.avgConfig.datesToCount")}
      />
      <div className={"shopfloor-column__general-tab__max-fail-count__wrapper"}>
        <TextInputComponent
          onChange={(newValue) =>
            onColumnChange({
              ...column,
              avgCountConfig: {
                ...column.avgCountConfig!,
                maxFailCount: parseInt(newValue) || 0,
              },
            })
          }
          disabled={!column.avgCountConfig?.enableMaxFailCount}
          value={column.avgCountConfig?.maxFailCount || 0}
          label={t("shopfloorboard.column.avgConfig.maxFailCount")}
        />

        <CheckboxComponent
          onCheck={(checked) =>
            onColumnChange({
              ...column,
              avgCountConfig: {
                ...column.avgCountConfig!,
                enableMaxFailCount: checked,
              },
            })
          }
          value={""}
          checked={!!column.avgCountConfig?.enableMaxFailCount}
        />
      </div>

      <CheckboxComponent
        onCheck={(checked) =>
          onColumnChange({
            ...column,
            avgCountConfig: {
              ...column.avgCountConfig!,
              countWeekends: checked,
            },
          })
        }
        value={t("shopfloorboard.column.avgConfig.countWeekends")}
        checked={!!column.avgCountConfig?.countWeekends}
      />
    </>
  );
};

export default ColumnEditFormGeneralTab;
