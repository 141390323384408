import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  ColorSliderComponent,
  LoaderComponent,
  TextInputComponent,
} from "sfm-component-library";
import {
  defaultColumnColor,
  ShopfloorBoardColumn,
} from "../../../utils/sfboard/SfBoard.types";
import {
  updateColumnAddThumb,
  updateColumnChangeMaxValue,
  updateColumnCheckIsPercentage,
  updateColumnRemoveThumb,
  updateColumnUpdateColor,
  updateColumnUpdateThumb,
} from "../../../utils/sfboard/SfBoardColumn.utils";

interface ColumnEditFormVisualTabProps {
  column: ShopfloorBoardColumn;
  onColumnChange(column: ShopfloorBoardColumn): void;
  popupContentElement: HTMLElement | null;
}

const ColumnEditFormVisualTab: FC<ColumnEditFormVisualTabProps> = ({
  column,
  onColumnChange,
  popupContentElement,
}) => {
  const { t } = useTranslation();

  /**
   * if no visual settings are defined yet, sets the default settings
   */
  useEffect(() => {
    if (!column.visualConfig) {
      onColumnChange({ ...column, visualConfig: defaultColumnColor });
    }
    // eslint-disable-next-line
  }, []);

  /**
   *
   * @returns if column is set to percentage, returns 100, else if maxValue in Visual Configuaration is defined, returns maxValue else retuns targetValue of column
   */
  const getMaxValue = (): number => {
    if (column.visualConfig?.colorThresholdIsPercentage) {
      return 100;
    }
    if (column.visualConfig?.useCustomMaxValue) {
      return column.visualConfig.maxValue || 0;
    }
    return column.targetValue;
  };

  return (
    <div className={"shopfloor-column__visual-tab"}>
      {column.visualConfig ? (
        <>
          <div className="shopfloor-column__visual-tab__options">
            <CheckboxComponent
              value={t("shopfloorboard.column.edit.CheckManualMaxValue")}
              onCheck={(value) =>
                onColumnChange({
                  ...column,
                  visualConfig: {
                    ...column.visualConfig!,
                    useCustomMaxValue: value,
                    maxValue: column.targetValue,
                  },
                })
              }
              checked={
                column.visualConfig?.colorThresholdIsPercentage
                  ? false
                  : !!column.visualConfig.useCustomMaxValue
              }
              disabled={column.visualConfig?.colorThresholdIsPercentage}
            />
            {!column.visualConfig?.colorThresholdIsPercentage &&
              !!column.visualConfig.useCustomMaxValue && (
                <TextInputComponent
                  type="number"
                  value={getMaxValue()}
                  onChange={(newValue) =>
                    onColumnChange(updateColumnChangeMaxValue(newValue, column))
                  }
                />
              )}
            <div
              className={
                "shopfloor-column__visual-tab__options__percentage-checkbox"
              }
            >
              <CheckboxComponent
                value={t("shopfloorboard.column.edit.isPercentage")}
                onCheck={(value) =>
                  onColumnChange(updateColumnCheckIsPercentage(value, column))
                }
                checked={!!column.visualConfig.colorThresholdIsPercentage}
              />
            </div>

            <ButtonComponent
              title={t("general.buttons.reset")}
              onClick={() =>
                onColumnChange({ ...column, visualConfig: defaultColumnColor })
              }
              type="button"
            />
          </div>
          <ColorSliderComponent
            thumbs={column.visualConfig.colorThresholds}
            onAddThumb={(newThumb) =>
              onColumnChange(updateColumnAddThumb(column, newThumb))
            }
            onThumbValueChange={(newValues) =>
              onColumnChange(updateColumnUpdateThumb(column, newValues))
            }
            onRemoveThumb={(removedThumb) =>
              onColumnChange(updateColumnRemoveThumb(column, removedThumb))
            }
            trackColors={column.visualConfig.colorList
              .sort(
                (firstColor, secondColor) =>
                  firstColor.index - secondColor.index
              )
              .map((columnColor) => columnColor.color)}
            onColorChange={(newColor, index) =>
              onColumnChange(updateColumnUpdateColor(column, newColor, index))
            }
            addButtonTitle={t("shopfloorboard.column.edit.addTreshold")}
            removeButtonTitle={t("general.buttons.remove")}
            colorPickerTitle={t("shopfloorboard.column.edit.setColor") + ":"}
            tooltipGapText={t("general.or")}
            isPercentage={column.visualConfig.colorThresholdIsPercentage}
            maxValue={getMaxValue()}
            portal={popupContentElement || undefined}
          />
          <CheckboxComponent
            checked={!!column.visualConfig?.chartShowWeekend}
            onCheck={(chartShowWeekend) =>
              onColumnChange({
                ...column,
                visualConfig: {
                  ...column.visualConfig!,
                  chartShowWeekend,
                },
              })
            }
            value={t("shopfloorboard.column.edit.chartWeekends")}
          />
        </>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default ColumnEditFormVisualTab;
