import React, { useContext, useEffect, useState } from "react";
import { useProtocol } from "../../utils/protocol/useProtocol";
import {
  BoxComponent,
  BoxType,
  LayoutComponent,
  PopupComponent,
} from "sfm-component-library";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAxios } from "../../utils/AxiosUtil";
import { generateListEntries } from "../../utils/groupSort/GroupList.utils";
import { UserAction } from "../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { useGroups } from "../../utils/group/Group.utils";
import { UserContext } from "../App";
import { Protocol } from "../../utils/protocol/Protocol.types";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list-solid.svg";
import { ReactComponent as GridIcon } from "../../assets/icons/grip-solid.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/clone.svg";

import "../../styles/ProtocolOverviewPage.scss";
import EditProtocolConfigPopUp from "../../components/protocol/EditProtocolConfigPopUp";
import { BoardCopyForm } from "../../components/shopfloorboard/board/BoardCopyForm";
import { BoardCopyRequest } from "../../utils/sfboard/SfBoard.types";
import {
  copyProtocol,
  exportProtocol,
} from "../../utils/protocol/Protocol.axios";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { downloadCsv } from "../../utils/protocol/Protocol.utils";

const ProtocolOverviewPage: React.FC = () => {
  const { Protocols: loadedProtocols, mutate: setProtocols } = useProtocol();

  const { t } = useTranslation();
  const { axios } = useAxios();
  const history = useHistory();

  const [viewMode, setViewMode] = useState<"list" | "default">("default");
  const { user } = useContext(UserContext);
  const [showEditConfig, setShowEditConfig] = useState<boolean>(false);
  const loadedGroups = useGroups() ?? [];
  const [isLoading, setLoading] = useState<boolean>(false);

  const [configToEdit, setConfigToEdit] = useState<Protocol>();
  const [configToCopy, setConfigToCopy] = useState<Protocol>();

  /**
   * On render call mutate to refetch data
   */
  useEffect(() => {
    setProtocols([]);
  }, [setProtocols]);
  /**
   * Helper to copy a protocol
   *
   * @param copy the copy request
   * @returns promised void to detect when process is finished
   */
  const onCopyBoard = async (copy: BoardCopyRequest): Promise<void> => {
    try {
      const result = await copyProtocol(copy, axios);
      if (!result) {
        generateNotificationWithTranslations("warning");
        return;
      }
      setProtocols([...loadedProtocols, result]);
      generateNotificationWithTranslations("success");
      setConfigToCopy(undefined);
    } catch {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * Method to determine which view should be displayed
   * @param listView boolean that determines the type of view
   * @param configs Protocols that contains entries
   * @param givenUser User object
   * @param givenHistory History used to push
   * @param givenGroups Group[] to determine names for list-view
   * @returns JSX.Element containing the chosen view
   */
  const getCorrectEntryView = (viewMode: "list" | "default"): JSX.Element => {
    switch (viewMode) {
      case "list":
        return (
          <div className="protocol--list">
            {generateListEntries<Protocol>({
              type: "Protocol",
              user,
              history,
              entries: loadedProtocols,
              setConfigToEdit,
              setConfigToCopy,
              loadedGroups,
              setShowEditConfig,
              onClick: (protocol) =>
                history.push(`/protocol/detail`, {
                  protocol: protocol,
                }),
            })}
          </div>
        );
      case "default":
      default:
        return (
          <div className="protocol__overview--grid">
            {loadedProtocols.map((protocol, index) => (
              <BoxComponent
                background={protocol.color}
                type={BoxType.PACKAGE}
                key={`box-config-component-item-${index}`}
                onClick={() =>
                  history.push(`/protocol/detail`, {
                    protocol: protocol,
                  })
                }
                title={protocol.name}
                onClickConfig={() => {
                  if (
                    isUserAllowedToDoThis(UserAction.EDIT_PROTOCOL_CONFIG, user)
                  ) {
                    setConfigToEdit(protocol);
                    setShowEditConfig(true);
                  }
                }}
                hideConfig={
                  !isUserAllowedToDoThis(UserAction.EDIT_PROTOCOL_CONFIG, user)
                }
                buttons={[
                  {
                    icon: <CopyIcon />,
                    hidden: !isUserAllowedToDoThis(
                      UserAction.CREATE_PROTOCOL_CONFIG,
                      user
                    ),
                    onClick: () => setConfigToCopy(protocol),
                  },
                ]}
              />
            ))}
          </div>
        );
    }
  };

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.PROTOCOL)}
      title={t("protocol.title")}
    >
      <div className="protocol__overview">
        <EditProtocolConfigPopUp
          configToEdit={configToEdit}
          onSubmit={(editedConfig) => {
            if (configToEdit) {
              setProtocols(
                loadedProtocols.map((oldConfig) =>
                  oldConfig.id === editedConfig.id ? editedConfig : oldConfig
                )
              );
              setConfigToEdit(undefined);
            } else {
              setProtocols([...loadedProtocols, editedConfig]);
            }
            setShowEditConfig(false);
          }}
          isOpen={showEditConfig}
          setIsOpen={(isOpen) => {
            setShowEditConfig(isOpen);
            if (!isOpen) {
              setConfigToEdit(undefined);
            }
          }}
          onDelete={() => {
            setProtocols(
              loadedProtocols.filter(
                (config) => config.id! !== configToEdit!.id!
              )
            );
            setConfigToEdit(undefined);
            setShowEditConfig(false);
          }}
        />

        {configToCopy && (
          <PopupComponent
            isOpen
            title={t("shopfloorboard.config.copy.title", {
              replace: { name: configToCopy.name },
            })}
            toggleOpen={() => setConfigToCopy(undefined)}
            footerButtons={[
              {
                title: t("general.buttons.cancel"),
                onClick: () => setConfigToCopy(undefined),
                borderColor: "black",
                className: "color-white",
                isLoading: isLoading,
              },
              {
                title: t("general.buttons.copy"),
                borderColor: "#A9FAA2",
                type: "submit",
                form: "board-copy-form",
                isLoading: isLoading,
              },
            ]}
          >
            <BoardCopyForm
              board={configToCopy}
              onCopy={async (copy) => {
                setLoading(true);
                await onCopyBoard(copy);
                setLoading(false);
              }}
            />
          </PopupComponent>
        )}

        <div className="energy__overview--adding">
          <div className="energy__overview--adding-view">
            <GridIcon
              className={[
                "view-svg",
                viewMode === "default" ? "active" : "",
              ].join(" ")}
              onClick={() => setViewMode("default")}
            />
            <ListIcon
              className={[
                "view-svg",
                "list-icon",
                viewMode === "list" ? "active" : "",
              ].join(" ")}
              onClick={() => setViewMode("list")}
            />
            {isUserAllowedToDoThis(UserAction.CREATE_ENERGY_CONFIG, user) && (
              <DownloadIcon
                className={["view-svg", "list-icon"].join(" ")}
                onClick={() =>
                  exportProtocol(axios, user?.companyId!, user?.id!).then(
                    downloadCsv
                  )
                }
              />
            )}
          </div>
          {isUserAllowedToDoThis(UserAction.CREATE_ENERGY_CONFIG, user) && (
            <AddIcon onClick={() => setShowEditConfig(true)} />
          )}
        </div>
        <div>
          {loadedProtocols.length > 0 ? (
            getCorrectEntryView(viewMode)
          ) : (
            <p>{t("protocol.nothingFound")}</p>
          )}
        </div>
      </div>
    </LayoutComponent>
  );
};

export default ProtocolOverviewPage;
