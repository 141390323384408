import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ShopfloorColumn, { ShopfloorColumnProps } from "./ShopfloorColumn";
import { ReactComponent as ChevronLeftIcon } from "../../../assets/icons/chevron_left.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/icons/chevron_right.svg";
import { ShopfloorBoardConfiguration } from "../../../utils/sfboard/SfBoard.types";
import "../../../utils/polyfill/Smoothscroll";

export interface ColumnSliderProps {
  kioskMode?: boolean;
  onKioskExit?(): void;
  columns: ShopfloorColumnProps[];
  boardConfig?: ShopfloorBoardConfiguration;
  onKioskSupported?(): void;
  initiallyOpenTaskId?: string;
}

export const ColumnSlider: React.FC<ColumnSliderProps> = ({
  columns,
  kioskMode,
  onKioskExit,
  boardConfig,
  onKioskSupported,
  initiallyOpenTaskId,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState<{
    page: number;
    behavior?: ScrollToOptions["behavior"];
  }>({ page: 0 });
  const [{ width }, setSize] = useState({
    height: window?.innerHeight,
    width: window?.innerWidth,
  });
  const [lastMaxColumns, setLastMaxColumns] = useState(0);

  /**
   * Calcuate the maximal allowed columns size
   */
  const maxColumns = useMemo<number>(() => {
    const maxColumnsConfig = boardConfig?.visualConfig?.maxColumns ?? -3;
    const maxPossibleColumns = Math.floor(width / 420);
    const newMaxColumns =
      maxColumnsConfig <= 0
        ? maxPossibleColumns
        : Math.min(maxColumnsConfig, maxPossibleColumns);

    if (newMaxColumns !== lastMaxColumns) {
      setScroll({ page: 0, behavior: undefined });
    }
    setLastMaxColumns(newMaxColumns);
    return newMaxColumns;
  }, [width, boardConfig?.visualConfig?.maxColumns, lastMaxColumns]);

  /**
   * Scroll between pages when scroll or width changes
   */
  useEffect(() => {
    if (sliderRef.current) {
      let localScroll = scroll;

      sliderRef.current.scrollTo({
        left: localScroll.page * width,
        behavior: localScroll.behavior,
      });
    }
  }, [scroll, width, sliderRef]);

  /**
   * Register a window resize listener and update size state on change
   */
  useEffect(() => {
    const listener = () => {
      setScroll((scroll) => ({ ...scroll, behavior: undefined }));
      setSize({
        height: window?.innerHeight,
        width: window?.innerWidth,
      });
    };

    window?.addEventListener("resize", listener);
    return () => window?.removeEventListener("resize", listener);
  }, []);

  /**
   * Check for custom element fullscreen mode.
   * Kiosk mode is only visable when this check was successfull
   */
  useEffect(() => {
    if (
      sliderRef.current &&
      sliderRef.current.requestFullscreen !== undefined
    ) {
      onKioskSupported?.();
    }
  }, [sliderRef, onKioskSupported]);

  /**
   * Builds the pages from the provided columns
   */
  const [pagedColumns, maxPages] = useMemo(() => {
    const pageList: JSX.Element[] = [];

    let currentPage: ShopfloorColumnProps[] = [];

    for (let i = 0; i < columns.length; i++) {
      currentPage.push(columns[i]);
      if (currentPage.length >= maxColumns || i === columns.length - 1) {
        pageList.push(
          <>
            {currentPage.map((props, index) => (
              <ShopfloorColumn
                {...props}
                initiallyOpenTaskId={initiallyOpenTaskId}
                key={`shopfloor-column-${pageList.length}-${index}`}
              />
            ))}
          </>
        );
        currentPage = [];
      }
    }

    return [
      <>
        {pageList.map((page, index) => (
          <div className="page" key={`page-${index}`}>
            {page}
          </div>
        ))}
      </>,
      pageList.length - 1,
    ];
  }, [columns, maxColumns, initiallyOpenTaskId]);

  /**
   * Handles kiosk mode scrolling.
   * Also detect when fullscreen mode is disbled which automatically disable kiosk mode too.
   */
  useEffect(() => {
    const intervalUnit = boardConfig?.visualConfig?.kioskColumnDuration ?? 5;
    let interval: number | undefined = undefined;

    if (kioskMode && sliderRef.current) {
      if (!document?.fullscreenElement) sliderRef.current.requestFullscreen?.();

      // @ts-expect-error
      interval = setInterval(() => {
        setScroll((scroll) => {
          if (maxPages <= scroll.page) return { behavior: "smooth", page: 0 };
          return { behavior: "smooth", page: scroll.page + 1 };
        });
      }, intervalUnit * 1000 * maxColumns);
    }

    const listener = () => {
      if (!document?.fullscreenElement) {
        onKioskExit?.();
      }
    };

    document?.addEventListener("fullscreenchange", listener);
    return () => {
      document?.removeEventListener("fullscreenchange", listener);
      clearInterval(interval);
    };
  }, [
    sliderRef,
    kioskMode,
    onKioskExit,
    columns.length,
    maxColumns,
    boardConfig?.visualConfig?.kioskColumnDuration,
    maxPages,
  ]);

  /**
   * Navigate between pages
   *
   * @param dir sets the direction to navigate (1 = next page; -1 = previous page)
   */
  const navigatePage = useCallback(
    (dir: 1 | -1) => {
      setScroll((scroll) => {
        const newPage = scroll.page + dir;
        if (newPage < 0) return scroll;
        if (maxPages < newPage) return scroll;

        return { page: newPage, behavior: "smooth" };
      });
    },
    [maxPages]
  );

  return (
    <div className="shopfloor-board--column-slider">
      {!kioskMode && (
        <>
          {!!scroll.page && (
            <div
              className="shopfloor-board--wrapper--navigation left"
              onClick={() => navigatePage(-1)}
            >
              <ChevronLeftIcon />
            </div>
          )}
          {scroll.page < maxPages && (
            <div
              className="shopfloor-board--wrapper--navigation right"
              onClick={() => navigatePage(1)}
            >
              <ChevronRightIcon />
            </div>
          )}
        </>
      )}
      <div className="content" ref={sliderRef}>
        {pagedColumns}
      </div>
    </div>
  );
};
