import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  LoaderComponent,
  PopupComponent,
  TableComponent,
} from "sfm-component-library";
import "../../styles/AdministrationPage.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import { ExtendedGroup, Group, NewGroup } from "../../utils/group/Group.types";
import {
  createGroup,
  deleteGroup,
  getGroupsByCompany,
  updateGroup,
} from "../../utils/group/Group.utils";
import { ReactComponent as AddIcon } from "./../../assets/icons/add.svg";
import { ReactComponent as EditIcon } from "./../../assets/icons/edit.svg";
import { Company } from "../../utils/company/Company.types";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { GroupMemberOverview } from "../../components/administration/group/GroupMemberOverview";
import { GroupEditForm } from "../../components/administration/group/GroupEditForm";
import { UserContext } from "../../pages/App";

export const GroupAdmin: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const { user } = useContext(UserContext);
  const [company, setCompany] = useState<Company>();
  const [selectedGroup, setSelectedGroup] = useState<{
    group: ExtendedGroup | null;
    action: "edit" | "overview";
  }>();
  const [loadedGroups, setLoadedGroups] = useState<ExtendedGroup[]>([]);
  const [isLoading, setLoading] = useState(true);

  /**
   * Load groups and company
   */
  useEffect(() => {
    if (!user) return;
    if (user.company) setCompany(user.company);
    getGroupsByCompany(axios, user.companyId, true).then((groups) => {
      if (groups) setLoadedGroups(groups);
    });
    setLoading(false);
  }, [user, axios]);

  /**
   * Handle group update/create. Update or create a group based on type
   *
   * @param group the group which was create or updated
   */
  const onSave = async (group: Group | NewGroup): Promise<void> => {
    setLoading(true);
    if ("id" in group) {
      // update
      const updatedGroup = await updateGroup(axios, group, true);

      if (updatedGroup) {
        setLoadedGroups((groups) => [
          ...groups.filter((item) => item.id !== group.id),
          updatedGroup,
        ]);
        setSelectedGroup(undefined);
        generateNotificationWithTranslations("success");
      } else {
        generateNotificationWithTranslations("warning");
      }
    } else {
      // create new
      const newGroup = await createGroup(axios, group);

      if (newGroup) {
        setLoadedGroups((groups) => [...groups, { ...newGroup, members: [] }]);
        setSelectedGroup(undefined);
        generateNotificationWithTranslations("success");
      } else {
        generateNotificationWithTranslations("warning");
      }
    }
    setLoading(false);
  };

  /**
   * Handle group delete.
   *
   * @param group the group which should be deleted
   */
  const onDelete = async (group: Group): Promise<void> => {
    setLoading(true);
    const success = await deleteGroup(axios, group.id);
    setLoading(false);
    if (success) {
      setLoadedGroups((groups) =>
        groups.filter((item) => item.id !== group.id)
      );
      generateNotificationWithTranslations("success");
      setSelectedGroup(undefined);
    } else {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * Generate the needed group entries for the table component
   */
  const groupEntries = useMemo(
    () =>
      loadedGroups.map((group) => ({
        content: [
          group.name,
          <div onClick={() => setSelectedGroup({ action: "overview", group })}>
            {t(
              `administration.group.members.${
                group.members.length === 1 ? "one" : "more"
              }`,
              {
                replace: { amount: group.members.length },
              }
            ).toString()}
          </div>,
          <EditIcon
            onClick={() => {
              setSelectedGroup({ action: "edit", group });
            }}
          />,
        ],
        id: group.id,
      })),
    [loadedGroups, t]
  );

  return (
    <>
      {user && selectedGroup?.action === "edit" && (
        <PopupComponent isOpen toggleOpen={() => setSelectedGroup(undefined)}>
          <GroupEditForm
            group={
              selectedGroup.group === null ? undefined : selectedGroup.group
            }
            companyId={user.companyId}
            onSave={onSave}
            onDelete={onDelete}
          />
        </PopupComponent>
      )}
      {user && selectedGroup?.action === "overview" && selectedGroup?.group && (
        <PopupComponent
          isOpen
          toggleOpen={() => setSelectedGroup(undefined)}
          title={t("administration.group.overviewTitle")}
        >
          <GroupMemberOverview group={selectedGroup.group} />
        </PopupComponent>
      )}
      <div className="default-admin">
        <div className="default-admin--header">
          {company && (
            <ButtonComponent
              onClick={() => setSelectedGroup({ action: "edit", group: null })}
              borderColor={"black"}
              title={
                <div className="default-admin--header--add">
                  <p>{t("administration.group.add")}</p>
                  <AddIcon fill="white" />
                </div>
              }
            />
          )}
        </div>

        {isLoading ? (
          <LoaderComponent />
        ) : loadedGroups.length === 0 ? (
          <p>{t("administration.group.empty")}</p>
        ) : (
          <TableComponent
            listEntries={groupEntries}
            customTag={[{ flex: 4 }, { flex: 2 }, { flex: 1 }]}
            searchPlaceholder={t("administration.group.searchPlaceholder")}
            searchButtonTitle={t("general.buttons.search")}
            showIndex
            searchable
          />
        )}
      </div>
    </>
  );
};
