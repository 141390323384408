import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { ButtonComponent } from "sfm-component-library";
import { AppDataContext } from "../../../pages/App";
import {
  Protocol,
  ProtocolInput,
  ProtocolInputType,
} from "../../../utils/protocol/Protocol.types";
import "./ProtocolExportViewStyles.scss";

interface ProtocolDetailPageProps {
  protocol: Protocol;
}

const ProtocolExportView: React.FC<ProtocolDetailPageProps> = ({
  protocol,
}) => {
  const { t } = useTranslation();
  const { logo } = useContext(AppDataContext);

  const printRef = useRef<HTMLDivElement>(null);

  /**
   * helpter to get html content for protocol input
   * @param protocolInput
   * @returns string representation of value or img tag in case of image
   */
  const getContentForInput = (
    protocolInput: ProtocolInput
  ): JSX.Element | string => {
    switch (protocolInput.inputType) {
      case ProtocolInputType.CHECKBOX:
        return (protocolInput.value as boolean)
          ? t("protocol.export.yes")
          : t("protocol.export.no");
      case ProtocolInputType.IMAGE:
        try {
          const url = URL.createObjectURL(protocolInput.value as File);
          return <img src={url} alt={protocolInput.name} />;
        } catch (error) {
          return "Error while creating url for file";
        }

      case ProtocolInputType.NUMBER:
        return protocolInput.value.toString();
      case ProtocolInputType.DROPDOWN:
      case ProtocolInputType.BARCODE:
      case ProtocolInputType.TEXT:
        return protocolInput.value as string;
      default:
        return "";
    }
  };

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <ButtonComponent
            disabled={!logo}
            title={t("general.buttons.download")}
            type="button"
          />
        )}
        content={() => printRef.current}
        onPrintError={() => console.error("Print failed.")}
      />
      <div ref={printRef} className="protocol-export-view">
        <div className="protocol-header">
          {logo && (
            <img
              alt="Logo"
              className="company-logo"
              src={URL.createObjectURL(logo)}
            />
          )}
          <h2>{protocol.name}</h2>
        </div>
        <h3>{`${t("protocol.fill.filledBy")}: ${protocol.user?.firstname} ${
          protocol.user?.lastname
        } ${t("protocol.fill.filledAt")}: ${
          new Date(protocol.createDate).toLocaleDateString() || ""
        }`}</h3>
        {protocol.inputs?.map((p) => (
          <div className={`input-value-pair ${p.priority}`}>
            <label>{p.name}</label>
            <div>{getContentForInput(p)}</div>
          </div>
        ))}
        <h3>{t("protocol.fill.summary")}</h3>
        <div className={"input-value-pair summary"}>
          <label>
            {t(`protocol.fill.summaryTypes.${protocol.summaryType}`)}
          </label>
          <div>{protocol.summary}</div>
        </div>
      </div>
    </>
  );
};

export default ProtocolExportView;
