import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  LayoutComponent,
  LoaderComponent,
  TextInputComponent,
} from "sfm-component-library";
import ShopfloorTask from "../../components/shopfloorboard/task/ShopfloorTask";
import { useAxios } from "../../utils/AxiosUtil";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { ShopfloorBoardConfiguration } from "../../utils/sfboard/SfBoard.types";
import { useSimpleShopfloorBoardConfiguration } from "../../utils/sfboard/useSimpleShopfloorBoardConfiguration";
import { Task } from "../../utils/tasks/Tasks.types";
import { fetchAllTasksForCompany } from "../../utils/tasks/TasksUtil";
import { User } from "../../utils/user/User.types";
import { loadAllUsersFromBackendByCompanyId } from "../../utils/user/User.utils";
import { UserContext } from "../App";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import "./../../styles/TaskOverviewStyles.scss";

export interface TaskoverviewPageProps {}

const TaskOverviewPage: FC<TaskoverviewPageProps> = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const { shopfloorBoardConfigurations: loadedConfigs } =
    useSimpleShopfloorBoardConfiguration();
  const { axios } = useAxios();
  const [showLoader, setShowLoader] = useState(false);
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const [loadedUsers, setLoadedUsers] = useState<User[]>([]);
  const [showArchive, setshowArchive] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const [filteredConfigs, setfilteredConfigs] =
    useState<ShopfloorBoardConfiguration[]>(loadedConfigs);

  /**
   * filteres all configs, that does not include tasks
   */
  useEffect(() => {
    setfilteredConfigs(
      loadedConfigs
        .filter((boardConfigToFilter) =>
          filterTasksForSearchString(allTasks).some(
            (task) => task.shopfloorBoardId === boardConfigToFilter.id
          )
        )
        .sort(({ name: firstConfig }, { name: secondConfig }) =>
          firstConfig.localeCompare(secondConfig)
        )
    );
    // eslint-disable-next-line
  }, [loadedConfigs, allTasks, searchString, showArchive]);

  /**
   * fetches all tasks and users from backend
   */
  useEffect(() => {
    if (axios && user) {
      setShowLoader(true);
      fetchAllTasksForCompany(axios, user.companyId).then((fetchedTasks) => {
        setAllTasks(fetchedTasks);
        setShowLoader(false);
      });
      loadAllUsersFromBackendByCompanyId(user.companyId, axios).then(
        (loadedUsers) => {
          setLoadedUsers(loadedUsers);
        }
      );
    }
  }, [axios, user]);

  /**
   * filters all tasks, that does not include searchstring in the title
   * @param tasks tasks to filter
   * @returns filtered tasks
   */
  const filterTasksForSearchString = (tasks: Task[]): Task[] =>
    tasks
      .filter(
        (task) =>
          (showArchive || !task.archived) &&
          (searchString === "" || task.title.indexOf(searchString) !== -1)
      )
      .sort(({ title: firstTitle }, { title: secondTitle }) =>
        firstTitle.localeCompare(secondTitle)
      );

  /**
   * filters all tasks, that does not include searchstring in the title and is part of the given config
   * @param tasks tasks to filter
   * @param config config of the task
   * @returns filtered tasks
   */
  const filterTasksForSearchStringAndConfig = (
    tasks: Task[],
    config: ShopfloorBoardConfiguration
  ): Task[] =>
    tasks
      .filter(
        (task) =>
          task.shopfloorBoardId === config.id &&
          (showArchive || !task.archived) &&
          (searchString === "" || task.title.indexOf(searchString) !== -1)
      )
      .sort(({ title: firstTitle }, { title: secondTitle }) =>
        firstTitle.localeCompare(secondTitle)
      );

  /**
   * handles task updates
   * @param updatedTask task to update
   */
  const handleUpdateTask = (updatedTask: Task): void => {
    setAllTasks((prevTasks) =>
      prevTasks.map((taskToUpdate) =>
        taskToUpdate.id === updatedTask.id ? updatedTask : taskToUpdate
      )
    );
  };

  /**
   * handle deletion of a task
   * @param deletedTask task to delete
   */
  const handleDeleteTask = (deletedTask: Task): void =>
    setAllTasks((prevTasks) =>
      prevTasks.filter((taskToFilter) => taskToFilter.id !== deletedTask.id)
    );

  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.TASK_OVERVIEW, true)}
      title={t("taskoverview.title")}
    >
      <div className="task-overview">
        <div className={"task-overview__header"}>
          <TextInputComponent
            value={searchString}
            onChange={setSearchString}
            placeholder={t("taskoverview.search.placeholder")}
          />
          {searchString !== "" && (
            <CloseIcon
              className={"task-overview__header__close"}
              onClick={() => setSearchString("")}
            />
          )}
          <div className="task-overview__archive-button__wrapper">
            <ButtonComponent
              title={
                <div
                  className={[
                    "growing-button-content",
                    showArchive
                      ? "task-overview__archive-button__content--white"
                      : "task-overview__archive-button__content--black",
                  ].join(" ")}
                >
                  <p>
                    {t(
                      `taskoverview.${
                        showArchive ? "hideArchive" : "showArchive"
                      }`
                    )}
                  </p>
                  <ArchiveIcon />
                </div>
              }
              onClick={() => setshowArchive(!showArchive)}
              borderColor={showArchive ? "black" : "white"}
            />
          </div>
        </div>
        {!showLoader ? (
          <div className={"task-overview__list-wrapper"}>
            {filterTasksForSearchString(allTasks).length > 0 ? (
              filteredConfigs.map((config) => (
                <div className={"task-overview__config-box"}>
                  <h2>{config.name}</h2>
                  {filterTasksForSearchStringAndConfig(allTasks, config).map(
                    (task) => (
                      <ShopfloorTask
                        minified
                        task={task}
                        updateTask={handleUpdateTask}
                        onDelete={() => handleDeleteTask(task)}
                        users={loadedUsers}
                      />
                    )
                  )}
                </div>
              ))
            ) : (
              <p className={"task-overview__no-task-found"}>
                {t("taskoverview.search.noTaskFound")}
              </p>
            )}
          </div>
        ) : (
          <LoaderComponent />
        )}
      </div>
    </LayoutComponent>
  );
};

export default TaskOverviewPage;
