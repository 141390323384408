export interface Notification {
  id?: string;

  notificationType: NotificationType;
  action: NotificationAction;
  changedContentName: string;

  receiverUserIds: string[];
  seenByUserIds: string[];
  senderUserId?: string;
  senderFirstName?: string;
  senderLastName?: string;
  boardId: string;
  taskId: string;

  createdAt: Date;
  deleteDate: Date;
  level: NotificationLevel;
}

export enum NotificationType {
  TASK = "TASK",
  TASK_FILES = "TASK_FILES",
  BOARD = "BOARD",
  BOARD_CONFIG = "BOARD_CONFIG",
  ENERGY_BOARD = "ENERGY_BOARD",
  POWER_BI = "POWER_BI",
  LOGO = "LOGO",
  PROTOCOL = "PROTOCOL",
}

export enum NotificationAction {
  UPDATED = "UPDATED",
  CREATED = "CREATED",
  DELETED = "DELETED",
  FINISHED = "FINISHED",
  DELAYED = "DELAYED",
  DEADLINE_MISSED = "DEADLINE_MISSED",
  NOTIFY = "NOTIFY",
}
export enum NotificationLevel {
  HIGH = "HIGH",
  NORMAL = "NORMAL",
  LOW = "LOW",
}
