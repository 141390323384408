import React, { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  PopupComponent,
  TextInputComponent,
} from "sfm-component-library";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import { EnergyBoard } from "../../utils/energy/Energy.types";
import {
  createNewEnergBoard,
  deleteEnergyBoard,
  updateEnergyBoardConfig,
} from "../../utils/energy/Energy.utils";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { updateGroupEnergy, useGroups } from "../../utils/group/Group.utils";
import { generateNewPowerBIConfig } from "../../utils/powerbi/PowerBI.types";
import { UserAction } from "../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { GroupSelect } from "../administration/groupselect/GroupSelect";
import DeletePopUp from "../deletepopup/DeletePopUp";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/delete.svg";
import "./EditEnergyBoardConfigPopUp.styles.scss";
import { EditEnergyBoardConfigPopUpProps } from "./EditEnergyBoardConfigPopUp.types";

const EditEnergyBoardConfigPopUp: FC<EditEnergyBoardConfigPopUpProps> = ({
  configToEdit,
  setIsOpen,
  onSubmit,
  onDelete,
  isOpen,
}) => {
  const { user } = useContext(UserContext);
  const [config, setConfig] = useState<EnergyBoard | undefined>(configToEdit);
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [deleteTaskIsLoading, setDeleteTaskIsLoading] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { axios } = useAxios();
  const groups = useGroups();

  /**
   * sets config, when isOpen, user or configToEdit, is changed
   */
  useEffect(() => {
    if (isOpen && user) {
      setConfig({
        ...(configToEdit || generateNewPowerBIConfig(user)),
        lastUpdatedBy: user.id!,
      });
    }
  }, [isOpen, user, configToEdit]);

  /**
   * handles submit, updates Energy Board Config, if configToEdit exist, else creates new Energy Board
   */
  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    try {
      let success = false;
      let localConfig = config;

      if (configToEdit) {
        success = await updateEnergyBoardConfig(config!, axios);
      } else {
        localConfig = await createNewEnergBoard(config!, axios);
        success = true;
      }

      if (success) {
        success = await updateGroupEnergy(
          axios,
          (localConfig as EnergyBoard).id!,
          (localConfig as EnergyBoard).groups
        );
      }

      if (success) {
        generateNotificationWithTranslations("success");
        onSubmit(localConfig as EnergyBoard);
      } else {
        generateNotificationWithTranslations("warning");
      }
    } catch (err) {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * handles delete Config, creates Notification
   */
  const handleDelete = (): void => {
    setDeleteTaskIsLoading(true);
    deleteEnergyBoard(
      { deletionId: configToEdit!.id!, deleterId: user!.id! },
      axios
    ).then((success) => {
      if (success) {
        generateNotificationWithTranslations("success");
        onDelete?.();
      } else {
        generateNotificationWithTranslations("warning");
      }
      setDeleteTaskIsLoading(false);
      setOpenDeletePopUp(false);
    });
  };

  return config ? (
    <>
      <PopupComponent
        isOpen={isOpen}
        toggleOpen={setIsOpen}
        footerButtons={[
          {
            title: t("general.buttons.cancel"),
            onClick: () => {
              setIsOpen(false);
              setConfig(undefined);
            },
            borderColor: "black",
            className: "color-white",
          },
          {
            title: t("general.buttons.save"),
            borderColor: "#A9FAA2",
            type: "submit",
            form: "energy-edit-form",
          },
        ]}
      >
        <form id="energy-edit-form" onSubmit={handleSubmit}>
          <div className={"energy-edit__header"}>
            <h2>
              {t(`energy.config.${configToEdit ? "edit" : "create"}.title`)}
            </h2>
            {isUserAllowedToDoThis(UserAction.DELETE_ENERGY_CONFIG, user) &&
              configToEdit && (
                <ButtonComponent
                  title={
                    <div className="energy-edit__header__button-content">
                      <p>{t("general.buttons.delete")}</p>
                      <DeleteIcon />
                    </div>
                  }
                  borderColor="#F86B6B"
                  type="button"
                  onClick={() => setOpenDeletePopUp(true)}
                />
              )}
          </div>
          <div>
            <TextInputComponent
              label={t("powerBI.config.edit.boardName")}
              value={config.name || ""}
              onChange={(newValue) => setConfig({ ...config, name: newValue })}
            />
            <TextInputComponent
              label={t("powerBI.config.edit.color")}
              value={config.color || ""}
              onChange={(newValue) => setConfig({ ...config, color: newValue })}
              type="color"
            />
            <hr />
            <p>{t("administration.group.title")}</p>
            <GroupSelect
              assignedGroups={config.groups}
              groups={groups ?? []}
              onUpdate={(newGroups) => {
                setConfig({ ...config, groups: newGroups });
              }}
              dropdownPortal={document.body}
            />
            <TextInputComponent
              label={t("energy.weather.lat")}
              value={config.coordinates?.lat || ""}
              onChange={(newValue) =>
                setConfig({
                  ...config,
                  coordinates: {
                    lon: config.coordinates?.lon || 0.0,
                    lat: parseFloat(newValue) || 0.0,
                  },
                })
              }
              type="number"
            />
            <TextInputComponent
              label={t("energy.weather.lon")}
              value={config.coordinates?.lon || ""}
              onChange={(newValue) =>
                setConfig({
                  ...config,
                  coordinates: {
                    lat: config.coordinates?.lat || 0.0,
                    lon: parseFloat(newValue) || 0.0,
                  },
                })
              }
              type="number"
            />
          </div>
        </form>
      </PopupComponent>
      <DeletePopUp
        isOpen={openDeletePopUp}
        toggleOpen={setOpenDeletePopUp}
        onDelete={handleDelete}
        deleteIsLoading={deleteTaskIsLoading}
        content={<p>{t("energy.config.delete")}</p>}
      />
    </>
  ) : (
    <></>
  );
};

export default EditEnergyBoardConfigPopUp;
