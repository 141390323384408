import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "sfm-component-library";
import RegisterFormTab from "../components/register/RegisterFormTab";
import { useAxios } from "../utils/AxiosUtil";
import { Company } from "../utils/company/Company.types";
import {
  createEmptyUserForRegistration,
  RegisterTab,
  User,
} from "../utils/user/User.types";
import {
  createUserOnBackend,
  generateUserCreationErrorNotifications,
} from "../utils/user/User.utils";
import { ReactComponent as LeftIcon } from "./../assets/icons/chevron_left.svg";
import "./../styles/RegisterPageStyles.scss";

interface RegisterPageProps {}

const RegisterPage: React.FC<RegisterPageProps> = () => {
  const history = useHistory();
  const { axios } = useAxios();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<User>(
    createEmptyUserForRegistration()
  );
  const [currentTab, setCurrentTab] = useState<RegisterTab>(
    RegisterTab.PROFILE
  );
  const inputFields: {
    [RegisterTab.PROFILE]: (keyof User)[];
    [RegisterTab.COMPANY]: (keyof Company)[];
  } = {
    [RegisterTab.PROFILE]: ["firstname", "lastname", "mail", "username"],
    [RegisterTab.COMPANY]: ["name", "street", "zip", "city", "country"],
  };
  const { t } = useTranslation();

  /**
   *  handles UserObject change
   * @param key
   * @param newValue
   * @param tab that is currently edited
   */
  const handleUserObjectChange = (
    key: string,
    newValue: string,
    tab: RegisterTab
  ): void => {
    switch (tab) {
      case RegisterTab.COMPANY:
        setNewUser({
          ...newUser,
          company: { ...newUser.company!, [key]: newValue },
        });
        break;
      default:
        setNewUser({ ...newUser, [key]: newValue });
        break;
    }
  };

  return (
    <div className="register__page">
      <form
        className="register__form"
        onSubmit={(evt) => {
          evt.preventDefault();
          toggleLoading(true);
          createUserOnBackend(newUser, axios).then((result) => {
            toggleLoading(false);
            if (!result.user) {
              generateUserCreationErrorNotifications(result);
              return;
            }
            history.push("/");
          });
        }}
      >
        <div className="register__wrapper">
          <div className={"register__side-menu"}>
            <div className="register__side-menu__card">
              <div className={"register__page__title__wrapper"}>
                <LeftIcon onClick={() => history.goBack()} />
                <h1 className="register__page__title">{t("register.title")}</h1>
              </div>
              <div className="register__navigation">
                {Object.values(RegisterTab).map((tab) => (
                  <p
                    key={tab}
                    onClick={() => setCurrentTab(tab)}
                    className={`register__navigation__item register__navigation__item${
                      currentTab === tab ? "--focused" : ""
                    }`}
                  >
                    {"• " + t(`${tab.toLowerCase()}.title`)}
                  </p>
                ))}
              </div>
              <ButtonComponent
                title={t("register.registerButton")}
                borderColor="black"
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="register__info-box">
            {Object.values(RegisterTab).map((tab) => (
              <RegisterFormTab
                inputFields={inputFields[tab]}
                user={newUser}
                tab={tab}
                onUserChange={handleUserObjectChange}
                currentVisibleTab={currentTab}
                onScrollTabChange={setCurrentTab}
                key={tab}
              />
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};
export default RegisterPage;
