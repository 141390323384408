import { AxiosInstance } from "axios";
import { ReactComponent as CubeIcon } from "../../assets/icons/cube.svg";
import { ReactComponent as LightbulbIcon } from "../../assets/icons/lightbulb.svg";
import { ReactComponent as ProtocolIcon } from "../../assets/icons/protocol.svg";
import { ReactComponent as IndustrialTrucksAnalysisIcon } from "../../assets/icons/industrial_trucks_analysis.svg";
import { ReactComponent as LPAIcon } from "../../assets/icons/lpa.svg";
import { ReactComponent as PowerBIIcon } from "../../assets/icons/powerbi.svg";
import { ReactComponent as MLIcon } from "../../assets/icons/scan.svg";
import { FeatureModule } from "../license/License.types";
import { FileEntry, FileType } from "../tasks/Tasks.types";
import { Company, License } from "./Company.types";

/**
 * API METHOD - to store updated {@link ApiKey}s on the server
 * @param axios networ instance
 * @param company data to update
 * @returns success if status code 200 was recieved
 */
export const updateApiKeysForCompany = (
  axios: AxiosInstance,
  company: Company
): Promise<boolean> =>
  axios
    .post("/user/company/api/", company)
    .then((serverResp) => serverResp.status === 200)
    .catch((exc) => {
      console.error("Error during api key update!", exc);
      return false;
    });

/**
 * GET API - for loading a single {@link Company} for given id
 * @param axios
 * @param id id of the company, that needs to be loaded
 * @returns loaded {@link Company} if successful, else returns undefined
 */
export const fetchSingleCompany = (
  axios: AxiosInstance,
  id: string
): Promise<Company> =>
  axios
    .get("/user/company/", { params: { companyId: id } })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during companies fetch!", exc);
      return undefined;
    });

/**
 * GET API - that fetches all existing componies on the platform
 * @param axios
 * @returns list of {@link Company} if successful, else returns an empty list
 */
export const fetchAllCompanies = (axios: AxiosInstance): Promise<Company[]> => {
  return axios
    .get("/user/company/all/")
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during companies fetch!", exc);
      return [];
    });
};

/**
 * GET API - that fetches all pending companies on the platform
 *
 * @param axios the axios instance
 * @returns list of {@link Company} if successful, else returns an empty list
 */
export const fetchAllPendingCompanies = (
  axios: AxiosInstance
): Promise<Company[]> => {
  return axios
    .get("/user/company/all/pending/")
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during pending companies fetch!", exc);
      return [];
    });
};

/**
 * UPDATE API - activate a given company
 *
 * @param axios the axios instance
 * @param companyId the id of the company to activate
 * @returns success if status code 200 was recieved
 */
export const activateCompany = (
  axios: AxiosInstance,
  companyId: string
): Promise<boolean> =>
  axios
    .post("/user/company/activate/", companyId)
    .then((serverResp) => serverResp.status === 200)
    .catch((exc) => {
      console.error("Error during company activating!", exc);
      return false;
    });

/**
 *
 * @param licenses licenses that the company purchased
 * @returns the right icon for each license
 */
export const mapLicensesToIcons = (license: License): JSX.Element[] =>
  license.features.map(({ feature }) => {
    switch (feature) {
      case FeatureModule.SHOPFLOOR_BOARD:
        return <CubeIcon />;
      case FeatureModule.LPA:
        return <LPAIcon />;
      case FeatureModule.POWERBI:
        return <PowerBIIcon />;
      case FeatureModule.INDUSTRIALTRUCKSANALYSIS:
        return <IndustrialTrucksAnalysisIcon />;
      case FeatureModule.ENERGY:
        return <LightbulbIcon />;
      case FeatureModule.PROTOCOL:
        return <ProtocolIcon />;
      case FeatureModule.ML:
        return <MLIcon />;

      default:
        return <span />;
    }
  });

/**
 * POST API - to update an existing {@link Company}
 * @param company newly edited Company
 * @param axios
 * @returns true if successful, else returns false
 */
export const updateCompany = (
  company: Company,
  axios: AxiosInstance
): Promise<boolean> =>
  axios
    .post("/user/company/update", company)
    .then((response) => response.status === 200)
    .catch((exc) => {
      console.error("Error during company update", exc);
      return false;
    });

/**
 * GET API - to fetch a logo for a given companyId
 * @param axios
 * @param companyId id of the company
 * @returns returns logo as {@link File} if successful, else returns undefined
 */
export const fetchLogoForCompany = (
  axios: AxiosInstance,
  companyId: string
): Promise<File | undefined> =>
  axios
    .get("/user/company/logo/", {
      params: { companyId },
      responseType: "blob",
    })
    .then(
      (fileResponse) =>
        new File([fileResponse.data], "logo", { type: FileType.LOGO })
    )
    .catch((exc) => {
      console.error("Error during download file!", exc);
      return undefined;
    });

/**
 * POST API - to save a new logo for a company
 * @param axios
 * @param file logo to save
 * @param companyId id of the company
 * @param userId id of the current user
 * @returns the newly created {@link FileEntry}
 */
export const saveNewLogoForCompany = (
  axios: AxiosInstance,
  file: File,
  companyId: string,
  userId: string
): Promise<FileEntry | undefined> => {
  // build the request
  let uploadFormData = new FormData();

  uploadFormData.append("companyId", companyId);
  uploadFormData.append("userId", userId);
  uploadFormData.append("fileData", file);

  // run the axios command
  return axios
    .post("/user/company/logo/", uploadFormData)
    .then((fileResponse) => fileResponse.data)
    .catch((exc) => {
      console.error("Error during uploading file!", exc);
      return undefined;
    });
};

/**
 * POST API - to update the logo for a given company
 * @param axios
 * @param file new logo
 * @param companyId id of the company
 * @returns updated {@link FileEntry}
 */
export const updateLogoForCompany = (
  axios: AxiosInstance,
  file: File,
  companyId: string,
  performerId: string
): Promise<FileEntry | undefined> => {
  // build the request
  let uploadFormData = new FormData();
  const logoChangedNotificationRequest = new Blob(
    [
      JSON.stringify({
        companyId,
        performerId,
      }),
    ],
    {
      type: "application/json",
    }
  );
  uploadFormData.append(
    "logoChangedNotificationRequest",
    logoChangedNotificationRequest
  );
  uploadFormData.append("filedata", file);

  // run the axios command
  return axios
    .post("/user/company/logo/update", uploadFormData)
    .then((fileResponse) => fileResponse.data)
    .catch((exc) => {
      console.error("Error during uploading file!", exc);
      return undefined;
    });
};

/**
 * DELETE API - to delete an existing {@link Company}
 * @param axios
 * @param companyId id of the company, that needs to be deleted
 * @returns true if successful, else returns false
 */
export const deleteCompany = (
  axios: AxiosInstance,
  companyId: string
): Promise<boolean> =>
  axios
    .post("/user/company/delete", companyId)
    .then((response) => response.status === 200)
    .catch((exc) => {
      console.error("Error during company update", exc);
      return false;
    });
