import React, { useContext, useEffect, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import QRCode from "react-qr-code";
import {
  ButtonComponent,
  DropdownComponent,
  LayoutComponent,
  LoaderComponent,
  PopupComponent,
  TextInputComponent,
  generateNotification,
} from "sfm-component-library";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAxios } from "../../utils/AxiosUtil";
import {
  ExtractValue,
  Protocol,
  ProtocolDocument,
  ProtocolInput,
  ProtocolInputType,
  ProtocolSummaryType,
  createEmptyProtocol,
} from "../../utils/protocol/Protocol.types";
import {
  downloadCsv,
  getCompressedImagePromise,
  getCorrectElementForProtocolInput,
} from "../../utils/protocol/Protocol.utils";
import "../../styles/ProtocolDetailPage.scss";
import {
  completeProtocol,
  deleteProtocolDocument,
  downloadFileForProtocol,
  exportSingleProtocol,
  loadProtocol,
  updateProtocol,
  uploadFileForProtocol,
} from "../../utils/protocol/Protocol.axios";
import { UserContext } from "../App";
import { FileEntry, FileType } from "../../utils/tasks/Tasks.types";
import { ReactComponent as LeftIcon } from "./../../assets/icons/chevron_left.svg";
import { ReactComponent as ProtocolIcon } from "../../assets/icons/protocol.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icons/file.svg";
import { ReactComponent as EditIcon } from "./../../assets/icons/edit.svg";
import { ReactComponent as DownloadIcon } from "./../../assets/icons/download.svg";

import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { UserAction } from "../../utils/user/User.types";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import EditProtocolFieldForm from "../../components/protocol/EditProtocolFieldForm";
import { useProtocol } from "../../utils/protocol/useProtocol";

import ProtocolExportView from "../../components/protocol/export/ProtocolExportView";

interface ProtocolDetailPageProps {}

const ProtocolDetailPage: React.FC<ProtocolDetailPageProps> = () => {
  const location = useLocation<{
    protocol: Protocol;
    view: boolean;
    configId: string;
  }>();
  const [localProtocol, setLocalProtocol] = useState<Protocol>(
    { ...location.state?.protocol } || createEmptyProtocol()
  );
  const { fetch } = useProtocol();
  const { id } = useParams<{ id: string | undefined }>();
  const [viewMode, toggleViewMode] = useState<boolean>(false);
  const [editMode, toggleEditMode] = useState<boolean>(false);
  const [isLoading, toggleLoading] = useState<boolean>(
    location.state?.protocol?.originId ? true : false
  );
  const [formValid, setFormValid] = useState<boolean>(false);
  const { t } = useTranslation();
  const { axios } = useAxios();
  const history = useHistory();
  const [protocolFieldId, setProtocolFieldId] = useState<string>("");
  const [bardcodeScannerOpen, toggleBarcodeScanner] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  /**
   * Handles the input on one ProtocolInput
   * @param value  the new value
   * @param id  the id of the ProtocolInput
   */
  const handleProtocolInput = (
    value: ExtractValue<ProtocolInputType>,
    id: string
  ): void => {
    const newProtocol = { ...localProtocol };
    const foundIndex = newProtocol.inputs.findIndex((input) => input.id === id);
    if (foundIndex === -1) return;
    newProtocol.inputs[foundIndex].value = value;
    setLocalProtocol(newProtocol);
  };

  /**
   * Checks if the form is valid
   */
  useEffect(() => {
    if (!localProtocol.inputs) return;
    const formValid: boolean =
      localProtocol.inputs.every((input) => {
        if (!input.required) return true;
        if (input.inputType === ProtocolInputType.IMAGE) {
          return !!input.value;
        } else if (input.inputType === ProtocolInputType.CHECKBOX) {
          return input.value;
        } else {
          return input.value !== "";
        }
      }) &&
      (!localProtocol.summaryEnabled || !!localProtocol.summaryType);
    setFormValid(formValid);
  }, [localProtocol]);

  /**
   * If view mode is active, the protocol should not be editable
   */
  useEffect(() => {
    if (!location.state || !location.state.view) return;
    toggleViewMode(true);
  }, [location.state]);

  /**
   * Helper to store filled out protocol
   */
  const sendFilledOutProtocol = async (): Promise<void> => {
    if (!axios || !user?.id) return;
    toggleLoading(true);
    const allProtocolInputs: ProtocolInput[] = [...localProtocol.inputs];
    const compressionPromises: Promise<void>[] = [];

    for (const imageInput of allProtocolInputs) {
      if (imageInput.inputType === ProtocolInputType.IMAGE) {
        compressionPromises.push(
          getCompressedImagePromise(
            axios,
            imageInput,
            localProtocol.id ?? "",
            user.id
          )
        );
      }
    }

    try {
      await Promise.all(compressionPromises);
    } catch (error) {
      console.error("Error occurred during image compression:", error);
    }

    const success = await completeProtocol(
      {
        ...localProtocol,
        inputs: [...allProtocolInputs],
        userId: user.id,
        documents: [],
      },
      axios
    );

    if (success) {
      generateNotification(t("protocol.fill.success"), "success");
      history.push("/protocol");
    } else {
      generateNotification(t("protocol.fill.error"), "warning");
    }
    toggleLoading(false);
  };

  /**
   * Helper to load all images of an already filled out protocol
   */
  const loadImagesForFilledOutProtocol = async (
    protocol: Protocol
  ): Promise<void> => {
    if (!axios || !user?.id) return;
    const allProtocolInputs: ProtocolInput[] = [...protocol.inputs];
    for await (const imageInput of allProtocolInputs) {
      if (
        imageInput.inputType === ProtocolInputType.IMAGE &&
        !!imageInput.fileEntry
      ) {
        const imageFile: File | undefined = await downloadFileForProtocol(
          protocol.id ?? "",
          imageInput.fileEntry,
          axios,
          imageInput.id
        );
        if (imageFile) {
          imageInput.value = imageFile as File;
        }
      }
    }

    toggleLoading(false);
    setLocalProtocol({
      ...protocol,
      inputs: [...allProtocolInputs],
    });
  };

  /**
   * Checks whether the protocol is already filled out
   */
  useEffect(() => {
    if (!location.state?.protocol && !id && !location.state?.configId) {
      history.push("/protocol");
    }
    if (location.state?.protocol?.originId) {
      toggleLoading(true);
      loadImagesForFilledOutProtocol(location.state?.protocol!);
    } else if (id && !location.state?.protocol) {
      toggleLoading(true);
      fetch(id).then((data) => {
        if (!data) {
          toggleLoading(false);
          return;
        }
        toggleViewMode(true);
        loadImagesForFilledOutProtocol(data);
      });
    } else if (location.state?.configId) {
      toggleLoading(true);
      loadProtocol(axios, location.state.configId).then((protocol) => {
        if (protocol) setLocalProtocol(protocol);
        toggleLoading(false);
      });
    } else if (
      !!location.state &&
      !!location.state.protocol &&
      !!location.state.protocol.documents &&
      location.state.protocol.documents.length > 0
    ) {
      toggleLoading(true);
      loadFilesForDocuments();
    }

    //eslint-disable-next-line
  }, [location.state?.protocol, id]);

  /**
   * Helper to update ProtocolInput
   * @param partialInput  Partial ProtocolInput
   */
  const editPartialInput = (partialInput: Partial<ProtocolInput>): void => {
    setLocalProtocol((oldProtocol) => ({
      ...(oldProtocol || createEmptyProtocol(user)),
      inputs:
        oldProtocol?.inputs?.map((input) => {
          return input.id === partialInput.id
            ? { ...input, ...partialInput }
            : input;
        }) || [],
    }));
  };

  /**
   * Responsible for approriatly emptying the protocol
   */
  useEffect(() => {
    if (!!id || editMode || !location.state?.protocol) return;

    const emptiedProtocol: Protocol = {
      ...localProtocol,
      inputs: localProtocol.inputs.map((input) => ({
        ...input,
        value: "",
      })),
    };
    setLocalProtocol(emptiedProtocol);
    //eslint-disable-next-line
  }, [editMode, id, location.state?.protocol, user]);

  /**
   * Helper to update ProtocolDocument
   * @param partialDocument  Partial Protocol Document
   */
  const editPartialDocument = (
    partialDocument: Partial<ProtocolDocument>
  ): void => {
    setLocalProtocol((oldProtocol) => ({
      ...(oldProtocol || createEmptyProtocol(user)),
      documents:
        oldProtocol?.documents?.map((doc) => {
          return doc.id === partialDocument.id
            ? { ...doc, ...partialDocument }
            : doc;
        }) || [],
    }));
  };

  /**
   * handles update of Protocol config
   */
  const handleUpdateOfProtocolConfig = async (): Promise<void> => {
    if (!localProtocol || !user?.id) return;
    try {
      let success = false;
      let localConfig: Protocol | null = {
        ...localProtocol,
        inputs: localProtocol.inputs.filter(
          (input) => !!input.name && input.name !== ""
        ),
        documents: localProtocol.documents?.filter((doc) => {
          return (
            (doc.link && doc.linkContent !== "" && doc.name !== "") ||
            (!doc.link && doc.name !== "")
          );
        }),
      };
      for await (const documentFile of localConfig.documents ?? []) {
        if (
          !documentFile.link &&
          !!documentFile.file &&
          !documentFile.fileEntry
        ) {
          const fileEntry: FileEntry | undefined = await uploadFileForProtocol(
            documentFile.file as File,
            localConfig.id ?? "",
            user.id,
            FileType.DOCUMENT,
            axios
          );
          if (fileEntry) {
            documentFile.fileEntry = fileEntry;
            documentFile.file = undefined;
          }
        }
      }
      success = await updateProtocol(
        axios,
        {
          ...localConfig,
          documents: [
            ...(localConfig.documents?.map((doc) => {
              return {
                ...doc,
                file: undefined,
              };
            }) || []),
          ],
        },
        user.id
      );

      if (success && !!localConfig) {
        toggleEditMode(false);
        await loadFilesForDocuments();
        generateNotificationWithTranslations("success");
      } else {
        generateNotificationWithTranslations("warning");
      }
    } catch (err) {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * Helper to load all documents of a protocol
   */
  const loadFilesForDocuments = async (): Promise<void> => {
    if (!axios || !user?.id) return;
    const allProtocolDocuments: ProtocolDocument[] = [
      ...(localProtocol.documents ?? []),
    ];
    for await (const document of allProtocolDocuments) {
      if (!document.link) {
        const documentFile: File | undefined = await downloadFileForProtocol(
          location.state?.protocol?.id ?? "",
          document.fileEntry!,
          axios,
          undefined,
          document.id
        );
        if (documentFile) {
          document.file = documentFile;
        }
      }
    }

    toggleLoading(false);
    setLocalProtocol({
      ...localProtocol,
      documents: [...allProtocolDocuments],
    });
  };

  /**
   * Helper to delete a document from a protocol
   */
  const handleProtocolDocumentDeletion = async (
    documentId: string
  ): Promise<void> => {
    if (!axios || !user?.id) return;
    const success = await deleteProtocolDocument(
      localProtocol.id || "",
      documentId,
      axios
    );
    if (success) {
      generateNotificationWithTranslations("success");
      setLocalProtocol((oldProtocol) => ({
        ...oldProtocol,
        documents:
          oldProtocol?.documents?.filter((doc) => doc.id !== documentId) || [],
      }));
    } else {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * Helper to prepare export of current viewing protocol
   */
  const exportSingleSelectedProtocol = async (): Promise<void> =>
    exportSingleProtocol(
      axios,
      user?.companyId!,
      user?.id!,
      localProtocol.id ?? ""
    ).then(downloadCsv);
  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.PROTOCOL)}
      title={t("protocol.title")}
    >
      <div className="protocol-detail">
        {editMode ? (
          <>
            <div className="protocol-detail__edit-header">
              <h2>{`${t("protocol.config.edit.title")}: ${
                localProtocol.name
              }`}</h2>
              <LeftIcon onClick={() => toggleEditMode(false)} />
            </div>
            <div className="protocol-edit__input-container">
              <EditProtocolFieldForm
                protocol={localProtocol}
                setProtocol={setLocalProtocol}
                onChangeProtocol={(partialInput: Partial<ProtocolInput>) =>
                  editPartialInput(partialInput)
                }
                onChangeProtocolDocument={(
                  partialDocument: Partial<ProtocolDocument>
                ) => editPartialDocument(partialDocument)}
                handleUpdateOfProtocolConfig={handleUpdateOfProtocolConfig}
                handleProtocolDocumentDeletion={(documentId: string) =>
                  handleProtocolDocumentDeletion(documentId)
                }
                toggleEditMode={() => toggleEditMode(false)}
              />
            </div>
          </>
        ) : (
          <>
            <div className="protocol-detail__header">
              {viewMode ? (
                <>
                  <h2>{`${t("protocol.fill.filledBy")}: ${
                    localProtocol.user?.firstname
                  } ${localProtocol.user?.lastname}`}</h2>
                  <h2>{`${t("protocol.fill.filledAt")}: ${
                    new Date(localProtocol.createDate).toLocaleDateString() ||
                    ""
                  }`}</h2>
                  <LeftIcon onClick={() => history.goBack()} />
                </>
              ) : (
                <>
                  {!!localProtocol.slugId && (
                    <div className="protocol-detail__qr-wrapper">
                      <QRCode value={localProtocol.slugId} />
                    </div>
                  )}
                  <div className="protocol-detail__title-wrapper">
                    <h2>{`${t("protocol.fill.title")}: ${
                      localProtocol.name
                    }`}</h2>
                    <p className="protocol-detail__slug">
                      {`${t("protocol.fill.slugId")}: ${localProtocol.slugId}`}
                    </p>
                  </div>

                  {isUserAllowedToDoThis(
                    UserAction.VIEW_FILLED_PROTOCOL,
                    user
                  ) && (
                    <ProtocolIcon
                      onClick={() => {
                        history.push("/protocol/filled", {
                          originId: localProtocol.id,
                        });
                      }}
                    />
                  )}
                  {!localProtocol?.originId &&
                    isUserAllowedToDoThis(
                      UserAction.EDIT_PROTOCOL_CONFIG,
                      user
                    ) && (
                      <EditIcon
                        className="edit-icon"
                        onClick={() => toggleEditMode(true)}
                      />
                    )}
                  {!localProtocol?.originId &&
                    !!localProtocol.id &&
                    isUserAllowedToDoThis(
                      UserAction.EXPORT_PROTOCOL_CONFIG,
                      user
                    ) && (
                      <DownloadIcon
                        className="download-icon"
                        onClick={() => exportSingleSelectedProtocol()}
                      />
                    )}
                </>
              )}
            </div>
            <div className="protocol-detail__fields export">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  !viewMode && sendFilledOutProtocol();
                }}
                className="protocol-detail__form"
              >
                {!isLoading ? (
                  <>
                    {!viewMode && (
                      <div className="protocol-detail__document-wrapper">
                        {!!localProtocol.documents &&
                          localProtocol.documents.length > 0 &&
                          localProtocol.documents.map((doc) => (
                            <div className="protocol-detail__document">
                              <DocumentIcon />
                              <a
                                href={
                                  doc.file
                                    ? URL.createObjectURL(doc.file)
                                    : doc.linkContent || ""
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {doc.name}
                              </a>
                            </div>
                          ))}
                      </div>
                    )}
                    {localProtocol.inputs?.length > 0 ? (
                      <>
                        {localProtocol.inputs?.map((input, index) => (
                          <div
                            key={index}
                            className={[
                              "protocol-detail__input",
                              `${input.inputType}`,
                              `${input.priority}`,
                            ].join(" ")}
                          >
                            {getCorrectElementForProtocolInput(
                              input,
                              (value) => handleProtocolInput(value, input.id),
                              viewMode,
                              (protocolInputId: string) => {
                                setProtocolFieldId(protocolInputId);
                                toggleBarcodeScanner(true);
                              }
                            )}
                          </div>
                        ))}
                        {localProtocol.summaryEnabled && (
                          <div className="protocol-detail__summary">
                            <h3>{t("protocol.fill.summary")}</h3>
                            <DropdownComponent
                              disabled={viewMode}
                              options={Object.keys(ProtocolSummaryType).map(
                                (key) => ({
                                  value: key,
                                  label: t(`protocol.fill.summaryTypes.${key}`),
                                })
                              )}
                              selectedOption={localProtocol.summaryType || ""}
                              onChange={(option) =>
                                setLocalProtocol((oldProtocol) => ({
                                  ...oldProtocol,
                                  summaryType:
                                    option.value as ProtocolSummaryType,
                                }))
                              }
                              required
                            />
                            <TextInputComponent
                              disabled={viewMode}
                              value={localProtocol.summary || ""}
                              onChange={(value) =>
                                setLocalProtocol((oldProtocol) => ({
                                  ...oldProtocol,
                                  summary: value,
                                }))
                              }
                              type="multiline"
                              label={t("protocol.fill.summaryText")}
                            />
                          </div>
                        )}
                        {viewMode ? (
                          <div className="protocol-detail__button-container">
                            {localProtocol && (
                              <ProtocolExportView protocol={localProtocol} />
                            )}
                          </div>
                        ) : (
                          <div className="protocol-detail__button-container">
                            {localProtocol.inputs?.length > 0 && (
                              <ButtonComponent
                                title={t("general.buttons.send")}
                                type="submit"
                                disabled={!formValid}
                              />
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="protocol-detail__no-inputs">
                        {t("protocol.fill.noInputs")}
                      </div>
                    )}
                  </>
                ) : (
                  <LoaderComponent />
                )}
              </form>
            </div>
          </>
        )}
        <PopupComponent
          toggleOpen={() => toggleBarcodeScanner(!bardcodeScannerOpen)}
          isOpen={bardcodeScannerOpen}
        >
          <BarcodeScannerComponent
            width={"80%"}
            height={"80%"}
            onError={(err) => console.log(err)}
            onUpdate={(err, result) => {
              if (result) {
                handleProtocolInput(result.getText(), protocolFieldId);
                setProtocolFieldId("");
                toggleBarcodeScanner(false);
              } else return;
            }}
          />
        </PopupComponent>
      </div>
    </LayoutComponent>
  );
};

export default ProtocolDetailPage;
