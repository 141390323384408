import { isEqual } from "lodash";
import { useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../../pages/App";
import { useAxios } from "../AxiosUtil";
import { ShopfloorBoardConfiguration } from "./SfBoard.types";

/**
 * SWR Hook - to fetch all the latest shopfloorboards
 * @returns a list of {@link ShopfloorBoardConfiguration} when successful, else returns an empty list
 */
export const useSimpleShopfloorBoardConfiguration = (): {
  shopfloorBoardConfigurations: ShopfloorBoardConfiguration[];
  isLoading: boolean;
  isError: any;
  mutate(shopfloorBoardConfigurations: ShopfloorBoardConfiguration[]): void;
} => {
  const { user } = useContext(UserContext);
  const { axios, fetcher } = useAxios();
  const { data, error, mutate } = useSWR(
    !!axios && user
      ? `/data/config/sfboard/company/simple/group/?companyId=${
          user.companyId
        }&userId=${user.id!}`
      : null,
    fetcher,
    {
      compare: (
        oldShopfloorBoardConfigurations:
          | ShopfloorBoardConfiguration[]
          | undefined,
        newShopfloorBoadConfigurations:
          | ShopfloorBoardConfiguration[]
          | undefined
      ) =>
        isEqual(
          oldShopfloorBoardConfigurations,
          newShopfloorBoadConfigurations
        ),
    }
  );

  return {
    shopfloorBoardConfigurations: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
