import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RetractableComponent,
  TextInputComponent,
} from "sfm-component-library";
import {
  WidgetComponent,
  WidgetConfigComponent,
  WidgetType,
} from "../../../utils/user/Dashboard.types";
import dayjs from "dayjs";

/**
 * Time Widget whichs uses moment to format the desired time
 */
export const TimeWidget: WidgetComponent<WidgetType.TIME> = ({ format }) => {
  const [time, setTime] = useState<string>(
    dayjs().format(format ?? "HH:mm:ss")
  );

  /**
   * Automatically update the time via an interval.
   * If milliseconds are detected then the interval
   * is running every 11ms instead of every 1s
   */
  useEffect(() => {
    const localFormat = format ?? "HH:mm:ss";

    setTime(dayjs().format(localFormat));

    const intr = setInterval(
      () => {
        setTime(dayjs().format(localFormat));
      },
      localFormat.includes("SSS") ? 11 : 1000
    );

    return () => clearInterval(intr);
  }, [format]);

  return (
    <div className="time-widget">
      <h2>{time}</h2>
    </div>
  );
};

export const TimeWidgetConfig: WidgetConfigComponent<WidgetType.TIME> = ({
  info,
  options,
  mutateInfo,
  mutateOptions,
}) => {
  const { t } = useTranslation();
  const now = dayjs();

  return (
    <>
      <p>{t("dashboard.edit.color")}</p>
      <TextInputComponent
        type="color"
        value={info.color}
        onChange={(color) => mutateInfo({ color })}
      />
      <p>{t("dashboard.widget.time.format")}</p>
      <TextInputComponent
        type="text"
        value={options?.format ?? "HH:mm:ss"}
        onChange={(format) => mutateOptions({ format })}
      />
      <p className="time-widget--demo">
        {t("dashboard.widget.time.example")}
        {": "}
        {dayjs().format(options?.format ?? "HH:mm:ss")}
      </p>
      <RetractableComponent
        title={t("dashboard.widget.time.formatRetractable")}
      >
        <table className="time-widget--table">
          <tr>
            {(
              t("dashboard.widget.time.formatTableHeading", {
                returnObjects: true,
              }) as string[]
            ).map((name) => (
              <th>{name}</th>
            ))}
          </tr>
          {(
            t("dashboard.widget.time.formatDetails", {
              returnObjects: true,
            }) as { format: string; desc: string }[]
          ).map(({ format, desc }) => (
            <tr>
              <td>
                <b>{format}</b>
              </td>
              <td>{now.format(format)}</td>
              <td>{desc}</td>
            </tr>
          ))}
        </table>
        <p>{t("dashboard.widget.time.formatEscape")}</p>
      </RetractableComponent>
    </>
  );
};
