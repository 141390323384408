import React, { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CheckboxComponent,
  PopupComponent,
  TextInputComponent,
} from "sfm-component-library";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../utils/GeneralUtils";
import { updateGroupProtocol, useGroups } from "../../utils/group/Group.utils";
import { UserAction } from "../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../utils/user/User.utils";
import { GroupSelect } from "../administration/groupselect/GroupSelect";
import DeletePopUp from "../deletepopup/DeletePopUp";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/delete.svg";

import {
  Protocol,
  createEmptyProtocol,
} from "../../utils/protocol/Protocol.types";
import {
  createProtocol,
  deleteProtocol,
  updateProtocol,
} from "../../utils/protocol/Protocol.axios";
import "./EditProtocolConfigPopUp.styles.scss";

export interface EditProtocolConfigPopUpProps {
  onSubmit(newConfig: Protocol): void;
  onDelete?(): void;
  configToEdit?: Protocol;
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

const EditProtocolConfigPopUp: FC<EditProtocolConfigPopUpProps> = ({
  configToEdit,
  setIsOpen,
  onSubmit,
  onDelete,
  isOpen,
}) => {
  const { user } = useContext(UserContext);
  const [config, setConfig] = useState<Protocol | undefined>(configToEdit);
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [deleteProtocolIsLoading, toggleDeleteProtocolLoading] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { axios } = useAxios();
  const groups = useGroups();

  /**
   * sets config, when isOpen, user or configToEdit, is changed
   */
  useEffect(() => {
    if (isOpen && user) {
      setConfig(configToEdit || createEmptyProtocol(user));
    }
  }, [isOpen, user, configToEdit]);

  /**
   * handles submit, updates Protocol, if configToEdit exist, else creates new Protocol
   */
  const handleSubmit = async (
    evt: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    evt.preventDefault();
    if (!config) return;
    try {
      let success = false;
      let localConfig: Protocol | null = {
        ...config,
        inputs: config.inputs.filter(
          (input) => !!input.name && input.name !== ""
        ),
      };

      if (configToEdit) {
        success = await updateProtocol(axios, localConfig, user!.id!);
      } else {
        localConfig = await createProtocol(axios, localConfig, user!.id!);
        success = true;
      }

      if (success && !!localConfig) {
        success = await updateGroupProtocol(
          axios,
          localConfig.id ?? "",
          localConfig.groups
        );
      }

      if (success && !!localConfig) {
        generateNotificationWithTranslations("success");
        onSubmit(localConfig);
      } else {
        generateNotificationWithTranslations("warning");
      }
    } catch (err) {
      generateNotificationWithTranslations("warning");
    }
  };

  /**
   * handles delete Config, creates Notification
   */
  const handleDelete = (): void => {
    toggleDeleteProtocolLoading(true);
    deleteProtocol(configToEdit!.id!, axios).then((success) => {
      if (success) {
        generateNotificationWithTranslations("success");
        onDelete?.();
      } else {
        generateNotificationWithTranslations("warning");
      }
      toggleDeleteProtocolLoading(false);
      setOpenDeletePopUp(false);
    });
  };

  return config ? (
    <>
      <PopupComponent
        isOpen={isOpen}
        toggleOpen={setIsOpen}
        footerButtons={[
          {
            title: t("general.buttons.cancel"),
            onClick: () => {
              setIsOpen(false);
              setConfig(undefined);
            },
            borderColor: "black",
            className: "color-white",
          },
          {
            title: t("general.buttons.save"),
            borderColor: "#A9FAA2",
            type: "submit",
            form: "protocol-edit-form",
          },
        ]}
      >
        <form id="protocol-edit-form" onSubmit={handleSubmit}>
          <div className={"protocol-edit__header"}>
            <h2>
              {t(`protocol.config.${configToEdit ? "edit" : "create"}.title`)}
            </h2>
            {isUserAllowedToDoThis(UserAction.DELETE_PROTOCOL_CONFIG, user) &&
              configToEdit && (
                <ButtonComponent
                  title={
                    <div className="protocol-edit__header__button-content">
                      <p>{t("general.buttons.delete")}</p>
                      <DeleteIcon />
                    </div>
                  }
                  borderColor="#F86B6B"
                  type="button"
                  onClick={() => setOpenDeletePopUp(true)}
                />
              )}
          </div>
          <div>
            <TextInputComponent
              label={t("protocol.config.edit.protocolName")}
              value={config.name || ""}
              onChange={(newValue) => setConfig({ ...config, name: newValue })}
            />
            <TextInputComponent
              label={t("protocol.config.edit.color")}
              value={config.color || ""}
              onChange={(newValue) => setConfig({ ...config, color: newValue })}
              type="color"
            />

            <hr />
            <p>{t("administration.group.title")}</p>
            <GroupSelect
              assignedGroups={config.groups}
              groups={groups ?? []}
              onUpdate={(newGroups) => {
                setConfig({ ...config, groups: newGroups });
              }}
              dropdownPortal={document.body}
            />
            <CheckboxComponent
              checked={config.showGroupsOnly ?? false}
              onCheck={(showGroupsOnly) =>
                setConfig({ ...config, showGroupsOnly })
              }
              value={t("administration.group.showGroupsOnly")}
            />
          </div>
        </form>
      </PopupComponent>
      <DeletePopUp
        isOpen={openDeletePopUp}
        toggleOpen={setOpenDeletePopUp}
        onDelete={handleDelete}
        deleteIsLoading={deleteProtocolIsLoading}
        content={<p>{t("protocol.config.delete")}</p>}
      />
    </>
  ) : (
    <></>
  );
};

export default EditProtocolConfigPopUp;
