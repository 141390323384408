import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PopupComponent } from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { generateNotificationWithTranslations } from "../../../utils/GeneralUtils";
import { ShopfloorBoardConfiguration } from "../../../utils/sfboard/SfBoard.types";
import {
  deleteTaskForShopfloorBoard,
  saveTaskForShopfloorBoard,
  updateTaskForShopfloorBoard,
} from "../../../utils/sfboard/SfBoard.utils";
import {
  createEmptyTask,
  FileEntry,
  FileType,
  Task,
} from "../../../utils/tasks/Tasks.types";
import {
  convertTaskDates,
  deleteAllRemovedFiles,
  downloadFile,
  postAllFiles,
} from "../../../utils/tasks/TasksUtil";
import { User } from "../../../utils/user/User.types";
import TaskCreationEdit from "./TaskCreationEdit";

interface TaskCreationPopupProps {
  isOpen: boolean;
  toogleOpenState(state: boolean): void;
  setTask(task: Task): void;
  users: User[];
  sfConfig?: ShopfloorBoardConfiguration;
  task?: Task;
  columnId?: string;
  onDelete?(): void;
}

const TaskCreationPopup: React.FC<TaskCreationPopupProps> = ({
  isOpen,
  toogleOpenState,
  setTask,
  sfConfig,
  users,
  task,
  columnId,
  onDelete,
}) => {
  const { user } = useContext(UserContext);
  const { axios } = useAxios();
  const { t } = useTranslation();
  const [localTask, setLocalTask] = useState<Task>(
    task ||
      createEmptyTask(sfConfig!.id!, sfConfig!.companyId, user!.id!, columnId)
  );
  const [popupContentElement, setPopupContentElement] = useState<HTMLElement>();
  const [{ imageFiles, documentFiles }, setFiles] = useState<{
    imageFiles: File[];
    documentFiles: File[];
  }>({ imageFiles: [], documentFiles: [] });
  const [isLoading, setLoading] = useState<boolean>(false);

  /**
   * set initially the link to the scrollable content in the popup
   */
  useEffect(() => {
    setPopupContentElement(document.getElementById("popup-component-content")!);
  }, [isOpen]);

  /**
   * if task already exists and image does not exist, loads image files attaches it to the fileEntry
   */
  useEffect(() => {
    if (task) {
      Promise.all(
        task.fileEntries.map((taskFile) => {
          if (taskFile.file) {
            return taskFile.file;
          }
          if (taskFile.type === FileType.IMAGE) {
            return downloadFile(task.id!, taskFile.fileName, axios);
          }
          return undefined;
        })
      ).then((receivedFiles) => {
        const fileEntriesWithImages: FileEntry[] = task.fileEntries.map(
          (fileEntry) => {
            const receivedFile: File | undefined = receivedFiles.find(
              (fileToFind) => fileToFind?.name === fileEntry.fileName
            );
            return { ...fileEntry, file: receivedFile };
          }
        );
        setLocalTask({
          ...localTask,
          fileEntries: fileEntriesWithImages,
        });
      });
    }
    // eslint-disable-next-line
  }, [axios]);

  /**
   * Helper to save task on server, saves all files that are added to the task, deletes all files, that were removed and closes the popup
   */
  const handleSaveTask = (): void => {
    setLoading(true);
    if (task) {
      updateTaskForShopfloorBoard(localTask, axios)
        .then((success) => {
          if (!success) {
            generateNotificationWithTranslations("warning");
            return;
          }
          const fileAddPromise = postAllFiles(
            imageFiles,
            documentFiles,
            task.id!,
            user!.id!,
            axios
          );
          const fileDeletePromise = deleteAllRemovedFiles(
            localTask.fileEntries,
            task.fileEntries,
            task.id!,
            axios
          );
          return Promise.all([fileAddPromise, fileDeletePromise]);
        })
        .then((response) => {
          if (!response) {
            generateNotificationWithTranslations("warning");
            return;
          }
          toogleOpenState(false);
          generateNotificationWithTranslations("success");
          setTask({
            ...localTask,
            fileEntries: [...localTask.fileEntries, ...response[0]],
          });
          setLoading(false);
        });
    } else {
      saveTaskForShopfloorBoard(localTask, axios).then((newTask) => {
        if (!newTask) {
          generateNotificationWithTranslations("warning");
          return;
        }
        return postAllFiles(
          imageFiles,
          documentFiles,
          newTask.id!,
          user!.id!,
          axios
        ).then((fileEntries) => {
          if (!fileEntries) {
            generateNotificationWithTranslations("warning");
            return;
          }
          setLocalTask(
            createEmptyTask(sfConfig!.id!, sfConfig!.companyId, user!.id!)
          );
          toogleOpenState(false);
          generateNotificationWithTranslations("success");
          setTask({
            ...convertTaskDates(newTask),
            fileEntries: [...newTask.fileEntries, ...fileEntries],
          });
          setLoading(false);
        });
      });
    }
  };

  /**
   * handle Task deletion
   */
  const handleDeleteTask = async (): Promise<void> => {
    setLoading(true);
    const taskDeleted = await deleteTaskForShopfloorBoard(task!.id!, axios);
    if (taskDeleted) {
      onDelete?.();
      generateNotificationWithTranslations("success");
    } else {
      generateNotificationWithTranslations("warning");
    }
    setLoading(false);
  };

  return (
    <PopupComponent
      onScroll={() => {
        setPopupContentElement(
          document.getElementById("popup-component-content")!
        );
      }}
      toggleOpen={toogleOpenState}
      isOpen={isOpen}
      footerButtons={[
        {
          isLoading: isLoading,
          title: t("general.buttons.cancel"),
          onClick: () => toogleOpenState(false),
          borderColor: "black",
          className: "color-white",
        },
        {
          isLoading: isLoading,
          title: t("general.buttons.save"),
          borderColor: "#A9FAA2",
          form: "task-creation-from-input",
        },
      ]}
      size="large"
    >
      <TaskCreationEdit
        task={localTask}
        columnId={columnId || ""}
        setTask={setLocalTask}
        sfConfig={sfConfig}
        users={users}
        isLoading={isLoading}
        popup={popupContentElement}
        onDelete={handleDeleteTask}
        setFiles={setFiles}
        onSave={handleSaveTask}
      />
    </PopupComponent>
  );
};

export default TaskCreationPopup;
