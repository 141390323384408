import { useContext, useState } from "react";
import { LayoutComponent, LoaderComponent } from "sfm-component-library";
import { Dashboard } from "../components/dashboard/Dashboard";
import { NavigationConfiguration } from "../utils/navigation/NavigationConfiguration";
import { PageType } from "../utils/navigation/NavigationConfiguration.types";
import { UserContext } from "./App";

interface DashboardPageProps {}

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const { user, setUser } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);

  return (
    <LayoutComponent {...NavigationConfiguration(PageType.DASHBOARD)}>
      {!!user ? (
        <Dashboard
          user={user}
          setUser={setUser}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      ) : (
        <LoaderComponent />
      )}
    </LayoutComponent>
  );
};
export default DashboardPage;
