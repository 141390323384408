import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DropdownComponent, TextInputComponent } from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { convertToDateForInput } from "../../../utils/GeneralUtils";
import { ShopfloorBoardConfiguration } from "../../../utils/sfboard/SfBoard.types";
import {
  createQuarterCircle,
  generateColumnsOptions,
  generateOptionsForUsers,
} from "../../../utils/sfboard/SfBoard.utils";
import { Task } from "../../../utils/tasks/Tasks.types";
import { User, UserAction } from "../../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../../utils/user/User.utils";

interface TaskCreationInfoTabProps {
  task: Task;
  onTaskChange(task: Task): void;
  popupContentElement?: HTMLElement;
  users: User[];
  sfConfig?: ShopfloorBoardConfiguration;
  editColumnDisabled?: boolean;
}

export const TaskCreationInfoTab: FC<TaskCreationInfoTabProps> = ({
  task,
  onTaskChange,
  popupContentElement,
  users,
  sfConfig,
  editColumnDisabled,
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <div className="task-edit__basis-information__wrapper">
      <div className="task-edit__basis-information">
        <TextInputComponent
          required
          onChange={(value) => onTaskChange({ ...task, title: value })}
          value={task.title}
          label={t("shopfloorboard.task.title")}
          disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
        />
        <TextInputComponent
          required
          onChange={(value) => onTaskChange({ ...task, description: value })}
          value={task.description}
          type="multiline"
          label={t("shopfloorboard.task.description")}
          disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
        />
        <TextInputComponent
          onChange={(value) => onTaskChange({ ...task, action: value })}
          value={task.action}
          type="multiline"
          label={t("shopfloorboard.task.action")}
          disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
        />
      </div>
      <div className="task-edit__side-information">
        <div className="task-edit__pdca-wrapper">
          <TextInputComponent
            type="number"
            min={0}
            max={4}
            onChange={(value) =>
              onTaskChange({
                ...task,
                pdcaBallStatus: Number(value),
              })
            }
            value={task.pdcaBallStatus}
            label={t("shopfloorboard.task.pdcaStatus")}
            disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
          />
          <div>{createQuarterCircle(task.pdcaBallStatus)}</div>
        </div>
        <TextInputComponent
          type="number"
          min={0}
          max={99}
          onChange={(value) =>
            onTaskChange({ ...task, priority: Number(value) })
          }
          value={task.priority}
          label={t("shopfloorboard.task.priority")}
          disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
        />
        <TextInputComponent
          type="date"
          onChange={(value) =>
            onTaskChange({ ...task, targetDate: new Date(value) })
          }
          value={convertToDateForInput(task.targetDate)}
          label={t("shopfloorboard.task.targetDate")}
          disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
        />
        <div>
          {/* User selection Dropdown */}
          <DropdownComponent
            portal={popupContentElement}
            label={t("shopfloorboard.task.respUser")}
            selectedOption={task.respUserId}
            onChange={(option) =>
              onTaskChange({ ...task, respUserId: option.value })
            }
            options={generateOptionsForUsers(users)}
            disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
          />
          {sfConfig?.columns && (
            <DropdownComponent
              portal={popupContentElement}
              label={t("shopfloorboard.task.column")}
              required
              selectedOption={task.shopfloorBoardColumnId}
              onChange={(columnOption) =>
                onTaskChange({
                  ...task,
                  shopfloorBoardColumnId: columnOption.value,
                })
              }
              options={generateColumnsOptions(sfConfig.columns)}
              disabled={
                !isUserAllowedToDoThis(UserAction.EDIT_TASK, user) ||
                editColumnDisabled
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
