import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckboxComponent,
  LayoutComponent,
  LoaderComponent,
  TableComponent,
  TextInputComponent,
} from "sfm-component-library";
import { useAxios } from "../../utils/AxiosUtil";
import { loadAllEnergySpecificationsInRange } from "../../utils/energy/Energy.utils";
import { NavigationConfiguration } from "../../utils/navigation/NavigationConfiguration";
import { PageType } from "../../utils/navigation/NavigationConfiguration.types";
import { convertToDateForInput } from "../../utils/GeneralUtils";
import "../../styles/EnergyManagementPredictionPage.scss";
import useSWR from "swr";
const EnergyManagementPredictionPage: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();

  const [isLoaded, toggleLoaded] = useState<boolean>(false);
  const [selectedIndividualTime, setSelectedIndividualTime] = useState<{
    start: Date;
    end: Date;
  }>({ end: new Date(), start: new Date() });
  const [isTimeFrame, toggleTimeFrame] = useState<boolean>(false);

  const energyItemsIndividualFrame = useSWR(
    !axios ? null : "energyItemsIndividualFrame",
    () =>
      loadAllEnergySpecificationsInRange(axios, {
        start: new Date(
          selectedIndividualTime.start.getFullYear(),
          selectedIndividualTime.start.getMonth(),
          selectedIndividualTime.start.getDate(),
          -1,
          0 - new Date().getTimezoneOffset(),
          0,
          0
        ),
        end: isTimeFrame
          ? new Date(
              selectedIndividualTime.end.getFullYear(),
              selectedIndividualTime.end.getMonth(),
              selectedIndividualTime.end.getDate(),
              23,
              59,
              59,
              999
            )
          : new Date(
              selectedIndividualTime.start.getFullYear(),
              selectedIndividualTime.start.getMonth(),
              selectedIndividualTime.start.getDate(),
              23,
              59 - new Date().getTimezoneOffset(),
              59,
              999
            ),
      })
  );

  /**
   * checks whether selected filter is individual time frame and if dates have been set
   */
  useEffect(() => {
    if (!energyItemsIndividualFrame.data || !selectedIndividualTime) return;
    energyItemsIndividualFrame.mutate();
  }, [selectedIndividualTime, energyItemsIndividualFrame]);

  /**
   * If all necessaty data is loaded, toggle isLoaded to true
   */
  useEffect(() => {
    if (
      !energyItemsIndividualFrame.data ||
      energyItemsIndividualFrame.data.length === 0
    )
      return;
    toggleLoaded(true);
  }, [energyItemsIndividualFrame.data]);
  /**
   * Generate the needed energy specification entries for the table component
   */
  const dataTableRows = useMemo(() => {
    if (
      !energyItemsIndividualFrame.data ||
      energyItemsIndividualFrame.data.length === 0
    )
      return [
        {
          content: [t("energy.prediction.noData")],
          id: "0",
        },
      ];

    return (
      energyItemsIndividualFrame.data.map((item, itemIndex) => {
        let averagePrediction = "-";
        if (item.predictedValues && item.predictedValues.length > 0) {
          averagePrediction = (
            item.predictedValues.reduce((a, b) => a + b, 0) /
            item.predictedValues.length /
            1000
          ).toFixed(4);
        }

        return {
          content: [
            new Date(item.timestamp).toLocaleString("de-DE"),
            `${Number(item.value / 1000).toFixed(4)} €`,
            `${averagePrediction} €`,
          ],
          id: `${itemIndex}`,
        };
      }) ?? [
        {
          content: [t("energy.prediction.noData")],
          id: "0",
        },
      ]
    );
  }, [energyItemsIndividualFrame.data, t]);
  return (
    <LayoutComponent
      {...NavigationConfiguration(PageType.ENERGY)}
      title={t("energy.title")}
    >
      {isLoaded ? (
        <div className="energy-prediction-page--wrapper">
          <div className="energy-prediction-page--header">
            <h3>
              {isTimeFrame
                ? t("energy.prediction.selectTimeFrime")
                : t("energy.prediction.pointInTime")}
            </h3>
            <div className="energy-prediction-page--wrapper-menu">
              <TextInputComponent
                type="date"
                placeholder="Von"
                value={convertToDateForInput(selectedIndividualTime.start)}
                onChange={(newValue) => {
                  try {
                    const parsedDate = new Date(newValue);
                    setSelectedIndividualTime({
                      ...selectedIndividualTime,
                      start: parsedDate,
                    });
                  } catch (e) {
                    console.error("Error parsing date");
                  }
                }}
              />
              {isTimeFrame && (
                <TextInputComponent
                  type="date"
                  placeholder="Bis"
                  value={convertToDateForInput(selectedIndividualTime.end)}
                  onChange={(newValue) => {
                    try {
                      const parsedDate = new Date(newValue);
                      setSelectedIndividualTime({
                        ...selectedIndividualTime,
                        end: parsedDate,
                      });
                    } catch (e) {
                      console.error("Error parsing date");
                    }
                  }}
                />
              )}
              <CheckboxComponent
                onCheck={() => toggleTimeFrame(!isTimeFrame)}
                value={t("energy.prediction.timeFrame")}
                checked={isTimeFrame}
              />
            </div>
          </div>
          <TableComponent
            showIndex
            listEntries={dataTableRows}
            customTag={[{ flex: 1 }, { flex: 1 }, { flex: 1 }]}
            header={t("energy.prediction.tableHeader", {
              returnObjects: true,
            })}
          />
        </div>
      ) : (
        <LoaderComponent />
      )}
    </LayoutComponent>
  );
};

export default EnergyManagementPredictionPage;
