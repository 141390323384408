import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CameraInputComponent } from "sfm-component-library";
import { UserContext } from "../../../pages/App";
import { FileEntry, FileType } from "../../../utils/tasks/Tasks.types";
import { UserAction } from "../../../utils/user/User.types";
import { isUserAllowedToDoThis } from "../../../utils/user/User.utils";
import { ReactComponent as CameraIcon } from "./../../../assets/icons/camera_add.svg";
import { ReactComponent as NoCameraIcon } from "./../../../assets/icons/no_camera.svg";
interface TaskCreationMediaTabProps {
  taskId?: string;
  newImageFiles: File[];
  taskFileEntries: FileEntry[];
  setNewImageFiles(imageFiles: File[]): void;
  deleteFileEntry(fileEntry: FileEntry[]): void;
}

export const TaskCreationMediaTab: FC<TaskCreationMediaTabProps> = ({
  taskFileEntries,
  newImageFiles,
  setNewImageFiles,
  deleteFileEntry,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [imagefileList, setImagefileList] = useState<File[]>([]);

  /**
   * set imagelist on fileList change
   */
  useEffect(
    () =>
      setImagefileList([
        ...taskFileEntries
          .filter(
            (fileEntry) => fileEntry.type === FileType.IMAGE && !!fileEntry.file
          )
          .map((fileEntry) => fileEntry.file!),
        ...newImageFiles,
      ]),
    [taskFileEntries, newImageFiles]
  );

  return (
    <div className={"task-edit__media"}>
      <CameraInputComponent
        files={imagefileList}
        onNewPictureTaken={(image) =>
          setNewImageFiles([...newImageFiles, image])
        }
        placeholderImage={
          <div className="task-edit__media__wrapper">
            {isUserAllowedToDoThis(UserAction.EDIT_TASK, user) ? (
              <>
                <CameraIcon className="task-edit__media__camera-placeholder" />
                <p>{t("shopfloorboard.task.cameraPlaceholderText")}</p>
              </>
            ) : (
              <NoCameraIcon className="task-edit__media__camera-placeholder" />
            )}
          </div>
        }
        onRemovePicture={(pictureIndex) => {
          if (newImageFiles.includes(imagefileList[pictureIndex])) {
            setNewImageFiles(
              newImageFiles.filter((_, index) => pictureIndex !== index)
            );
          } else {
            deleteFileEntry(
              taskFileEntries.filter(
                (fileEntry) => fileEntry.file !== imagefileList[pictureIndex]
              )!
            );
          }
        }}
        disabled={!isUserAllowedToDoThis(UserAction.EDIT_TASK, user)}
      />
    </div>
  );
};
