import { useMemo } from "react";
import { DropdownComponent, TableComponent } from "sfm-component-library";
import {
  DropdownComponentProps,
  Option,
} from "sfm-component-library/build/DropdownComponent/DropdownComponent.types";
import { RowEntry } from "sfm-component-library/build/TableComponent/TableComponent.types";
import { Group } from "../../../utils/group/Group.types";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/close.svg";
import "./GroupSelect.scss";
import { useTranslation } from "react-i18next";

export interface GroupSelectProps {
  groups: Group[];
  assignedGroups: string[];
  onUpdate(newGroups: string[]): void;
  dropdownPortal?: DropdownComponentProps["portal"];
}

export const GroupSelect: React.FC<GroupSelectProps> = ({
  groups,
  assignedGroups,
  onUpdate,
  dropdownPortal,
}) => {
  const { t } = useTranslation();

  /**
   * Memorize all assigned groups which are included in the available groups.
   */
  const filteredAssignedGroups = useMemo(() => {
    return assignedGroups.filter((group1) =>
      groups.map((group2) => group2.id).includes(group1)
    );
  }, [groups, assignedGroups]);

  /**
   * Memorize selectable groups and maps them to a {@link Option} list.
   */
  const selectableGroups = useMemo<Option[]>(() => {
    return groups
      .filter((item) => !filteredAssignedGroups.includes(item.id))
      .map((item) => ({ label: item.name, value: item.id }));
  }, [groups, filteredAssignedGroups]);

  /**
   * Memorize assigned groups and maps them to a {@link RowEntry} list.
   */
  const assignedGroupEntries = useMemo<RowEntry[]>(() => {
    const localAssignedGroupEntries = filteredAssignedGroups
      .map((groupId) => groups.find((item) => item.id === groupId))
      .map((group) => {
        if (group) {
          return {
            content: [
              group.name,
              <RemoveIcon
                onClick={() =>
                  onUpdate(
                    filteredAssignedGroups.filter(
                      (groupId) => group.id !== groupId
                    )
                  )
                }
              />,
            ],
            id: group.id,
          } as RowEntry;
        } else {
          return undefined;
        }
      })
      .filter((item) => !!item) as RowEntry[];
    if (localAssignedGroupEntries.length === 0) {
      return [
        {
          id: "",
          content: [t("administration.group.emptyShort").toString()],
        },
      ];
    }
    return localAssignedGroupEntries;
  }, [filteredAssignedGroups, groups, onUpdate, t]);

  return (
    <div className="group-select">
      <DropdownComponent
        portal={dropdownPortal}
        onChange={(option) => onUpdate([...assignedGroups, option.value])}
        options={selectableGroups}
        placeholder={t("administration.group.add")}
        selectedOption=""
      />
      <TableComponent
        listEntries={assignedGroupEntries}
        customTag={[{ flex: 3 }, { flex: 1 }]}
      />
    </div>
  );
};
