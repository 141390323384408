import { useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../../pages/App";
import { useAxios } from "../AxiosUtil";
import { EnergyBoard } from "./Energy.types";

/**
 * SWR Hook - to fetch all the latest EnergyBaords
 * @returns a list of {@link EnergyBoard} when successful, else returns an empty list
 */
export const useEnergyBoard = (): {
  EnergyBoards: EnergyBoard[];
  isLoading: boolean;
  isError: any;
  mutate(EnergyBoards: EnergyBoard[]): void;
} => {
  const { user } = useContext(UserContext);
  const { axios, fetcher } = useAxios();
  const { data, error, mutate } = useSWR(
    !!axios && user
      ? `/data/energy/company/group/?companyId=${
          user.companyId
        }&userId=${user.id!}`
      : null,
    fetcher
  );
  return {
    EnergyBoards: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

/**
 * SWR Hook - to fetch correct EnergyBaord for id
 * @returns a {@link EnergyBoard} when successful, else returns an empty list
 */
export const useSingleEnergyBoard = (
  id: string
): {
  energyBoard: EnergyBoard;
  isLoading: boolean;
  isError: any;
  mutate(EnergyBoard: EnergyBoard): void;
} => {
  const { axios, fetcher } = useAxios();
  const { data, error, mutate } = useSWR(
    !!axios && id ? `/data/energy/?configId=${id}` : null,
    fetcher
  );
  return {
    energyBoard: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
